import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default function FrontPage({ handleGetStarted }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" align="center" sx={{ color: "white" }}>
        Tell us what makes your property unique, and you'll receive a
      </Typography>
      <Typography variant="h4" align="center" sx={{ color: "white" }}>
        personalized pricing consultation with your custom quote
      </Typography>

      <Button variant="custom-button" sx={{ mt: 4 }} onClick={handleGetStarted}>
        Get Started
      </Button>
    </Box>
  );
}
