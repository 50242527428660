import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SendIcon from "@mui/icons-material/Send";
import { continueConversation, initiateConversation } from "./FlexiGPTApis";

function FlexiGPTModal({ hotelId }) {
  const [openChatModal, setOpenChatModal] = useState(false);
  const handleOpenChatModal = () => setOpenChatModal(true);
  const handleCloseChatModal = () => {
    setInputText("");
    setGptMessageList([]);
    setConversationList([]);
    setOpenChatModal(false);
  };

  const restartConversation = () => {
    setGptMessageList([]);
    setConversationList([]);
    setInputText("");
    setTextLoader(false);
  };

  const [inputText, setInputText] = useState("");
  const [textLoader, setTextLoader] = useState(false);
  const [fieldLoader, setFieldLoader] = useState(false);
  const [conversationList, setConversationList] = useState([]);
  const [gptMessageList, setGptMessageList] = useState([]);
  const [gptException, setGptException] = useState(false);
  const [startEpoch, setStartEpoch] = useState(null);
  const sendText = async (inputText) => {
    setTextLoader(true);
    //New chat initiate conversation
    if (conversationList?.length === 0) {
      let tempStartEpoch = new Date().getTime();
      setStartEpoch(tempStartEpoch);
      const payload = {
        hotelId: hotelId,
        conversationType: "SalesReport",
        userQuery: inputText,
        startEpoch: tempStartEpoch,
      };
      await initiateConversation(payload)
        .then((res) => handleGPTResponse(res, inputText))
        .then(() => setTextLoader(false))
        .catch((err) => {
          console.log("Something went wrong: ", err);
          setTextLoader(false);
          let convoList = conversationList;
          convoList.push({
            type: "assistant",
            text: "Session timeout! kindly restart session",
          });
          setFieldLoader(true);
        });
    } else {
      const payload = {
        hotelId: hotelId,
        conversationType: "SalesReport",
        messageModel: {
          gptResponse: "",
          hotelId: hotelId,
          messageList: gptMessageList,
        },
        userQuery: inputText,
        startEpoch: startEpoch,
      };
      await continueConversation(payload)
        .then((res) => handleGPTResponse(res, inputText))
        .then(() => setTextLoader(false))
        .catch((err) => console.log("Something went wrong: ", err));
    }
  };
  const handleGPTResponse = (res, input) => {
    if (res?.gptResponse === "") {
      console.log("GPT Response not provided.");
    } else {
      let convoList = conversationList;

      convoList.push({ type: "user", text: input });
      convoList.push({ type: "assistant", text: res?.gptResponse });
      setGptMessageList(res?.messageList);
      setConversationList(conversationList);
    }
    setInputText("");
  };
  return (
    <>
      <div
        className="informationCardWrapper"
        style={{
          height: "auto",
          width: "auto",
          marginTop: "1rem",
        }}
      >
        <div className="d-flex  flex-column mt-4">
          {" "}
          <div
            style={{
              color: "black",
              fontWeight: "500",
              fontSize: "18px",
              marginLeft: "1rem",
            }}
          >
            {" "}
            FlexiGPT
          </div>
          <p
            className="font-weight-bold mt-2 "
            style={{
              color: "gray",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            Make the most out of AI and use the tailormade version of ChatGPT -
            "FlexiGPT".
          </p>
          <div className="d-flex justify-content-center w-100">
            <Button
              variant="outlined"
              className={"supportModalButton"}
              style={{
                width: "20rem",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              startIcon={<QuickreplyIcon />}
              onClick={() => {
                handleOpenChatModal();
              }}
            >
              Try FlexiGPT
            </Button>
          </div>
        </div>
      </div>
      <Modal
        show={openChatModal}
        onHide={handleCloseChatModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton>FlexiGPT</Modal.Header>
        <Modal.Body>
          <Grid container spacing={0}>
            <Grid xs={12} sx={{ height: "25rem", overflowY: "scroll" }}>
              {conversationList?.length === 0 ? (
                <Stack
                  className="lc_conversation"
                  direction={"row"}
                  spacing={2}
                  key={0}
                >
                  <Stack className="lc_avatarWrapper">
                    <Avatar
                      className="lc_avatar"
                      sx={{ backgroundImage: "var(--backgroundImg)" }}
                    >
                      F
                    </Avatar>
                  </Stack>
                  <Stack spacing={1} className="lc_conversationDetails">
                    <Stack
                      direction={"row"}
                      spacing={1}
                      className="lc_nameTimeWrapper"
                    >
                      <Typography className="lc_userName">FlexiGPT</Typography>
                    </Stack>
                    <Typography className="lc_message">
                      {
                        "Hi Hotelier! Welcomet to flexiGPT assistant. You can ask any of the below questions or ask custom questions."
                      }
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Stack className="lc_groupConversations">
                  {conversationList.map((messageData, index) => {
                    return (
                      <Stack
                        className="lc_conversation"
                        direction={"row"}
                        spacing={2}
                        key={index}
                        sx={{ margin: "10px" }}
                      >
                        <Stack className="lc_avatarWrapper">
                          <Avatar
                            className="lc_avatar"
                            sx={{ backgroundImage: "var(--backgroundImg)" }}
                          >
                            {messageData?.type === "user" ? "H" : "F"}
                          </Avatar>
                        </Stack>
                        <Stack spacing={1} className="lc_conversationDetails">
                          <Stack
                            direction={"row"}
                            spacing={1}
                            className="lc_nameTimeWrapper"
                          >
                            <Typography className="lc_userName">
                              {messageData?.type === "user"
                                ? "Hotelier"
                                : "FlexiGPT"}
                            </Typography>
                          </Stack>
                          <Typography
                            className="lc_message"
                            style={{
                              fontWeight:
                                messageData?.text?.includes(
                                  "Session timeout"
                                ) && "bold",
                            }}
                            paragraph={true}
                          >
                            {messageData?.text?.split("\n").map((textSplit) => {
                              return (
                                <p style={{ marginBottom: "5px" }}>
                                  {textSplit}
                                </p>
                              );
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={2}>
            {conversationList?.length === 0 && (
              <>
                <Grid xs={6}>
                  <Button
                    variant="custom-button"
                    endIcon={<SendIcon />}
                    sx={{ margin: "10px" }}
                    onClick={() => {
                      setInputText(
                        "How many rooms per day are available in the coming week?"
                      );
                      sendText(
                        "How many rooms per day are available in the coming week?"
                      );
                    }}
                  >
                    How many rooms per day are available in the coming week?
                  </Button>
                </Grid>
                <Grid xs={6}>
                  <Button
                    variant="custom-button"
                    endIcon={<SendIcon />}
                    sx={{ margin: "10px" }}
                    onClick={() => {
                      setInputText("What's my total reveune for January 2024?");
                      sendText("What's my total reveune for January 2024?");
                    }}
                  >
                    What's my total reveune for January 2024?
                  </Button>
                </Grid>
                {/* <Grid xs={6}>
                  <Button variant="custom-button" endIcon={<SendIcon />}>
                    What's my revenue for the first week of January 2024?
                  </Button>
                </Grid>
                <Grid xs={6}>
                  <Button variant="custom-button" endIcon={<SendIcon />}>
                    When is the booking SFBOOKING_12930_12930 arriving?
                  </Button>
                </Grid> */}
              </>
            )}
          </Grid>
          <TextField
            placeholder="Message FlexiGPT..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendText(inputText);
              }
            }}
            sx={{ width: "100%" }}
            disabled={textLoader || fieldLoader}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => sendText(inputText)}
                    disabled={textLoader || fieldLoader}
                  >
                    {textLoader ? <CircularProgress /> : <SendIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <TextField
            label="message"
            className="input-lg w-100 mt-0"
            placeholder="Message FlexiGPT..."
            onChange={(e) => setInputText(e.target.value)}
            value={inputText}
            name="input_text"
            id="input_text"
          /> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FlexiGPTModal;
