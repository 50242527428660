import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Fab,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  dateFnsFormat,
  getGenderList,
  getIshaGenderList,
  getIshaRelationWithPrimary,
  getNationalityList,
} from "../constants/Calendar-constants";
import { format } from "date-fns";
import moment from "moment";

const NewChildView = (props) => {
  const nationalities = getNationalityList();
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const hotelId = hotel?.hotelId;
  const ishaRelationWithPrimary = getIshaRelationWithPrimary();
  const ishaGenderList = getIshaGenderList(hotelId);
  const genderList = getGenderList();
  const existingChildetail = props?.existingChildDetails[props?.dataIndex];
  const childFormik = useFormik({
    initialValues: {
      childFirstName: existingChildetail?.guestName
        ? existingChildetail?.guestName?.split(" ")[0]
        : "",
      childLastName: existingChildetail?.guestName
        ? existingChildetail?.guestName?.split(" ")[1]
        : "",
      childGender: existingChildetail?.guestGender
        ? existingChildetail?.guestGender
        : "Male",
      childDOB:
        existingChildetail?.guestDOB && existingChildetail?.guestDOB != "NA"
          ? moment(existingChildetail?.guestDOB, "YYYY-MM-DD", false).toDate()
          : "",
      childCountry: existingChildetail?.guestCountry
        ? existingChildetail?.guestCountry
        : "",
      childNationality: existingChildetail?.guestNationality
        ? existingChildetail?.guestNationality
        : "",
      childRelationshipToPrimary: existingChildetail?.guestPrimaryRelation
        ? existingChildetail?.guestPrimaryRelation
        : "Other",
    },
    onSubmit: (values) => {
      let email =
        values.childFirstName.replace(/\s+/g, ".").toLowerCase() +
        hotelId +
        Math.round(Date.now() / 1000) +
        "@example.com";
      let tempChildDOB = "";
      if (values.childDOB != "" && values.childDOB != "NA") {
        tempChildDOB = format(values.childDOB, "yyyy-MM-dd");
      }
      let temp = {
        guestName: values.childFirstName + " " + values.childLastName,
        guestEmail: email,
        guestPhone: "000000000",
        guestGender: values.childGender,
        guestAddress1: "",
        guestAddress2: "",
        guestZipcode: "",
        guestCity: "",
        guestState: "",
        guestCountry: values.childCountry,
        guestNationality: values.childNationality,
        guestPrograms: "",
        guestPrimaryRelation: values.childRelationshipToPrimary,
        guestGovtIdType: "",
        guestGovtIdValue: "",
        guestOccupation: "",
        guestETA: "",
        guestETD: "",
        guestApprovedOverseas: "New",
        guestOCOCheckin: "No",
        guestCategory: "CHILD",
        guestDOB: values.childDOB,
      };
      if (existingChildetail) {
        if (props?.roomIndex != undefined) {
          props.updateChildDetail(temp, props?.roomIndex, props?.dataIndex);
        } else {
          props.updateChildDetail(temp, props?.dataIndex);
        }
      } else {
        if (props?.roomIndex != undefined) {
          props.addChildDetail(temp, props?.roomIndex);
        } else {
          props.addChildDetail(temp);
        }
      }
      // props.addChildDetail(temp);
      props.onClose();
    },
  });
  //Setting the date of birth selection in formik
  const setDateOfBirthSelection = (dob) => {
    try {
      childFormik.setFieldValue("childDOB", dob);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <form
      noValidate
      style={{ height: "auto" }}
      onSubmit={childFormik.handleSubmit}
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1c-content1"
          id="panel1c-header1"
        >
          <div>
            <Typography>Child details</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                required
                id="childFirstName"
                type={"text"}
                label="First name"
                name="childFirstName"
                value={childFormik.values.childFirstName}
                onChange={childFormik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                required
                id="childLastName"
                type={"text"}
                label="Last name"
                name="childLastName"
                value={childFormik.values.childLastName}
                onChange={childFormik.handleChange}
                // onBlur={guestFormik.handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                select
                id="childGender"
                label="Gender"
                name="childGender"
                value={childFormik.values.childGender}
                onChange={(e) => {
                  childFormik.setFieldValue("childGender", e.target.value);
                }}
                fullWidth
              >
                {props?.hotelType === "ISHA"
                  ? ishaGenderList?.length > 0 &&
                    ishaGenderList.map((genVal) => {
                      return (
                        <MenuItem key={genVal} value={genVal}>
                          {genVal}
                        </MenuItem>
                      );
                    })
                  : genderList?.length > 0 &&
                    genderList.map((genVal) => {
                      return (
                        <MenuItem key={genVal} value={genVal}>
                          {genVal}
                        </MenuItem>
                      );
                    })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat={dateFnsFormat}
                  fullWidth
                  disableMaskedInput
                  mask="___ ___, ____"
                  id="childDOB"
                  name="childDOB"
                  onChange={setDateOfBirthSelection}
                  // className={classes.textField}
                  value={childFormik.values.childDOB}
                  label="Date of Birth"
                  InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                  onBlur={childFormik.handleBlur}
                  renderInput={(params) => {
                    params.error = Boolean(childFormik.errors.guestDOB);
                    return (
                      <TextField
                        helperText={childFormik.errors.guestDOB}
                        fullWidth
                        {...params}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {/* <TextField
                id="childNationality"
                label="Nationality"
                name="childNationality"
                type={"text"}
                value={childFormik.values.childNationality}
                onChange={childFormik.handleChange}
                onBlur={childFormik.handleBlur}
                fullWidth
              /> */}
              <Autocomplete
                fullWidth
                renderInput={(params) => {
                  params.inputProps.autoComplete = "new-password";
                  return <TextField {...params} label="Nationality" />;
                }}
                onChange={(eve, val) => {
                  childFormik.setFieldValue("childNationality", val);
                }}
                options={nationalities}
                // options={
                //   isNull(formik.values.nationality) ||
                //   isUndefined(countries[formik.values.country])
                //     ? []
                //     : countries[formik.values.country]
                // }
                value={childFormik.values.childNationality}
              ></Autocomplete>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                id="childCountry"
                label="Country"
                name="childCountry"
                type={"text"}
                value={childFormik.values.childCountry}
                onChange={childFormik.handleChange}
                onBlur={childFormik.handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                select
                id="childRelationshipToPrimary"
                label="Relationship With Primary"
                name="childRelationshipToPrimary"
                value={childFormik.values.childRelationshipToPrimary}
                onChange={(e) => {
                  childFormik.setFieldValue(
                    "childRelationshipToPrimary",
                    e.target.value
                  );
                }}
                fullWidth
              >
                {ishaRelationWithPrimary?.length > 0 &&
                  ishaRelationWithPrimary.map((relation, index) => {
                    return (
                      <MenuItem key={relation} value={relation}>
                        {relation}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Fab
        color="primary"
        variant="extended"
        type="submit"
        style={{ margin: "30px" }}
      >
        Save child detail
      </Fab>
    </form>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(NewChildView);
