import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import CustomReportPdfDoc from "./CustomReportPdfDoc";
import { connect } from "react-redux";
import { Modal, Stack, Box, Typography, Divider } from "@mui/material";
import * as AiIcons from "react-icons/ai";
import { handleSpringDataRequest } from "../../../api/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  borderRadius: "8px",
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

function CustomReportPdf({
  reportColumns = null,
  reportData = null,
  reportName = null,
  data,
  dateRange,
  pinnedRow,
  hotelLogo = null,
  isGroupDashboard = false,
}) {
  const [reportColumn, setReportColumn] = useState(null);
  function getDistinctArray(array, property) {
    if (array) {
      const distinctArray = [];
      const uniqueValues = new Set();

      for (const item of array) {
        if (!uniqueValues.has(item[property])) {
          uniqueValues.add(item[property]);
          distinctArray.push(item);
        }
      }

      return distinctArray;
    }
    return array;
  }

  useEffect(() => {
    // console.log("reportColumn", reportColumns);
    // array distinct by headerName to show the booking time, check-in and check-out time
    const arr = getDistinctArray(reportColumns, "headerName");
    setReportColumn(arr);
  }, [reportColumns]);
  // Modal to display the warning if the columns selected are less than 0 or more than 10
  const [openPdfWarningModal, setOpenPdfWarningModal] = useState(false);
  function handleOpenPdfWarningModal() {
    if (reportColumn?.filter((col) => col?.hide !== true)?.length > 10) {
      setOpenPdfWarningModal(true);
    }
  }
  function handleClosePdfWarningModal() {
    setOpenPdfWarningModal(false);
  }
  // console.log("grp data : ", data);
  // Formatting the hotel address
  function formatHotelAddress() {
    const { hotel_address, hotel_city, hotel_state, hotel_country } =
      data.accessControl.hotel;
    let add = "";
    add += hotel_address;
    add += ", ";
    add += hotel_city;
    add += ", ";
    add += hotel_state;
    add += ", ";
    add += hotel_country;
    return add;
  }

  return (
    <Stack>
      {
        reportColumn && reportData && reportName && (
          // (reportColumn?.filter((col) => col?.hide !== true)?.length >= 0 ?
          <PDFDownloadLink
            document={
              <Document title={reportName}>
                <Page
                  orientation="landscape"
                  size="A4"
                  style={styles.page}
                  wrap
                >
                  <CustomReportPdfDoc
                    reportColumns={reportColumn}
                    reportData={reportData}
                    reportName={reportName}
                    hotelName={!isGroupDashboard ? data.hotelName : null}
                    hotelLogo={!isGroupDashboard ? hotelLogo : null}
                    hotelAddress={
                      !isGroupDashboard ? formatHotelAddress() : null
                    }
                    currency={localStorage.getItem("hotelCurrency")}
                    dateRange={dateRange}
                    pinnedRow={pinnedRow}
                  />
                </Page>
              </Document>
            }
            fileName={`${reportName}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <p
                  style={{
                    fontSize: "15px",
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  Loading report...
                </p>
              ) : (
                <a
                  href={url}
                  style={{ fontSize: "0.8rem" }}
                  rel="noopener"
                  // download={reportName}
                  target="_blank"
                  download={reportName}
                >
                  {window.screen.width > 416 && (
                    <Typography
                      onClick={handleOpenPdfWarningModal}
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "fit-content",
                      }}
                    >
                      Export as PDF
                    </Typography>
                  )}
                </a>
              )
            }
          </PDFDownloadLink>
        )
        // : (
        //   <Typography
        //     style={{
        //       fontSize: "15px",
        //       textDecoration: "none",
        //       color: "black",
        //       cursor: "pointer",
        //     }}
        //     onClick={handleOpenPdfWarningModal}
        //   >
        //     Export as PDF
        //   </Typography>
        // ))
      }
      <Modal
        open={openPdfWarningModal}
        onClose={handleClosePdfWarningModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            className="position-relative d-flex"
            sx={{ justifyContent: "space-between", padding: "10px" }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Alert
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ cursor: "pointer" }}
              onClick={handleClosePdfWarningModal}
            >
              <AiIcons.AiOutlineClose />
            </Typography>
          </Stack>
          <Divider />
          <Stack sx={{ padding: "10px" }}>
            We recommend that you select fewer than 10 columns for a better PDF
            viewing experience.
            {/* Number of columns selected must be{" "}
            {reportColumn?.filter((col) => col?.hide !== true).length === 0 &&
              "greater than 0"}{" "}
            {reportColumn?.filter((col) => col?.hide !== true).length > 10 &&
              "less than 15"}
            . Please select columns between 1-15 and proceed again. */}
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}

export default connect(mapStateToProps)(CustomReportPdf);
