import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { djangoHandleDataMutationRequest } from "../../api/index";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import moment from "moment";
import { connect } from "react-redux";
import axios from "axios";

function DetailedInvSummTable({
  detailedInvData,
  bookingId,
  hotelId,
  currency,
  userId,
  loggedUserEmail,
  updateSummaryData,
  mockTableTrue,
  mockTableFalse,
  data,
}) {
  // Returning actual date based on the epoch date
  const timeFormatter = (cell, row) => {
    return row.service_date;
  };

  // Getting the hotel currency
  const hotelCurrency = localStorage.getItem("hotelCurrency");

  const [hotelConvertedCurrency, sethotelConvertedCurrency] = useState(
    localStorage.getItem("hotelCurrency")
  );
  const [hotelConvertedCurrencySymbol, sethotelConvertedCurrencySymbol] =
    useState(localStorage.getItem("symbol"));

  useEffect(() => {
    if (
      sessionStorage.getItem("convertedHotelCurrency") != null &&
      sessionStorage.getItem("convertedHotelCurrency") !== undefined
    ) {
      sethotelConvertedCurrency(
        sessionStorage.getItem("convertedHotelCurrency")
      );
      sethotelConvertedCurrencySymbol(
        sessionStorage.getItem("convertedHotelCurrencySymbol")
      );
      if (
        hotelCurrency !== "" &&
        hotelCurrency !== undefined &&
        hotelCurrency !== null &&
        sessionStorage.getItem("convertedHotelCurrency") !== "" &&
        sessionStorage.getItem("convertedHotelCurrency") !== undefined &&
        sessionStorage.getItem("convertedHotelCurrency") !== null
      ) {
        getConvertedvalue();
      }
    }
  }, [
    sessionStorage.getItem("convertedHotelCurrency"),
    sessionStorage.getItem("defaultRate"),
  ]);

  // Check if the row is editable or not
  const checkIfEditable = (cell, row) => {
    console.log("row : ", row);
    if (
      JSON.parse(
        data?.accessControl?.user_feature_map
          ?.AllowPerNightRoomPriceEditAdvFolio
      ).read === "YES"
    ) {
      if (row?.room_id && row?.room_id.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // Tax percentage formatter
  const taxPercentageFormatter = (cell, row) => {
    console.log("check service amount : ", row?.service_amount);
    let taxPercent = 0;
    if (row?.service_amount != 0) {
      taxPercent = Number(row?.service_tax) / Number(row?.service_amount);
      taxPercent = taxPercent * 100;
    } else {
      taxPercent = 0;
    }
    return Number(taxPercent).toFixed(2);
  };

  // getting the services data for the invoice

  // HOTFIX : Rounding off the folio amount

  const { SearchBar } = Search;

  // const products = advInvoiceData.items;

  // let products = invoiceData?.invoice.items;
  const [products, setproducts] = useState(detailedInvData);
  const [convertedAmount, setconvertedAmount] = useState(0);

  useEffect(() => {
    sessionStorage.setItem("itemizedItems", JSON.stringify(detailedInvData));
  }, []);

  const getConvertedvalue = async () => {
    if (
      sessionStorage.getItem("defaultRate") === 0 &&
      sessionStorage.getItem("defaultRate") === undefined &&
      sessionStorage.getItem("defaultRate") === null
    ) {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}`
      );
      setconvertedAmount(data.data);
    } else {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}&amount=${sessionStorage.getItem("defaultRate")}`
      );
      setconvertedAmount(data.data);
    }
  };
  useEffect(() => {
    if (
      hotelCurrency !== null &&
      hotelCurrency !== undefined &&
      hotelConvertedCurrency !== null &&
      hotelConvertedCurrency !== undefined &&
      hotelConvertedCurrency !== ""
    ) {
      if (hotelCurrency !== hotelConvertedCurrency) {
        const data = JSON.parse(sessionStorage.getItem("itemizedItems"))?.map(
          (res) => {
            let e = res;
            console.log("e in itemized invoice : ", e);
            e["amount"] = e["amount"] * convertedAmount;
            return e;
          }
        );

        setproducts(data);
      } else {
        setproducts(JSON.parse(sessionStorage.getItem("itemizedItems")));
      }
    }
  }, [convertedAmount, hotelConvertedCurrency]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      headerStyle: {
        fontSize: "12px",
      },
      editable: false,
    },
    {
      dataField: "desc",
      text: "Description",
      sort: true,
      headerStyle: {
        fontSize: "12px",
      },
      editable: false,
      // hidden: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      headerStyle: {
        fontSize: "12px",
      },
      editable: false,
    },
    {
      dataField: "amount",
      text: `Amount (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      headerStyle: {
        fontSize: "12px",
      },
      editable: false,
      formatter: (cell) => {
        return Math.abs(Number(cell.toFixed(2)));
      },
      // hide: true
    },
  ];

  return (
    <>
      <div className="invoiceSummTableWrapper">
        <ToolkitProvider
          keyField="service_id"
          data={products}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div className="folioTableWrapper">
                <BootstrapTable
                  {...props.baseProps}
                  tabIndexCell
                  headerWrapperClasses="foo"
                  bodyClasses="tableBody"
                  bordered={false}
                  // selectRow={selectRow}
                  pagination={paginationFactory()}
                  cellEdit={cellEditFactory({
                    mode: "click",
                    blurToSave: true,
                  })}
                  // rowStyle = {rowStyle}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(DetailedInvSummTable);
