import React, { Fragment, useState, useEffect } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import {
  readableTimeConverter,
  roundOffValues,
  validateAddress,
  handleFullAddString,
} from "../../Utils/Utils";
import axios from "axios";

const borderColor = "#E5e5e5";

// formatting the date
const formatDate = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

// rounding of the digits
const handleDigits = (num) => {
  let formattedNum = parseFloat(num);
  return parseFloat(formattedNum).toFixed(2);
};

const InvoiceDualText = ({ boldText, normalText }) => {
  return (
    <View style={styles.dualText}>
      <Text style={styles.boldText}>{boldText}:</Text>
      <Text style={styles.normalText}>{normalText}</Text>
    </View>
  );
};

const InvoiceTableHeader = () => (
  <View style={styles.headerContainer}>
    <Text style={[styles.infoRow, { width: "40%" }]}>
      Date {"&"} Description
    </Text>
    <Text style={styles.infoRow}>Type</Text>
    <Text style={styles.infoRow}>Sub-total</Text>
    <Text style={styles.infoRow}>Tax</Text>
    <Text style={styles.infoRow}>Total</Text>
  </View>
);

const InvoiceTaxBreakdownHeader = () => (
  <View style={styles.container}>
    <Text style={[styles.description, { fontSize: "10px" }]}>Tax Name</Text>
    <Text style={[{ width: "50%" }, { fontSize: "10px" }]}>Tax Amount</Text>
  </View>
);

const InvoiceFeeBreakdownHeader = () => (
  <View style={styles.headerContainer}>
    <Text style={[styles.infoRow, { width: "50%" }]}>Fee Name</Text>
    <Text style={[styles.infoRow, { width: "50%" }]}>Fee Amount</Text>
  </View>
);

const InvoiceTableRow = ({ items, symbol }) => {
  const rows = items.map(
    (item, index) =>
      item.pos_order_id === "" && (
        <View style={styles.row} key={index}>
          <View
            style={[
              styles.infoRow,
              {
                width: "40%",
              },
            ]}
          >
            <Text style={[{ fontSize: "7px" }]}>{item?.service_date}</Text>
            <Text style={[{ fontSize: "7px" }]}>{item?.service_desc}</Text>
          </View>
          <Text style={[styles.infoRow]}>{item.service_amount_type}</Text>
          <Text style={[styles.infoRow]}>
            {symbol + "" + handleDigits(Math.abs(item.service_amount))}
          </Text>
          <Text style={[styles.infoRow]}>
            {symbol + "" + handleDigits(Math.abs(item.service_tax))}
          </Text>
          <Text style={[styles.infoRow]}>
            {symbol + "" + handleDigits(Math.abs(item?.service_total))}
          </Text>
        </View>
      )
  );
  return <Fragment>{rows}</Fragment>;
};

const InvoiceTaxBreakdownHeaderItems = ({ items, symbol }) => {
  const rows = Object.keys(items)?.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text style={[styles.infoRow, { width: "50%" }]}>{item}</Text>
      <Text style={[styles.infoRow, { width: "50%" }]}>
        {symbol + "" + handleDigits(items[item])}
      </Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

// Check cutomer identification
const checkCustomIdentification = (customerIdentification) => {
  if (customerIdentification.length === 0) {
    return "";
  } else if (customerIdentification.includes("null")) {
    return "";
  } else {
    return customerIdentification;
  }
};

// Handling customer address
const handleCustomerAddress = (address) => {
  if (!address.length > 0) {
    return null;
  } else if (address.includes("null")) {
    return null;
  } else if (address.includes("NA")) {
    return null;
  } else {
    return [`${address}, `];
  }
};

// handle invoice header
const handleInvoiceHeader = (customFolioConfig) => {
  if (
    customFolioConfig?.booking_invoice_header &&
    !customFolioConfig?.booking_invoice_header
      .toLowerCase()
      .includes("null", "n/a")
  ) {
    return customFolioConfig?.booking_invoice_header;
  } else {
    return "Folio";
  }
};

// Check if the data is not null or includes n/a
const checkInvoiceData = (data) => {
  if (data === null) {
    return "";
  } else if (String(data).toLowerCase().startsWith("null")) {
    return "";
  } else if (String(data).toLowerCase().startsWith("n/a")) {
    return "";
  } else {
    return String(data);
  }
};

const handleCustomBookingId = (invoice, customFolioConfig) => {
  if (invoice?.status === "CHECKED_OUT") {
    // return (
    //   String(checkInvoiceData(customFolioConfig.cust_booking_invoice_prefix)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_seq_start)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_invoice_suffix))
    // );
    return String(invoice?.custom_invoice_id);
  } else {
    return "";
  }
};

const handleGetBillToCta = (invoice, summaryData) => {
  let adjustedAmt = 0;
  let finalAmt = 0;
  if (invoice?.cta_billed_items.length > 0) {
    const data = invoice.cta_billed_items?.forEach(
      (item) => (adjustedAmt += item?.refund_amount)
    );
    finalAmt = Number(summaryData?.bill_to_cta_amt) - Number(adjustedAmt);
  } else {
    finalAmt = 0;
  }
  return parseFloat(finalAmt).toFixed(2);
};

const handleTotalRefund = (invoice, summaryData) => {
  let refund = 0;
  let adjustedAmt = 0;
  const data = invoice?.cta_billed_items?.forEach(
    (item) => (adjustedAmt += item?.refund_amount)
  );
  if (invoice?.cta_billed_items.length > 0) {
    refund = Number(summaryData?.refund) - Number(adjustedAmt);
  } else {
    refund = Number(summaryData?.refund) - Number(summaryData?.bill_to_cta_amt);
  }
  return Number(refund).toFixed(2);
};

const SelfCheckinBreakdownHeader = () => (
  <View style={styles.headerContainer}>
    <Text style={[styles.infoRow, { width: "50%" }]}>Name</Text>
    <Text style={[styles.infoRow, { width: "50%" }]}>Amount</Text>
  </View>
);

const SelfCheckinBreakdownHeaderItems = ({ items, symbol }) => {
  const rows = items?.map(
    (item, index) =>
      item.type !== "POS" && (
        <View style={styles.row} key={index}>
          <Text style={[styles.infoRow, { width: "50%" }]}>{item.type}</Text>
          <Text style={[styles.infoRow, { width: "50%" }]}>
            {symbol + "" + handleDigits(item.amount)}
          </Text>
        </View>
      )
  );
  return <Fragment>{rows}</Fragment>;
};

function InvoiceDocWOPos({
  invoice,
  hotelData,
  symbol,
  customDetails,
  hotelLogo,
  summaryData,
  customerIdentification,
  posSubTotal,
  posTotal,
  posTotalTax,
  groupHotelEmail,
  ctaDetails,
  selfCheckin,
}) {
  const hotelCurrency = localStorage.getItem("hotelCurrency");
  const [convertedAmount, setconvertedAmount] = useState(0);

  const [hotelConvertedCurrency, sethotelConvertedCurrency] = useState(
    localStorage.getItem("hotelCurrency")
  );
  const [hotelConvertedCurrencySymbol, sethotelConvertedCurrencySymbol] =
    useState(localStorage.getItem("symbol"));

  const getConvertedvalue = async () => {
    if (
      sessionStorage.getItem("defaultRate") === 0 &&
      sessionStorage.getItem("defaultRate") === undefined &&
      sessionStorage.getItem("defaultRate") === null
    ) {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}`
      );
      setconvertedAmount(data.data);
    } else {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}&amount=${sessionStorage.getItem("defaultRate")}`
      );
      setconvertedAmount(data.data);
    }
    symbol =
      hotelCurrency === hotelConvertedCurrency
        ? symbol
        : hotelConvertedCurrencySymbol;
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("convertedHotelCurrency") != null &&
      sessionStorage.getItem("convertedHotelCurrency") !== undefined
    ) {
      sethotelConvertedCurrency(
        sessionStorage.getItem("convertedHotelCurrency")
      );
      sethotelConvertedCurrencySymbol(
        sessionStorage.getItem("convertedHotelCurrencySymbol")
      );
      if (
        hotelCurrency !== "" &&
        hotelCurrency !== undefined &&
        hotelCurrency !== null &&
        sessionStorage.getItem("convertedHotelCurrency") !== "" &&
        sessionStorage.getItem("convertedHotelCurrency") !== undefined &&
        sessionStorage.getItem("convertedHotelCurrency") !== null
      ) {
        getConvertedvalue();
      }
    }
  }, [
    sessionStorage.getItem("convertedHotelCurrency"),
    sessionStorage.getItem("defaultRate"),
  ]);

  // Get the sub total val
  function getSumValues(item, type) {
    console.log("invoice items : ", item);
    const val = item.reduce((acc, i) => {
      if (i.pos_order_id === "") {
        console.log("item.service : ", i[type]);
        return acc + i[type];
      }
      return acc;
    }, 0);
    console.log("val : ", val);
    return Number(val ?? 0.0);
  }

  return (
    <View style={{ position: "relative", padding: "10px" }}>
      <View
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        {/* Hotel details */}
        <View style={{ width: "33%" }}>
          {/* <Text style={{ fontSize: "8px", marginBottom: 3.5 }}>Bill From</Text> */}
          <Text style={[styles.boldText, { marginBottom: 2, color: "grey" }]}>
            {hotelData?.hotel_name}
          </Text>
          <Text
            style={[
              styles.boldText,
              { marginBottom: 3, flexWrap: "wrap", color: "grey" },
            ]}
          >
            {handleFullAddString(hotelData?.hotel_address)}
          </Text>
          <View style={styles.dualText}>
            <Text style={[styles.boldText, { color: "grey" }]}>
              {customDetails &&
              customDetails?.invoice_contact_email &&
              !customDetails?.invoice_contact_email
                .toLowerCase()
                .includes("null", "n/a")
                ? customDetails?.invoice_contact_email
                : hotelData?.hotel_email}
            </Text>
          </View>
          <View style={styles.dualText}>
            <Text style={[styles.boldText, { color: "grey" }]}>Phone:</Text>
            <Text style={[styles.boldText, { color: "grey" }]}>
              {customDetails &&
              customDetails?.invoice_contact_phone &&
              !customDetails?.invoice_contact_phone
                .toLowerCase()
                .includes("null", "n/a")
                ? customDetails?.invoice_contact_phone
                : hotelData?.hotel_contact}
            </Text>
          </View>
          {hotelData?.tax_identification_number.length > 0 ? (
            <View style={styles.dualText}>
              <Text style={[styles.boldText, { color: "grey" }]}>
                {String(symbol)?.includes("Rs")
                  ? "Hotel GST No. : "
                  : "Hotel Tax IN : "}
              </Text>
              <Text style={[styles.boldText, { color: "grey" }]}>
                {hotelData.tax_identification_number}
              </Text>
            </View>
          ) : null}
          {invoice?.booking_reference_id !== "" && (
            <View style={styles.dualText}>
              <Text style={[styles.boldText, { color: "grey" }]}>
                {"Booking reference id : "}
              </Text>

              <Text style={[styles.boldText, { color: "grey" }]}>
                {invoice?.booking_reference_id}
              </Text>
            </View>
          )}
        </View>

        {/* Hotel LOGO */}
        <View
          style={{ position: "relative", width: "33%", alignItems: "center" }}
        >
          <Text style={{ fontSize: "12px", marginBottom: "4px" }}>
            {handleInvoiceHeader(customDetails)}
          </Text>
          {customDetails &&
            customDetails?.show_logo.toLowerCase() === "yes" && (
              <Image style={styles.image} src={getExtension(hotelLogo)} />
            )}
        </View>

        {/* Bill to - logic */}
        <View
          style={{
            position: "relative",
            width: "33%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {/* CTA Details incase bill is settled to DIRECT_BILLING */}
          {/* {invoice?.segment === "DIRECT_BILLING" && (
            <View style={{ textAlign: "right", width: "50%" }}>
              <Text
                style={{
                  fontSize: "8px",
                  paddingRight: "5px",
                  marginBottom: 3,
                }}
              >
                Bill to
              </Text>
              <Text style={[styles.boldText, { marginBottom: 3.5 }]}>
                {ctaDetails?.cta_name}
              </Text>
              {ctaDetails?.cta_address_line1.length > 0 && (
                <Text
                  style={[
                    styles.boldText,
                    {
                      marginBottom: 3.5,
                      marginLeft: 10,
                      flexWrap: "wrap",
                    },
                  ]}
                >
                  {ctaDetails?.cta_address_line1}{" "}
                  {ctaDetails?.cta_address_line2} {ctaDetails?.cta_address_city}{" "}
                  {ctaDetails?.cta_address_state}
                </Text>
              )}
              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginBottom: 3.5,
                  },
                  { textAlign: "right" },
                ]}
              >
                <Text style={styles.boldText}>Email : </Text>
                {ctaDetails?.cta_email.length > 0 ? (
                  <Text style={styles.boldText}>{ctaDetails?.cta_email}</Text>
                ) : (
                  <Text style={styles.boldText}>{"-"}</Text>
                )}
              </View>
              <View
                style={[
                  {
                    flexDirection: "row",
                    marginBottom: 3.5,
                    justifyContent: "flex-end",
                  },
                  { textAlign: "right" },
                ]}
              >
                <Text style={styles.boldText}>Phone:</Text>
                {ctaDetails?.cta_phone?.length > 0 ||
                ctaDetails?.cta_phone !== "NA" ? (
                  <Text style={styles.boldText}>{ctaDetails?.cta_phone}</Text>
                ) : (
                  <Text style={styles.boldText}>{"-"}</Text>
                )}
              </View>
              {ctaDetails?.cta_tax_id_no?.length === 0 ? (
                <View
                  style={[
                    {
                      flexDirection: "row",
                      marginBottom: 3.5,
                      justifyContent: "flex-end",
                      marginRight: 50,
                    },
                  ]}
                >
                  <Text style={styles.boldText}>
                    {" "}
                    {String(symbol).includes("Rs")
                      ? "CTA GST No. :"
                      : "CTA Tax IN :"}
                  </Text>
                  <Text style={styles.boldText}> </Text>
                </View>
              ) : (
                <View
                  style={[
                    {
                      flexDirection: "row",
                      marginBottom: 3.5,
                      justifyContent: "flex-end",
                    },
                    { textAlign: "right" },
                  ]}
                >
                  <Text style={styles.boldText}>
                    {" "}
                    {String(symbol).includes("Rs")
                      ? "CTA GST No. :"
                      : "CTA Tax IN :"}
                  </Text>
                  <Text style={styles.boldText}>
                    {ctaDetails?.cta_tax_id_no}
                  </Text>
                </View>
              )}
            </View>
          )} */}

          <View
            style={{
              textAlign: "right",
            }}
          >
            {/* <Text
              style={{
                fontSize: "8px",
                paddingRight: "5px",
                marginBottom: 3,
              }}
            >
              Bill to
            </Text> */}
            <Text style={[styles.boldText, { marginBottom: 2, color: "grey" }]}>
              {invoice?.segment === "DIRECT_BILLING"
                ? ctaDetails?.cta_name
                : invoice?.customer_name}
            </Text>
            {invoice.segment === "DIRECT_BILLING" ? (
              `${ctaDetails?.cta_address_line1}  ${ctaDetails?.cta_address_line2} ${ctaDetails?.cta_address_city} ${ctaDetails?.cta_address_state}`
            ) : !invoice?.customer_address_full_str.includes("NA", "null") ? (
              <Text
                style={[
                  styles.boldText,
                  {
                    marginBottom: 2,
                    flexWrap: "wrap",
                    color: "grey",
                  },
                ]}
              >
                {handleFullAddString(invoice?.customer_address_full_str)}
              </Text>
            ) : (
              <Text
                style={[
                  styles.boldText,
                  {
                    marginBottom: 2,
                    flexWrap: "wrap",
                    color: "grey",
                  },
                ]}
              >
                {handleCustomerAddress(invoice?.customer_address)}
              </Text>
            )}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: 2,
                color: "grey",
              }}
            >
              {/* <Text style={styles.boldText}>Email : </Text> */}
              {invoice?.segment === "DIRECT_BILLING" ? (
                ctaDetails?.cta_email.length > 0 ? (
                  <Text style={[styles.boldText, { color: "grey" }]}>
                    {ctaDetails?.cta_email}
                  </Text>
                ) : null
              ) : invoice?.customer_email.length > 0 ? (
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {invoice?.customer_email}
                </Text>
              ) : null}
            </View>
            {invoice?.segment === "DIRECT_BILLING" ? (
              ctaDetails?.cta_phone?.length > 0 ||
              ctaDetails?.cta_phone !== "NA" ? (
                <View
                  style={{
                    position: "relative",
                    flexDirection: "row",
                    marginBottom: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "grey",
                  }}
                >
                  <Text style={[styles.boldText, { color: "grey" }]}>
                    Phone:
                  </Text>
                  <Text style={[styles.boldText, { color: "grey" }]}>
                    {ctaDetails?.cta_phone}
                  </Text>
                </View>
              ) : null
            ) : invoice?.customer_phone.length > 0 ? (
              <View
                style={{
                  position: "relative",
                  flexDirection: "row",
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  color: "grey",
                }}
              >
                <Text style={[styles.boldText, { color: "grey" }]}>Phone:</Text>
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {invoice?.customer_phone}
                </Text>
              </View>
            ) : null}
            {invoice?.segment === "DIRECT_BILLING" ? (
              ctaDetails?.cta_tax_id_no?.trim()?.length !== 0 ? (
                <View
                  style={[
                    {
                      flexDirection: "row",
                      marginBottom: 3.5,
                      justifyContent: "flex-end",
                    },
                  ]}
                >
                  <Text style={[styles.boldText, { color: "grey" }]}>
                    {" "}
                    {String(symbol).includes("Rs")
                      ? "Direct billing GST No. :"
                      : "Direct billing tax id: "}
                    {/* : "CTA Tax IN :" */}
                  </Text>
                  <Text style={[styles.boldText, { color: "grey" }]}>
                    {ctaDetails?.cta_tax_id_no}
                  </Text>
                </View>
              ) : null
            ) : customerIdentification?.length !== 0 ? (
              <View
                style={[
                  {
                    flexDirection: "row",
                    marginBottom: 2,
                  },
                ]}
              >
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {String(symbol).includes("Rs")
                    ? "Guest GST No. :"
                    : "Guest Tax IN :"}
                </Text>
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {customerIdentification}
                </Text>
              </View>
            ) : null}
            {invoice.segment === "DIRECT_BILLING" ? (
              <View
                style={{
                  marginBottom: 3.5,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Text style={[styles.boldText, { color: "grey" }]}>
                  Guest name :
                </Text>
                <Text
                  style={[
                    [styles.boldText, { color: "grey", marginBottom: 2 }],
                  ]}
                >
                  {invoice?.customer_name}
                </Text>
              </View>
            ) : null}
            {invoice.segment === "DIRECT_BILLING" &&
            customerIdentification.length > 0 ? (
              <View
                style={{
                  marginBottom: 3.5,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {String(symbol).includes("Rs")
                    ? "Guest GST No. :"
                    : "Guest Tax IN :"}
                </Text>
                <Text
                  style={[styles.boldText, { marginBottom: 2, color: "grey" }]}
                >
                  {customerIdentification}
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </View>

      {/* Folio details */}
      <View
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ position: "relative", width: "50%", marginTop: "10px" }}>
          {/* <Text style={{ marginBottom: 5, fontSize: "8px" }}>
            {handleInvoiceHeader(customDetails)}
          </Text> */}

          {customDetails?.hide_stayflexi_booking_id.toLowerCase() === "no" && (
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "bold",
                marginBottom: 3,
              }}
            >{`${handleInvoiceHeader(customDetails)}#${
              invoice?.bookingid
            }`}</Text>
          )}
          {customDetails &&
          Number(summaryData?.payment_made) > 0 &&
          handleCustomBookingId(invoice, customDetails).trim().length > 0 ? (
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "bold",
                marginBottom: 3,
              }}
            >{`${handleInvoiceHeader(customDetails)}#${handleCustomBookingId(
              invoice,
              customDetails
            )}`}</Text>
          ) : null}

          <InvoiceDualText
            boldText={"Check-in"}
            normalText={formatDate(invoice?.checkin)}
          />
          <InvoiceDualText
            boldText={"Check-out"}
            normalText={formatDate(invoice?.checkout)}
          />
        </View>
        <View
          style={{
            position: "relative",
            width: "50%",
            display: "flex",
            alignItems: "flex-end",
            alignContent: "flex-end",
          }}
        >
          <View style={styles.dualText}>
            <InvoiceDualText
              boldText={"Nights"}
              normalText={invoice?.num_nights}
            />
            <InvoiceDualText boldText={"Adults"} normalText={invoice?.adults} />
            <InvoiceDualText
              boldText={"Children"}
              normalText={invoice?.children}
            />
          </View>
          <InvoiceDualText
            boldText={"Room No.(s)"}
            normalText={invoice?.roomids}
          />
          <InvoiceDualText
            boldText={"Source"}
            normalText={invoice?.booking_source_displayname}
          />
        </View>
      </View>

      {invoice?.items && (
        <View style={styles.tableContainer}>
          <InvoiceTableHeader />
          <InvoiceTableRow
            items={invoice?.items}
            symbol={
              hotelCurrency === hotelConvertedCurrency
                ? symbol
                : hotelConvertedCurrencySymbol
            }
          />
        </View>
      )}

      <View
        style={{
          marginTop: 15,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ display: "flex", flexDirection: "column" }}>
          {summaryData?.deposit_amount > 0.0 && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "7.5px",
              }}
            >
              <Text style={{ fontSize: "10.5px" }}> Cash deposit : </Text>
              {hotelCurrency === hotelConvertedCurrency && (
                <Text style={{ fontSize: "8px" }}>
                  {" "}
                  {symbol} {Number(summaryData?.deposit_amount).toFixed(2)}{" "}
                </Text>
              )}
              {hotelCurrency !== hotelConvertedCurrency && (
                <Text style={{ fontSize: "8px" }}>
                  {" "}
                  {hotelConvertedCurrencySymbol}{" "}
                  {Number(summaryData?.deposit_amount) * convertedAmount}{" "}
                </Text>
              )}
            </View>
          )}
          {summaryData?.auths && summaryData?.auths.length > 0 && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "4px",
              }}
            >
              {summaryData?.auths?.map((item, index) => (
                <View
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "7.5px",
                  }}
                >
                  <Text style={{ wordBreak: "break-word", fontSize: "8px" }}>
                    {item?.pm_payment_type} {item?.pm_payment_issuer}{" "}
                    Authorized:
                  </Text>
                  {hotelCurrency === hotelConvertedCurrency && (
                    <Text style={{ wordBreak: "break-word", fontSize: "8px" }}>
                      {" "}
                      {symbol} {Number(item?.pm_amount)}
                    </Text>
                  )}
                  {hotelCurrency !== hotelConvertedCurrency && (
                    <Text style={{ wordBreak: "break-word", fontSize: "8px" }}>
                      {" "}
                      {hotelConvertedCurrencySymbol}{" "}
                      {Number(item?.pm_amount) * convertedAmount}
                    </Text>
                  )}
                </View>
              ))}
            </View>
          )}
          {/* {summaryData?.tax_breakdown && (
            <View>
              <Text style={{ fontSize: "9px", marginBottom: 5 }}>
                Tax breakdown
              </Text>
              <View style={{ width: "60%" }}>
                <InvoiceTaxBreakdownHeader />
                <InvoiceTaxBreakdownHeaderItems
                  items={summaryData?.tax_breakdown}
                  symbol={
                    hotelCurrency === hotelConvertedCurrency
                      ? symbol
                      : hotelConvertedCurrencySymbol
                  }
                />
              </View>
            </View>
          )} */}
          {summaryData &&
            Object.entries(summaryData?.fee_breakdown).length > 0 && (
              <View>
                <Text
                  style={{
                    fontSize: "8px",
                    marginBottom: 5,
                    marginTop: "10px",
                  }}
                >
                  Booking fees breakdown ( Inclusive )
                </Text>
                <View style={{ width: "60%" }}>
                  <InvoiceFeeBreakdownHeader />
                  <InvoiceTaxBreakdownHeaderItems
                    items={summaryData?.fee_breakdown}
                    symbol={
                      hotelCurrency === hotelConvertedCurrency
                        ? symbol
                        : hotelConvertedCurrencySymbol
                    }
                  />
                </View>
              </View>
            )}
          {selfCheckin?.length > 0 && (
            <View>
              <Text style={{ fontSize: "8px", marginBottom: 5, marginTop: 10 }}>
                Magic Link Breakdown
              </Text>
              <View style={{ width: "60%" }}>
                <SelfCheckinBreakdownHeader />
                <SelfCheckinBreakdownHeaderItems
                  items={selfCheckin}
                  symbol={
                    hotelCurrency === hotelConvertedCurrency
                      ? symbol
                      : hotelConvertedCurrencySymbol
                  }
                />
              </View>
            </View>
          )}
        </View>

        <View>
          {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Sub total"}
              normalText={
                symbol +
                "" +
                // handleDigits(summaryData?.total_pretax_amount_with_services)
                getSumValues(invoice?.items, "service_amount")
              }
            />
          )}
          {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Sub total"}
              normalText={
                hotelConvertedCurrencySymbol +
                "" +
                handleDigits(
                  getSumValues(invoice?.items, "service_tax") * convertedAmount
                )
              }
            />
          )}
          {/* {invoice?.applied_promocode?.length > 0 &&
            Number(invoice?.promo_amount) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={`Discount`}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(invoice?.promo_amount * convertedAmount * -1)
                }
              />
            )}
          {invoice?.applied_promocode?.length > 0 &&
            Number(invoice?.promo_amount) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={`Discount`}
                normalText={
                  symbol + "" + handleDigits(invoice?.promo_amount * -1)
                }
              />
            )} */}
          {symbol === "Rs." ? (
            <>
              {hotelCurrency === hotelConvertedCurrency && (
                <InvoiceDualText
                  boldText={"CGST"}
                  normalText={
                    symbol +
                    "" +
                    handleDigits(
                      getSumValues(invoice?.items, "service_tax") / 2
                    )
                  }
                />
              )}

              {hotelCurrency !== hotelConvertedCurrency && (
                <InvoiceDualText
                  boldText={"CGST"}
                  normalText={
                    hotelConvertedCurrencySymbol +
                    "" +
                    handleDigits(
                      getSumValues(invoice?.items, "service_tax") *
                        convertedAmount
                    )
                  }
                />
              )}
              {hotelCurrency === hotelConvertedCurrency && (
                <InvoiceDualText
                  boldText={"SGST"}
                  normalText={
                    symbol +
                    "" +
                    handleDigits(
                      getSumValues(invoice?.items, "service_tax") / 2
                    )
                  }
                />
              )}
              {hotelCurrency !== hotelConvertedCurrency && (
                <InvoiceDualText
                  boldText={"SGST"}
                  normalText={
                    hotelConvertedCurrencySymbol +
                    "" +
                    handleDigits(
                      (getSumValues(invoice?.items, "service_tax") / 2) *
                        convertedAmount
                    )
                  }
                />
              )}
            </>
          ) : (
            <>
              {hotelCurrency === hotelConvertedCurrency && (
                <InvoiceDualText
                  boldText={"Tax & Fees"}
                  normalText={
                    symbol + "" + getSumValues(invoice?.items, "service_tax")
                  }
                />
              )}

              {hotelCurrency !== hotelConvertedCurrency && (
                <InvoiceDualText
                  boldText={"Tax & Fees"}
                  normalText={
                    hotelConvertedCurrencySymbol +
                    "" +
                    handleDigits(
                      getSumValues(invoice?.items, "service_tax") *
                        convertedAmount
                    )
                  }
                />
              )}
            </>
          )}
          {/* {parseInt(summaryData?.total_online_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Gateway payments"}
                normalText={
                  symbol + "" + handleDigits(summaryData?.total_online_payment)
                }
              />
            )} */}
          {/* 
          {parseInt(summaryData?.total_online_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Gateway payments"}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    summaryData?.total_online_payment * convertedAmount
                  )
                }
              />
            )} */}

          {parseInt(summaryData?.cash_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Cash payments"}
                normalText={
                  symbol + "" + handleDigits(summaryData?.cash_payment)
                }
              />
            )}

          {parseInt(summaryData?.cash_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Cash payments"}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(summaryData?.cash_payment * convertedAmount)
                }
              />
            )}

          {parseInt(summaryData?.offline_check_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Offline cheque payments"}
                normalText={
                  symbol + "" + handleDigits(summaryData?.offline_check_payment)
                }
              />
            )}

          {parseInt(summaryData?.offline_check_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Offline cheque payments"}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    summaryData?.offline_check_payment * convertedAmount
                  )
                }
              />
            )}

          {parseInt(summaryData?.offline_card_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Offline card payments"}
                normalText={
                  symbol + "" + handleDigits(summaryData?.offline_card_payment)
                }
              />
            )}

          {parseInt(summaryData?.offline_card_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Offline card payments"}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    summaryData?.offline_card_payment * convertedAmount
                  )
                }
              />
            )}

          {parseInt(summaryData?.upi_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"UPI payments"}
                normalText={
                  symbol + "" + handleDigits(summaryData?.upi_payment)
                }
              />
            )}

          {parseInt(summaryData?.upi_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"UPI payments"}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(summaryData?.upi_payment * convertedAmount)
                }
              />
            )}

          {parseInt(summaryData?.other_payment) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Other payments"}
                normalText={
                  symbol + "" + handleDigits(summaryData?.other_payment)
                }
              />
            )}

          {parseInt(summaryData?.other_payment) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={"Other payments"}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(summaryData?.other_payment * convertedAmount)
                }
              />
            )}

          {hotelCurrency === hotelConvertedCurrency &&
            parseInt(handleTotalRefund(invoice, summaryData)) > 0 && (
              <InvoiceDualText
                boldText={"Refund"}
                normalText={
                  symbol + "" + handleTotalRefund(invoice, summaryData)
                }
              />
            )}

          {hotelCurrency !== hotelConvertedCurrency &&
            parseInt(handleTotalRefund(invoice, summaryData)) > 0 && (
              <InvoiceDualText
                boldText={"Refund"}
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  (
                    handleTotalRefund(invoice, summaryData) * convertedAmount
                  ).toFixed(2)
                }
              />
            )}

          {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Total"}
              normalText={
                symbol + "" + getSumValues(invoice?.items, "service_total")
              }
            />
          )}
          {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Total"}
              normalText={
                hotelConvertedCurrencySymbol +
                "" +
                handleDigits(
                  getSumValues(invoice?.items, "service_total") *
                    convertedAmount
                )
              }
            />
          )}
          {/* {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Total paid"}
              normalText={symbol + "" + handleDigits(summaryData?.payment_made)}
            />
          )}
          {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Total paid"}
              normalText={
                hotelConvertedCurrencySymbol +
                "" +
                handleDigits(summaryData?.payment_made * convertedAmount)
              }
            />
          )} */}
          {/* {invoice?.booking_source_displayname !== "Walk-In" && (
            <InvoiceDualText
              boldText={"Total"}
              normalText={
                hotelConvertedCurrencySymbol +
                "" +
                handleDigits(
                  summaryData?.total_amount_with_services * convertedAmount
                )
              }
            />
          )} */}

          {/* {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Total paid"}
              normalText={symbol + "" + handleDigits(summaryData?.payment_made)}
            />
          )}

          {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Total paid"}
              normalText={
                hotelConvertedCurrencySymbol +
                handleDigits(summaryData?.payment_made * convertedAmount)
              }
            />
          )} */}

          {Number(handleGetBillToCta(invoice, summaryData)) > 0 &&
            hotelCurrency === hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={
                  "Billed to" + " " + invoice?.booking_source_displayname
                }
                normalText={
                  symbol +
                  "" +
                  handleDigits(handleGetBillToCta(invoice, summaryData))
                }
              />
            )}

          {Number(handleGetBillToCta(invoice, summaryData)) > 0 &&
            hotelCurrency !== hotelConvertedCurrency && (
              <InvoiceDualText
                boldText={
                  "Billed to" + " " + invoice?.booking_source_displayname
                }
                normalText={
                  hotelConvertedCurrencySymbol +
                  "" +
                  handleDigits(
                    Number(handleGetBillToCta(invoice, summaryData)) *
                      convertedAmount
                  )
                }
              />
            )}

          {/* {hotelCurrency === hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Balance due"}
              normalText={symbol + "" + handleDigits(summaryData?.balance_due)}
            />
          )} */}
          {/* {hotelCurrency !== hotelConvertedCurrency && (
            <InvoiceDualText
              boldText={"Balance due"}
              normalText={
                hotelConvertedCurrencySymbol +
                "" +
                handleDigits(summaryData?.balance_due * convertedAmount)
              }
            />
          )} */}
        </View>
      </View>

      {/* Displaying guest and hotel signature */}
      <View style={styles.signature}>
        <View style={styles.signatureData}>
          <Text style={{ fontSize: "8px", marginBottom: 5, marginTop: "auto" }}>
            Guest signature :
          </Text>
          {customDetails &&
          Object.keys(customDetails).length > 0 > 0 &&
          customDetails?.show_signature === "YES" &&
          invoice?.customer_signature_base64.length > 0 ? (
            <Image
              style={[styles.parentImage, { marginLeft: 5 }]}
              src={getExtension(invoice?.customer_signature_base64)}
            />
          ) : (
            <Text style={{ marginLeft: 5 }}>_________________________</Text>
          )}
        </View>
        <View style={styles.signatureData}>
          {symbol === "$" ? (
            <Text style={{ fontSize: "8px", marginBottom: 5 }}>
              Guest Name :
            </Text>
          ) : (
            <Text style={{ fontSize: "8px", marginBottom: 5 }}>
              Hotel signature :
            </Text>
          )}
          <Text style={{ marginLeft: 5 }}>_________________________</Text>
        </View>
      </View>

      {/* Displaying Cancellation Policies */}
      {customDetails &&
        Object.keys(customDetails).length > 0 > 0 &&
        customDetails?.show_cancellation_policy === "YES" && (
          <View>
            <Text
              style={{
                fontSize: "8px",
                marginTop: 7.5,
                marginBottom: 7.5,
                textDecoration: "underline",
              }}
            >
              Cancellation Policies
            </Text>
            <View>
              <Text style={{ fontSize: "8px", marginBottom: 5 }}>
                Guests will be refunded {invoice?.policy?.refund_prestay_before}
                % of total booking value when cancelled before{" "}
                {readableTimeConverter(invoice?.policy?.prestay_before)} of
                checkin date.
              </Text>
              <Text style={{ fontSize: "8px", marginBottom: 5 }}>
                Guests will be refunded {invoice?.policy?.refund_prestay_within}
                % if cancelled within{" "}
                {readableTimeConverter(invoice?.policy?.prestay_before)} of
                checkin date. checkin date
              </Text>
            </View>
            {invoice?.policy?.poststay !== 0 && (
              <View>
                <Text style={{ fontSize: "8px", marginBottom: 5 }}>
                  Guests will be refunded {invoice?.policy?.refund_poststay}% if
                  cancelled within{" "}
                  {readableTimeConverter(invoice?.policy?.poststay)} of checkin
                  date. checkin date
                </Text>
              </View>
            )}
          </View>
        )}

      {/* Displaying Terms and Conditions */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_terms_and_condition.toLowerCase() === "yes" && (
          <View style={{ marginTop: 10 }}>
            <Text
              style={{
                fontSize: "8px",
                marginBottom: 7.5,
                textDecoration: "underline",
              }}
            >
              Terms and Conditions
            </Text>
            <Text style={{ fontSize: "8px", marginBottom: 5 }}>
              {localStorage.getItem("tnc")}
            </Text>
          </View>
        )}
    </View>
  );
}

export default InvoiceDocWOPos;

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    width: "85px",
    height: "auto",
  },
  image: {
    position: "relative",
    width: "35px",
    alignSelf: "center",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 2,
    alignContent: "flex-end",
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "8px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "8px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderLeft: "none",
  },
  headerContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderLeft: "none",
  },
  description: {
    width: "45%",
    wordWrap: "word-break",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // textAlign: "center",
    paddingLeft: 2.5,
    paddingTop: 2,
  },
  taxDescription: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 2.5,
    wordWrap: "word-break",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 2.5,
    wordWrap: "word-break",
  },
  amount: {
    width: "15%",
    paddingLeft: 2.5,
    wordWrap: "word-break",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
    borderLeft: "none",
    borderTop: "none",
  },
  row: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    borderTop: "none",
    borderLeft: "none",
  },
  taxRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 32,
    fontStyle: "bold",
  },
  signature: {
    marginTop: 25,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "8px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "8px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  infoRow: {
    position: "relative",
    width: "12%",
    marginRight: "2px",
    wordBreak: "break-all",
    borderLeft: "1px solid #E5e5e5",
    fontSize: "8px",
    padding: "2.5px",
  },
});
