import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import { BASE_SPRING_ENDPOINT } from "../../../../api/config";
import { useState } from "react";
import Success from "./Success";

export default function ContactUs({ country, product, setOpenContactModal }) {
  const [errorFirst, setErrorFirst] = useState(false);
  const [errorSecond, setErrorSecond] = useState(false);
  const [errorThird, setErrorThird] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [contact, setContact] = useState();
  const [congratulations, setCongratulations] = useState(false);

  const pendingOnboardingEmail = () => {
    const payload = {
      name: name,
      email: email,
      status: "ACTIVE",
    };
    axios
      .post(
        `${BASE_SPRING_ENDPOINT}web-api/createPendingOnboardingHotel`,
        payload
      )
      .then(() => {
        // setCongratulations(true);
      });
  };

  const registerUser = () => {
    let ctr;
    Object.keys(country).map((item) => {
      if (country[item]) {
        ctr = item;
      }
    });

    const payload = {
      country: ctr || "",
      portfolio: "",
      propertyType: "",
      quantity: "",
      rooms: "",
      products: product,
      exProducts: "",
      name: name || "",
      countryCode: "",
      contact: contact || "",
      email: email || "",
    };
    axios
      .post(
        `${BASE_SPRING_ENDPOINT}core/api/v1/pricingWebPage/createUserRegistration`,
        payload
      )
      .then(() => {
        setCongratulations(true);
      });
  };
  const handleNextFive = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!name) {
      setErrorFirst(true);
    } else if (!contact) {
      setErrorSecond(true);
    } else if (!email) {
      setErrorThird(true);
    } else if (!emailPattern.test(email)) {
      setErrorThird(true);
    } else {
      registerUser();
      //   pendingOnboardingEmail();
    }
  };

  return (
    <Box>
      {congratulations ? (
        <Success />
      ) : (
        <Box>
          <Typography
            variant="h4"
            sx={{ mb: 0, color: "white" }}
            align="center"
          >
            Contact us
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "white", mt: 2, mb: 1 }}
            align="center"
          >
            Your name
          </Typography>
          <TextField
            sx={{ width: 270 }}
            // sx={{ minWidth: 270 }}
            // label="Name"
            variant="outlined"
            size="small"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e?.target?.value);
              setErrorFirst(false);
            }}
            error={errorFirst}
            helperText={errorFirst ? "This field is required" : ""}
            InputProps={{
              style: { backgroundColor: "white" },
            }}
            InputLabelProps={{ style: { color: "#1BC5BD" } }}
          />

          <Typography
            variant="h5"
            sx={{ mt: 2, mb: 1, color: "white" }}
            align="center"
          >
            Phone number
          </Typography>

          <MuiPhoneNumber
            // sx={{ maxWidth: 270 }}
            defaultCountry={"us"}
            fullWidth
            margin="normal"
            variant="outlined"
            value={contact}
            size="small"
            InputProps={{
              style: { backgroundColor: "white" },
            }}
            onChange={(e) => {
              setContact(e);
              setErrorSecond(false);
            }}
            helperText={
              errorSecond ? "Please enter a valid phone number" : null
            }
            error={errorSecond}
          />

          <Typography
            variant="h5"
            sx={{ mt: 2, mb: 1, color: "white" }}
            align="center"
          >
            Email
          </Typography>

          <TextField
            // label="Email"
            variant="outlined"
            size="small"
            fullWidth
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorThird(false);
            }}
            error={errorThird}
            helperText={errorThird ? "Please enter a valid email" : ""}
            InputProps={{
              style: { backgroundColor: "white" },
            }}
            InputLabelProps={{ style: { color: "#1BC5BD" } }}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="secondary-button"
              onClick={() => setOpenContactModal(false)}
            >
              Cancel
            </Button>
            <Button variant="custom-button" onClick={handleNextFive}>
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
