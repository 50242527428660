import { Box } from "@mui/material";
import React from "react";

export default function Header() {
  const handleClick = () => {
    const redirectLink = "https://business.stayflexi.com";
    window.open(redirectLink, "_blank");

    // window.location.href = redirectLink;
  };
  return (
    <Box
      component="img"
      sx={{
        display: "block",
        maxWidth: "150px",
        overflow: "hidden",
        width: "100%",
        cursor: "pointer",
      }}
      src={process.env.PUBLIC_URL + `/media/sf-logo/sf_logo_new.png`}
      alt={"Stayflexi"}
      onClick={handleClick}
    />
  );
}
