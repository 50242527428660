import {
  InputLabel,
  Select,
  Stack,
  TextField,
  ClickAwayListener,
  Box,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import StockWrapper from "../StockWrapper";
import { connect } from "react-redux";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { exportCSV } from "react-bootstrap-table2-toolkit";

import {
  djangoHandleDataRequests,
  handleSpringDataRequest,
} from "../../../api";
import { SecondaryButton } from "../../../Utils/ButtonHelper";
import { DateRange } from "react-date-range";
import { toast } from "material-react-toastify";
import ReportsLoader from "../../../Utils/ReportsLoader";
import { Divider, FormControl, MenuItem, Typography } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

function FoodCostingReport({ data }) {
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  //columns of table
  const [rowsdats, setrowsdats] = useState(null);
  const [reportsColumns, setreportsColumns] = useState([]);
  const [selectedKitchen, setselectedKitchen] = useState();
  const [selectedShopsId, setselectedShopsId] = useState("");

  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  const [supplier, setsupplier] = useState("");

  // Getting the start date as well as the end date to load the data
  const [dateVal, setDateVal] = useState(dayjs(new Date()));
  const handleChange = (newVal) => {
    setDateVal(newVal);
  };

  //   Get all the shops for the given hotel
  const [shops, setShops] = useState([]);

  const getShopData = () => {
    handleSpringDataRequest(
      `core/api/v1/shop/category/get-all?email=${localStorage.getItem("email")}`
    )
      // .then((res) => console.log(res))
      .then((res) => {
        setShops(res);
        const data = res.filter(
          (shop) => shop.shop_status.toLowerCase() === "active"
        );
        setselectedShopsId(data[0].id);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // Get the suppliers
  const [suppliers, setSuppliers] = useState([]);
  function handleGetAllSuppliers() {
    handleSpringDataRequest("core/api/v1/rm-supplier/get-all")
      .then((res) => {
        setSuppliers(res);
        setsupplier(res[0]?.supplier_id);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    //   handleGetAllSuppliers();
    getShopData();
  }, []);

  //Function to get report data
  const getAllRestaurantReportData = () => {
    setrowsdats(null);
    handleSpringDataRequest(
      `core/api/v1/reports/get-food-costing-report?shopId=${selectedShopsId}&fromDate=${moment(
        dateRange[0].startDate
      ).format("YYYY-MM-DD 00:00:00")}&toDate=${moment(
        dateRange[0].endDate
      ).format("YYYY-MM-DD 23:59:59")}&hotel_id=${hotelId}`
    )
      .then((res) => {
        setreportsData(res);
        setrowsdats(productsGenerator(res.length, res));
      })
      .catch((err) =>
        toast.error("Error in getting  data! Please try again later.")
      );
  };

  useEffect(() => {
    if (
      selectedShopsId !== null &&
      selectedShopsId !== undefined &&
      selectedShopsId !== ""
    )
      getAllRestaurantReportData();
  }, [selectedShopsId]);

  //Rows data generator
  const productsGenerator = (quantity, data) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        menu_item_name: data[i].menu_item_name,
        menu_item_quantity: data[i].menu_item_quantity,
        selling_price: data[i].selling_price,
        total_sell_amount: data[i].total_sell_amount.toFixed(3),
        purchase_price:
          data[i].purchase_price === 0 ? 0 : data[i].purchase_price.toFixed(3),
        total_purchase_amount:
          data[i].total_purchase_amount === 0
            ? 0
            : data[i].total_purchase_amount.toFixed(2),
        profit_ratio: data[i].profit_ratio,
      });
    }
    return items;
  };

  useEffect(() => {
    const names = [
      "Name",
      "Quantity",
      "Selling Price",
      "Total Sell Amount",
      `Purchase Price`,
      `Total Purchase Amount`,
      `Profit Ratio`,
    ];
    if (reportsData?.length > 0) {
      let arr = [];
      const array = Object.keys(reportsData[0]);
      for (var i = 0; i < array?.length; i++) {
        arr.push({
          dataField: array[i],
          text: names[i],
          sort: true,
        });
      }

      setreportsColumns(arr);
    }
  }, [reportsData]);

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <StockWrapper
        backBtn={true}
        hideDrawer={true}
        stockTypeName={"Food Costing Report"}
        contentArea={
          <Stack className="position-relative mt-8">
            <>
              {/* <h1 className="mt-3 mb-3">Warehouse Current Stock Report</h1>
  <Divider className="mt-2 mb-2" /> */}
              {rowsdats ? (
                <div className="App">
                  {/* {reportsColumns.length > 0 && ( */}
                  <ToolkitProvider
                    keyField="id"
                    data={rowsdats}
                    columns={reportsColumns}
                    exportCSV={{ fileName: "Food Costing Report.csv" }}
                    search
                  >
                    {(props) => (
                      <div>
                        <Stack
                          className="position-relative w-100 justify-content-between mt-8"
                          direction={"row"}
                        >
                          <Stack
                            className="position-relative"
                            direction="row"
                            spacing={2}
                          >
                            <SearchBar
                              {...props.searchProps}
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            />
                            <Stack
                              spacing={4}
                              className="position-relative"
                              style={{ width: "fit-content" }}
                            >
                              <ClickAwayListener onClickAway={handleClickAway}>
                                <Box
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <button
                                    onClick={handleClick}
                                    className="border rounded p-2"
                                    style={{
                                      backgroundColor: "transparent",
                                      height: "37.5px",
                                    }}
                                  >
                                    {moment(dateRange[0].startDate).format(
                                      "DD MMM YYYY"
                                    )}{" "}
                                    {" to "}
                                    {moment(dateRange[0].endDate).format(
                                      "DD MMM YYYY"
                                    )}
                                  </button>
                                  {open ? (
                                    <Box sx={styles}>
                                      <Stack spacing={3}>
                                        <DateRange
                                          editableDateInputs={true}
                                          onChange={(item) =>
                                            setDateRange([item.selection])
                                          }
                                          moveRangeOnFirstSelection={false}
                                          ranges={dateRange}
                                        />
                                        <Stack
                                          className="position-relative w-100 d-flex justify-content-between"
                                          direction="row"
                                        >
                                          <SecondaryButton
                                            text="Cancel"
                                            onClick={() => setOpen(false)}
                                          />
                                          <Button
                                            variant="custom-button"
                                            onClick={() => {
                                              getAllRestaurantReportData();
                                              handleClickAway();
                                            }}
                                          >
                                            Apply
                                          </Button>
                                        </Stack>
                                      </Stack>
                                    </Box>
                                  ) : null}
                                </Box>
                              </ClickAwayListener>
                            </Stack>
                            <Stack className="ml-3">
                              <FormControl fullWidth>
                                {/* <InputLabel id="demo-simple-select-label">
                                  Outlet name
                                </InputLabel> */}
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectedShopsId}
                                  label="Outlet name"
                                  name="shop_id"
                                  size="small"
                                  onChange={(e) =>
                                    setselectedShopsId(e.target.value)
                                  }
                                >
                                  {shops
                                    .filter(
                                      (shop) =>
                                        shop.shop_status.toLowerCase() ===
                                        "active"
                                    )
                                    .map((item, index) => (
                                      <MenuItem value={item.id} key={index}>
                                        {item.shop_name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Stack>
                          </Stack>

                          <ExportCSVButton
                            {...props.csvProps}
                            fileName="CustomFileName"
                            style={{
                              display: "block",
                              background: "black",
                              color: "white",
                              marginTop: -1.5,
                              marginBottom: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            Export CSV
                          </ExportCSVButton>
                        </Stack>
                        <Stack className="position-relativre mt-8">
                          {reportsColumns.length > 0 && rowsdats.length > 0 ? (
                            <BootstrapTable
                              pagination={paginationFactory({
                                sizePerPage: 10,
                              })}
                              {...props.baseProps}
                            />
                          ) : (
                            <Typography
                              className="position-relative mt-8 text-align-center"
                              style={{
                                fontSize: "15px",
                                marginTop: "15px",
                                color: "grey",
                                textAlign: "center",
                              }}
                            >
                              No data found!
                            </Typography>
                          )}
                        </Stack>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              ) : (
                <ReportsLoader />
              )}
            </>
          </Stack>
        }
      />
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(FoodCostingReport);
