import axios from "axios";
import { ToastContainer, toast } from "material-react-toastify";
import React, { useEffect } from "react";

function AcceptBid() {
  const url = new URL(window.location.href);
  const isAccept = url.searchParams.get("accept");
  const hotelId = url.searchParams.get("hotelId");
  const bidId = url.searchParams.get("bidId");

  useEffect(() => {
    if (isAccept === "true") {
      axios
        .get(
          `http://35.224.71.140/core/api/v1/bids/acceptBid?hotelId=${hotelId}&bidId=${bidId}`
        )
        .then((res) => toast.success("Bid successfully accepted"))
        .catch((err) => toast.error(err.response.data));
    } else {
      axios
        .get(
          `http://35.224.71.140/core/api/v1/bids/rejectBid?hotelId=${hotelId}&bidId=${bidId}`
        )
        .then((res) => toast.success("Bid successfully rejected"))
        .catch((err) => toast.error(err.response.data));
    }
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        wid
      />
    </div>
  );
}

export default AcceptBid;
