import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { djangoHandleDataMutationRequest } from "../../../api/index";
import noBooking from "./not_found.svg";
import MockLoaderTable from "./MockLoaderTable";
import { connect } from "react-redux";

function BillToCTATable({
  cashDepositsData,
  bookingId,
  hotelId,
  updateInvoice,
  upDateViewManage,
  loggedUserEmail,
  updateSummaryData,
  setOpenManagePay,
  data,
  pgName,
}) {
  // getting the services data for the invoice

  const products = cashDepositsData;

  const columns = [
    {
      dataField: "log_timestamp",
      text: "Log Time",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_date",
      text: "Service Date",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "user_email",
      text: "Action By",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      style: {
        wordBreak: "break-all",
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_description",
      text: "Description",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "approved",
      text: "Accounts payment status",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      style: {
        wordBreak: "break-all",
        fontSize: "15px",
      },
      editable: false,
      formatter: (cell) => {
        if (cell.toLowerCase() === "yes") {
          return "APPROVED";
        } else if (cell.toLowerCase() === "no") {
          return "REJECTED";
        } else {
          return cell;
        }
      },
      hidden:
        JSON.parse(
          data?.accessControl?.user_feature_map?.PaymentConfirmationFolio
        ).read !== "YES",
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "refund_amount",
      text: "Net Adjusted Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_id",
      text: " ",
      sort: false,
      headerStyle: {
        fontSize: "15px",
      },
      formatter: (cell, row) => {
        const cellData = cashDepositsData?.filter(
          (item) => item.service_id === cell
        )[0];
        return cellData?.amount > cellData?.refund_amount
          ? JSON.parse(
              data?.accessControl?.user_feature_map?.DirectBillingAdjustment
            ).read === "YES" && (
              <button
                className="btn viewManageCTABtns"
                onClick={() => handleRefundCounterOpen(row, cell)}
              >
                Adjust (-ve)
              </button>
            )
          : "";
      },
      editable: false,
    },
  ];

  // refund modal

  const [openRefund, setOpenRefund] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [amount, setAmount] = useState();
  const [refundedAmt, setRefundedAmt] = useState(0.0);
  function handleRefundCounterOpen(row, val) {
    setOpenRefund(true);
    setServiceId(val);
    setAmount(row.amount);
    setRefundedAmt(row?.refund_amount);
  }
  const handleRefundCounterClose = () => setOpenRefund(false);

  //refund  states

  const [counterRefundAmt, setCounterRefundAmt] = useState("");
  const [counterRefundDate, setCounterRefundDate] = useState(new Date());
  const [counterRefundName, setCounterRefundName] = useState();

  // refund modal loading
  const [openRefundLoading, setOpenRefundLoading] = useState(false);

  // refund modal --- api
  function handleRefundCounter() {
    setOpenRefundLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/refundCTAPayment/", {
      hotel_id: hotelId,
      service_id: serviceId,
      booking_id: bookingId,
      amount: Number(counterRefundAmt),
      refund_reason: counterRefundName,
      payment_type: "Direct Billing",
      logged_user_email: loggedUserEmail,
    })
      .then((res) => updateInvoice())
      .then((res) => updateSummaryData())
      .then((res) => toast.success("Successfully adjusted the amount"))
      .then((res) => upDateViewManage())
      .then((res) => setOpenRefundLoading(false))
      .then((res) => handleRefundCounterClose())
      .then((res) => setCounterRefundAmt())
      .then((res) => setCounterRefundDate(new Date()))
      .then((res) => setCounterRefundName())
      .catch((error) => toast.error("Ooops! Couldn't refund the amount."))
      .then((error) => updateInvoice())
      .then((error) => updateSummaryData())
      .then((error) => upDateViewManage())
      .then((error) => setOpenRefundLoading(false))
      .then((error) => handleRefundCounterClose())
      .then((error) => setCounterRefundAmt())
      .then((error) => setCounterRefundDate(new Date()))
      .then((error) => setCounterRefundName());
  }

  const [cashDepositChargeAmt, setCashDepositChargeAmt] = useState("");

  const [cashDepositChargeName, setCashDepositChargeName] = useState();

  useEffect(() => {
    const filteredData = cashDepositsData?.filter(
      (item) => item.service_id === serviceId
    )[0];
    setCashDepositChargeAmt(filteredData?.amount);
    setCashDepositChargeName(filteredData?.service_description);
  }, [serviceId]);

  return (
    <>
      {products ? (
        <div className="invoiceSummTableWrapper">
          <ToolkitProvider
            keyField="service_id"
            data={products}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockLoaderTable />
      )}
      {/* refund modal to refund the service items amount */}
      <Modal
        open={openRefund}
        onClose={handleRefundCounterClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Adjust CTA payments</div>
              <div className="close" onClick={() => handleRefundCounterClose()}>
                X
              </div>
            </div>
            <div className="fieldWrapper mt-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Adjustment date"
                  value={counterRefundDate}
                  onChange={(newValue) => {
                    setCounterRefundDate(newValue);
                  }}
                  name="refund_date"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="fieldWrapper mt-3">
              <TextField
                id="outlined-name"
                label="Amount"
                type="text"
                name="refund_amount"
                value={counterRefundAmt}
                onChange={(e) =>
                  /^[0-9.]*$/.test(e.target.value)
                    ? setCounterRefundAmt(e.target.value)
                    : setCounterRefundAmt(counterRefundAmt)
                }
                sx={{ width: "100%" }}
              />
              {counterRefundAmt > amount - refundedAmt && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "smaller",
                  }}
                >
                  Refund amount exceeding bill amount
                </div>
              )}
            </div>
            <div className="fieldWrapper mt-3">
              <TextField
                id="outlined-name"
                label="Adjustment reason"
                name="refund_reason"
                value={counterRefundName}
                onChange={(e) => setCounterRefundName(e.target.value)}
                sx={{ width: "100%" }}
              />
            </div>

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleRefundCounterClose()}
              >
                Cancel
              </button>
              {openRefundLoading === false ? (
                <button
                  className="submit"
                  onClick={() => handleRefundCounter()}
                  style={{ width: "150px" }}
                  disabled={
                    counterRefundAmt > 0 &&
                    counterRefundAmt <= amount - refundedAmt
                      ? false
                      : true
                  }
                >
                  Adjust
                </button>
              ) : (
                <button className="submit" style={{ width: "150px" }}>
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(BillToCTATable);
