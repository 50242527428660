import {
  Stack,
  Button,
  Box,
  ClickAwayListener,
  Divider,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { djangoHandleDataRequests } from "../../../../api";
import ShopWrapper from "../ShopWrapper";
import moment from "moment";
import { DateRange } from "react-date-range";
import { SecondaryButton } from "../../../../Utils/ButtonHelper";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import ReportsLoader from "../../../../Utils/ReportsLoader";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const OrderWiseSalesSummaryReportForParticularDay = ({ data }) => {
  // getting the hotel currency
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId } = useParams();

  // Getting the start date as well as the end date to load the data
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  const [copyreportsData, setCopyreportsData] = useState(null);

  useEffect(() => {
    getOrderWiseSalesSummaryReportForParticularDayData();
  }, []);

  //Function to get report data
  const getOrderWiseSalesSummaryReportForParticularDayData = () => {
    setCopyreportsData(null);
    shopId &&
      djangoHandleDataRequests(
        `pos/OrderWiseSalesSummaryReportForParticularDay/?shop_id=${shopId}&start_date=${moment(
          dateRange[0].startDate
        ).format("YYYY-MM-DD 00:00:00")}&end_date=${moment(
          dateRange[0].endDate
        ).format("YYYY-MM-DD 23:59:59")}&hotel_id=${hotelId}`
      )
        .then((res) => {
          setreportsData(res);
          setCopyreportsData(
            res.filter((item) => item.payment_type.length > 0).map((i) => i)
          );
          // setrowsdats(productsGenerator(res.length, res));
        })
        .catch((err) =>
          toast.error("Error in getting data! Please try again later.")
        );
  };

  // Columns
  const headerStyle = {
    fontSize: "12px",
    width: "125px",
    wordBreak: "break-all",
  };

  const columns = [
    {
      dataField: "order_no",
      text: "Invoice no.",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
      },
    },
    {
      dataField: "order_status",
      text: "Order status",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "my_amount",
      text: "My amount",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "total_discount",
      text: "Total discount",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "service_charge",
      text: "Service charge",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "net_sales",
      text: "Net sales",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "cgst_amount",
      text: "CGST amount",
      hidden: hotelCurrency.toLowerCase() === "inr" ? false : true,
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "sgst_amount",
      text: `${
        localStorage.getItem("utgstStatus") === "ENABLED" ? "UTGST" : "SGST"
      } amount`,
      hidden: hotelCurrency.toLowerCase() === "inr" ? false : true,
      dataType: "float",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "tax_percentage",
      text: "Tax %",
      hidden: hotelCurrency.toLowerCase() === "inr" ? false : true,
      dataType: "float",
      formatter: (cell) => {
        return Number(cell).toFixed(2);
      },
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "cgst_tax_perc",
      text: "CGST tax %",
      hidden: hotelCurrency.toLowerCase() === "inr" ? false : true,
      dataType: "float",
      formatter: (cell) => {
        return Number(cell).toFixed(2);
      },
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "sgst_tax_perc",
      text: `${
        localStorage.getItem("utgstStatus") === "ENABLED" ? "UTGST" : "SGST"
      } tax %`,
      hidden: hotelCurrency.toLowerCase() === "inr" ? false : true,
      dataType: "float",
      formatter: (cell) => {
        return Number(cell).toFixed(2);
      },
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "total_sales",
      text: "Total sales",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "settled_amount",
      text: "Settled amount",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "balance_amount",
      text: "Balance amount",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "payment_type",
      text: "Payment type",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "comp_reason",
      text: "Reason",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "remark",
      text: "Remarks",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "staff_name",
      text: "Staff name",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
  ];

  // tax_percentage, cgst_tax_perc, sgst_tax_perc

  //Rows data generator
  // const productsGenerator = (quantity, data) => {
  //   const items = [];
  //   for (let i = 0; i < quantity; i++) {
  //     if (data[i].payment_type.length > 0) {
  //       items.push({
  //         order_no: data[i].order_no,
  //         date: data[i].date,
  //         order_status: data[i].order_status,
  //         my_amount: data[i].my_amount,
  //         total_discount: data[i].total_discount,
  //         service_charge: data[i].service_charge,
  //         net_sales: data[i].net_sales,
  //         total_tax: data[i].total_tax,
  //         cgst_amount: data[i].cgst_amount,
  //         sgst_amount: data[i].sgst_amount,
  //         total_sales: data[i].total_sales,
  //         settled_amount: data[i].settled_amount,
  //         balance_amount: data[i].balance_amount,
  //         payment_type: data[i].payment_type,
  //         comp_reason: data[i].comp_reason,
  //       });
  //     }
  //   }
  //   return items;
  // };

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  //columns of table

  // const [rowsdats, setrowsdats] = useState([]);
  // const [reportsColumns, setreportsColumns] = useState([]);
  // const names = [
  //   "Invoice No.",
  //   "Date",
  //   "Order status",
  //   `My Amount (${hotelCurrency})`,
  //   `Total discount (${hotelCurrency})`,
  //   `Service charge (${hotelCurrency})`,
  //   `Net sales (${hotelCurrency})`,
  //   `Total tax (${hotelCurrency})`,
  //   `CGST Amount (${hotelCurrency})`,
  //   `SGST Amount (${hotelCurrency})`,
  //   `Total sales (${hotelCurrency})`,
  //   `Settled amount (${hotelCurrency})`,
  //   `Balance amount (${hotelCurrency})`,
  //   "Payment type",
  //   "Reason",
  // ];
  // useEffect(() => {
  //   if (reportsData?.length > 0) {
  //     let arr = [];
  //     const array = Object.keys(reportsData[0]);
  //     for (var i = 0; i < array?.length; i++) {
  //       arr.push({
  //         dataField: array[i],
  //         text: names[i],
  //         sort: true,
  //         style: {
  //           wordWrap: "break-word",
  //           overflow: "hidden",
  //         },
  //         headerStyle: {
  //           wordWrap: "break-word",
  //           overflow: "hidden",
  //         },
  //       });
  //     }

  //     if (hotelCurrency.toLowerCase() !== "inr") {
  //       const index_cgst = arr.findIndex(
  //         (ob) => ob.dataField.toLowerCase() === "cgst_amount"
  //       );
  //       arr.splice(index_cgst + 1, 1);
  //       arr.splice(index_cgst, 1);

  //       return setreportsColumns(arr);
  //     } else {
  //       setreportsColumns(arr);
  //     }
  //     // setreportsColumns(arr);
  //   }
  // }, [reportsData, hotelCurrency]);

  return (
    <ShopWrapper
      backBtn={true}
      contentArea={
        <>
          <h1 className="mt-3 mb-3">Order Wise Sales Summary Report</h1>
          <Divider className="mt-2 mb-2" />
          {copyreportsData ? (
            <div className="App">
              {/* {reportsColumns.length > 0 && ( */}
              <ToolkitProvider
                keyField="order_no"
                data={copyreportsData}
                // data={rowsdats}
                columns={columns}
                exportCSV
                search
              >
                {(props) => (
                  <div>
                    <Stack
                      className="position-relative w-100 justify-content-between mt-4"
                      direction="row"
                    >
                      <Stack
                        className="position-relative"
                        spacing={2}
                        direction="row"
                      >
                        <SearchBar
                          {...props.searchProps}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            height: "40px",
                          }}
                        />
                        <Stack spacing={4} className="position-relative">
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <Box sx={{ position: "relative" }}>
                              <button
                                onClick={handleClick}
                                className="border border-1 rounded p-2"
                                style={{
                                  backgroundColor: "transparent",
                                  height: "37.5px",
                                }}
                              >
                                {moment(dateRange[0].startDate).format(
                                  "DD MMM YYYY"
                                )}{" "}
                                {" to "}
                                {moment(dateRange[0].endDate).format(
                                  "DD MMM YYYY"
                                )}
                              </button>
                              {open ? (
                                <Box sx={styles}>
                                  <Stack spacing={3}>
                                    <DateRange
                                      editableDateInputs={true}
                                      onChange={(item) =>
                                        setDateRange([item.selection])
                                      }
                                      moveRangeOnFirstSelection={false}
                                      ranges={dateRange}
                                    />
                                    <Stack
                                      className="position-relative w-100 d-flex justify-content-between"
                                      direction="row"
                                    >
                                      <SecondaryButton
                                        text="Cancel"
                                        onClick={() => setOpen(false)}
                                      />
                                      <Button
                                        variant="custom-button"
                                        onClick={() => {
                                          getOrderWiseSalesSummaryReportForParticularDayData();
                                          handleClickAway();
                                        }}
                                      >
                                        Apply
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </Box>
                              ) : null}
                            </Box>
                          </ClickAwayListener>
                        </Stack>
                      </Stack>
                      <ExportCSVButton
                        {...props.csvProps}
                        style={{
                          display: "block",
                          background: "black",
                          color: "white",
                          marginTop: -1.5,
                          marginBottom: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        Export CSV
                      </ExportCSVButton>
                    </Stack>
                    <Stack className="mt-4">
                      {columns.length > 0 && copyreportsData.length > 0 ? (
                        <BootstrapTable
                          pagination={paginationFactory({ sizePerPage: 10 })}
                          {...props.baseProps}
                        />
                      ) : (
                        <Typography
                          className="position-relative mt-8 text-align-center"
                          style={{
                            fontSize: "15px",
                            marginTop: "15px",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          No data found!
                        </Typography>
                      )}
                    </Stack>
                  </div>
                )}
              </ToolkitProvider>
              {/* )} */}
            </div>
          ) : (
            <ReportsLoader />
          )}
        </>
      }
    />
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(
  OrderWiseSalesSummaryReportForParticularDay
);
