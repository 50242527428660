import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
// ** React Imports
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "material-react-toastify";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {
  addNewCustomerNote,
  addNewDoNotRentStatus,
  deleteTheCustomerNotes,
  getAllCustomerNotes,
  removeNewDoNotRentStatus,
  upadateNotes,
} from "../api/BookingAndCustomerNotesApi";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  FormControl,
  Select,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Card, Modal } from "react-bootstrap";
import {
  addNewBookingNote,
  deleteTheBookingNotes,
  getAllBookingNotes,
} from "../api/BookingAndCustomerNotesApi";
import { shallowEqual, useSelector } from "react-redux";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";

function Notes(props) {
  const {
    reservationData,
    saveCustomer,
    saveCustomerRes,
    load,
    doNotRent,
    getDoNotRentStatus,
    setLoad,
    allGuestList,
    eventData,
    fetchGuestList,
  } = props;
  const [selectedNotes, setSelectedNotes] = useState("Booking Notes");
  const [bookingNotes, setbookingNotes] = useState([]);
  const [loading, setloading] = useState(false);
  const [bookingNoteData, setbookingNoteData] = useState("");
  const [remark, setremark] = useState("");
  const [allBookingTags, setallBookingTags] = useState([]);
  const [bookingRemark, setbookingRemark] = useState("");
  const [updateNotesModal, setupdateNotesShow] = useState(false);
  const [oldEmail, setoldEmail] = useState(reservationData.user_email);

  const handleNoteClose = () => setupdateNotesShow(false);
  const handleNoteShow = () => setupdateNotesShow(true);
  // tag modal apis
  const [allHotelTags, setallHotelTags] = useState([]);

  //Add Do Not rent
  const [dshow, setDShow] = useState(false);

  const handleDClose = () => setDShow(false);
  const handleDShow = () => setDShow(true);

  //Add Do Not rent
  const [rshow, setRShow] = useState(false);

  const handleRClose = () => setRShow(false);
  const handleRShow = () => setRShow(true);

  //Add Booking Note Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Add Booking Note Modal
  const [bookingShow, setBookingShow] = useState(false);

  const handleCloseBookingShow = () => setBookingShow(false);
  const handleOpenBookingShow = () => setBookingShow(true);

  //Add Booking Note Modal
  const [showTagModal, setshowTagModal] = useState(false);

  const handleCloseTagModal = () => setshowTagModal(false);
  const handleShowTagModal = () => setshowTagModal(true);

  const [checked, setChecked] = React.useState(false);
  const [updateChecked, setUpdateChecked] = useState(false);

  const handleChanges = () => {
    setChecked(!checked);
  };

  //Add delete Note Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [Severity, setSeverity] = useState("HIGH");

  const handleDeleteClose = () => setShowDeleteModal(false);

  //Customer Notes
  const [customerNotes, setcustomerNotes] = useState([]);
  const [loader, setloader] = useState(false);
  const [customerNoteData, setcustomerNoteData] = useState("");
  const [showUserIdImagesback, setshowUserIdImagesback] = useState(0);

  useEffect(async () => {
    await getAllCustomerData();
  }, []);

  const getAllCustomerData = async () => {
    try {
      const data = await getAllCustomerNotes(reservationData.user_email);
      setcustomerNotes(data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteShow = (id) => {
    setdeleteId(id);
    setShowDeleteModal(true);
  };
  const [deleteId, setdeleteId] = useState("");

  const deleteNoteInModal = async (id) => {
    handleDeleteShow(id);
  };

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  useEffect(async () => {
    await getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const data = await getAllBookingNotes(reservationData.booking_id);
      setbookingNotes(data);
    } catch (e) {
      console.error(e);
    }
  };
  const deleteBookingNote = async (id, notes) => {
    const data = await deleteTheBookingNotes(
      reservationData.booking_id,
      id,
      localStorage.getItem("email"),
      notes
    ).then((data)=>{
      toast.success("Successfully deleted the booking note")
      getAllData();}
    ).catch((err)=>{
      toast.error("Something went wrong !");
    })
    // if (data.message === "Booking note deleted") {
    //   toast.success("Successfully deleted the booking note");
    // } else {
    //   toast.error("Something went wrong !");
    // }
    // getAllData();
  };

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/booking-tags/get-all?bookingId=${reservationData?.booking_id}`
    )
      .then((res) => {
        setallBookingTags(res[0]?.tags?.split(","));
        setbookingRemark(res[0]?.remark);
        // const data = res[0].tags.split(",");
        // console.log("allbokking tags", data);
        // setselectedBookingTags(data);
      })
      .catch((err) => console.error("Error in fetching tags"));

    handleSpringDataRequest(`core/api/v1/hotel-booking-tags/get-all`)
      .then((res) => {
        const data = res.filter((item) => item.status === "ACTIVE");
        // console.log("all hotel tags", data);
        setallHotelTags(data);
      })
      .catch((err) => console.error("Error in fetching tags"));
  }, []);

  const createBookingNote = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      bookingID: reservationData.booking_id,
      note: bookingNoteData,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewBookingNote(payload);
    setloading(false);
    if (data.message === "Booking note recorded") {
      toast.success("Successfully created the booking note");
    } else {
      toast.error("Something went wrong !");
    }
    await getAllData();
    handleCloseBookingShow();
  };

  const updateBookingTags = () => {
    const payload = {
      id: "",
      bookingId: reservationData.booking_id,
      tags: allBookingTags.toString(),
      remark: remark,
    };
    handleSpringDataMutationRequest(
      "PUT",
      `core/api/v1/booking-tags/update`,
      payload
    )
      .then((res) => {
        toast.success("Successfully added tag");
        setremark("");
        handleCloseTagModal();
      })
      .catch((err) => {
        toast.error(err.response);
        setremark("");
        handleCloseTagModal();
      });
  };

  const handleSelectedBookingTags = (name) => {
    // console.log(name, allBookingTags.indexOf(name));
    var idx = allBookingTags.indexOf(name);
    if (idx !== -1) {
      const temp = allBookingTags.filter((item) => item !== name && item);
      // console.log("temp : ", temp);
      return setallBookingTags(temp);
    } else {
      setallBookingTags([...allBookingTags, name]);
    }
  };

  const createCustomerNote = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      cust_email: oldEmail,
      note: customerNoteData,
      do_not_rent: checked,
      bookingId: reservationData.booking_id,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewCustomerNote(payload).then(()=>{
      toast.success("Successfully created the customer note");
    }).catch(()=>toast.error("Something went wrong !"))
    setloading(false);
    // if (data.message === "Customer note recorded") {
    //   toast.success("Successfully created the customer note");
    // } else {
    //   toast.error("Something went wrong !");
    // }
    setChecked(false);
    await getAllCustomerData();
    handleClose();
  };

  const createDoNotRentStatus = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      emailId: oldEmail,
      severity: Severity,
    };
    const data = await addNewDoNotRentStatus(payload);
    setloading(false);
    if (data.status === 200) {
      toast.success("Successfully added Do-Not rent status");
    } else {
      toast.error("Something went wrong !");
    }
    setChecked(false);
    await getDoNotRentStatus(oldEmail);
    handleDClose();
  };

  const removeDoNotRentStatus = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      emailId: oldEmail,
      status: "NO",
      severity: Severity,
    };
    const data = await removeNewDoNotRentStatus(payload);
    setloading(false);
    if (data.status === 200) {
      toast.success("Successfully removed Do-Not rent status");
    } else {
      toast.error("Something went wrong !");
    }
    setChecked(false);
    await getDoNotRentStatus(oldEmail);
    handleRClose();
  };

  const deleteCustomerNote = async (id, notes) => {
    const data = await deleteTheCustomerNotes(
      oldEmail,
      id,
      reservationData.booking_id,
      localStorage.getItem("email"),
      notes
    );

    if (data.message === "Customer note deleted") {
      toast.success("Successfully deleted the customer note");
    } else {
      toast.error("Something went wrong !");
    }
    getAllCustomerData();
  };

  const UpdateDonotRent = async () => {
    const data = await upadateNotes(oldEmail);
    if (data === "Updated do not rent") {
      toast.success(data);
    } else {
      toast.error("Something went wrong");
    }
    getAllCustomerData();
    handleNoteClose();
  };

  const deletCustomerNotes = async () => {
    const data = await deleteTheCustomerNotes(
      oldEmail,
      deleteId,
      localStorage.getItem("email"),
      customerNoteData
    );
    if (data.message === "Customer note deleted") {
      toast.success("Successfully deleted the customer note");
    } else {
      toast.error("Something went wrong !");
    }
    getAllCustomerData();
    handleDeleteClose();
  };
  return (
    <div>
      {" "}
      <FormControl style={{ marginTop: "10px", marginLeft: "10rem" }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedNotes}
          onChange={(e) => setSelectedNotes(e.target.value)}
        >
          <FormControlLabel
            value="Booking Notes"
            control={<Radio />}
            label="Booking Notes"
          />
          <FormControlLabel
            style={{ marginLeft: "4rem" }}
            value="Customer Notes"
            control={<Radio />}
            label="Customer Notes"
          />
        </RadioGroup>
      </FormControl>
      <Modal
        show={bookingShow}
        onHide={handleCloseBookingShow}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a booking note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* //Tag modal */}
          <Modal
            show={showTagModal}
            onHide={handleCloseTagModal}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Booking tags</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="staffTableWrapper">
                <Stack
                  direction={"row"}
                  className="position-relative w-100 d-flex justify-content-start"
                  // spacing={1}
                  style={{
                    flexWrap: "wrap",
                  }}
                >
                  {allHotelTags.map((item) => (
                    <Stack className="mr-2 mb-2">
                      <Button
                        key={item.roomId}
                        variant={
                          allBookingTags?.includes(item.tagName) &&
                          "custom-button"
                        }
                        style={{
                          height: "37.5px",
                          border: allBookingTags?.includes(item.tagName)
                            ? "none"
                            : "2px solid black",
                          color: allBookingTags?.includes(item.tagName)
                            ? "white"
                            : "black",
                          width: "fit-content",
                          padding: "2px",
                          textTransform: "capitalize",
                        }}
                        onClick={() => handleSelectedBookingTags(item.tagName)}
                      >
                        {" "}
                        {item.tagName}{" "}
                      </Button>
                    </Stack>
                  ))}
                  <TextField
                    label="Remark"
                    className="input-lg w-100 mt-3"
                    placeholder="Enter remark  here"
                    onChange={(e) => setremark(e.target.value)}
                    value={remark}
                    name="schedule_name"
                    id="schedule_name"
                  />
                </Stack>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseTagModal}>
                Close
              </Button>
              <Button variant="custom-button" onClick={updateBookingTags}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>{" "}
          {/* <OutlinedInput
                label=""
                notched={false}
                className="input-lg w-100"
                placeholder="Enter a note"
                onChange={(e) => setbookingNoteData(e.target.value)}
              /> */}
          <TextField
            required
            id="outlined-required"
            label="Booking notes"
            placeholder="Enter a note"
            className="w-100"
            onChange={(e) => setbookingNoteData(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBookingShow}>
            Close
          </Button>
          <Button
            variant="custom-button"
            disabled={bookingNoteData.length === 0}
            onClick={createBookingNote}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose} style={{ zIndex: "3000" }}>
        <Modal.Header closeButton>
          <Modal.Title>Enter note for this customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="d-flex align-items-center ml-3 mb-3">
              <input
                type="checkbox"
                checked={checked}
                onChange={handleChanges}
                style={{ marginBottom: "0px" }}
              />
              <p
                className="mb-0 ml-2 badge badge-danger"
                style={{ fontSize: "17px" }}
              >
                Do not rent
              </p>
            </div> */}

          <TextField
            required
            id="outlined-required"
            label="Customer notes"
            placeholder="Enter a note"
            className="w-100"
            onChange={(e) => setcustomerNoteData(e.target.value)}
          />
          {/* <OutlinedInput
              label=""
              notched={false}
              className="input-lg w-100"
              placeholder="Enter a note"
              onChange={(e) => setcustomerNoteData(e.target.value)}
            /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="custom-button"
            disabled={customerNoteData.length === 0}
            onClick={createCustomerNote}
          >
            {loading ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={dshow} onHide={handleDClose} style={{ zIndex: "3000" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you want to add Donot rent tag to this user
          </Modal.Title>
        </Modal.Header>
        <select
          className="form-select form-select-lg ml-4 mr-4"
          size="lg"
          style={{
            marginLeft: "3rem !important",
            marginRight: "3rem !important",
            heigth: "3rem !important",
          }}
          value={Severity}
          onChange={(e) => setSeverity(e.target.value)}
        >
          <option selected value="LOW">
            LOW
          </option>
          <option value="MEDIUM">MEDIUM</option>

          <option value="HIGH">HIGH</option>
          <option value="BLACKLIST">BLACKLIST</option>
        </select>
        {/* <>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => console.log("e", e)}
            >
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </> */}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleDClose}>
            Close
          </Button>
          <Button variant="custom-button" onClick={createDoNotRentStatus}>
            {loading ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={rshow} onHide={handleRClose} style={{ zIndex: "3000" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you want to remove Donot rent tag to this user
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleRClose}>
            Close
          </Button>
          <Button variant="custom-button" onClick={removeDoNotRentStatus}>
            {loading ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete Customer Note */}
      <Modal
        show={showDeleteModal}
        onHide={handleDeleteClose}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete customer note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Deleting these will not remove donot rent status of customer</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="custom-button" onClick={() => deletCustomerNotes()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={updateNotesModal}
        onHide={handleNoteClose}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Are you sure you want to remove donot rent?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Close
          </Button>
          <Button variant="custom-button" onClick={() => UpdateDonotRent()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {selectedNotes === "Booking Notes" && (
        <>
          <div className="d-flex align-items-center mb-3">
            <h5
              style={{
                marginLeft: "16px",
                marginRight: "16px",
                marginBottom: "0px",
                marginTop: "1rem",
              }}
            >
              Booking Notes
            </h5>
            <AddIcon
              onClick={handleOpenBookingShow}
              disabled={loading}
              sx={{
                marginTop: "1rem",
                "&:hover": { backgroundColor: "#B5B5C3", borderRadius: "50px" },
              }}
            >
              Add booking note
              {loading && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </AddIcon>
            {localStorage.getItem("email") ===
              "ishafoundationsbeta@example.com" && (
              <TurnedInIcon
                onClick={handleShowTagModal}
                sx={{
                  cursor: "pointer",
                  marginLeft: "1rem",
                  "&:hover": {
                    backgroundColor: "#B5B5C3",
                    borderRadius: "50px",
                  },
                }}
              ></TurnedInIcon>
            )}
          </div>{" "}
          {bookingNotes.length <= 0 ? (
            <h6 style={{ marginLeft: "16px" }} className="text-muted">
              No booking notes{" "}
            </h6>
          ) : (
            bookingNotes?.map((e) => (
              <div className="d-flex align-items-center">
                <Card className="mb-2" style={{ marginLeft: "16px" }}>
                  <Card.Body style={{ width: "40rem", padding: "1rem" }}>
                    {e.notes}
                  </Card.Body>
                </Card>
                <DeleteIcon
                  onClick={() => deleteBookingNote(e.id, e?.notes)}
                  className="ml-2"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))
          )}
        </>
      )}
      {selectedNotes === "Customer Notes" && (
        <>
          <div className="d-flex align-items-center mb-3">
            <h5
              style={{
                marginLeft: "16px",
                marginRight: "16px",
                marginBottom: "0px",
              }}
            >
              Customer Notes
            </h5>
            <AddIcon
              onClick={handleShow}
              disabled={loading}
              sx={{
                "&:hover": { backgroundColor: "#B5B5C3", borderRadius: "50px" },
              }}
            >
              Add customer note
              {loading && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </AddIcon>
            {(doNotRent === undefined || doNotRent?.do_not_rent === "NO") && (
              <Button
                // className={classes.footer}
                style={{
                  height: "2.5rem",
                  display: "block",
                  marginLeft: "0.5rem",
                  marginRight: "1rem",
                  background: "#F64E60",
                  color: "#fff",
                }}
                onClick={handleDShow}
              >
                Do-Not Rent
              </Button>
            )}

            {doNotRent && doNotRent?.do_not_rent === "YES" && (
              <Button
                // className={classes.footer}
                variant="custom-button"
                style={{
                  height: "2.5rem",
                  display: "block",
                  marginLeft: "0.5rem",
                  marginRight: "1rem",
                  color: "#fff",
                }}
                onClick={handleRShow}
              >
                Remove Do-Not Rent
                {/* {load && (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            )} */}
              </Button>
            )}
          </div>

          {customerNotes.length <= 0 ? (
            <h6 style={{ marginLeft: "16px" }} className="text-muted">
              No customer notes{" "}
            </h6>
          ) : (
            customerNotes?.map((e) => (
              <div className="d-flex align-items-center">
                <Card className="mb-2" style={{ marginLeft: "16px" }}>
                  <Card.Body style={{ width: "40rem", padding: "1rem" }}>
                    {e.notes}
                  </Card.Body>
                </Card>
                {e.do_not_rent === "YES" && (
                  <p
                    className="mb-0 badge badge-danger ml-2"
                    style={{ fontSize: "13px" }}
                  >
                    <DoNotDisturbIcon
                      className="mb-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNoteShow()}
                    />
                  </p>
                )}

                <DeleteIcon
                  onClick={() =>
                    e.do_not_rent === "YES"
                      ? deleteNoteInModal(e.id)
                      : deleteCustomerNote(e.id, e?.notes)
                  }
                  className="ml-2"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
}

export default Notes;
