import axios from "axios";
import { toast } from "material-react-toastify";

const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETHOTELIMAGESURL = "user/groupBranding";
const SETHOTELIMAGESURL = "common/set-group-whitelabel-config";
const USERID = "core/api/v1/image/update-user-id-images";
const GUESTSID = "core/api/v1/image/update-guests-id-images";
const ISHAPRIMARYDOC = "core/api/v1/image/upload-isha-primary-attachments";
const ISHAOTHERDOC = "core/api/v1/image/upload-isha-other-guests-attachments";
const GETISHADOCS = "core/api/v1/image/get-isha-guest-documents";
const GETUSERID = "core/api/v1/image/get-user-id-images";
const UPDATEHOTELLOGO = "core/api/v1/image/update-hotel-logo";

export const getAllImagesAndUrl = async () => {
  return axios
    .get(
      COMMON_URL +
        GETHOTELIMAGESURL +
        "?hostUrl=" +
        `${window.location.hostname}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setLogoAndFavicon = async (data) => {
  return axios
    .post(COMMON_URL + SETHOTELIMAGESURL, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setBookingUserIdImages = async (data) => {
  return axios
    .post(COMMON_URL + USERID, data)
    .then((resp) => resp.data)
    .catch((err) => {
      toast.error(err.response.data);
    });
};

export const setOtherGuestImages = async (data) => {
  return axios
    .post(COMMON_URL + GUESTSID, data)
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};

export const setIshaGuestDocument = async (data) => {
  return axios
    .post(COMMON_URL + ISHAPRIMARYDOC, data)
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};

export const setIshaOtherGuestDocuments = async (data) => {
  return axios
    .post(COMMON_URL + ISHAOTHERDOC, data)
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getIshaDocuments = async (emailId) => {
  return axios
    .get(COMMON_URL + GETISHADOCS + "?emailId=" + `${emailId}`)
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};
export const setIshaUserImages = async (data) => {
  return axios
    .post(
      "https://isha-api.stayflexi.com/web-api/isha/update-user-ID-images",
      data
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getUserId = async (emailId) => {
  return axios
    .get(COMMON_URL + GETUSERID + "?emailId=" + emailId)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
export const getUserIdWithHotelId = async (emailId, hotelId) => {
  return axios
    .get(
      COMMON_URL +
        GETUSERID +
        "?emailId=" +
        emailId +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getUserIdIsha = async (emailId, hotelId) => {
  return axios
    .get(
      COMMON_URL +
        "web-api/isha/get-user-id-images" +
        "?emailId=" +
        emailId +
        "&hotelId=" +
        hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setHotelLogo = async (data) => {
  return axios
    .post(COMMON_URL + UPDATEHOTELLOGO, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
