export const unionTerritories = [
  "LADAKH",
  "JAMMU AND KASHMIR",
  "PUDUCHERRY",
  "LAKSHADWEEP",
  "DELHI",
  "CHANDIGARH",
  "DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  "ANDAMAN AND NICOBAR ISLANDS",
];
