import React, { useState } from "react";
import "../../css/Form.css";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import CheckIcon from "@mui/icons-material/Check";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import RoofingOutlinedIcon from "@mui/icons-material/RoofingOutlined";
import { useEffect } from "react";
import axios from "axios";
import ApartmentIcon from "@mui/icons-material/Apartment";
import VillaIcon from "@mui/icons-material/Villa";
import { codes } from "../CountryCode.js";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../api/index.js";
import { BASE_SPRING_ENDPOINT } from "../../../../api/config.js";
import Calendly from "./Calendly.jsx";
import MuiPhoneNumber from "material-ui-phone-number";
import InfoIcon from "@mui/icons-material/Info";
import Success from "./Success.jsx";
import amplitude from "amplitude-js";
import { AMPLITUDE_KEY } from "../../../CalendarPage/constants/AmplitudeConstants.js";

export default function Forms({ handleBackHome, region, scrollToHeight }) {
  const checkboxStyle = {
    root: {
      borderColor: "white",
    },
    checked: {
      borderColor: "white",
    },
    ml: 1,
    backgroundColor: "white",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 800,
    minHeight: 200,
    bgcolor: "#666666",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };
  const arr = [0, 0, 0, 0, 0, 0];
  const portfolioIcons = [
    <HotelOutlinedIcon sx={{ fontSize: "4rem" }} />,
    <VillaIcon sx={{ fontSize: "4rem" }} />,
    <ApartmentIcon sx={{ fontSize: "4rem" }} />,
    <RoofingOutlinedIcon sx={{ fontSize: "4rem" }} />,
  ];
  const propertyIcon = [
    <CorporateFareIcon sx={{ fontSize: "4rem" }} />,
    <DomainAddIcon sx={{ fontSize: "4rem" }} />,
  ];
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorFirst, setErrorFirst] = useState(false);
  const [errorSecond, setErrorSecond] = useState(false);
  const [errorThird, setErrorThird] = useState(false);
  const resetErrors = () => {
    setErrorFirst(false);
    setErrorSecond(false);
    setErrorThird(false);
  };

  const [step, setStep] = useState(0);

  //------------Step 0---------------

  const [country, setCountry] = useState({
    India: false,
    USA: false,
    Other: false,
  });
  const [portfolio, setPortfolio] = useState({
    Hotel: true,
    "Vacation Rental": false,
    Hostel: false,
    "Alternative Accommodation": false,
  });
  useEffect(() => {
    setCountry(region);
  }, [region]);

  const handleCountry = (item) => {
    setErrorFirst(false);
    const data = {
      India: false,
      USA: false,
      Other: false,
    };
    data[item] = true;
    setCountry(data);
  };
  const handlePortfolio = (item) => {
    setErrorSecond(false);
    const data = {
      Hotel: false,
      "Vacation Rental": false,
      Hostel: false,
      "Alternative Accommodation": false,
    };
    data[item] = true;
    setPortfolio(data);
  };
  const handleNextZero = () => {
    if (Object.values(country).every((value) => value === false)) {
      setErrorFirst(true);
    } else if (Object.values(portfolio).every((value) => value === false)) {
      setErrorSecond(true);
    } else {
      setStep(5);
      // if (portfolio["Alternative Accommodation"]) {
      //   setStep(5);
      // } else {
      //   setStep(1);
      // }
    }
  };
  //----Step 1----------

  const [propertyType, setPropertyType] = useState({
    Single: true,
    Multiple: false,
  });
  const [quantity, setQuantity] = useState(1);
  const handleProprtyType = (item) => {
    setErrorFirst(false);
    const data = { Single: false, Multiple: false };
    data[item] = true;
    setPropertyType(data);
  };

  const handleQuantity = (qty) => {
    if (qty >= 0) {
      setQuantity(qty);
    }
  };
  useEffect(() => {
    if (propertyType?.Single) {
      setQuantity(1);
    } else {
      setQuantity(2);
    }
  }, [propertyType]);
  const handleNextOne = () => {
    if (Object.values(propertyType).every((value) => value === false)) {
      setErrorFirst(true);
    } else {
      if (propertyType?.Multiple) {
        if (!quantity || quantity < 2) {
          setErrorSecond(true);
        } else {
          if (portfolio["Vacation Rental"] || portfolio?.Hostel) {
            setStep(3);
          } else {
            setStep(2);
          }
        }
      } else {
        if (portfolio["Vacation Rental"] || portfolio?.Hostel) {
          setStep(3);
          handleAmplitude("USER IS GOING TO EXPLORE OUR PRODUCTS");
        } else {
          setStep(2);
          handleAmplitude("USER IS GOING TO FILL ROOM COUNTS");
        }
      }
    }
  };
  const handlePrevOne = () => {
    resetErrors();
    setPropertyType({
      Single: true,
      Multiple: false,
    });
    setQuantity(1);
    setStep(5);
  };
  //-------Step 2---------
  const [rooms, setRooms] = useState(1);
  const handleNextSecond = () => {
    if (!rooms || rooms < 1) {
      setErrorFirst(true);
    } else {
      setStep(3);
      handleAmplitude("USER IS GOING TO EXPLORE OUR PRODUCTS");
    }
  };
  const handlePrevSecond = () => {
    resetErrors();
    setRooms(1);
    setStep(1);
  };
  //---Step 3------------

  // const prd =
  // {
  //   PMS: false,
  //   "Channel manager": false,
  //   BE: false,
  //   Rev: false,
  //   Mgmt: false,
  //   POS: false,
  //   Payments: false,
  //   Adons: false,
  // };
  const pmsCotegoryDescriptions = {
    "Operations hub":
      "Streamline your property's operations with our comprehensive Operations Hub, automating 70% of manual tasks.",
    "Sales hub":
      "Boost your property's revenue with our Sales Hub, increasing earnings by up to 50%.",
    "Guest hub":
      "Enhance guest experiences with our Guest Hub, offering seamless check-ins, upsells, and communication.",
    "Payments hub":
      "Simplify and secure your property's financial transactions with our Payments Hub.",
  };
  const pmsCotegory = {
    "Operations hub": true,
    "Sales hub": true,
    "Guest hub": true,
    "Payments hub": true,
  };
  const pmsProducts = {
    "Operations hub": {
      PMS: true,
      POS: false,
      "Stock management": false,
      Expenses: false,
    },

    "Sales hub": {
      "Channel manager": true,
      "Booking engine": false,

      "Revenue manager": false,
      "Direct billing": false,
    },

    "Guest hub": {
      "Magic link": true,
      "Kiosk & QR": false,
      "Guest messaging": false,
      "Guest reviews": false,
    },
    "Payments hub": {},
  };
  const [productPricing, setProductPricing] = useState([]);
  const [cotegory, setCotegory] = useState(pmsCotegory);
  const [products, setProducts] = useState(pmsProducts);

  const handleCheckBox = (key, index) => {
    if (index > 0) {
      const data = { ...cotegory };
      data[key] = !cotegory[key];
      setCotegory(data);
    }
  };

  const handleProduct = (key, item, index) => {
    if (index > 0 && cotegory[key]) {
      setErrorFirst(false);
      const data = { ...products };
      data[key][item] = !products[key][item];
      setProducts(data);
    }
  };
  const handleNextThree = () => {
    setStep(4);
    handleAmplitude("USER IS GOING TO EXPLORE EXISTING PRODUCTS");
    // if (Object.values(products).every((value) => value === false)) {
    //   setErrorFirst(true);
    // } else {

    // }
  };
  const handlePrevThree = () => {
    resetErrors();
    setProducts(pmsProducts);
    setCotegory(pmsCotegory);
    if (portfolio["Vacation Rental"] || portfolio?.Hostel) {
      setStep(1);
    } else {
      setStep(2);
    }
  };

  //---------Step 4---------------
  const abroadProducts = {
    Cloudbeds: false,
    "Anand systems": false,
    Skytouch: false,
    InnSoft: false,
    Protel: false,
    "Oracle Opera": false,
    StayNTouch: false,
    MEWS: false,
    InnRoad: false,
    Other: false,
  };
  const indianProducts = {
    Ezee: false,
    Djubo: false,
    Axisrooms: false,
    Staah: false,
    Asiatech: false,
    BookingJini: false,
    Other: false,
  };

  const [exProducts, setExProducts] = useState();
  const assigningExProducts = () => {
    if (country?.India) {
      setExProducts(indianProducts);
    } else {
      setExProducts(abroadProducts);
    }
  };
  useEffect(() => {
    assigningExProducts();
  }, [country]);
  const handleExProduct = (item) => {
    setErrorFirst(false);
    const data = { ...exProducts };
    data[item] = !exProducts[item];
    setExProducts(data);
  };
  const handleNextFour = () => {
    // setStep(5);
    registerUser();
  };
  const handlePrevFour = () => {
    resetErrors();
    assigningExProducts();
    setStep(3);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  const handelingData = (data) => {
    const tempObj = {};
    data.map((item) => {
      tempObj[item?.serviceName] = false;
    });
    // setProducts(tempObj);
    // setExProducts(tempObj);
  };
  const gettingProducts = () => {
    handleSpringDataRequest(
      `core/api/v1/pricingWebPage/getAllPricingWebServices`
    )
      .then((data) => {
        handelingData(data);
        setProductPricing(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // gettingProducts();
  }, []);
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //---------Step 5---------
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [contact, setContact] = useState();
  const [countryCode, setCountryCode] = useState("");
  //   {
  //   name: "India",
  //   dial_code: "+91",
  //   code: "IN",
  // }

  const [final, setFinal] = useState(false);
  // const [values, setValues] = useState({
  //   phone_number: "",
  //   message: "",
  // });

  // const handleChange = (name) => (event) => {
  //   setValues({ ...values, [name]: event.target.value });
  // };
  const handleChangeNumber = (name) => (event) => {
    setContact(event);
    // setValues({ ...values, [name]: event });
  };

  // const validatePhoneNumber = () => {
  //   const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  //   try {
  //     const parsedNumber = phoneUtil.parse(contact, countryCode?.code);

  //     if (!phoneUtil.isValidNumber(parsedNumber)) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } catch (e) {
  //     return false;
  //   }
  // };
  const [finalPrice, setFinalPrice] = useState(0);
  // const priceCalculation = () => {
  //   let selctedCountry = "";
  //   if (country?.India) {
  //     selctedCountry = "serviceChargeIndia";
  //   } else if (country?.["USA / Canada"]) {
  //     selctedCountry = "serviceChargeUSA";
  //   } else {
  //     selctedCountry = "serviceChargeOther";
  //   }
  //   let price = 0;

  //   productPricing.map((item) => {
  //     if (products[item?.serviceName]) {
  //       price += item[selctedCountry];
  //     }
  //   });
  //   if (portfolio?.Hotel) {
  //     setFinalPrice(price * rooms);
  //   }
  //   setFinalPrice(price * quantity);
  // };

  const registerUser = () => {
    let ctr;
    Object.keys(country).map((item) => {
      if (country[item]) {
        ctr = item;
      }
    });
    const productsArr = [];
    Object.keys(cotegory).map((item) => {
      if (cotegory[item]) {
        productsArr.push(item);
        Object.keys(products[item]).map((elm) => {
          if (products[item][elm]) {
            productsArr.push(elm);
          }
        });
      }
    });
    const exProductsArr = [];
    Object.keys(exProducts).map((item) => {
      if (exProducts[item]) {
        exProductsArr.push(item);
      }
    });

    let prt;
    Object.keys(portfolio).map((item) => {
      if (portfolio[item]) {
        prt = item;
      }
    });
    let prtyp;
    Object.keys(propertyType).map((item) => {
      if (propertyType[item]) {
        prtyp = item;
      }
    });
    const payload = {
      country: ctr || "",
      portfolio: prt || "",
      propertyType: prtyp || "",
      quantity: prtyp === "Single" ? quantity : 1,
      rooms: rooms,
      products:
        prt === "Alternative Accommodation" ? "" : JSON.stringify(productsArr),
      exProducts:
        prt === "Alternative Accommodation"
          ? ""
          : JSON.stringify(exProductsArr),
      name: name || "",
      countryCode: countryCode?.dial_code || "",
      contact: contact || "",
      email: email || "",
    };
    setSubmitLoader(true);
    axios
      .post(
        `${BASE_SPRING_ENDPOINT}core/api/v1/pricingWebPage/createUserRegistration`,
        payload
      )
      .then(() => {
        // setFinal(true);
        setSubmitLoader(false);
        setStep(6);
        handleAmplitude("USER HAS MADE FINAL SUBMISSION");
      })
      .catch(() => setSubmitLoader(false));
    // handleSpringDataMutationRequest(
    //   "POST",
    //   `core/api/v1/pricingWebPage/createUserRegistration`,
    //   payload
    // );
  };

  const handleNextFive = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!name) {
      setErrorFirst(true);
    } else if (!contact) {
      setErrorSecond(true);
    }
    // else if (!validatePhoneNumber()) {
    //   setErrorSecond(true);
    // }
    else if (!email) {
      setErrorThird(true);
    } else if (!emailPattern.test(email)) {
      setErrorThird(true);
    } else {
      // priceCalculation();

      if (portfolio["Alternative Accommodation"]) {
        registerUser();
        handleAmplitude("USER IS INTERESTED FOR ALTERNATIVE ACCOMMODATION");
      } else {
        handleAmplitude(
          "USER FILLED HIS DETAILS, NOW GOING TO PROPERTY TYPE PAGE"
        );
        setStep(1);
      }
    }
  };
  const handlePrevFive = () => {
    resetErrors();
    setName("");
    setEmail("");
    setContact("");
    setStep(0);
    // if (portfolio["Alternative Accommodation"]) {
    //   setStep(0);
    // } else {
    //   setStep(4);
    // }
  };

  const [meeting, setMeeting] = useState(false);

  //-----------------------------------

  const handleAmplitude = (userAction) => {
    let ctr;
    Object.keys(country).map((item) => {
      if (country[item]) {
        ctr = item;
      }
    });
    const productsArr = [];
    Object.keys(cotegory).map((item) => {
      if (cotegory[item]) {
        productsArr.push(item);
        Object.keys(products[item]).map((elm) => {
          if (products[item][elm]) {
            productsArr.push(elm);
          }
        });
      }
    });
    const exProductsArr = [];
    exProducts &&
      Object.keys(exProducts).map((item) => {
        if (exProducts[item]) {
          exProductsArr.push(item);
        }
      });

    let prt;
    Object.keys(portfolio).map((item) => {
      if (portfolio[item]) {
        prt = item;
      }
    });
    let prtyp;
    Object.keys(propertyType).map((item) => {
      if (propertyType[item]) {
        prtyp = item;
      }
    });
    /* const amplitudeEventProperties = {
      country: ctr || "",
      portfolio: prt || "",
      propertyType: prtyp || "",
      quantity: prtyp === "Single" ? quantity : 1,
      rooms: rooms,
      products:
        prt === "Alternative Accommodation" ? "" : JSON.stringify(productsArr),
      exProducts:
        prt === "Alternative Accommodation"
          ? ""
          : JSON.stringify(exProductsArr),
      name: name || "",
      countryCode: countryCode?.dial_code || "",
      contact: contact || "",
      email: email || "",
    };

    amplitude.getInstance().init(AMPLITUDE_KEY, {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude.getInstance().identify(new amplitude.Identify());
    // amplitude
    //   .getInstance()
    //   .identify(new amplitude.Identify().set("USER_EMAIL", "test@test.com"));
    amplitude.getInstance().logEvent(userAction, amplitudeEventProperties); */
  };
  // useEffect(() => {
  //   handleAmplitude("USER ENTERED TO PRICING WEB");
  // }, []);

  //scroll to top on next

  useEffect(() => {
    scrollToHeight();
  }, [step]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {step === 0 && (
        <Box>
          <Typography variant="h4" sx={{ mb: 2, color: "white" }}>
            Let's get started on your custom quote
          </Typography>
          <Typography variant="h5" align="center" sx={{ color: "white" }}>
            Your Country
          </Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Grid container spacing={2}>
              {Object?.keys(country)?.map((item) => (
                <Grid item xs={4}>
                  <Chip
                    label={item}
                    variant={country[item] ? "contained" : "outlined"}
                    color={errorFirst ? "error" : "primary"}
                    sx={{
                      fontSize: "16px",
                      color: "white",
                      cursor: "pointer",
                      width: "150px",
                      height: "30px",
                    }}
                    onClick={() => handleCountry(item)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Typography
            variant="h5"
            align="center"
            sx={{ mb: 2, color: "white" }}
          >
            Which property type best describes your business?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {Object.keys(portfolio)?.map((item, idx) => (
              <Box onClick={() => handlePortfolio(item)}>
                <Avatar
                  sx={{
                    width: 70,
                    height: 70,
                    cursor: "pointer",
                    border: errorSecond && "1px solid #F44335",
                    color: portfolio[item] ? "white" : "black",
                    background: portfolio[item]
                      ? "linear-gradient(-150deg, #1ab394, #1a91ae)"
                      : "white",
                  }}
                  variant="square"
                >
                  {portfolio[item] ? (
                    <CheckIcon sx={{ fontSize: "4rem" }} />
                  ) : (
                    portfolioIcons[idx]
                  )}
                </Avatar>

                {item.split(" ").map((elm) => (
                  <Typography
                    variant="h7"
                    align="center"
                    sx={{ color: "white", fontSize: "1.2rem" }}
                  >
                    {elm} <br />
                  </Typography>
                ))}
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            {/* <Button
              variant="secondary-button"
              onClick={handlePrev}
            >
              <KeyboardDoubleArrowLeftIcon /> Prev
            </Button> */}
            <Button variant="custom-button" onClick={handleNextZero}>
              Next <KeyboardDoubleArrowRightIcon />
            </Button>
          </Box>
        </Box>
      )}
      {step === 1 && (
        <Box>
          <Typography
            variant="h4"
            align="center"
            sx={{ mb: 2, color: "white" }}
          >
            Do you run a single {portfolio?.Hostel ? "location" : "property"} or{" "}
            <br></br> multiple {portfolio?.Hostel ? "locations" : "properties"}?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            {Object?.keys(propertyType)?.map((item, idx) => (
              <Box onClick={() => handleProprtyType(item)}>
                <Avatar
                  sx={{
                    width: 70,
                    height: 70,
                    cursor: "pointer",
                    border: errorFirst && "1px solid #F44335",
                    color: propertyType[item] ? "white" : "black",
                    background: propertyType[item]
                      ? "linear-gradient(-150deg, #1ab394, #1a91ae)"
                      : "white",
                  }}
                  variant="square"
                >
                  {propertyType[item] ? (
                    <CheckIcon sx={{ fontSize: "4rem" }} />
                  ) : (
                    propertyIcon[idx]
                  )}
                </Avatar>

                <Typography
                  variant="h7"
                  align="center"
                  sx={{ color: "white", fontSize: "1.2rem" }}
                >
                  {item}
                </Typography>
              </Box>
            ))}
          </Box>
          {(portfolio?.Hostel ||
            (propertyType?.Multiple &&
              (portfolio?.Hotel || portfolio["Vacation Rental"]))) && (
            <>
              <Typography
                variant="h5"
                align="center"
                sx={{ mt: 2, mb: 2, color: "white" }}
              >
                How many
                {portfolio?.Hotel && " properties "}
                {portfolio["Vacation Rental"] && " listings "}
                {portfolio?.Hostel &&
                  (propertyType?.Multiple ? " total beds ?" : " beds ?")}
                {!portfolio?.Hostel && "do you manage ?"}
              </Typography>
              <TextField
                // disabled={!propertyType?.Multiple}
                // label={
                //   portfolio?.Hotel
                //     ? "Properties"
                //     : portfolio["Vacation Rental"]
                //     ? "Listings"
                //     : "Beds"
                // }
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={quantity}
                onChange={(e) => {
                  handleQuantity(e.target.value);

                  setErrorSecond(false);
                }}
                error={errorSecond}
                helperText={errorSecond ? "value should be greater than 1" : ""}
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
                InputLabelProps={{ style: { color: "#1BC5BD" } }}
              />
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="secondary-button" onClick={handlePrevOne}>
              <KeyboardDoubleArrowLeftIcon /> Prev
            </Button>
            <Button variant="custom-button" onClick={handleNextOne}>
              Next <KeyboardDoubleArrowRightIcon />
            </Button>
          </Box>
        </Box>
      )}
      {step === 2 && (
        <Box>
          <Typography variant="h4" sx={{ mb: 2, color: "white" }}>
            How many rooms do you have {propertyType?.Multiple && " in total "}?
          </Typography>
          <Typography variant="h5" sx={{ color: "white", mt: 1, mb: 1 }}>
            {" "}
            {propertyType?.Multiple && "Total "} Rooms
          </Typography>
          <TextField
            // label="Rooms"
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={rooms}
            onChange={(e) => {
              if (e.target.value >= 0) {
                setRooms(e?.target?.value);
              }
              setErrorFirst(false);
            }}
            error={errorFirst}
            helperText={errorFirst ? "This field is required" : ""}
            InputProps={{
              style: { backgroundColor: "white" },
            }}
            InputLabelProps={{ style: { color: "#1BC5BD" } }}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="secondary-button" onClick={handlePrevSecond}>
              <KeyboardDoubleArrowLeftIcon /> Prev
            </Button>
            <Button variant="custom-button" onClick={handleNextSecond}>
              Next <KeyboardDoubleArrowRightIcon />
            </Button>
          </Box>
        </Box>
      )}
      {step === 3 && (
        <Box>
          <Typography
            variant="h4"
            align="center"
            sx={{ mb: 2, color: "white" }}
          >
            What products you are interested?
          </Typography>
          {Object?.keys(cotegory)?.map((key, idx) => (
            <Box sx={{ mb: 4, bgcolor: "#444444", borderRadius: "5px", p: 1 }}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="h4" align="left" sx={{ color: "white" }}>
                  {key}
                </Typography>
                <Switch
                  checked={cotegory[key]}
                  onChange={() => handleCheckBox(key, idx)}
                ></Switch>
              </Box>

              <Typography sx={{ color: "white" }}>
                {/* <InfoIcon /> */}
                {pmsCotegoryDescriptions[key]}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  maxWidth: "680px",
                }}
              >
                {Object?.keys(products[key])?.map((item, index) => (
                  <Box sx={{ m: 1 }}>
                    <Chip
                      label={item}
                      variant={
                        products[key][item] && cotegory[key]
                          ? "contained"
                          : "outlined"
                      }
                      color={errorFirst ? "error" : "primary"}
                      sx={{
                        fontSize: "16px",
                        color: "white",
                        cursor: "pointer",
                        // width: "150px",
                        height: "30px",
                      }}
                      onClick={() => handleProduct(key, item, index)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="secondary-button" onClick={handlePrevThree}>
              <KeyboardDoubleArrowLeftIcon /> Prev
            </Button>
            <Button variant="custom-button" onClick={handleNextThree}>
              Next <KeyboardDoubleArrowRightIcon />
            </Button>
          </Box>
        </Box>
      )}
      {step === 4 && (
        <Box>
          {Object.values(exProducts)?.some((item) => item === true) && (
            <Alert severity="success" sx={{ mb: 1 }}>
              Congratulations, you are eligible for heavily discounted pricing.
            </Alert>
          )}
          <Typography
            variant="h4"
            align="center"
            sx={{ mb: 2, color: "white" }}
          >
            Which of these systems you are using? <br />
            You might be eligible for great discounts
            <br /> for switch over.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              maxWidth: "470px",
            }}
          >
            {/* <Grid container spacing={2} sx={{ width: "600px" }}> */}
            {Object?.keys(exProducts)?.map((item) => (
              <Box sx={{ mt: 2 }}>
                <Chip
                  label={item}
                  variant={exProducts[item] ? "contained" : "outlined"}
                  color={errorFirst ? "error" : "primary"}
                  sx={{
                    fontSize: "16px",
                    color: "white",
                    cursor: "pointer",
                    width: "150px",
                    height: "30px",
                  }}
                  onClick={() => handleExProduct(item)}
                />
              </Box>
            ))}
            {/* </Grid> */}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="secondary-button" onClick={handlePrevFour}>
              <KeyboardDoubleArrowLeftIcon /> Prev
            </Button>
            <Button
              variant="custom-button"
              onClick={handleNextFour}
              disabled={submitLoader}
            >
              Finish <KeyboardDoubleArrowRightIcon />
            </Button>
          </Box>
        </Box>
      )}
      {step === 5 && (
        <Box>
          <Typography
            variant="h4"
            sx={{ mb: 0, color: "white" }}
            align="center"
          >
            All about you
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "white", mt: 2, mb: 1 }}
            align="center"
          >
            Your name
          </Typography>
          <TextField
            sx={{ width: 270 }}
            // sx={{ minWidth: 270 }}
            // label="Name"
            variant="outlined"
            size="small"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e?.target?.value);
              setErrorFirst(false);
            }}
            error={errorFirst}
            helperText={errorFirst ? "This field is required" : ""}
            InputProps={{
              style: { backgroundColor: "white" },
            }}
            InputLabelProps={{ style: { color: "#1BC5BD" } }}
          />

          <Typography
            variant="h5"
            sx={{ mt: 2, mb: 1, color: "white" }}
            align="center"
          >
            Phone number
          </Typography>

          <MuiPhoneNumber
            // sx={{ maxWidth: 270 }}
            defaultCountry={country?.India ? "in" : "us"}
            fullWidth
            margin="normal"
            variant="outlined"
            value={contact}
            size="small"
            InputProps={{
              style: { backgroundColor: "white" },
            }}
            onChange={(e) => {
              setContact(e);
              setErrorSecond(false);
            }}
            helperText={
              errorSecond ? "Please enter a valid phone number" : null
            }
            error={errorSecond}
          />

          <Typography
            variant="h5"
            sx={{ mt: 2, mb: 1, color: "white" }}
            align="center"
          >
            Email
          </Typography>

          <TextField
            // label="Email"
            variant="outlined"
            size="small"
            fullWidth
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorThird(false);
            }}
            error={errorThird}
            helperText={errorThird ? "Please enter a valid email" : ""}
            InputProps={{
              style: { backgroundColor: "white" },
            }}
            InputLabelProps={{ style: { color: "#1BC5BD" } }}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="secondary-button" onClick={handlePrevFive}>
              <KeyboardDoubleArrowLeftIcon /> Prev
            </Button>
            <Button
              variant="custom-button"
              onClick={handleNextFive}
              disabled={submitLoader}
            >
              {portfolio["Alternative Accommodation"] ? "Finish" : "Next"}{" "}
              <KeyboardDoubleArrowRightIcon />
            </Button>
          </Box>
        </Box>
      )}

      {step === 6 ? (
        <Success />
      ) : (
        // <Calendly name={name} email={email} />
        <Box sx={{ mt: 2 }}>
          {arr?.map((elm, idx) => {
            let modifiedStep = 0;
            if (step === 0) {
              modifiedStep = 0;
            } else if (step == 5) {
              modifiedStep = 1;
            } else {
              modifiedStep = step + 1;
            }
            if (idx === modifiedStep) {
              return <span className="active"></span>;
            } else if (idx < modifiedStep) {
              return <span className="finish"></span>;
            }
            return <span className="step"></span>;
          })}
        </Box>
      )}

      {/* <Modal
        open={final}
        onClose={() => {
          setFinal(false);
          handleBackHome();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" align="center" sx={{ color: "white" }}>
              Thank you for expressing your interest. We appreciate your inquiry
              and will promptly get in touch with you soon.
            </Typography>
            <Button
              sx={{ mt: 4 }}
              variant="custom-button"
              onClick={() => {
                setStep(6);
                setFinal(false);
              }}
            >
              Schedule a meeting now
            </Button>
          </Box>
        </Box>
      </Modal> */}
    </Box>
  );
}
