import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Stack, Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiPaymentModeSelect = ({
  currentPaymentSelectionMode,
  setCurrentPaymentSelectionMode,
}) => {
  const names = ["Booking amount", "POS amount"];
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentPaymentSelectionMode(
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl sx={{ width: "100%", position: "relative" }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Settlement options
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={currentPaymentSelectionMode}
          onChange={handleChange}
          input={<OutlinedInput label="Settlement options" />}
          renderValue={(selected) => selected.join(", ")}
          // onBlur={(e) => {
          //   if (currentPaymentSelectionMode.length === 0) {
          //     setCurrentPaymentSelectionMode([names[0]]);
          //   }
          // }}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox
                checked={currentPaymentSelectionMode.indexOf(name) > -1}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {currentPaymentSelectionMode.length === 0 && (
        <Stack>
          <Typography sx={{ color: "red" }}>
            Select at least one option to proceed.
          </Typography>
        </Stack>
      )}
    </div>
  );
};

export default MultiPaymentModeSelect;
