import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Card,
  Stack,
  CardContent,
  CardHeader,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { DateRange } from "react-date-range";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import {
  ArrowBack,
  ArrowForward,
  ArrowForwardIosSharp,
  Info,
  MoreVert,
  Sync,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { styled } from "@mui/styles";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { filterParams, cellRenderer } from "../Reports/utils/ReportUtils";
import BulkOperations from "../InventoryCalendar/Views/RightDrawer";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  applyRestrictions,
  getCalendarData,
  getCalendarDataCompare,
  getCalendarDataWithChannel,
  getChannels,
  setPmsInventory,
  getBasePriceCalendarData,
  setPricingInventoryRatePlan,
  setPmsInventorygridcount,
} from "../InventoryCalendar/api/InventoryApi";
import {
  InventoryDateformat,
  OpenCloseOptions,
  weekdays,
} from "../InventoryCalendar/constants/InventoryConstants";
import { InventoryTableDataModel } from "./BulkInitialDataTableModels";
import { cloneDeep, isEmpty, isNull, isUndefined } from "lodash";
import BulkSync from "../InventoryCalendar/Views/BulkSyncView";
import {
  channelLogoMap,
  channelLogoMapDash,
  dateFormat,
} from "../CalendarPage/constants/Calendar-constants";
import { PriceComparisonModel } from "../InventoryCalendar/models/PriceComparisonModel";
import DeniedAccess from "../AccessControl/Views/DeniedAccess";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import amplitude from "amplitude-js";
import {
  AMPLITUDE_KEY,
  ENV,
} from "../CalendarPage/constants/AmplitudeConstants";
import Modal from "@mui/material/Modal";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
//   import "./Views/OtASync.css";
import AgGridWrapper from "../Reports/AgGridWrapper";
import Box from "@mui/material/Box";
import BulkGroupOperations from "./BulkGroupOperations";

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordionSummaryDup = styled((props) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
  },
}));
const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  margin: "none",
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderBottom: "none",
}));

const BulkInventory = (props) => {
  const [numDays, setNumDays] = useState(7);
  const [value, setValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + numDays)),
    },
  ]);

  const [allHotels, setallHotels] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedHotels, setselectedHotels] = useState([]);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [bulkHotelData, setbulkHotelData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [menuValue, setMenuValue] = useState("");
  const [dates, setDates] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [ratePlanColumns, setRatePlanColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(true);
  const [tableData, setTableData] = useState(new InventoryTableDataModel({}));
  const [restrictions, setRestrictions] = useState([]);
  const [hotelChannels, setHotelChannels] = useState([]);
  const [selectedHotelChannel, setSelectedHotelChannel] = useState("all");
  const [priceCompare, setPriceCompare] = useState(false);
  const [compareData, setCompareData] = useState(new PriceComparisonModel({}));
  const [showRates, setShowRates] = useState(true);
  const [showInventory, setShowInventory] = useState(false);
  const [basePrice, setBasePrice] = useState(false);
  const [bulkInventoryData, setbulkInventoryData] = useState([]);
  const [tableDataARray, settableDataARray] = useState([]);

  useEffect(() => {
    if (restrictions?.length > 0) {
      const finalArray = restrictions?.filter((e) => e !== "Restrictions");
      if (finalArray.includes("Rates") && finalArray.includes("Inventory")) {
        setShowInventory(true);
        setShowRates(true);
      } else if (
        finalArray.includes("Rates") &&
        !finalArray.includes("Inventory")
      ) {
        setShowRates(true);
        setShowInventory(false);
      } else if (
        !finalArray.includes("Rates") &&
        finalArray.includes("Inventory")
      ) {
        setShowInventory(true);
        setShowRates(false);
      }
      sessionStorage.setItem("restrictions", finalArray?.toString());
    }
  }, [restrictions]);

  useEffect(() => {
    const data = sessionStorage.getItem("restrictions");
    if (data !== undefined && data !== null && data !== "") {
      setRestrictions(data.split(","));
      if (data.includes("Rates") && data.includes("Inventory")) {
        setShowInventory(true);
        setShowRates(true);
      } else if (data.includes("Rates") && !data.includes("Inventory")) {
        setShowRates(true);
        setShowInventory(false);
      } else if (!data.includes("Rates") && data.includes("Inventory")) {
        setShowInventory(true);
        setShowRates(false);
      }
    } else {
      setRestrictions(["Rates"]);
      setShowRates(true);
      setShowInventory(false);
    }
  }, [sessionStorage.getItem("restrictions")]);

  //Amplitude check on Rates & Inventory page
  const amplitudeEventProperties = {
    hotel_id: "20722",
    environment: ENV,
    email: props.data?.accessControl?.email,
    role: props.data?.accessControl?.ADMINRIGHTS,
  };

  /* useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_KEY, "20722", {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude
      .getInstance()
      .identify(
        new amplitude.Identify()
          .set("hotel_id", "20722")
          .set("email", props.data?.accessControl?.email)
          .set("role", props.data?.accessControl?.ADMINRIGHTS)
      );
    amplitude
      .getInstance()
      .logEvent("RATES & INVENTORY PAGE LOADED", amplitudeEventProperties);
  }, []); */

  const getDateText = () => {
    let val = "";
    val = val + moment(value[0].startDate).format("DD MMM YYYY") + " - ";
    val = val + moment(value[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const formDateArray = () => {
    let temp_arr = [];
    let temp_arr1 = [];
    let temp_arr2 = [];
    temp_arr2.push({
      headerAttrs: { hidden: true },
      dataField: "name",
      text: "Dates",
    });
    temp_arr1.push({
      headerAttrs: { hidden: true },
      dataField: "name",
      text: "Dates",
    });
    temp_arr.push({ dataField: "name", text: "Dates" });

    const diffTime = Math.abs(value[0].startDate - value[0].endDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    for (let i = 0; i < diffDays + 1; i++) {
      var temp_date = new Date(value[0].startDate);
      temp_date.setDate(temp_date.getDate() + i);
      temp_arr.push({
        dataField: (i + 1).toString(),
        text: moment(temp_date).format("ddd MMM D YYYY"),
      });
      temp_arr1.push({
        classes: function callback(cell, row, rowIndex, colIndex) {
          if (cell === 0 && row.alert) {
            return "warning-row";
          }
          if (rowIndex === 0 && row.alert) {
            return "edit-row";
          }
          if (row.new_add) {
            if (row[colIndex]) {
              return "open-close";
            }
            return "open-close-disabled";
          }
        },
        headerAttrs: { hidden: true },
        dataField: (i + 1).toString(),
        text: moment(temp_date).format("ddd MMM D YYYY"),
        type: "number",
        style: { fontWeight: "bold" },
      });

      temp_arr2.push({
        classes: function callback(cell, row, rowIndex, colIndex) {
          if (row.selectable) {
            return "rateplan-row";
          }
        },
        headerAttrs: { hidden: true },
        dataField: (i + 1).toString(),
        text: moment(temp_date).format("ddd MMM D YYYY"),
        type: "number",
        style: { fontWeight: "bold" },
      });
    }
    setDataColumns(temp_arr1);
    setRatePlanColumns(temp_arr2);

    setDates(temp_arr);
  };

  const getTableDataForCalendar = (res) => {
    try {
      let resp;
      //   if (basePrice) {
      //     resp = await getBasePriceCalendarData({
      //       from: moment(value[0].startDate).format(InventoryDateformat),
      //       to: moment(value[0].endDate).format(InventoryDateformat),
      //     });
      //   } else {
      //     if (selectedHotelChannel === "all") {
      //       resp = await getCalendarData({
      //         from: moment(value[0].startDate).format(InventoryDateformat),
      //         to: moment(value[0].endDate).format(InventoryDateformat),
      //       });
      //     } else {
      //       if (selectedHotelChannel !== "") {
      //         resp = await getCalendarDataWithChannel({
      //           from: moment(value[0].startDate).format(InventoryDateformat),
      //           to: moment(value[0].endDate).format(InventoryDateformat),
      //           channel: selectedHotelChannel,
      //         });
      //       }
      //     }
      //   }
      //   console.log("temp", resp);

      const arr = [];
      const data = res.map((item) => {
        const temp = new InventoryTableDataModel(
          item.rootCalendar,
          restrictions.includes("Restrictions")
        );
        arr.push(temp);
      });

      // restrictionsChange(restrictions)
      settableDataARray(arr);

      // setTableData(bulkInventoryData);

      // restrictionsChange(restrictions)
    } catch (e) {
      console.error(e);
    }
  };

  const refresh = async (hootel) => {
    setLoading(true);
    setApiLoading(true);
    formDateArray();
    setLoading(false);
    setTableData(
      new InventoryTableDataModel({}, restrictions.includes("Restrictions"))
    );
    settableDataARray([]);
    setCompareData(new PriceComparisonModel({}));
    if (priceCompare) {
      //   await comparisonData();
    } else {
      getBulkCalendarData(hootel);
    }
    // restrictionsChange(restrictions)
    setApiLoading(false);
  };

  const restrictionsChange = (eventData) => {
    setRestrictions(eventData);
    let myDta = bulkInventoryData?.map((item) =>
      item.allRoomTypes.forEach((room) => {
        let tempArr = eventData.filter((e) => e === "Restrictions");
        if (tempArr.length > 0 && room.restrictionArray.length <= 0) {
          let temporary = {};
          temporary.name = OpenCloseOptions["Close Room"];
          room.restrictionInfoList.forEach((restriction, index) => {
            temporary[(index + 1).toString()] =
              restriction["stopRTSell"] === "true";
          });
          room.restrictionArray.push(temporary);
        }
        if (tempArr.length <= 0) {
          room.restrictionArray = [];
        }
        let otherArr =
          tempArr.length > 0
            ? Object.keys(OpenCloseOptions).filter((ke) => ke !== "Close Room")
            : [];
        room.ratePlans.forEach((ratePlan) => {
          let arr = [];
          let existing_arr = [];
          otherArr.forEach((restrictionName) => {
            if (restrictionName !== "Close Room") {
              let temporaryRate = {};
              ratePlan.restrictionInfos.forEach((restriction, index) => {
                temporaryRate["name"] = OpenCloseOptions[restrictionName];
                switch (restrictionName) {
                  case "Close Rate":
                    temporaryRate[(index + 1).toString()] =
                      restriction["stopSell"] === "true";
                    temporaryRate["unique"] = restrictionName;
                    break;
                  case "Close On Arrival":
                    temporaryRate[(index + 1).toString()] =
                      restriction["closedOnArrival"] === "true";
                    temporaryRate["unique"] = restrictionName;
                    break;
                  case "Close On Departure":
                    temporaryRate[(index + 1).toString()] =
                      restriction["closedOnDeparture"] === "true";
                    temporaryRate["unique"] = restrictionName;
                    break;
                  case "Set Min Los":
                    temporaryRate[(index + 1).toString()] =
                      restriction["minLos"];
                    temporaryRate["selectable"] = true;
                    temporaryRate["additional"] = true;
                    temporaryRate["unique"] = restrictionName;
                    break;
                  case "Set Max Los":
                    temporaryRate[(index + 1).toString()] =
                      restriction["maxLos"];
                    temporaryRate["selectable"] = true;
                    temporaryRate["additional"] = true;
                    temporaryRate["unique"] = restrictionName;
                    break;
                  case "Set Cutoff":
                    temporaryRate[(index + 1).toString()] =
                      restriction["cutOff"];
                    temporaryRate["selectable"] = true;
                    temporaryRate["additional"] = true;
                    temporaryRate["unique"] = restrictionName;
                    break;
                  case "Set Max Adv Offset":
                    temporaryRate[(index + 1).toString()] =
                      restriction["maxAdvancedOffset"];
                    temporaryRate["selectable"] = true;
                    temporaryRate["additional"] = true;
                    temporaryRate["unique"] = restrictionName;
                    break;
                }
              });
              if (
                restrictionName === "Set Min Los" ||
                restrictionName === "Set Max Los" ||
                restrictionName === "Set Cutoff" ||
                restrictionName === "Set Max Adv Offset"
              ) {
                existing_arr.push(temporaryRate);
              } else {
                if (
                  restrictionName !== "Rates" &&
                  restrictionName !== "Inventory"
                )
                  arr.push(temporaryRate);
              }
            }
          });
          ratePlan.bootstrapTableArray = existing_arr;
          ratePlan.restrictionArray = arr;
        });
      })
    );
  };
  useEffect(() => {
    try {
      refresh();
    } catch (err) {
      console.error(err);
    }
  }, [numDays, value, selectedHotelChannel, priceCompare, basePrice]);

  //Room Type Filter states
  const [roomTypesFilter, setRoomTypesFilter] = useState([]);
  const [allState, setAllState] = useState(true);
  const [selectedRoomType, setSelectedRoomType] = useState(["All"]);

  // useEffect(() => {
  //   const n = tableData?.allRoomTypes.length;
  //   const obj = [{ All: true }];
  //   for (let i = 0; i < n; i++) {
  //     const objs = {};
  //     objs[tableData?.allRoomTypes[i].roomTypeName] = false;
  //     obj.push(objs);
  //   }

  //   setRoomTypesFilter(obj);
  // }, [tableData]);
  //View Connected channels state
  const [show, setShow] = useState(false);
  const [connectedChannels, setConnectedChannels] = useState([]);
  const [rPlanName, setrPlanName] = useState("");
  const [ratePlansArr, setRatePlansArr] = useState([]);

  const handleClose = () => {
    setConnectedChannels([]);
    setRatePlansArr([]);
    setrPlanName("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // Render the room ids component
  function renderRoomIdsComp(roomAvailability) {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginLeft: "2.5px",
          padding: 5,
        }}
      >
        <div style={{ position: "relative", width: "11%" }}>
          <b>Room Ids</b>
        </div>
        {bulkInventoryData?.map((item) =>
          item.allRoomTypes
            .filter((rooms) => rooms.roomTypeId === selectedRoomId)
            .map((room) =>
              room[roomAvailability].map((item) => (
                <div
                  style={{
                    position: "relative",
                    width: "11%",
                    display: "flex",
                    flexDirection: "row",
                    justifyDirection: "flex-start",
                    flexWrap: "wrap",
                    marginRight: "3px",
                  }}
                >
                  {item.roomIds?.map((ids, index) => (
                    <div
                      style={{
                        position: "relative",
                        marginRight: "1.5px",
                        border: "none",
                        backgroundColor: "#EBEDF3",
                        borderRadius: "2.5px",
                        minWidth: "25px",
                        height: "fit-content",
                        padding: 2,
                        textAlign: "center",
                        marginBottom: "2.5px",
                      }}
                    >
                      {ids}
                    </div>
                  ))}
                </div>
              ))
            )
        )}
      </div>
    );
  }

  // Render the information based on the table
  const [selectedRoomId, setSelectedRoomId] = useState("12353");
  const renderInfo = (name) => {
    switch (name) {
      case "Available":
        return renderRoomIdsComp("availableRoomCount");
        break;
      case "Booked":
        return renderRoomIdsComp("bookedRoomCount");
        break;
      case "Unassigned":
        return renderRoomIdsComp("holdRoomCount");
        break;
      case "Blocked":
        return renderRoomIdsComp("blockedRoomCount");
        break;
      case "Offline":
        return renderRoomIdsComp("offlineRoomCount");
        break;
    }
  };

  // Expand and display the required information
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => renderInfo(row.name),
  };
  const getBulkCalendarData = (hootel) => {
    setApiLoading(true);
    setLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      `web-api/get-group-hotel-calendar`,
      {
        fromDate: moment(value[0].startDate).format(InventoryDateformat),
        toDate: moment(value[0].endDate).format(InventoryDateformat),
        emailId: localStorage.getItem("groupEmail"),
        hotelIds: hootel !== undefined ? hootel : selectedHotels,
        parallelMode: true,
      }
    )
      .then((res) => {
        setbulkHotelData(res);
        const arrays = [];
        const data = res?.grcList?.map((item) => {
          const temp = new InventoryTableDataModel(
            item.rootCalendar,
            restrictions.includes("Restrictions")
          );
          arrays.push(temp);
        });

        // restrictionsChange(restrictions)
        settableDataARray(arrays);
        setallHotels(res?.selHotels);
        let arr = [];
        for (let i = 0; i < res?.grcList.length; i++) {
          let tempo = new InventoryTableDataModel(
            res?.grcList[i]?.rootCalendar,
            restrictions.includes("Restrictions"),
            res?.grcList[i]?.hotelName
          );
          arr.push(tempo);
        }
        setbulkInventoryData(arr);
        setApiLoading(false);
        setLoading(false);
      })
      .catch((er) => {
        console.log(er);
        setApiLoading(false);
        setLoading(false);
      });
  };

  const colors = [
    "#63b598",
    "#ce7d78",
    "#ea9e70",
    "#a48a9e",
    "#c6e1e8",
    "#648177",
    "#0d5ac1",
    "#f205e6",
    "#1c0365",
    "#14a9ad",
    "#4ca2f9",
    "#a4e43f",
    "#d298e2",
    "#6119d0",
    "#d2737d",
    "#c0a43c",
    "#f2510e",
    "#651be6",
    "#79806e",
    "#61da5e",
    "#cd2f00",
    "#9348af",
    "#01ac53",
    "#c5a4fb",
    "#996635",
    "#b11573",
    "#4bb473",
    "#75d89e",
    "#2f3f94",
    "#2f7b99",
    "#da967d",
    "#34891f",
    "#b0d87b",
    "#ca4751",
    "#7e50a8",
    "#c4d647",
    "#e0eeb8",
    "#11dec1",
    "#289812",
    "#566ca0",
    "#ffdbe1",
    "#2f1179",
    "#935b6d",
    "#916988",
    "#513d98",
    "#aead3a",
    "#9e6d71",
    "#4b5bdc",
    "#0cd36d",
    "#250662",
    "#cb5bea",
    "#228916",
    "#ac3e1b",
    "#df514a",
    "#539397",
    "#880977",
    "#f697c1",
    "#ba96ce",
    "#679c9d",
    "#c6c42c",
    "#5d2c52",
    "#48b41b",
    "#e1cf3b",
    "#5be4f0",
    "#57c4d8",
    "#a4d17a",
    "#225b8",
    "#be608b",
    "#96b00c",
    "#088baf",
    "#f158bf",
    "#e145ba",
    "#ee91e3",
    "#05d371",
    "#5426e0",
    "#4834d0",
    "#802234",
    "#6749e8",
    "#0971f0",
    "#8fb413",
    "#b2b4f0",
    "#c3c89d",
    "#c9a941",
    "#41d158",
    "#fb21a3",
    "#51aed9",
    "#5bb32d",
    "#807fb",
    "#21538e",
    "#89d534",
    "#d36647",
    "#7fb411",
    "#0023b8",
    "#3b8c2a",
    "#986b53",
    "#f50422",
    "#983f7a",
    "#ea24a3",
    "#79352c",
    "#521250",
    "#c79ed2",
    "#d6dd92",
    "#e33e52",
    "#b2be57",
    "#fa06ec",
    "#1bb699",
    "#6b2e5f",
    "#64820f",
    "#1c271",
    "#21538e",
    "#89d534",
    "#d36647",
    "#7fb411",
    "#0023b8",
    "#3b8c2a",
    "#986b53",
    "#f50422",
    "#983f7a",
    "#ea24a3",
    "#79352c",
    "#521250",
    "#c79ed2",
    "#d6dd92",
    "#e33e52",
    "#b2be57",
    "#fa06ec",
    "#1bb699",
    "#6b2e5f",
    "#64820f",
    "#1c271",
    "#9cb64a",
    "#996c48",
    "#9ab9b7",
    "#06e052",
    "#e3a481",
    "#0eb621",
    "#fc458e",
    "#b2db15",
    "#aa226d",
    "#792ed8",
    "#73872a",
    "#520d3a",
    "#cefcb8",
    "#a5b3d9",
    "#7d1d85",
    "#c4fd57",
    "#f1ae16",
    "#8fe22a",
    "#ef6e3c",
    "#243eeb",
    "#1dc18",
    "#dd93fd",
    "#3f8473",
    "#e7dbce",
    "#421f79",
    "#7a3d93",
    "#635f6d",
    "#93f2d7",
    "#9b5c2a",
    "#15b9ee",
    "#0f5997",
    "#409188",
    "#911e20",
    "#1350ce",
    "#10e5b1",
    "#fff4d7",
    "#cb2582",
    "#ce00be",
    "#32d5d6",
    "#17232",
    "#608572",
    "#c79bc2",
    "#00f87c",
    "#77772a",
    "#6995ba",
    "#fc6b57",
    "#f07815",
    "#8fd883",
    "#060e27",
    "#96e591",
    "#21d52e",
    "#d00043",
    "#b47162",
    "#1ec227",
    "#4f0f6f",
    "#1d1d58",
    "#947002",
    "#bde052",
    "#e08c56",
    "#28fcfd",
    "#bb09b",
    "#36486a",
    "#d02e29",
    "#1ae6db",
    "#3e464c",
    "#a84a8f",
    "#911e7e",
    "#3f16d9",
    "#0f525f",
    "#ac7c0a",
    "#b4c086",
    "#c9d730",
    "#30cc49",
    "#3d6751",
    "#fb4c03",
    "#640fc1",
    "#62c03e",
    "#d3493a",
    "#88aa0b",
    "#406df9",
    "#615af0",
    "#4be47",
    "#2a3434",
    "#4a543f",
    "#79bca0",
    "#a8b8d4",
    "#00efd4",
    "#7ad236",
    "#7260d8",
    "#1deaa7",
    "#06f43a",
    "#823c59",
    "#e3d94c",
    "#dc1c06",
    "#f53b2a",
    "#b46238",
    "#2dfff6",
    "#a82b89",
    "#1a8011",
    "#436a9f",
    "#1a806a",
    "#4cf09d",
    "#c188a2",
    "#67eb4b",
    "#b308d3",
    "#fc7e41",
    "#af3101",
    "#ff065",
    "#71b1f4",
    "#a2f8a5",
    "#e23dd0",
    "#d3486d",
    "#00f7f9",
    "#474893",
    "#3cec35",
    "#1c65cb",
    "#5d1d0c",
    "#2d7d2a",
    "#ff3420",
    "#5cdd87",
    "#a259a4",
    "#e4ac44",
    "#1bede6",
    "#8798a4",
    "#d7790f",
    "#b2c24f",
    "#de73c2",
    "#d70a9c",
    "#25b67",
    "#88e9b8",
    "#c2b0e2",
    "#86e98f",
    "#ae90e2",
    "#1a806b",
    "#436a9e",
    "#0ec0ff",
    "#f812b3",
    "#b17fc9",
    "#8d6c2f",
    "#d3277a",
    "#2ca1ae",
    "#9685eb",
    "#8a96c6",
    "#dba2e6",
    "#76fc1b",
    "#608fa4",
    "#20f6ba",
    "#07d7f6",
    "#dce77a",
    "#77ecca",
  ];
  const handleRate = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 20)
      setselectedHotels(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );

    let hootel = typeof value === "string" ? value.split(",") : value;
    refresh(hootel);
  };

  useEffect(() => {
    const data = bulkHotelData?.selHotels?.filter(
      (hotel) => hotel.selected === true
    );
    const selectedId = data?.map((item) => item.hotelId);

    setselectedHotels(selectedId);
  }, [bulkHotelData]);
  return (
    <div style={{ width: "100%" }}>
      {1 == 2 ? (
        <DeniedAccess />
      ) : (
        <Grid sx={{ width: "100%" }} spacing={0} container>
          {menuValue === "bulk" && (
            <BulkGroupOperations
              bulkHotelData={bulkHotelData}
              refresh={refresh}
              open={true}
              handleAddEventSidebar={() => {
                setMenuValue("");
              }}
              allHotelsData={props.allHotelsData}
            />
          )}
          {menuValue === "sync" && (
            <BulkSync
              refresh={refresh}
              open={true}
              handleAddEventSidebar={() => {
                setMenuValue("");
              }}
            />
          )}
          <Grid
            sx={{ height: "fit-content" }}
            container
            className="custom-header"
          >
            <Card
              sx={{ marginBottom: 2, height: "fit-content", width: "100%" }}
              elevation={3}
            >
              <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
                <Grid item container xs={12}>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    sm={1}
                    lg={1}
                    xl={1}
                    container
                    justifyContent="flex-start"
                  >
                    <IconButton
                      onClick={() => {
                        let date = value[0].startDate;
                        date.setDate(date.getDate() - numDays);
                        let end = value[0].endDate;
                        end.setDate(end.getDate() - numDays);
                        setValue([{ startDate: date, endDate: end }]);
                      }}
                      color="primary"
                    >
                      <ArrowBack fontSize="large" />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    spacing={2}
                    xs={9}
                    md={9}
                    sm={9}
                    lg={9}
                    xl={9}
                  >
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Rates / Inventory / Restrictions
                        </InputLabel>
                        <Select
                          disabled={priceCompare}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label=" Rates / Inventory / Restrictions"
                          multiple
                          value={restrictions}
                          renderValue={(selected) => selected.join(", ")}
                          onChange={(eve) => {
                            restrictionsChange(eve.target.value);
                          }}
                        >
                          <MenuItem
                            key="Inventory"
                            disabled={!showRates}
                            value={"Inventory"}
                            onClick={(e) => {
                              if (!showInventory) {
                                restrictions.push("Inventory");
                              } else {
                                let index = restrictions.indexOf("Inventory");
                                if (index > -1) {
                                  restrictions.splice(index, 1);
                                  setRestrictions(restrictions);
                                }
                              }
                              setShowInventory(!showInventory);
                            }}
                          >
                            <Checkbox
                              checked={restrictions.indexOf("Inventory") > -1}
                            />
                            <ListItemText primary="Inventory" />
                          </MenuItem>
                          <MenuItem
                            key="Rates"
                            disabled={!showInventory}
                            value={"Rates"}
                            onClick={(e) => {
                              if (!showRates) {
                                restrictions.push("Rates");
                              } else {
                                let index = restrictions.indexOf("Rates");
                                if (index > -1) {
                                  restrictions.splice(index, 1);
                                  setRestrictions(restrictions);
                                }
                              }
                              setShowRates(!showRates);
                            }}
                          >
                            <Checkbox
                              checked={restrictions.indexOf("Rates") > -1}
                            />
                            <ListItemText primary="Rates" />
                          </MenuItem>
                          <MenuItem key="Restrictions" value={"Restrictions"}>
                            <Checkbox
                              checked={
                                restrictions.indexOf("Restrictions") > -1
                              }
                            />
                            <ListItemText primary="Restrictions" />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <div>
                        <ClickAwayListener
                          onClickAway={() => {
                            setShowDatePicker(false);
                            setAnchorElDate(null);
                          }}
                        >
                          <div>
                            <TextField
                              fullWidth
                              focused={showDatePicker}
                              InputProps={{ readOnly: true }}
                              value={getDateText()}
                              label="Date range Maximum 8 days can be selected"
                              onClick={(eve) => {
                                setAnchorElDate(eve.currentTarget);
                                setShowDatePicker(true);
                              }}
                            ></TextField>
                            {showDatePicker && (
                              <Popper
                                style={{ zIndex: 2 }}
                                open={showDatePicker}
                                anchorEl={anchorElDate}
                                placement="bottom"
                                transition
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    style={{
                                      transformOrigin: (placement =
                                        "bottom-end"),
                                    }}
                                    {...TransitionProps}
                                  >
                                    <Paper elevation={5}>
                                      <DateRange
                                        color="#1BC5BD"
                                        // maxDate={
                                        //   new Date(
                                        //     new Date().setDate(
                                        //       value[0].startDate.getDate() +
                                        //         numDays
                                        //     )
                                        //   )
                                        // }
                                        rangeColors={["#1BC5BD"]}
                                        editableDateInputs={false}
                                        dragSelectionEnabled={false}
                                        months={1}
                                        onChange={(data) => {
                                          const diffTime = Math.abs(
                                            data.range1.endDate -
                                              data.range1.startDate
                                          );
                                          const diffDays = Math.ceil(
                                            diffTime / (1000 * 60 * 60 * 24)
                                          );

                                          if (diffDays <= numDays)
                                            setValue([{ ...data.range1 }]);
                                          else {
                                            const endDate = new Date(
                                              new Date(
                                                data.range1.startDate
                                              ).setDate(
                                                data.range1.startDate.getDate() +
                                                  numDays
                                              )
                                            );
                                            setValue([
                                              {
                                                startDate:
                                                  data.range1.startDate,
                                                endDate: endDate,
                                              },
                                            ]);
                                          }
                                        }}
                                        ranges={value}
                                      ></DateRange>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            )}
                          </div>
                        </ClickAwayListener>
                      </div>
                    </Grid>
                    {selectedHotels?.length > 0 && (
                      // <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                      <FormControl
                        fullWidth
                        sx={{
                          width: "26rem",
                          marginLeft: "1rem",
                          marginTop: "1.2rem",
                        }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Hotels
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedHotels}
                          multiple
                          onChange={handleRate}
                          label="Hotels"
                          // MenuProps={MenuProps}
                        >
                          {bulkHotelData?.selHotels?.map((name) => (
                            <MenuItem key={name.hotelId} value={name.hotelId}>
                              {name.hotelName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      // </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    md={2}
                    sm={2}
                    lg={2}
                    xl={2}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    {selectedHotels?.length > 0 && (
                      <Grid item>
                        <IconButton
                          title="more actions"
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setMenuOpen(!menuOpen);
                          }}
                        >
                          <Badge
                            badgeContent={priceCompare || basePrice ? 1 : 0}
                            color="primary"
                            variant="dot"
                          >
                            <MoreVert fontSize="large" />
                          </Badge>
                        </IconButton>
                        <Popper
                          style={{ zIndex: 2 }}
                          open={menuOpen}
                          anchorEl={anchorEl}
                          placement="bottom-end"
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin: (placement = "bottom-end"),
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => {
                                    setMenuOpen(false);
                                  }}
                                >
                                  <MenuList
                                    id="composition-menu"
                                    key="first-item"
                                    aria-labelledby="composition-button"
                                  >
                                    <MenuItem
                                      style={{
                                        borderBottom: "2px solid #EBEDF3",
                                      }}
                                      onClick={() => {
                                        /* amplitude
                                          .getInstance()
                                          .logEvent(
                                            "USER PERFORMING BULK OPERATIONS",
                                            amplitudeEventProperties
                                          ); */
                                        setMenuValue("bulk");
                                        setMenuOpen(false);
                                      }}
                                      key="bulk-oper"
                                    >
                                      Bulk Operations
                                    </MenuItem>

                                    {/* <MenuItem
                                    style={{
                                      borderBottom: "2px solid #EBEDF3",
                                    }}
                                    disabled={priceCompare}
                                    key="base-price"
                                  >
                                    {" "}
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          onChange={() => {
                                            setBasePrice(!basePrice);
                                          }}
                                          key="base-price"
                                          checked={basePrice}
                                          name="checkedA"
                                        />
                                      }
                                      label="Base price"
                                      labelPlacement="end"
                                    />
                                  </MenuItem> */}

                                    {/* {EditRateAccess && (
                                    <MenuItem key="price-compare">
                                      {" "}
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            onChange={() => {
                                              setPriceCompare(!priceCompare);
                                            }}
                                            key="compare"
                                            checked={priceCompare}
                                            name="checkPrice"
                                          />
                                        }
                                        label="Channels comparision"
                                        labelPlacement="end"
                                      />
                                    </MenuItem>
                                  )} */}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </Grid>
                    )}
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          let date = value[0].startDate;
                          date.setDate(date.getDate() + numDays);
                          let end = value[0].endDate;
                          end.setDate(end.getDate() + numDays);
                          setValue([{ startDate: date, endDate: end }]);
                        }}
                        color="primary"
                      >
                        <ArrowForward fontSize="large" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <p className="text-muted mt-2">
                  An all-in-one calendar that shows the availability of rooms
                  and their prices for all properties. It’s a helpful tool for
                  managers who handle multiple properties, making it easy to
                  keep track of everything in one place.
                </p>
                <Divider sx={{ margin: 2 }} />
                <Grid item container xs={12}>
                  {!loading && (
                    <BootstrapTable
                      wrapperClasses="header-table"
                      keyField="name"
                      data={[]}
                      columns={dates}
                    ></BootstrapTable>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {(apiLoading || loading) && (
            <Grid container sx={{ width: "100%" }}>
              {[...Array(1).keys()].map((val, index) => {
                return (
                  <Card key={val} style={{ width: "100%", marginBottom: 16 }}>
                    {[...Array(10).keys()].map((num, idx) => {
                      return (
                        <Skeleton
                          key={num * idx + 1}
                          sx={{ width: "97%", height: "16px", margin: 2 }}
                          variant="rectangular"
                          animation={num % 2 === 0 ? "pulse" : "wave"}
                        />
                      );
                    })}
                  </Card>
                );
              })}
            </Grid>
          )}

          {!apiLoading &&
            !loading &&
            !priceCompare &&
            bulkInventoryData?.length > 0 &&
            bulkInventoryData?.map((item, idx) => (
              <>
                {showInventory &&
                  !apiLoading &&
                  !loading &&
                  !priceCompare &&
                  tableDataARray[idx].aggregateTableArray.length != 0 && (
                    <Grid container>
                      <Card key="seperate-table" sx={{ marginBottom: 2 }}>
                        <CardContent>
                          <Typography
                            paddingRight={2}
                            sx={{
                              color: `${colors[idx]}`,
                              fontWeight: "bold",
                              marginTop: "1rem",
                              marginBottom: "1rem",
                            }}
                            variant="h5"
                          >
                            {item.hotelName}
                          </Typography>
                          <BootstrapTable
                            wrapperClasses="new-tab"
                            keyField="name"
                            data={tableDataARray[idx].aggregateTableArray}
                            columns={dataColumns}
                          ></BootstrapTable>
                        </CardContent>{" "}
                      </Card>
                    </Grid>
                  )}

                {!showInventory &&
                  !apiLoading &&
                  !loading &&
                  !priceCompare &&
                  tableDataARray[idx].aggregateTableArray.length != 0 && (
                    <Grid container>
                      <Card key="seperate-table" sx={{ marginBottom: 2 }}>
                        <CardContent>
                          <Typography
                            paddingRight={2}
                            sx={{
                              color: `${colors[idx]}`,
                              fontWeight: "bold",
                              marginTop: "1rem",
                              marginBottom: "1rem",
                            }}
                            variant="h5"
                          >
                            {item.hotelName}
                          </Typography>
                          <BootstrapTable
                            wrapperClasses="new-tab"
                            keyField="name"
                            data={tableDataARray[
                              idx
                            ].aggregateTableArray.filter(
                              (item) =>
                                item.name === "Total Available" ||
                                item.name === "Occupancy (%)" ||
                                item.name === "Total Booked"
                            )}
                            columns={dataColumns}
                          ></BootstrapTable>
                        </CardContent>{" "}
                      </Card>
                    </Grid>
                  )}

                {item.allRoomTypes.map((val, room_index) => {
                  if (
                    selectedRoomType.includes(val.roomTypeName) ||
                    selectedRoomType.includes("All")
                  ) {
                    return (
                      <Grow
                        key={val.roomTypeId}
                        in
                        timeout={1000}
                        style={{ transformOrigin: "0 0 0" }}
                        // onClick={() => setSelectedRoomId(val.roomTypeId)}
                        onMouseOver={() => setSelectedRoomId(val.roomTypeId)}
                      >
                        <Grid item>
                          <Card sx={{ marginBottom: 2 }}>
                            <CardHeader
                              titleTypographyProps={{ component: "div" }}
                              title={
                                <Grid container>
                                  <Stack direction={"row"}>
                                    <Typography
                                      paddingRight={2}
                                      sx={{
                                        color: `${colors[idx]}`,
                                        fontWeight: "bold",
                                      }}
                                      variant="h5"
                                    >
                                      {item.hotelName}
                                    </Typography>
                                    <Typography paddingRight={2} variant="h5">
                                      {` - ${val.roomTypeName}`}
                                    </Typography>
                                  </Stack>
                                  <div
                                    style={{
                                      fontSize: "13px",
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                    }}
                                    className="badge badge-secondary"
                                    onClick={() => {
                                      if (
                                        isNull(val.channels) ||
                                        isEmpty(val.channels)
                                      ) {
                                        setrPlanName(val.roomTypeName);
                                        setRatePlansArr(val.ratePlans);
                                        handleShow();
                                      } else {
                                        setRatePlansArr(val.ratePlans);
                                        setrPlanName(val.roomTypeName);
                                        setConnectedChannels(val.channels);
                                        handleShow();
                                      }
                                    }}
                                  >
                                    View connected channels
                                  </div>
                                </Grid>
                              }
                            >
                              <Info></Info>
                            </CardHeader>
                            <CardContent>
                              {showInventory && (
                                <Grid item xs={12}>
                                  <div>
                                    <BootstrapTable
                                      key={val.roomTypeId + room_index}
                                      wrapperClasses="new-tab"
                                      keyField="name"
                                      data={val.dataArray}
                                      columns={dataColumns}
                                      // expandRow={expandRow}
                                    ></BootstrapTable>
                                  </div>
                                </Grid>
                              )}
                              {!isUndefined(val.restrictionArray) &&
                                val.restrictionArray.length > 0 && (
                                  <div className="react-bootstrap-table new-tab">
                                    <table className="table table-bordered">
                                      <tbody>
                                        {val.restrictionArray.map(
                                          (item, index) => {
                                            return (
                                              <tr key={item.name}>
                                                <td>{item.name}</td>
                                                {Object.entries(item).map(
                                                  ([item_key, item_value]) => {
                                                    if (item_key !== "name") {
                                                      return (
                                                        <td
                                                          key={item_key}
                                                          style={{
                                                            background:
                                                              item_value
                                                                ? "#F29191"
                                                                : "#1BC5BD",
                                                            cursor: "pointer",
                                                            border:
                                                              "1px solid #cccccc",
                                                            paddingRight: "0",
                                                            paddingBottom: "0",
                                                            paddingLeft: "0",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              background:
                                                                "white",
                                                              padding: "20%",
                                                            }}
                                                          ></div>
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              {(showRates ||
                                (!isUndefined(val.restrictionArray) &&
                                  val.restrictionArray.length > 0)) &&
                                val.ratePlans.map(
                                  (ratePlan, rate_plan_index) => {
                                    return (
                                      <StyledAccordion
                                        key={ratePlan.ratePlanId}
                                      >
                                        <StyledAccordionSummaryDup
                                          showicon="true"
                                          aria-controls="panel1d-content"
                                          id="panel1d-header"
                                          sx={{ padding: 0 }}
                                        >
                                          {/* <Typography>
                                        {ratePlan.ratePlanName}
                                      </Typography> */}
                                          <BootstrapTable
                                            wrapperClasses="new-tab"
                                            keyField="name"
                                            data={ratePlan.displayHeader}
                                            columns={ratePlanColumns}
                                          ></BootstrapTable>
                                        </StyledAccordionSummaryDup>
                                        <StyledAccordionDetails>
                                          {showRates && (
                                            <BootstrapTable
                                              tabIndexCell
                                              wrapperClasses="new-tab"
                                              key={
                                                ratePlan.ratePlanId +
                                                val.roomTypeId
                                              }
                                              keyField="name"
                                              data={ratePlan.rateArray}
                                              columns={ratePlanColumns}
                                            ></BootstrapTable>
                                          )}
                                          {!isUndefined(
                                            ratePlan.bootstrapTableArray
                                          ) &&
                                            ratePlan.bootstrapTableArray
                                              .length > 0 && (
                                              <BootstrapTable
                                                tabIndexCell
                                                wrapperClasses="new-tab"
                                                key={
                                                  ratePlan.ratePlanId +
                                                  val.roomTypeId +
                                                  "restrict"
                                                }
                                                keyField="name"
                                                data={
                                                  ratePlan.bootstrapTableArray
                                                }
                                                columns={ratePlanColumns}
                                              ></BootstrapTable>
                                            )}
                                          {!isUndefined(
                                            ratePlan.restrictionArray
                                          ) &&
                                            ratePlan.restrictionArray.length >
                                              0 && (
                                              <div className="react-bootstrap-table new-tab">
                                                <table className="table table-bordered">
                                                  <tbody>
                                                    {ratePlan.restrictionArray.map(
                                                      (item, index) => {
                                                        return (
                                                          <tr key={item.name}>
                                                            <td>{item.name}</td>
                                                            {Object.entries(
                                                              item
                                                            ).map(
                                                              ([
                                                                item_key,
                                                                item_value,
                                                              ]) => {
                                                                if (
                                                                  item_key !==
                                                                    "name" &&
                                                                  item_key !==
                                                                    "unique"
                                                                ) {
                                                                  return (
                                                                    <td
                                                                      key={
                                                                        item_key
                                                                      }
                                                                      style={{
                                                                        background:
                                                                          item_value
                                                                            ? "#F29191"
                                                                            : "#1BC5BD",
                                                                        cursor:
                                                                          "pointer",
                                                                        paddingRight:
                                                                          "0",
                                                                        paddingBottom:
                                                                          "0",
                                                                        paddingLeft:
                                                                          "0",
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          background:
                                                                            "white",
                                                                          padding:
                                                                            "20%",
                                                                        }}
                                                                      ></div>
                                                                    </td>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            )}
                                        </StyledAccordionDetails>
                                      </StyledAccordion>
                                    );
                                  }
                                )}
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grow>
                    );
                  }
                })}
              </>
            ))}
        </Grid>
      )}
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "fit-content",
            maxHeight: 550,
            pb: 2,
            overflowY: "scroll",
            padding: "1rem",
          }}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>
              Connected channels for{" "}
           
            </Modal.Title>
          </Modal.Header>
          <Modal.Body> */}
          <div className="d-flex align-items-center">
            {" "}
            <p className="mb-0 mr-3"> Connected channels for </p>
            <div className="badge badge-primary">{rPlanName}</div>
          </div>

          {connectedChannels.length == 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              No connected channels
            </div>
          ) : (
            <div style={{ marginBottom: "1rem" }}>
              {connectedChannels.map((res) => {
                let logo = res.toUpperCase();
                if (channelLogoMap[logo] !== undefined)
                  return (
                    <img
                      style={{ height: "2rem", marginRight: "10px" }}
                      src={channelLogoMap[logo]}
                    />
                  );
              })}
            </div>
          )}
          {ratePlansArr && <Divider />}
          <div
            style={{ marginLeft: "10px", fontSize: "15px", marginTop: "10px" }}
            className="badge badge-secondary"
          >
            Rate plans
          </div>
          {ratePlansArr &&
            ratePlansArr.map((res) => {
              return (
                <div
                  style={{
                    display: "flex",
                    marginTop: "1rem",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <b>{res.ratePlanName}</b>
                  </div>
                  <div>
                    {res.channels.length == 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginLeft: "10px",
                        }}
                      >
                        No connected channels
                      </div>
                    ) : (
                      <div>
                        {res.channels.map((resp) => {
                          let logo = resp.toUpperCase();
                          if (channelLogoMap[logo] !== undefined)
                            return (
                              <img
                                style={{
                                  height: "2rem",
                                  marginLeft: "10px",
                                }}
                                src={channelLogoMap[logo]}
                              />
                            );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </Box>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(BulkInventory);
