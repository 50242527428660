import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Switch,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import { connect, shallowEqual, useSelector } from "react-redux";
import { setMonth } from "date-fns";

const NightAuditConfiguration = () => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const [markNoShow, setmarkNoShow] = useState(false);
  const [mode, setmode] = useState("AUTO");
  const [nightAuditTime, setnightAuditTime] = useState("22:00");
  const [cancellationMode, setcancellationMode] = useState("VOID_ALL");
  const [autoCheckout, setautoCheckout] = useState(false);
  const [noshowMode, setnoshowMode] = useState("VOID_ALL");
  const [status, setstatus] = useState("DISABLE");
  const [nextDayCheckin, setnextDayCheckin] = useState(false);
  const [folioItemEdit, setfolioItemEdit] = useState(false);
  const [expiryhours, setexpiryhours] = useState("");
  const [expiryType, setexpiryType] = useState("from_current_time");
  const [notifEmailList, setnotifEmailList] = useState("");
  const [notifPhoneList, setnotifPhoneList] = useState("");
  const [notifConfigData, setnotifConfigData] = useState({});
  const [loader, setloader] = useState(false);

  const getData = () => {
    handleSpringDataRequest(
      `core/api/v1/nightAuditConfig/get-night-audit-configuration`
    )
      .then((res) => {
        setnotifConfigData(res);
        if (Object.entries(res).length > 0) {
          setexpiryhours(res.enquiry_exp_hrs);
          setexpiryType(res.exp_type);
          setnotifEmailList(res.notif_email_list);
          setnotifPhoneList(res.notif_phone_list);
          setcancellationMode(res.cancellationMode);
          setfolioItemEdit(res.folioItemEdit === "true" ? true : false);
          setmarkNoShow(res.markNoShow === "true" ? true : false);
          setnextDayCheckin(res.nextDayCheckin === "true" ? true : false);
          setautoCheckout(res.autoCheckout === "true" ? true : false);
          setnoshowMode(res.noShowMode);
          setnotifEmailList(res.emailList);
          setnotifPhoneList(res.phoneList);
          setnightAuditTime(res.time);
          setmode(res.mode);
          setstatus(res.status);
        } else {
          setnotifEmailList("");
          setnotifPhoneList("");
        }
      })
      .catch((err) => toast.error("Error in fetching details"));
  };
  useEffect(() => {
    getData();
  }, []);

  // const createNightAuditSchedule = () => {
  //   const payload = {
  //     hashKey: nightAuditTime,
  //     hotelId: hotel?.hotelId,
  //     status: status,
  //   };
  //   handleSpringDataMutationRequest(
  //     "POST",
  //     `core/api/v1/nightAuditSchedule/create`,
  //     payload
  //   )
  //     .then((res) => console.log("res", res))
  //     .catch((err) => console.error(err));
  // };

  // const updateNightAuditSchedule = () => {
  //   const payload = {
  //     hashKey: nightAuditTime,
  //     hotelId: hotel?.hotelId,
  //     status: status,
  //   };
  //   handleSpringDataMutationRequest(
  //     "PUT",
  //     `core/api/v1/nightAuditSchedule/update`,
  //     payload
  //   )
  //     .then((res) => console.log("res", res))
  //     .catch((err) => console.error(err));
  // };

  const save = () => {
    setloader(true);
    const payload = {
      hotelId: hotel?.hotelId,
      markNoShow: markNoShow,
      mode: mode,
      time: nightAuditTime,
      status: status,
      cancellationMode: cancellationMode,
      noShowMode: noshowMode,
      autoCheckout: autoCheckout,
      nextDayCheckin: nextDayCheckin,
      folioItemEdit: folioItemEdit,
      emailList: notifEmailList,
      phoneList: notifPhoneList,
    };
    if (Object.entries(notifConfigData).length === 0) {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/nightAuditConfig/create`,
        payload
      )
        .then((res) => {
          toast.success("Successfully created");
          // createNightAuditSchedule();
          getData();
          setloader(false);
        })
        .catch((err) => {
          toast.error("Error in creating");
          setloader(false);
        });
    } else {
      handleSpringDataMutationRequest(
        "PUT",
        `core/api/v1/nightAuditConfig/update`,
        payload
      )
        .then((res) => {
          toast.success("Successfully updated");
          // updateNightAuditSchedule();
          getData();
          setloader(false);
        })
        .catch((err) => {
          toast.error("Error in updating");
          setloader(false);
        });
    }
  };

  const onToggleNightAudit = (e) => {
    if (status === "DISABLE") {
      setstatus("ENABLE");
    } else if (status === "ENABLE") {
      setstatus("DISABLE");
    }
  };
  const onToggleCancellationPolicy = (data, enabled) => {
    if (data === "VOID_ALL" && enabled) {
      setcancellationMode("VOID_ALL");
    } else if (data === "CANCEL_POLICY" && enabled) {
      setcancellationMode("CANCEL_POLICY");
    } else if (data === "CANCEL_POLICY" && enabled === false) {
      setcancellationMode("VOID_ALL");
    } else if (data === "VOID_ALL" && enabled === false) {
      setcancellationMode("CANCEL_POLICY");
    }
  };

  const onToggleNoShow = (data, enabled) => {
    if (data === "VOID_ALL" && enabled) {
      setnoshowMode("VOID_ALL");
    } else if (data === "CANCEL_POLICY" && enabled) {
      setnoshowMode("CANCEL_POLICY");
    } else if (data === "CANCEL_POLICY" && enabled === false) {
      setnoshowMode("VOID_ALL");
    } else if (data === "VOID_ALL" && enabled === false) {
      setnoshowMode("CANCEL_POLICY");
    }
  };

  return hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
    hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN" ? (
    <div>
      <Grid container>
        <div className="d-flex flex-column">
          <h2>Configuration</h2>
          <p>
            Night Audit Configuration is vital for night audit execution,
            offering two options-Auto and Manual modes. In Manual mode, daily
            manual audits are required, while Auto mode automates night audits
            based on booking status like No Shows Cancellations and Pennding
            departures. Successful Night audits display "Success" status; missed
            audits show "Pending" status. Any Changes to past date bookings
            shift status from "Success" to "Pending." Currently, revenue from
            rooms and Add-ons is included, with plans to encompass other
            departments' revenue in the future.
          </p>
          <Divider />
        </div>
        <div className="w-100">
          <div className="d-flex align-item-center mt-4">
            <h3 className="mb-0" style={{ marginTop: "5px" }}>
              Enable night audit
            </h3>
            <Switch
              checked={status === "ENABLE" ? true : false}
              onChange={onToggleNightAudit}
            />
          </div>
          <p className="text-muted  mb-3">
            Property night audit is crucial as it ensures accurate financial
            records, validates transactions, and maintains operational
            integrity. It guarantees transparency and informs informed
            decision-making for effective property management.
          </p>
          {status === "ENABLE" && (
            <div>
              {(hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN") && (
                <div className="d-flex" style={{ marginTop: "2rem" }}>
                  <h3
                    style={{
                      marginTop: "5px",
                      marginRight: "1rem",
                      width: "17rem",
                    }}
                  >
                    What mode you prefer?
                  </h3>

                  <FormControl style={{ width: "10rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      Night Audit Mode
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setmode(e.target.value)}
                      label="Night Audit Mode"
                      value={mode}
                      size="small"
                    >
                      <MenuItem key={"AUTO"} value={"AUTO"}>
                        {"AUTO"}
                      </MenuItem>
                      <MenuItem key={"MANUAL"} value={"MANUAL"}>
                        {"MANUAL"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}

              {mode === "AUTO" &&
                (hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                  hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN") && (
                  <p className="text-muted mt-3 mb-3">
                    Automatically handles pending arrivals, cancellations,
                    no-shows and departures as per the configuration defined
                    below. This saves a lot of time for your night auditor or
                    property manager. Please enable this option with caution. We
                    highly recommend to cross-check the reports for the first
                    few days to see if the system is generating correct reports.
                    AUTO mode enriches and fastens the audit process it does not
                    guarantee a successful audit
                  </p>
                )}
              {mode === "MANUAL" &&
                (hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                  hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN") && (
                  <p className="text-muted mt-3 mb-3">
                    In Manual Mode, the night audit has to be performed manually
                    by accessing the option under night audit history. You'll
                    locate the 'Run night audit' option on the top-right corner.
                    Our guided sequence of steps for manual night audit will
                    assist you in smoothly executing the night audits.
                  </p>
                )}
              {mode === "AUTO" && (
                <>
                  <div className="d-flex" style={{ marginTop: "2rem" }}>
                    <h3 style={{ marginTop: "5px", marginRight: "1rem" }}>
                      When would you like to run the night audit?
                    </h3>

                    <FormControl fullWidth style={{ width: "15rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        Night Audit Time
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => setnightAuditTime(e.target.value)}
                        className="mr-4"
                        label="Night Audit Time"
                        value={nightAuditTime}
                        size="small"
                      >
                        <MenuItem key={"22:00"} value={"22:00"}>
                          {"10 PM"}
                        </MenuItem>
                        <MenuItem key={"23:00"} value={"23:00"}>
                          {"11 PM"}
                        </MenuItem>
                        <MenuItem key={"00:00"} value={"00:00"}>
                          {"12 AM"}
                        </MenuItem>
                        <MenuItem key={"01:00"} value={"01:00"}>
                          {"1 AM"}
                        </MenuItem>
                        <MenuItem key={"02:00"} value={"02:00"}>
                          {"2 AM"}
                        </MenuItem>
                        <MenuItem key={"03:00"} value={"03:00"}>
                          {"3 AM"}
                        </MenuItem>
                        <MenuItem key={"034:00"} value={"04:00"}>
                          {"4 AM"}
                        </MenuItem>
                        <MenuItem key={"05:00"} value={"05:00"}>
                          {"5 AM"}
                        </MenuItem>
                        <MenuItem key={"06:00"} value={"06:00"}>
                          {"6 AM"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <p className="text-muted  mb-3">
                    This time might be used to send automated reports, shift the
                    day and freeze some edit operations on folios.
                  </p>
                </>
              )}

              {mode === "AUTO" && (
                <>
                  <Divider />
                  <h2 style={{ marginTop: "2rem", fontWeight: "bold" }}>
                    Cancellations
                  </h2>
                  <div
                    className="d-flex align-item-center"
                    style={{ marginTop: "1rem" }}
                  >
                    <h3 className="mb-0" style={{ marginTop: "5px" }}>
                      Void folio balance for all cancellations
                    </h3>
                    <Switch
                      checked={cancellationMode === "VOID_ALL" ? true : false}
                      onChange={(event) =>
                        onToggleCancellationPolicy(
                          "VOID_ALL",
                          event.target.checked
                        )
                      }
                    />
                  </div>
                  <p className="text-muted  mb-3">
                    This option will set the folio balance to 0 despite the
                    cancellation policy.
                  </p>

                  <div
                    className="d-flex align-item-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <h3 className="mb-0" style={{ marginTop: "5px" }}>
                      Adjust the folio balance as per cancellation policy?
                    </h3>
                    <Switch
                      checked={
                        cancellationMode === "CANCEL_POLICY" ? true : false
                      }
                      onChange={(event) =>
                        onToggleCancellationPolicy(
                          "CANCEL_POLICY",
                          event.target.checked
                        )
                      }
                    />
                  </div>
                  <p className="text-muted  mb-3">
                    Charges will be posted to folio as per cancellation policy
                  </p>
                </>
              )}

              {mode === "AUTO" && (
                <>
                  <Divider />
                  <h2 style={{ marginTop: "2rem", fontWeight: "bold" }}>
                    No shows
                  </h2>
                  <div
                    className="d-flex align-item-center"
                    style={{ marginTop: "1rem" }}
                  >
                    <h3 className="mb-0" style={{ marginTop: "5px" }}>
                      Void folio balance for all no shows?
                    </h3>
                    <Switch
                      checked={noshowMode === "VOID_ALL" ? true : false}
                      onChange={(event) =>
                        onToggleNoShow("VOID_ALL", event.target.checked)
                      }
                    />
                  </div>
                  <p className="text-muted  mb-3">
                    This option will set the folio balance to 0 despite the
                    cancellation policy.
                  </p>

                  <div
                    className="d-flex align-item-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <h3 className="mb-0" style={{ marginTop: "5px" }}>
                      Adjust the folio balance as per cancellation policy?
                    </h3>
                    <Switch
                      checked={noshowMode === "CANCEL_POLICY" ? true : false}
                      onChange={(event) =>
                        onToggleNoShow("CANCEL_POLICY", event.target.checked)
                      }
                    />
                  </div>
                  <p className="text-muted  mb-3">
                    Charges will be posted to folio as per cancellation policy
                  </p>

                  <div
                    className="d-flex align-item-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <h3 className="mb-0" style={{ marginTop: "5px" }}>
                      Automatically mark no-show after the check-out time is
                      passed
                    </h3>
                    <Switch
                      checked={markNoShow}
                      onChange={(event) => setmarkNoShow(event.target.checked)}
                    />
                  </div>
                  <p className="text-muted  mb-3">
                    This option will set the folio balance to 0 despite the
                    cancellation policy.
                  </p>
                </>
              )}

              {mode === "AUTO" && (
                <>
                  <Divider />

                  <h2
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Pending departures
                  </h2>
                  <div
                    className="d-flex align-item-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <h3 className="mb-0" style={{ marginTop: "5px" }}>
                      Automatically mark checked out after the checkout time is
                      passed.
                    </h3>
                    <Switch
                      checked={autoCheckout}
                      onChange={(event) =>
                        setautoCheckout(event.target.checked)
                      }
                    />
                  </div>
                  <p className="text-muted  mb-3">
                    This will mark all bookings as checked out that have folio
                    balance as 0.
                  </p>
                </>
              )}
              <Divider />
              <div
                className="d-flex align-item-center"
                style={{ marginTop: "2rem" }}
              >
                <h3 className="mb-0" style={{ marginTop: "5px" }}>
                  Disable next day walk-ins if the previous day night audit is
                  pending
                </h3>
                <Switch
                  checked={nextDayCheckin}
                  onChange={(event) => setnextDayCheckin(event.target.checked)}
                />
              </div>
              <p className="text-muted  mb-3">
                Front desk staff cannot create same-day walk-in bookings or
                process check-ins for the current day if the previous night's
                audit has not been completed.
              </p>

              {/* <div
                className="d-flex align-item-center"
                style={{ marginTop: "2rem" }}
              >
                <h3 className="mb-0" style={{ marginTop: "5px" }}>
                  Disable edits to folio items after the night audit is run
                </h3>
                <Switch
                  checked={folioItemEdit}
                  onChange={(event) => setfolioItemEdit(event.target.checked)}
                />
              </div>
              <p className="text-muted  mb-3">
                Front desk staff cannot edit or add folio items for the dates
                where night audit is successfully ran. Only super admin can edit
                the folio.
              </p> */}

              <>
                <Divider />
                <h2
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Notifications
                </h2>
                <h3 style={{ marginTop: "2rem" }}>Email notification?</h3>
                <p className="text-muted mt-2 mb-3">
                  Night audit report will be sent to the email addresses
                  provided below. Please enter multiple email addresses
                  separated by commas, without spaces before or after the
                  commas.
                </p>
                <TextField
                  onChange={(e) => {
                    setnotifEmailList(e.target.value);
                  }}
                  label="Notification email list"
                  sx={{ width: "50rem" }}
                  value={notifEmailList}
                  size={"small"}
                />

                {/* <h3 style={{ marginTop: "2rem" }}>Whatsapp notification?</h3>
                <p className="text-muted mt-2 mb-3">
                  Night audit report will be delivered to these contacts. Enter
                  them comma seperated don't add spaces after and before comma.{" "}
                </p>

                <TextField
                  onChange={(e) => {
                    setnotifPhoneList(e.target.value);
                  }}
                  label="Notification phone list"
                  sx={{ width: "50rem" }}
                  value={notifPhoneList}
                  size={"small"}
                /> */}
              </>
            </div>
          )}
        </div>
        <Button
          variant="custom-button"
          onClick={save}
          style={{
            float: "right",
            marginLeft: "auto",
            marginRight: "19rem",
            marginTop: "2rem",
          }}
          disabled={loader}
        >
          {loader ? (
            <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
          ) : (
            "Save"
          )}
        </Button>
      </Grid>
    </div>
  ) : (
    <h2>You dont have access to this page</h2>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(NightAuditConfiguration);
