import React from 'react';

function DashboardMockLoader() {
  return <div className={`mockLoaderTable d-flex flex-column p-3 justify-content-between box-shadow-main` } style={{boxShadow: 'none'}}>
      <div className="box w-25 p-2" style={{minHeight: '3rem'}}>{' '}</div>
      <div className="box w-50 p-2 mt-3" style={{minHeight: '1.5rem'}}>{' '}</div>
  <div className="d-flex w-100 align-items-center justify-content-between" style={{marginTop: '4rem'}}>
    <div className="box w-auto p-3 m-2" style={{minHeight: '15rem', borderRadius: '1.5rem'}}>{' '}</div>
    <div className="box w-auto p-3 m-2" style={{minHeight: '15rem', borderRadius: '1.5rem'}}>{' '}</div>
    <div className="box w-auto p-3 m-2" style={{minHeight: '15rem', borderRadius: '1.5rem'}}>{' '}</div>
    <div className="box w-auto p-3 m-2" style={{minHeight: '15rem', borderRadius: '1.5rem'}}>{' '}</div>
    <div className="box w-auto p-3 m-2" style={{minHeight: '15rem', borderRadius: '1.5rem'}}>{' '}</div>
    <div className="box w-auto p-3 m-2" style={{minHeight: '15rem', borderRadius: '1.5rem'}}>{' '}</div>
  </div>
  <div className="d-flex flex-column w-100 align-items-center justify-content-between ml-1 mr-1 p-2" style={{boxShadow: '0px 0px 16px 6px #0000001a', marginTop: '3rem', borderRadius: '1rem'}}>
    <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="w-25 d-flex">
            <div className="box w-100 p-2" style={{minHeight: '1.5rem'}}>{' '}</div>
            <div className="box w-100 p-2" style={{minHeight: '1.5rem'}}>{' '}</div>
        </div>
        <div className="w-25 d-flex">
            <div className="box w-100 p-2" style={{minHeight: '1.5rem'}}>{' '}</div>
            <div className="box w-100 p-2" style={{minHeight: '1.5rem'}}>{' '}</div>
        </div>
    </div>
    <div className="w-100 d-flex align-items-center justify-content-between" style={{marginTop: '0.5rem'}}>
        <div className="w-50 d-flex">
            <div className="box w-25 p-2" style={{minHeight: '2rem'}}>{' '}</div>
            <div className="box w-75 p-2" style={{minHeight: '2rem'}}>{' '}</div>
        </div>
        <div className="w-25 d-flex">
            <div className="box w-100 p-2" style={{minHeight: '2rem'}}>{' '}</div>
            <div className="box w-100 p-2" style={{minHeight: '2rem'}}>{' '}</div>
            <div className="box w-100 p-2" style={{minHeight: '2rem'}}>{' '}</div>
        </div>
    </div>
  </div>
  <div className="d-flex flex-column w-100 align-items-center justify-content-between ml-1 mr-1 p-2" style={{boxShadow: '0px 0px 16px 6px #0000001a', marginTop: '1rem' , borderRadius: '1rem'}}>
    <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="w-25 d-flex">
            <div className="box w-100 p-2" style={{minHeight: '1.5rem'}}>{' '}</div>
            <div className="box w-100 p-2" style={{minHeight: '1.5rem'}}>{' '}</div>
        </div>
        <div className="w-25 d-flex">
            <div className="box w-100 p-2" style={{minHeight: '1.5rem'}}>{' '}</div>
            <div className="box w-100 p-2" style={{minHeight: '1.5rem'}}>{' '}</div>
        </div>
    </div>
    <div className="w-100 d-flex align-items-center justify-content-between" style={{marginTop: '0.5rem'}}>
        <div className="w-50 d-flex">
            <div className="box w-25 p-2" style={{minHeight: '2rem'}}>{' '}</div>
            <div className="box w-75 p-2" style={{minHeight: '2rem'}}>{' '}</div>
        </div>
        <div className="w-25 d-flex">
            <div className="box w-100 p-2" style={{minHeight: '2rem'}}>{' '}</div>
            <div className="box w-100 p-2" style={{minHeight: '2rem'}}>{' '}</div>
            <div className="box w-100 p-2" style={{minHeight: '2rem'}}>{' '}</div>
        </div>
    </div>
  </div>
</div>;
}

export default DashboardMockLoader;
