import moment from "moment";
// import { check } from "prettier";
import { dateFormat } from "../constants/Calendar-constants";

export class MultipleBookingModel {
  userBookingInfo = {};
  userEmail = "";
  checkin = "";
  checkout = "";
  actualCheckin = "";
  actualCheckout = "";
  bookingAmount = 0;
  bookingDiscount = 0;
  bookingSavings = 0;
  hotelId = "";
  bookingSource = "STAYFLEXI_HD";
  refund = 0;
  taxPercentage = 0;
  externalPayment = 0;
  externalPaymentCard = 0;
  username = "";
  userPhone = "";
  reservationInfo = {};
  booking_id = "";
  timestamps = null;
  rooms_requested = {};
  checkin = "";
  checkout = "";
  type = "Guest";
  all_reservation_room_info = {};
  message_type = null;
  is_external = false;
  is_enquiry = false;
  completeData = [];
  message_source = "STAYFLEXI_HD";
  message_type = null;
  zipCode = "";
  state = "";
  country = "";
  address = "";
  address1 = "";
  city = "";
  taxNames = [];
  taxId = "";
  totalPrice = 0;
  priceMap = {};
  govt_id_type = "";
  govt_type_value = "";
  expiry_time = "";
  expiry_type = "";
  duration_type = "";
  special_requests = "";
  addons = [];
  appliedPromocode = "";
  promoAmount = 0;
  segment = "";
  dateOfBirth = "";
  gender = "";
  nationality = "";
  occupation = "";
  companyName = "";
  purposeOfVisit = "";
  programsAttended = "";
  emergency_contact = "";
  emergency_contact_name = "";
  customerETA = "";
  customerETD = "";
  ocoCheckin = "";
  approvedByOverseas = "";
  otherGuestDetails = [];
  roomData = [];
  prpList = null;

  constructor(data) {
    this.hotelId = data.hotelId;
    this.userEmail = data.email;
    this.checkin = moment(data.start).format(dateFormat);
    this.checkout = moment(data.end).format(dateFormat);
    this.actualCheckin = moment(data.start).format(dateFormat);
    this.actualCheckout = moment(data.end).format(dateFormat);
    this.username = data.title;
    this.userPhone = data.phoneNumber;
    this.completeData = data.allData;
    this.state = data.state;
    this.city = data.city;
    this.country = data.country;
    this.address = data.address1;
    this.address1 = data.address2;
    this.zipCode = data.zipcode;
    this.taxId = data.taxId;
    this.taxNames = data.taxNames;
    this.total_price = data.totalPrice;
    this.priceMap = data.priceMap;
    this.bookingSource = data.bookingSource;
    this.message_source = data.bookingSource;
    this.govt_id_type = data.govt_id_type;
    this.govt_id_value = data.govt_id_value;
    this.expiry_time = data.expiry_time;
    this.expiry_type = data.expiry_type;
    this.is_enquiry = data.is_enquiry;
    this.duration_type = data.duration_type;
    this.special_requests = data.special_requests;
    this.addons = data.addons;
    this.appliedPromocode = data.appliedPromocode;
    this.promoAmount = data.promoAmount;
    this.segment = data.segment;
    this.dateOfBirth = data.dateOfBirth;
    this.gender = data.gender;
    this.nationality = data.nationality;
    this.occupation = data.occupation;
    this.companyName = data.companyName;
    this.purposeOfVisit = data.purposeOfVisit;
    this.programsAttended = data.programsAttended;
    this.emergency_contact = data.emergencyContact;
    this.emergency_contact_name = data.emergencyContactName;
    this.customerETA = data.customer_eta;
    this.customerETD = data.customer_etd;
    this.ocoCheckin = data.ocoCheckin;
    this.approvedByOverseas = data.approvedByOverseas;
    this.otherGuestDetails = data.otherGuestDetails;
    this.requestorName = data.requestorName;
    this.requestorContact = data.requestorContact;
    this.requestorDept = data.requestorDept;
    this.requestorApprover = data.requestorApprover;
    this.requestorRemarks = data.requestorRemarks; //will be added in case needed for future
    this.isComplimentary = data.isComplimentary;
    this.roomData = data.roomData;
    this.bookingReferenceId = data.bookingReferenceId;
    this.companyGstNumber = data.companyGstNumber;
    this.prpList = data.prpList;
  }

  multipleBookingParams = () => {
    let obj1 = {};
    let obj2 = {};
    let obj3 = {};
    this.completeData.forEach((roomSelection) => {
      let priceMap = roomSelection.ratePlan["priceMap"];
      const key =
        roomSelection.roomTypeId + "-" + roomSelection.ratePlan.ratePlanId;
      obj1[key] = roomSelection.roomCount;
      obj2[key] = [];
      for (let i = 1; i <= roomSelection.roomCount; i++) {
        let price = 0;
        const count = roomSelection["selectionData"][i];
        const key1 = count["A"];
        const key2 = count["C"];
        if (key2 === 0) {
          let combine = key1.toString();
          price = price + priceMap[combine];
        } else {
          let combine = key1.toString() + "+" + key2.toString();
          price = price + priceMap[combine];
        }
        const temp_obj = {
          adults: key1,
          children: key2,
          ratePlanName: roomSelection.ratePlan.ratePlanName,
        };
        obj2[key].push(temp_obj);
        // obj3[key] = roomSelection.selectedRooms.reduce(
        //   (results, selectedId, indx) => {
        //     if (selectedId)
        //       results.push(roomSelection["combos"]["onlineRoomIds"][indx]);
        //     return results;
        //   },
        //   []
        // );
        obj3[key] = roomSelection.selectedRooms.reduce(
          (results, selectedId, indx) => {
            if (selectedId) {
              if (indx >= roomSelection["combos"]["onlineRoomIds"]?.length) {
                results.push(
                  roomSelection["combos"]["offlineRoomIds"][
                    indx - roomSelection["combos"]["onlineRoomIds"]?.length
                  ]
                );
              } else {
                results.push(roomSelection["combos"]["onlineRoomIds"][indx]);
              }
              return results;
            }
          },
          []
        );
      }
    });

    const resp = {
      userBookingInfo: {
        id: this.booking_id,
        userEmail: this.userEmail,
        checkin: this.checkin,
        checkout: this.checkout,
        actualCheckin: this.checkin,
        actualCheckout: this.checkout,
        bookingAmount: this.total_price,
        bookingDiscount: 0,
        bookingSavings: 0,
        hotelId: this.hotelId,
        ratePlanId: this.completeData[0].ratePlan.ratePlanId,
        bookingSource: this.bookingSource,
        refund: 0,
        taxPercentage: 0,
        externalPayment: 0,
        externalPaymentCard: 0,
        username: this.username,
        userPhone: this.userPhone,
        specialRequests: this.special_requests,
        appliedPromocode: this.appliedPromocode,
        promoAmount: this.promoAmount,
        segment: this.segment,
        bookingReferenceId: this.bookingReferenceId,
        companyGstNumber: this.companyGstNumber,
        taxExemptId: this.taxId,
        // appliedPromocode: "FLAT10",
      },
      admin_reservation_info: {
        newReservationInfo: {
          booking_id: this.booking_id,
          hotel_id: this.hotelId,
          timestamps: null,
          rooms_requested: obj1,
          checkin: this.checkin,
          checkout: this.checkout,
          type: "Guest",
          all_reservation_room_info: obj2,
          message_source: this.message_source,
          message_type: null,
        },
        physicalRoomIdsMap: obj3,
      },
      customer: {
        userName: this.username,
        phoneNumber: this.userPhone,
        email: this.userEmail,
        zipCode: this.zipCode,
        address: this.address,
        city: this.city,
        state: this.state,
        country: this.country,
        address1: this.address1,
        userName: this.username,
        govtIdType: this.govt_id_type,
        govtIdValue: this.govt_id_value,
        dateOfBirth: this.dateOfBirth,
        gender: this.gender,
        nationality: this.nationality,
        occupation: this.occupation,
        emergency_contact: this.emergency_contact,
        emergency_contact_name: this.emergency_contact_name,
      },
      miscDetails: {
        companyName: this.companyName,
        programsAttended: this.programsAttended,
        purposeOfVisit: this.purposeOfVisit,
        customerETA: this.customerETA,
        customerETD: this.customerETD,
        ocoCheckinComplete: this.ocoCheckin,
        approvedByOverseas: this.approvedByOverseas,
        isComplimentary: this.isComplimentary,
        compRequestName: this.requestorName,
        compRequestContact: this.requestorContact,
        compRequestDepartment: this.requestorDept,
        compRequestApprover: this.requestorApprover,
        compRemarks: this.requestorRemarks,
      },
      userDetails: {
        userName: this.username,
        phoneNumber: this.userPhone,
        email: this.userEmail,
        zipCode: this.zipCode,
        address: this.address,
        city: this.city,
        state: this.state,
        country: this.country,
        address1: this.address1,
      },
      govt_id_type: this.govt_id_type,
      govt_id_value: this.govt_id_value,
      is_external: false,
      is_enquiry: this.is_enquiry,
      expiry_time: this.expiry_time,
      duration_type: this.duration_type,
      expiry_type: this.expiry_type,
      exempt_tax_names: this.taxNames,
      taxId: this.taxId,
      addons: this.addons,
      otherGuestDetails: this.otherGuestDetails,
      roomData: this.roomData,
      prpList: this.prpList,
    };
    return resp;
  };

  paramsForBooking() {
    let obj1 = {};
    let obj2 = {};
    this.completeData.forEach((roomSelection) => {
      let priceMap = roomSelection.ratePlan["priceMap"];
      const key =
        roomSelection.roomTypeId + "-" + roomSelection.ratePlan.ratePlanId;
      obj1[key] = roomSelection.roomCount;
      obj2[key] = [];
      for (let i = 1; i <= roomSelection.roomCount; i++) {
        let price = 0;
        const count = roomSelection["selectionData"][i];
        const key1 = count["A"];
        const key2 = count["C"];
        if (key2 === 0) {
          let combine = key1.toString();
          price = price + priceMap[combine];
        } else {
          let combine = key1.toString() + "+" + key2.toString();
          price = price + priceMap[combine];
        }
        const temp_obj = {
          adults: key1,
          children: key2,
          amount: price,
          ratePlanName: roomSelection.ratePlan.ratePlanName,
        };
        obj2[key].push(temp_obj);
      }
    });
    const resp = {
      userBookingInfo: {
        id: this.booking_id,
        userEmail: this.userEmail,
        checkin: this.checkin,
        checkout: this.checkout,
        actualCheckin: this.checkin,
        actualCheckout: this.checkout,
        bookingAmount: this.total_price,
        bookingDiscount: 0,
        bookingSavings: 0,
        hotelId: this.hotelId,
        ratePlanId: this.completeData[0].ratePlan.ratePlanId,
        bookingSource: this.bookingSource,
        refund: 0,
        taxPercentage: 0,
        externalPayment: 0,
        externalPaymentCard: 0,
        username: this.username,
        userPhone: this.userPhone,
        specialRequests: this.special_requests,
        appliedPromocode: this.appliedPromocode,
        segment: this.segment,
        promoAmount: this.promoAmount,
        bookingReferenceId: this.bookingReferenceId,
        companyGstNumber: this.companyGstNumber,
        taxExemptId: this.taxId,
      },
      customer: {
        userName: this.username,
        phoneNumber: this.userPhone,
        email: this.userEmail,
        zipCode: this.zipCode,
        address: this.address,
        city: this.city,
        state: this.state,
        country: this.country,
        address1: this.address1,
        userName: this.username,
        govtIdType: this.govt_id_type,
        govtIdValue: this.govt_id_value,
        dateOfBirth: this.dateOfBirth,
        gender: this.gender,
        nationality: this.nationality,
        occupation: this.occupation,
        emergency_contact: this.emergency_contact,
        emergency_contact_name: this.emergency_contact_name,
      },
      miscDetails: {
        companyName: this.companyName,
        programsAttended: this.programsAttended,
        purposeOfVisit: this.purposeOfVisit,
        customerETA: this.customerETA,
        customerETD: this.customerETD,
        ocoCheckinComplete: this.ocoCheckin,
        approvedByOverseas: this.approvedByOverseas,
        isComplimentary: this.isComplimentary,
        compRequestName: this.requestorName,
        compRequestContact: this.requestorContact,
        compRequestDepartment: this.requestorDept,
        compRequestApprover: this.requestorApprover,
        compRemarks: this.requestorRemarks,
      },
      otherGuestDetails: this.otherGuestDetails,
      newReservationInfo: {
        booking_id: this.booking_id,
        hotel_id: this.hotelId,
        timestamps: null,
        rooms_requested: obj1,
        checkin: this.checkin,
        checkout: this.checkout,
        type: "Guest",
        all_reservation_room_info: obj2,
        message_source: this.message_source,
        message_type: null,
      },
      is_external: false,
      is_enquiry: this.is_enquiry,
      expiry_time: this.expiry_time,
      expiry_type: this.expiry_type,
      duration_type: this.duration_type,
      govt_id_type: this.govt_id_type,
      govt_id_value: this.govt_id_value,
      addons: this.addons,
      roomData: this.roomData,
      prpList: this.prpList,
      exempt_tax_names: this.taxNames,
      taxId: this.taxId,
    };
    return resp;
  }
}
