import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#E5e5e5";
const tableCell = "#F8F8F8";

// formatting the booking id

const StatusMap = [
  {
    name: "CONFIRMED",
    display_name: "Confirmed",
    color: "#F3AB59",
  },
  {
    name: "ADMIN_CONFIRMED",
    display_name: "Checked-In",
    color: "#5CBFA4",
  },
  {
    name: "CANCELLED",
    display_name: "Cancelled",
    color: "#d30000",
  },
  {
    name: "ON_HOLD",
    display_name: "On hold",
    color: "FF5C5C",
  },
  {
    name: "CHECKED_OUT",
    display_name: "Checked-out",
    color: "#2F84C6",
  },
  {
    name: "NO_SHOW",
    display_name: "No Show",
    color: "#A45A52",
  },
  {
    name: "BOOKING_ENQUIRY",
    display_name: "Enquiry",
    color: "#7F7D9C",
  },
];

// Format booking status
const formatBookingStatus = (status) => {
  const data = StatusMap.filter((item) => {
    if (item.name === status) {
      return item;
    }
  }).map((val) => {
    return val.display_name;
  });
  return data[0];
};

// get today time
const todayDate = new Date();
const getTime = () => {
  return moment(todayDate).format("DD-MM-YYYY HH:mm A");
};

// get the total room booking amount
const getTotalFieldAmt = (dashboardData, fieldName) => {
  var amt = 0;
  dashboardData.forEach((item) => (amt = amt + Number(item[fieldName])));
  return Number(amt).toFixed(2);
};

function DashboardReport({
  dashboardData,
  heading,
  hotelName,
  hotelLogo,
  address,
  currency,
}) {
  // get the image extension
  const getExtension = (img) => {
    if (img?.charAt(0) === "/") {
      return `data:image/jpeg;base64, ${img}`;
    } else if (img?.charAt(0) === "i") {
      return `data:image/png;base64, ${img}`;
    } else if (img?.charAt(0) === "U") {
      return `data:image/webp;base64, ${img}`;
    } else if (img?.charAt(0) === "R") {
      return `data:image/gif;base64, ${img}`;
    }
  };
  //Function to create adderss
  const [finalAddress, setfinalAddress] = useState("");
  const concatAddress = () => {
    const addr1 =
      address &&
      address.address1 !== null &&
      address.address1 !== "NA" &&
      address.address1;
    const addr2 =
      address &&
      address.address2 !== null &&
      address.address2 !== "NA" &&
      address.address2;

    let finaladd = "";
    if (addr1 && addr1 !== undefined && (addr2 === null || addr2 === undefined))
      finaladd = addr1;
    else if (
      addr2 &&
      addr2 !== undefined &&
      (addr1 === null || addr1 === undefined)
    )
      finaladd = addr2;
    else finaladd = addr1 + ", " + addr2;
    setfinalAddress(finaladd);
  };

  // Handling customer address
  const handleCustomerAddress = (address) => {
    if (!address.length > 0) {
      return "";
    } else if (address.includes("null")) {
      return "";
    } else if (address.includes("NA")) {
      return "";
    } else {
      let parsed = parseInt(address);
      return String(parsed) === String(NaN) ? [`${address}, `] : parsed + "";
    }
  };

  useEffect(() => {
    concatAddress();
  }, [address]);
  return (
    <View
      style={{
        width: "100vw",
        // height: "100vh",
        position: "relative",
        marginRight: 10,
        marginTop: 10,
        marginLeft: 10,
      }}
    >
      {/* <View style={styles.header}> */}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {/* {hotelLogo?.length > 0 && hotelLogo && (
          <View style={{ width: "45px" }}>
            <Image
              style={{ width: "65px", height: "45px" }}
              src={getExtension(hotelLogo)}
            />
          </View>
        )} */}
        <View>
          <Text style={{ fontSize: "14px", marginBottom: 5 }}>
            <b>{heading} Report</b>
          </Text>
          <Text style={{ fontSize: "10px", marginBottom: 5 }}>
            Report printed on : {moment().format("MMM DD YYYY hh:mm A")}
          </Text>
          <Text style={{ fontSize: "10px" }}>
            Total {heading} : {dashboardData.length}
          </Text>
        </View>
        <View>
          <Text style={{ fontSize: "10px", marginBottom: 5 }}>{hotelName}</Text>

          <Text style={{ fontSize: "8px", marginBottom: 5 }}>
            {finalAddress}
          </Text>
          <Text style={{ fontSize: "8px" }}>
            {address.address1 !== undefined &&
              handleCustomerAddress(address.city) +
                handleCustomerAddress(address.state) +
                handleCustomerAddress(address.country) +
                handleCustomerAddress(address.pincode)}
          </Text>
        </View>
      </View>
      <View style={styles.container}>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "5%" },
          ]}
        >
          <b>ID</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "8%" },
          ]}
        >
          <b>Room No.(s)</b>
        </Text>
        <Text
          style={[
            styles.customfield,
            { fontSize: "7px", fontWeight: "bold", width: "10%" },
          ]}
        >
          <b>Name</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "8%" },
          ]}
        >
          <b>Phone</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "7%" },
          ]}
        >
          <b>Checkin</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "7%" },
          ]}
        >
          <b>Checkout</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "8%" },
          ]}
        >
          <b>Source</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "8%" },
          ]}
        >
          <b>Status</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "8%" },
          ]}
        >
          <b>Room amount</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "10%" },
          ]}
        >
          <b>PAX</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "8%" },
          ]}
        >
          <b>Folio amt.</b>
        </Text>
        <Text
          style={[
            styles.field,
            { fontSize: "7px", fontWeight: "bold", width: "8%" },
          ]}
        >
          <b>Bal. due</b>
        </Text>
      </View>
      <View style={styles.containerDivider}></View>
      {dashboardData?.map((item, index) => (
        <View style={styles.dataContainer} key={index}>
          <Text style={[styles.field, { fontSize: "7px", width: "5%" }]}>
            {item?.booking_id?.split("_")[2]}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "8%" }]}>
            {item.room_ids}
          </Text>
          <Text style={[styles.customfield, { fontSize: "7px", width: "10%" }]}>
            {item.booking_user_name}
          </Text>
          <Text
            style={[
              styles.field,
              {
                fontSize: "7px",
                position: "relative",
                width: "8%",
                flexWrap: "wrap",
              },
            ]}
          >
            {item.booking_user_phone}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "7%" }]}>
            {item.cin}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "7%" }]}>
            {item.cout}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "8%" }]}>
            {item.booking_source}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "8%" }]}>
            {formatBookingStatus(item?.booking_status)}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "8%" }]}>
            {currency}
            {item.reservation_amount.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "10%" }]}>
            Adults: {item.adults} Children: {item.children}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "8%" }]}>
            {currency}
            {item.booking_amount.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.field, { fontSize: "7px", width: "8%" }]}>
            {currency}
            {item.balance_due.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      ))}
      {/* <View style={styles.containerDivider}></View> */}
      {/* <View style={styles.totalField}>
        <Text style={[styles.field, { width: "10%" }]}> Total </Text>
        <Text style={[styles.field, { width: "3%" }]}>{""}</Text>
        <Text style={[styles.field, { width: "8%" }]}>{""}</Text>
        <Text style={[styles.field, { width: "10%" }]}>{""}</Text>
        <Text style={[styles.field, { width: "8%" }]}>{""}</Text>
        <Text style={[styles.field, { width: "7%" }]}>{""}</Text>
        <Text style={[styles.field, { width: "7%" }]}>{""}</Text>
        <Text style={[styles.field, { width: "8%" }]}>{""}</Text>
        <Text style={[styles.field, { width: "8%" }]}>
          {" "}
          {currency}
          {getTotalFieldAmt(dashboardData, "reservation_amount").toLocaleString(
            undefined,
            {
              maximumFractionDigits: 2,
            }
          )}
        </Text>
        <Text style={[styles.field, { width: "10%" }]}>{""}</Text>
        <Text style={[styles.field, { width: "8%" }]}>
          {" "}
          {currency}
          {getTotalFieldAmt(dashboardData, "booking_amount").toLocaleString(
            undefined,
            {
              maximumFractionDigits: 2,
            }
          )}
        </Text>
        <Text style={[styles.field, { width: "8%" }]}>
          {" "}
          {currency}
          {getTotalFieldAmt(dashboardData, "balance_due").toLocaleString(
            undefined,
            {
              maximumFractionDigits: 2,
            }
          )}
        </Text>
      </View> */}
    </View>
  );
}

export default DashboardReport;

// Creating the styles for the dashboard reports fix
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 2,
    // backgroundColor: borderColor,
    marginLeft: 5,
    marginRight: 5,
    border: "none",
  },
  containerDivider: {
    flexDirection: "row",
    flexWrap: "wrap",
    // marginTop: 24,
    borderWidth: "2px",
    borderRadius: 2,
    backgroundColor: borderColor,
    marginLeft: 5,
    marginRight: 5,
    border: "0.5px solid black",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 12,
    borderWidth: 1,
    borderRadius: 2,
    // backgroundColor: tableCell,
    marginLeft: 5,
    marginRight: 5,
    border: "none",
  },
  field: {
    display: "flex",
    position: "relative",
    paddingTop: 5,
    paddingBottom: 5,
    flexWrap: "wrap",
    paddingRight: 1,
    width: "10%",
    paddingLeft: 4,
  },
  customfield: {
    display: "flex",
    position: "relative",
    paddingTop: 5,
    paddingBottom: 5,
    flexWrap: "wrap",
    paddingRight: 1,
    width: "14%",
    paddingLeft: 4,
  },
  header: {
    position: "relative",
    width: "100vw",
    // display: "flex",
    // justifyContent: "space-between",
    fontSize: "12px",
    // marginTop: "10px",
    flexDirection: "row",
    // paddingLeft: "10px",
    // paddingRight: "10px",
  },
  totalField: {
    flexDirection: "row",
    flexWrap: "wrap",
    // marginTop: 12,
    borderWidth: 1,
    borderRadius: 2,
    // backgroundColor: tableCell,
    marginLeft: 5,
    marginRight: 5,
    border: "none",
    position: "relative",
    fontSize: "8px",
    // paddingTop: "8px",
    // paddingBottom: "8px",
  },
});
