import React from "react";

function InformationCard({
  colorCode,
  value,
  heading,
  transform,
  marginLeft,
  cardWidth,
  cardHeight,
  fontSize,
  boxShadow,
}) {
  return (
    <div
      className="informationCardWrapper"
      style={{
        boxShadow: boxShadow,
        transform: transform,
        marginLeft: marginLeft,
      }}
    >
      <div className="colorCode" style={{ backgroundColor: colorCode }} />
      <div className="figure">{value}</div>
      <div className="heading">{heading}</div>
    </div>
  );
}

export default InformationCard;
