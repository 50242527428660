import moment from "moment";
import { CountryRegionData } from "react-country-region-selector";
import Nationalities from "../../../Utils/nationalities.json";

export const dateFormat = "DD-MM-YYYY HH:mm:ss";

export const navigationParamsForCalendar = {
  roomIdsSort: true,
  hotelId: "",
  startDate: moment().format(dateFormat),
  numOfDays: 7,
  roomTypes: null,
  availableRooms: false,
  blockedRooms: false,
  dirtyRooms: false,
  cleanRooms: false,
};

export const views = {
  resourceTimelineDay: 1,
  resourceTimelineWeek: 7,
  resourceTimelineMonthCustom: 30,
};

export const getMonday = (d) => {
  var day = d.getDay(),
    diff = d.getDate() - day; // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const hourToString = {
  "1 AM": { text: "01:00:00", val: 1 },
  "2 AM": { text: "02:00:00", val: 2 },
  "3 AM": { text: "03:00:00", val: 3 },
  "4 AM": { text: "04:00:00", val: 4 },
  "5 AM": { text: "05:00:00", val: 5 },
  "6 AM": { text: "06:00:00", val: 6 },
  "7 AM": { text: "07:00:00", val: 7 },
  "8 AM": { text: "08:00:00", val: 8 },
  "9 AM": { text: "09:00:00", val: 9 },
  "10 AM": { text: "10:00:00", val: 10 },
  "11 AM": { text: "11:00:00", val: 11 },
  "12 PM": { text: "12:00:00", val: 12 },
  "1 PM": { text: "13:00:00", val: 13 },
  "2 PM": { text: "14:00:00", val: 14 },
  "3 PM": { text: "15:00:00", val: 15 },
  "4 PM": { text: "16:00:00", val: 16 },
  "5 PM": { text: "17:00:00", val: 17 },
  "6 PM": { text: "18:00:00", val: 18 },
  "7 PM": { text: "19:00:00", val: 19 },
  "8 PM": { text: "20:00:00", val: 20 },
  "9 PM": { text: "21:00:00", val: 21 },
  "10 PM": { text: "22:00:00", val: 22 },
  "11 PM": { text: "23:00:00", val: 23 },
};

export const multiOptions = {
  group: "Group booking",
  // quick:"Quick Booking",
  // inquiry:"Create Inquiry"
};

export const dateFnsFormat = "do MMM, yyyy";

export const getCountriesAndStates = () => {
  let arr = {};
  CountryRegionData.forEach((country) => {
    arr[country[0]] = country[2].split("|").map((state) => state.split("~")[0]);
  });
  return arr;
};

export const getNationalityList = () => {
  let arr = [];
  Nationalities.forEach((nationality) => {
    arr.push(nationality?.country);
  });
  return arr;
};

export const displaySlots = { 0: 0, 8: 1, 16: 2 };
export const displayTimes = { 0: 0, 1: 8, 2: 16 };

export const channelNamesMap = {
  STAYFLEXI_OM: "Stayflexi Marketplace",
  STAYFLEXI_OD: "Stayflexi Booking Engine",
  STAYFLEXI_HD: "Walk-In Booking",
  STAYFLEXI_GHA: "Google Hotel Ad",
  STAYFLEXI_MOBILE: "StayFlexi Mobile OTA",
  EXPEDIA: "EXPEDIA",
  "A-EXPEDIA": "EXPEDIA",
  ORBITZ: "ORBITZ",
  "A-ORBITZ": "ORBITZ",
  TRAVELOCITY: "TRAVELOCITY",
  "A-TRAVELOCITY": "TRAVELOCITY",
  HOTELS: "HOTELS.COM",
  "A-HOTELS.COM": "HOTELS.COM",
  "HOTELS.COM": "HOTELS.COM",
  HOTWIRE: "HOTWIRE",
  "A-HOTWIRE": "HOTWIRE",
  WOTIF: "WOTIF",
  "A-WOTIF": "WOTIF",
  CHEAPTICKETS: "CHEAPTICKETS",
  EBOOKERS: "EBOOKERS",
  "A-EBOOKERS": "EBOOKERS",
  LASTMINUTE: "LASTMINUTE",
  EGENCIA: "EGENCIA.COM",
  "A-EGENCIA": "EGENCIA.COM",
  "THOMAS COOK": "THOMAS COOK",
  "A-THOMAS COOK": "THOMAS COOK",
  AMERICAN_EXPRESS_TRAVEL: "AMERICAN_EXPRESS_TRAVEL",
  AMEX_HOTEL_COLLECTION: "AMEX_HOTEL_COLLECTION",
  AMEX_FINE_HOTELS_AND_RESORTS: "AMEX_FINE_HOTELS_AND_RESORTS",
  MRJET: "MRJET",
  MAKEMYTRIP: "MAKEMYTRIP",
  GOIBIBO: "GOIBIBO",
  "GO-MMT": "GOIBIBO/MAKEMYTRIP",
  TRAVELGURU: "TRAVELGURU",
  YATRA: "YATRA",
  BOOKING: "BOOKING.COM",
  "BOOKING.COM": "BOOKING.COM",
  PRICELINE: "PRICELINE",
  AGODA: "AGODA",
  EXPEDIA_AFFILIATE_NETWORK: "EXPEDIA_AFFILIATE_NETWORK",
  CLEARTRIP: "CLEARTRIP",
  BOOKING_HOTEL: "BOOKING_HOTEL",
  TRIPADVISOR: "TRIPADVISOR",
  TRIVAGO: "TRIVAGO",
  IMPALA: "IMPALA",
  EASEMYTRIP: "EASEMYTRIP",
  PAYTM: "PAYTM",
  AXISROOMS: "AXISROOMS",
  VIA: "VIA.COM",
  "VIA.COM": "VIA.COM",
  STAAH: "STAAH",
  GOOMO: "GOOMO",
  TRAVELOKA: "TRAVELOKA",
  CTRIP: "CTRIP",
  AIRBNB: "AIRBNB",
  TIKET: "TIKET",
  HOSTEL_WORLD: "HOSTEL_WORLD",
  SIMPLOTEL: "SIMPLOTEL",
  HOTELBEDS: "HOTELBEDS",
  HAPPYEASYGO: "HAPPYEASYGO",
  GETAROOM: "GETAROOM",
  OSTROVOK: "OSTROVOK",
  CHANNEX: "CHANNEX",
};

export const channelLogoMap = {
  STAYFLEXI_OM:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/sfstickers.png",
  STAYFLEXI_OD:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/sfstickers.png",
  STAYFLEXI_HD:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/walkin.png",
  "WALK-IN":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/walkin.png",
  "Walk-In":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/walkin.png",
  STAYFLEXI_GHA:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/sfstickers.png",
  STAYFLEXI_BE:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/sfstickers.png",
  STAYFLEXI_MOBILE:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/sfstickers.png",
  "Stayflexi Mobile":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/sfstickers.png",
  EXPEDIA:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/expedia.png",
  "A-EXPEDIA":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/expedia.png",
  ORBITZ:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/orbitz.png",
  "A-ORBITZ":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/orbitz.png",
  TRAVELOCITY:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/travelocity.png",
  "A-TRAVELOCITY":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/travelocity.png",
  HOTELS:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/hotelscom.png",
  "A-HOTELS.COM":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/hotelscom.png",
  "HOTELS.COM":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/hotelscom.png",
  HOTWIRE:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/hotwire.png",
  "A-HOTWIRE":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/hotwire.png",
  WOTIF:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/wotif.png",
  "A-WOTIF":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/wotif.png",
  CHEAPTICKETS:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/cheaptickets.png",
  "CHEAPTICKETS.COM":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/cheaptickets.png",
  "A-CHEAPTICKETS":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/cheaptickets.png",
  EBOOKERS:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/ebookers.png",
  "A-EBOOKERS":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/ebookers.png",
  LASTMINUTE: "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/",
  EGENCIA:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/egencia.png",
  "A-EGENCIA":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/egencia.png",
  "THOMAS COOK":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/thomascook.png",
  "A-THOMAS COOK":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/thomascook.png",
  MAKEMYTRIP:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/go-mmt.png",
  GOIBIBO:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/goibibo.png",
  "GO-MMT":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/go-mmt.png",
  GOMMT:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/go-mmt.png",
  "Go-MMT":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/go-mmt.png",
  YATRA:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/yatra.png",
  BOOKING:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/booking.png",
  "BOOKING.COM":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/booking.png",
  "BOOKING COM":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/booking.png",
  PRICELINE:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/priceline.png",
  "PRICELINE.COM":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/priceline.png",
  AGODA:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/agoda.png",
  CLEARTRIP:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/cleartrip.png",
  EASEMYTRIP:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/easemytrip.png",
  PAYTM:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/paytm.png",
  VIA: "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/via.png",
  via: "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/via.png",
  "VIA.COM":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/via.png",
  GOOMO:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/goomo.png",
  goomo:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/goomo.png",
  TRAVELOKA:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/traveloka.png",
  traveloka:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/traveloka.png",
  CTRIP:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/ctrip.png",
  ctrip:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/ctrip.png",
  AIRBNB:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/airbnb.png",
  TIKET:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/tiket.png",
  "TIKET.COM":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/tiket.png",
  "EXPEDIA AFFILIATE NETWORK":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/expediagroup.png",
  "A-EXPEDIA AFFILIATE NETWORK":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/expediagroup.png",
  "AMERICAN EXPRESS TRAVEL":
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/amex.png",
  AMEX_HOTEL_COLLECTION:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/",
  AMEX_FINE_HOTELS_AND_RESORTS:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/",
  MRJET: "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/",
  TRAVELGURU:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/travelguru.png",
  BOOKING_HOTEL: "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/",
  TRIPADVISOR:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/tripadvisor.png",
  TRIVAGO:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/trivago.png",
  IMPALA:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/impala.png",
  AXISROOMS:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/axisrooms.png",
  STAAH:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/staah.png",
  HOSTEL_WORLD:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/hostelworld.png",
  SIMPLOTEL:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/simplotel.png",
  HOTELBEDS:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/hotelbeds.png",
  HAPPYEASYGO:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/happyeasygo.png",
  GETAROOM:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/getaroom.png",
  getaroom:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/getaroom.png",
  OSTROVOK:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/ostrovok.png",
  CHANNEX:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/channex.png",
  jasperrooms:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/jasperfavicon.png",
  BOOKINGDOTCOM:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/booking.png",
  hotelsbyweek:
    "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/favicon/hbw-fev-con.png",
};

let firstPath = "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/";
export const channelLogoMapDash = {
  jasperrooms: firstPath + "jasperlogo.png",
  HOSTEL_WORLD: firstPath + "hostelworld.png",
  //----Added By Himanshu----
  hotelsbyweek: firstPath + "hbwlogo.png",
  HOSTELWORLD: firstPath + "hostelworld.png",
  TRIPADVISOR: firstPath + "tripadvisor.png",
  TRIVAGO: firstPath + "trivago.png",
  HOTELBEDS: firstPath + "hotelbeds.png",
  HAPPYEASYGO: firstPath + "happyeasygo.png",
  TRAVELGURU: firstPath + "travelguru.png",
  GETAROOM: firstPath + "getaroom.jpeg",
  getaroom: firstPath + "getaroom.jpeg",
  BOOKING: firstPath + "bookingcom.png",
  "BOOKING.COM": firstPath + "bookingcom.png",
  "BOOKING.com": firstPath + "bookingcom.png",
  "GO-MMT": firstPath + "go-mmt.jpg",
  MAKEMYTRIP: firstPath + "makemytrip.png",
  GOIBIBO: firstPath + "goibibo.png",
  GOOMO: firstPath + "goomo.png",
  YATRA: firstPath + "yatra.png",
  CLEARTRIP: firstPath + "cleartrip.jpeg",
  AGODA: firstPath + "agoda.png",
  TRAVELOKA: firstPath + "traveloka.png",
  EASEMYTRIP: firstPath + "easemytrip.jpg",
  CTRIP: firstPath + "ctrip.jpg",
  PAYTM: firstPath + "paytm.jpeg",
  VIA: firstPath + "via_logo.jpg",
  "VIA.COM": firstPath + "via_logo.jpg",
  TIKET: firstPath + "tiket.png",
  "TIKET.COM": firstPath + "tiket.png",
  AIRBNB: firstPath + "airbnb.png",
  PRICELINE: firstPath + "priceline.png",
  "PRICELINE.COM": firstPath + "priceline.png",
  TRAVELOCITY: firstPath + "travelocity-logo-vector.png",
  "A-TRAVELOCITY": firstPath + "travelocity-logo-vector.png",
  ORBITZ: firstPath + "orbitz.png",
  "A-ORBITZ": firstPath + "orbitz.png",
  EXPEDIA: firstPath + "expedia.png",
  "A-EXPEDIA": firstPath + "expedia.png",
  "EXPEDIA AFFILIATE NETWORK": firstPath + "expedia_affiliate.png",
  "A-EXPEDIA AFFILIATE NETWORK": firstPath + "expedia_affiliate.png",
  EBOOKERS: firstPath + "ebookers-com-vector-logo.png",
  "A-EBOOKERS": firstPath + "ebookers-com-vector-logo.png",
  WOTIF: firstPath + "logo-wotif.png",
  "WOTIF.COM": firstPath + "logo-wotif.png",
  "A-WOTIF": firstPath + "logo-wotif.png",
  HOTWIRE: firstPath + "hotwire.png",
  "HOTWIRE.COM": firstPath + "hotwire.png",
  "A-HOTWIRE": firstPath + "hotwire.png",
  EGENCIA: firstPath + "logo-egencia-brand.png",
  "A-EGENCIA": firstPath + "logo-egencia-brand.png",
  "THOMAS COOK": firstPath + "logo-thomas-cook.png",
  "A-THOMAS COOK": firstPath + "logo-thomas-cook.png",
  "HOTELS.COM": firstPath + "Hotels.com_logo.png",
  "A-HOTELS.COM": firstPath + "Hotels.com_logo.png",
  HOTELS: firstPath + "Hotels.com_logo.png",
  CHEAPTICKETS: firstPath + "cheaptickets-01.jpg",
  "CHEAPTICKETS.COM": firstPath + "cheaptickets-01.jpg",
  "A-CHEAPTICKETS": firstPath + "cheaptickets-01.jpg",
  STAYFLEXI_HD: firstPath + "favicon/walkin.png",
  "WALK-IN": firstPath + "favicon/walkin.png",
  "Walk-In": firstPath + "favicon/walkin.png",
  STAYFLEXI_OD: firstPath + "sflogos.png",
  STAYFLEXI_OM: firstPath + "sflogos.png",
  "STAYFLEXI MOBILE": firstPath + "sflogos.png",
  STAYFLEXI_GHA: firstPath + "sflogos.png",
  STAYFLEXI_BE: firstPath + "sflogos.png",
  STAYFLEXI_MOBILE: firstPath + "sflogos.png",
};

export const segmentMap = [
  "FIT",
  "Loyalty",
  "Wholesale",
  "Charters",
  "Packages",
  "Groups",
  "Others",
];

export const majorSegmentMap = [
  "OTA",
  "Metasearch",
  "Corporate",
  "Airline",
  "In-House Sales",
  "Tour Operator",
  "Others",
];

export const metasearchArray = [
  "GOOGLE",
  "TRIPADVISOR",
  "TRIVAGO",
  "KAYAK",
  "SKAYSCANNER",
  "MOMONDO",
];

export const otaSubsourceMap = [
  // "OTA",
  "Agoda",
  "AIRBNB",
  "Akbartravels.com",
  "American Express Global Business Travel",
  "American Express Global Business Travel",
  "American Express Travel",
  "Amex Fine Hotels & Resort",
  "Amex The Hotel Collection",
  "Abritel",
  "ABSBooking",
  "ApartmentsOnline",
  "beachhuts",
  "Bedandbreakfast",
  "Belhotel",
  "BookaBach",
  "Bookeasy",
  "Booking247",
  "Bookit",
  "bravofly",
  "Britains Finest",
  "BungalowSpecials",
  "Booking",
  "CheapTickets",
  "Cleartrip",
  "Ctrip",
  "Camping Vision",
  "Darmawisata Indonesia",
  "Despegar",
  "Decolar",
  "Dorms",
  "Easemytrip",
  "ebookers",
  "Egencia",
  "Eviivo",
  "Expedia",
  "eDreams.com",
  "EdreamsOdigeo",
  "explore",
  "Eztravel",
  "FerienparkSpecials",
  "goibibo",
  "Goibibo",
  "Goomo",
  "Getout Travel",
  "Go Voyages",
  "Halalbooking",
  "HolidayParkSpecials",
  "Hoo",
  "Hoojoozat",
  "Hooroo",
  "Hostelhunter",
  "Hostelsclub",
  "Hotelcard",
  "Hoteldekho",
  "Hotelrip",
  "Hotelspecials",
  "HotelTonight",
  "Hotusa",
  "HRS",
  "Homeaway",
  "Happyeasygo",
  "Homeaway",
  "Hostelsclub",
  "HosteWorld",
  "Hotelbeds",
  "Hotels",
  "Hotelscombined",
  "Hotwire",
  "iescape",
  "Inhores",
  "IRCTC",
  "jetcost",
  "Jetstar",
  "Lastminute",
  "Laterooms.com",
  "Luxenomad",
  "Makemytrip",
  "MrandMrsSmith",
  "MrJet",
  "Neckermann",
  "need it now",
  "not1night",
  "Orbitz",
  "Ostrovok",
  "Odigeo",
  "Paytm",
  "Pegipegi",
  "Pillow",
  "Priceline",
  "Qunar",
  "RatestoGo",
  "Rakuten Travel",
  "Skyscanner",
  "Spies",
  "Sawadee",
  "StayingAt",
  "Stayz",
  "TabletHotels",
  "ThomasCook",
  "Tiket",
  "Tjareborg",
  "Travelbook",
  "TravelGuru",
  "Travelocity",
  "Traveloka",
  "Trip",
  "Trip Factory",
  "TravelStay",
  "Venere",
  "Via",
  "Ving",
  "VRBO",
  "weg",
  "Wotif",
  "Yatra",
  "Yuktravel",
  "Zenhotels",
];

export const subSegmentMap = {
  FIT: ["BAR", "Discount", "Long Stay"],
  Loyalty: ["Priority Club"],
  Wholesale: ["Wholesale"],
  Charters: ["Charters"],
  Packages: ["Packages"],
  Groups: ["Business Group", "Social Group", "Leisure Group"],
  Others: ["Complimentary", "House Use"],
};

export const getSlotType = (slotname) => {
  if (slotname === "NOON")
    // return [
    //   { month: "long", year: "numeric" }, // top level of text
    //   { weekday: "short" },
    // ];
    return { day: 1, hour: 0 };
  else return { day: 0, hour: 8 }; // lower level of text
};

export const customErrorMessage =
  "Something wrong happened." + " Try again later.";

export const getIshaPurposeOfVisit = (hotelId) => {
  if (hotelId == "24740") {
    return ishaUSPurposeOfVisit;
  }
  return ishaPurposeOfVisit;
};

export const ishaUSPurposeOfVisit = [
  "-",
  "Day visit",
  "Overnight visit",
  "Program additional days stay",
  "Program lodging upgrade",
  "Special event",
  "Yantra ceremony",
  "Vivaha",
  "Studio owner",
  "Quarantine",
  "Others",
];

export const checkIshaAccess = (hotel_email) => {
  const ishaHotelEmails = [
    "swami.ibhana@ishafoundation.org",
    "cottage.admin@ishafoundation.org",
    "ishapreprod@example.com",
  ];
  return ishaHotelEmails?.includes(hotel_email);
};

export const checkIshaUSAccess = (hotel_email) => {
  const ishaUSHotelEmails = ["alex.m@ishausa.org"];
  return ishaUSHotelEmails?.includes(hotel_email);
};
export const ishaPurposeOfVisit = [
  "-",
  "Program Participation",
  "Isha Health Solutions",
  "Isha Home School",
  "Isha Samskriti",
  "Volunteering",
  "Rituals",
  "Wedding",
  "Dhyanalinga",
  "Adiyogi",
  "Lingabhairavi",
  "Others",
];

export const getIshaProgramsAttended = () => {
  return ishaProgramsAttended;
};

export const ishaProgramsAttended = [
  "-",
  "No",
  "HathaYoga",
  "Isha Kriya",
  "Inner Engineering",
  "Advanced Programs",
];

export const getIshaRelationWithPrimary = () => {
  return ishaRelationWithPrimary;
};

export const ishaRelationWithPrimary = [
  "-",
  "SELF",
  "Father",
  "Mother",
  "Wife",
  "Husband",
  "Sister",
  "Brother",
  "Son",
  "Daughter",
  "Other",
];

export const getApprovedByOverseas = () => {
  return approvedByOverseas;
};

export const approvedByOverseas = [
  "New",
  "Pending",
  "Approved",
  "Partially Approved",
];

export function isDateInDesiredBannerRange(date) {
  // Extract the day of the week (Wednesday = 3, Thursday = 4) and time
  const day = date.getDay(); // Get the day of the week (Sunday = 0, Monday = 1, ..., Saturday = 6)
  const hour = date.getHours(); // Get the hour of the day (0-23)

  // Check if the day falls between Wednesday (1) and Thursday (4)
  if (day >= 3 && day <= 4) {
    // Check if the time is between 4 pm (16) on Wednesday and 5 pm (17) on Thursday
    if (
      (day === 3 && hour >= 16) ||
      (day === 4 && hour < 17) ||
      (day > 3 && day < 4)
    ) {
      return true;
    }
  }

  return false;
}

export const genderList = ["Male", "Female", "Non-Binary"];

export const ishaGenderList = ["Male", "Female", "Others"];

export const getGenderList = () => {
  return genderList;
};

export const getIshaGenderList = (hotelId) => {
  if (hotelId == "24740") {
    return genderList;
  }
  return ishaGenderList;
};

export const magicLinkBreakdownMap = {
  SELF_CHECKIN: "Self check-in",
  EARLY_CHECKIN: "Early check-in",
  LATE_CHECKOUT: "Late check-out",
  POS: "POS",
  SELF_UPGRADE: "Room upgrade",
};

export const maxCompressMBSize = 0.25;

export const validateEmail = (mail) => {
  if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(mail)) {
    return true;
  }
  return false;
  // return mail.match(
  //   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // );
};

export const validatePhoneNumber = (contact) => {
  const phoneNumberRegex = /^[\d+]+$/;
  if (phoneNumberRegex.test(contact)) {
    return true;
  }
  return false;
};
