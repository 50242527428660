import React, { useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import countryList from "react-select-country-list";
import MenuItem from "@mui/material/MenuItem";
import {
  default as ImageUploading,
  default as ReactImageUploading,
} from "react-images-uploading";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api/index";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "material-react-toastify";
import "react-responsive-modal/styles.css";
import {
  CircularProgress,
  Button,
  Divider,
  Grid,
  CardContent,
  Autocomplete,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  getUserId,
  setBookingUserIdImages,
} from "../../BookingDashboard/apis/BrandingModalApi";
import { format } from "date-fns";
import { shallowEqual, useSelector } from "react-redux";
import { Card, Modal } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  addNewCustomerNote,
  deleteTheCustomerNotes,
  getAllCustomerNotes,
  upadateNotes,
  addNewDoNotRentStatus,
  removeNewDoNotRentStatus,
} from "../../CalendarPage/api/BookingAndCustomerNotesApi";
import { Stack, IconButton } from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { updateCustomer } from "../../CalendarPage/api/ReservationApi";
import Webcam from "react-webcam";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadIcon from "@mui/icons-material/Upload";
import {
  getAllCustomers,
  imageUpdateByEmail,
} from "../../FlexibleSlot/api/FlexibleSlotApi";
import { getGeoCodeDetails } from "../../CalendarPage/api/ReservationApi";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  checkIshaAccess,
  checkIshaUSAccess,
  dateFnsFormat,
  getApprovedByOverseas,
  getGenderList,
  getIshaGenderList,
  getIshaProgramsAttended,
  getIshaPurposeOfVisit,
  getIshaRelationWithPrimary,
  getNationalityList,
  maxCompressMBSize,
  validateEmail,
  validatePhoneNumber,
} from "../../CalendarPage/constants/Calendar-constants";
import { getCountriesAndStates } from "../../CalendarPage/constants/Calendar-constants";
import moment from "moment";
import imageCompression from "browser-image-compression";

function CustomerInformation({
  onCancelClick,
  bookingId,
  updateData,
  data,
  reservationId,
  doNotRent,
  getDoNotRentStatus,
  accessControl,
  hotelType,
}) {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const [subUser, setsubUser] = useState(hotel?.accessControl?.ADMINRIGHTS);
  const ishaProgramsAttended = getIshaProgramsAttended();
  const ishaRelationWithPrimary = getIshaRelationWithPrimary();
  const ishaApprovedByOverseas = getApprovedByOverseas();
  const nationalities = getNationalityList();
  const genderList = getGenderList();
  const [roomCount, setRoomCount] = useState();
  const [resIdList, setResIdList] = useState();
  /* Getting the customer information fields */
  // Customer information details all states
  const [custName, setCustName] = useState();
  const [custEmail, setCustEmail] = useState();
  const [custContact, setCustContact] = useState("");
  const [custGender, setCustGender] = useState("");
  const [custDOB, setCustDOB] = useState(null);
  const [custAddressLine1, setCustAddressLine1] = useState();
  const [custAddressLine2, setCustAddressLine2] = useState();
  const [custAddressCity, setCustAddressCity] = useState();
  const [custState, setCustState] = useState();
  const [custCountry, setCustCountry] = useState();
  const [custAddressZip, setCustAddressZip] = useState();
  const [custNationality, setCustNationality] = useState("");
  const [custOccupation, setCustOccupation] = useState("");
  const [custCategory, setCustCategory] = useState("ADULT");

  //Additional ISHA Guest config details
  const [custEmergency, setCustEmergency] = useState("");
  const [custEmergencyName, setCustEmergencyName] = useState("");
  const [custCompany, setCustCompany] = useState("");
  const [custPurpose, setCustPurpose] = useState("");
  const [custRelation, setCustRelation] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [custETA, setCustETA] = useState(currentDate);
  const [custETD, setCustETD] = useState(currentDate);
  const [custOCO, setCustOCO] = useState("No");
  const [custApprovedOverseas, setCustApprovedOverseas] = useState("New");

  const [selectedPrograms, setSelectedPrograms] = useState(["No"]);
  const handleProgramChange = (event) => {
    const {
      target: { value },
    } = event;
    let attendedPrograms = typeof value === "string" ? value.split(",") : value;
    setSelectedPrograms(attendedPrograms);
  };
  const [govIdType, setGovIdType] = useState("");
  const [govIdNum, setGovIdNum] = useState();

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const [oldEmail, setoldEmail] = useState("");

  const ishaPurposeOfVisit = getIshaPurposeOfVisit(hotelId);
  const ishaGenderList = getIshaGenderList(hotelId);
  //images

  const [images, setImages] = useState([]);

  const [faviconImages, setFaviconImages] = useState([]);
  // const [govIdImg, setGovIdImg] = useState()

  useEffect(async () => {
    setCustName(data?.customer_name);
    setCustContact(data?.customer_phone);
    setRoomCount(parseInt(data?.room_ids_list.length));
    setCustEmail(data?.customer_email);
    if (data?.customer_gender === "NA") {
      setCustGender("");
    } else {
      setCustGender(data?.customer_gender);
    }
    if (data?.customer_dob === "" || data?.customer_dob === "NA") {
      setCustDOB("");
    } else {
      setCustDOB(new Date(data?.customer_dob));
    }
    setCustAddressLine1(data?.customer_address?.customer_address_line1);
    setCustAddressLine2(data?.customer_address?.customer_address_line2);
    setCustAddressCity(data?.customer_address?.customer_address_city);
    setCustState(data?.customer_address?.customer_address_state);
    setCustCountry(data?.customer_address?.customer_address_country);
    setCustAddressZip(data?.customer_address?.customer_address_zip);
    setCustNationality(data?.customer_nationality);
    setCustOccupation(data?.customer_occupation);
    setCustEmergency(data?.customer_emergency_contact);
    setCustEmergencyName(data?.customer_emergency_contact_name);
    setGovIdType(data?.govt_id_type !== "NA" ? data?.govt_id_type : "");
    setGovIdNum(data?.govt_id_value);
    setoldEmail(data?.customer_email);
    setCustCategory(data?.customer_category);
    //Isha guest config details
    if (
      JSON.parse(hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails)
        .read === "YES" &&
      (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
    ) {
      setCustCompany(data?.customer_company_name);
      setCustPurpose(data?.customer_purpose_of_visit);
      setCustRelation(data?.customer_relationship);
      let val = data?.customer_programs_attended;
      let attendedPrograms = typeof val === "string" ? val.split(",") : val;
      setSelectedPrograms(attendedPrograms);
      if (data?.customer_eta !== "") {
        setCustETA(
          new Date(
            moment(data?.customer_eta, "DD-MM-YYYY HH:mm:ss", false).format(
              "YYYY-MM-DDTHH:mm:ssZ"
            )
          )
        );
      }
      if (data?.customer_etd !== "") {
        setCustETD(
          new Date(
            moment(data?.customer_etd, "DD-MM-YYYY HH:mm:ss", false).format(
              "YYYY-MM-DDTHH:mm:ssZ"
            )
          )
        );
      }
      setCustOCO(
        data?.customer_oco_checkin !== "" ? data?.customer_oco_checkin : "No"
      );
      setCustApprovedOverseas(
        data?.customer_approved_overseas !== ""
          ? data?.customer_approved_overseas
          : "New"
      );
    }

    const datasa = await getUserId(data?.customer_email);
    setimageUpload(
      datasa?.frontUrl === "" && datasa?.backUrl === "" ? "Camera" : "Device"
    );
    setPassportImageUpload(
      datasa?.passportImageUrl === "" ? "Camera" : "Device"
    );
    datasa?.frontUrl !== "" && setImages([{ data_url: datasa?.frontUrl }]);
    datasa?.backUrl !== "" && setFaviconImages([{ data_url: datasa?.backUrl }]);
    datasa?.passportImageUrl !== "" &&
      setPassportImages([{ data_url: datasa?.passportImageUrl }]);
  }, [data]);

  const createDoNotRentStatus = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      emailId: custEmail,
      severity: Severity,
    };
    const data = await addNewDoNotRentStatus(payload);
    setloading(false);
    if (data.status === 200) {
      toast.success("Successfully added Do-Not rent status");
    } else {
      toast.error("Something went wrong !");
    }
    setChecked(false);
    await getDoNotRentStatus(custEmail);
    handleDClose();
  };

  const removeDoNotRentStatus = async () => {
    setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      emailId: custEmail,
      status: "NO",
      severity: Severity,
    };
    const data = await removeNewDoNotRentStatus(payload);
    setloading(false);
    if (data.status === 200) {
      toast.success("Successfully removed Do-Not rent status");
    } else {
      toast.error("Something went wrong !");
    }
    setChecked(false);
    await getDoNotRentStatus(custEmail);
    handleRClose();
  };

  //Add Do Not rent
  const [dshow, setDShow] = useState(false);

  const handleDClose = () => setDShow(false);
  const handleDShow = () => setDShow(true);

  //Add Do Not rent
  const [rshow, setRShow] = useState(false);

  const handleRClose = () => setRShow(false);
  const handleRShow = () => setRShow(true);

  /* Predefining Government Id types */
  // Goverment IDs --- US properties
  const govIdUS = ["Passport", "Drivers License", "Others"];

  // Government IDs --- Indian properties
  const govIdIndia = ["Passport", "Aadhar", "Drivers License", "Others"];

  // getting the list of the countries
  //const countries = useMemo(() => countryList().getData(), []);
  const countries = getCountriesAndStates();

  // loader while editing the customer information
  const [editCustLoader, setEditCustLoader] = useState(false);

  //Passport image - isha requirement
  const [passportImageUpload, setPassportImageUpload] = useState("Device");
  const [showPassportModal, setShowPassportModal] = useState(false);
  const handleClosePassportModal = () => setShowPassportModal(false);
  const handleOpenPassportModal = () => setShowPassportModal(true);
  const [passportImage, setPassportImage] = useState(undefined);
  const passportCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPassportImage(imageSrc);
  }, [webcamRef]);
  const [passportImages, setPassportImages] = useState([]);
  //Need to check this
  const [showFrontImagePopup, setshowFrontImagePopup] = useState(false);
  const [showPopUpImages, setshowPopUpImages] = useState("");

  const handleshowFrontImagePopupClose = () => setshowFrontImagePopup(false);
  const handleFrontImagePopupShow = (data) => {
    setshowPopUpImages(data);
    setshowFrontImagePopup(true);
  };

  //get geoCode details
  const getGeoCodeValues = (zipcode) => {
    try {
      getGeoCodeDetails(zipcode).then((resp) => {
        const re = /^[0-9\b]+$/;
        //Cases where the city is not mentioned in gecoding api
        if (!re.test(resp.city)) {
          setCustAddressCity(resp.city);
        } else {
          setCustAddressCity("");
        }
        setCustState(resp.state);
        setCustCountry(resp.country);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  // update the customer details
  const updateCustomerDetails = async () => {
    if (custName === "") {
      toast.error("Please enter name !");
    } else {
      setEditCustLoader(true);
      let tempFrontDataImage =
        images[0] !== undefined ? images[0]?.file : undefined;
      let tempBackDataImage =
        faviconImages[0] !== undefined ? faviconImages[0]?.file : undefined;
      let tempPassportImage =
        passportImages[0] !== undefined ? passportImages[0]?.file : undefined;
      const options = {
        maxSizeMB: maxCompressMBSize,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      let compressedFrontData = null;
      let compressedBackData = null;
      let compressedPassportData = null;
      if (tempFrontDataImage !== undefined) {
        const compressedFile = await imageCompression(
          tempFrontDataImage,
          options
        );
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;
            compressedFrontData = result;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (tempBackDataImage !== undefined) {
        const compressedFile = await imageCompression(
          tempBackDataImage,
          options
        );
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;
            compressedBackData = result;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (tempPassportImage !== undefined) {
        const compressedFile = await imageCompression(
          tempPassportImage,
          options
        );
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;
            compressedPassportData = result;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const userIdPayload = {
        emailId: custEmail,
        frontData:
          image !== undefined
            ? image.split(",")[1]
            : compressedFrontData !== null
            ? compressedFrontData?.split(",")[1]
            : null,
        frontType:
          image !== undefined
            ? "jpeg"
            : images[0]
            ? images[0].file?.type
            : null,
        backData:
          backImage !== undefined
            ? backImage.split(",")[1]
            : compressedBackData !== null
            ? compressedBackData?.split(",")[1]
            : null,
        backType:
          backImage !== undefined
            ? "jpeg"
            : faviconImages[0]
            ? faviconImages[0]?.file?.type
            : null,
        passportImageData:
          passportImage != undefined
            ? passportImage.split(",")[1]
            : compressedPassportData !== null
            ? compressedPassportData?.split(",")[1]
            : null,
        passportImageType:
          passportImage != undefined
            ? "jpeg"
            : passportImages[0]
            ? passportImages[0]?.file?.type
            : null,
        hotelId: hotelId,
      };

      if (oldEmail !== custEmail) {
        await imageUpdateByEmail(oldEmail, custEmail);
      }
      if (
        userIdPayload?.frontData !== undefined ||
        userIdPayload?.backData !== undefined ||
        userIdPayload?.passportImageData !== undefined
      ) {
        if (custName === "") {
          toast.error("Please enter name !");
        } else {
          if (
            userIdPayload.frontData !== null ||
            userIdPayload.backData !== null ||
            userIdPayload.passportImageData !== null
          ) {
            await setBookingUserIdImages(userIdPayload);
          }
        }
      }
      let tempGuestDOB = "";
      if (custDOB != "" && custDOB != "NA") {
        tempGuestDOB = format(custDOB, "yyyy-MM-dd");
      }
      if (
        JSON.parse(
          hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
        ).read === "YES" &&
        (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
      ) {
        handleSpringDataMutationRequest(
          "PUT",
          `user/updateCustomerDetails/?resId=${bookingId}`,
          {
            userName: custName,
            address: custAddressLine1,
            address1: custAddressLine2,
            city: custAddressCity,
            state: custState,
            country: custCountry,
            phoneNumber: custContact,
            zipCode: custAddressZip,
            email: custEmail,
            govtIdType: govIdType,
            govtIdValue: govIdNum,
            dateOfBirth: tempGuestDOB,
            gender: custGender,
            nationality: custNationality,
            occupation: custOccupation,
            purpose_of_visit: custPurpose,
            company_name: custCompany,
            programs_attended: selectedPrograms?.toString(),
            relationship_with_primary: custRelation,
            emergency_contact: custEmergency,
            emergency_contact_name: custEmergencyName,
            customer_eta: moment(custETA).format("DD-MM-YYYY HH:mm:ss"),
            customer_etd: moment(custETD).format("DD-MM-YYYY HH:mm:ss"),
            customer_oco_checkin: custOCO,
            customer_approved_overseas: custApprovedOverseas,
            guest_category: custCategory,
          }
        )
          .then((res) => console.log())
          .catch((err) => console.log(err));
        const ishaPayload = {
          userEmail: custEmail,
          userPhone: custContact,
          purposeOfVisit: custPurpose,
          programsAttended: selectedPrograms?.toString(),
          relationshipWithPrimary: custRelation,
          userCompany: custCompany,
          customerETA: moment(custETA).format("DD-MM-YYYY HH:mm:ss"),
          customerETD: moment(custETD).format("DD-MM-YYYY HH:mm:ss"),
          ocoCheckinComplete: custOCO,
          approvedByOverseas: custApprovedOverseas,
        };
        handleSpringDataMutationRequest(
          "POST",
          `core/api/v1/setIshaGuestConfig`,
          ishaPayload
        )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      } else {
        handleSpringDataMutationRequest(
          "PUT",
          `user/updateCustomerDetails/?resId=${bookingId}`,
          {
            userName: custName,
            address: custAddressLine1,
            address1: custAddressLine2,
            city: custAddressCity,
            state: custState,
            country: custCountry,
            phoneNumber: custContact,
            zipCode: custAddressZip,
            email: custEmail,
            govtIdType: govIdType,
            govtIdValue: govIdNum,
            dateOfBirth: tempGuestDOB,
            gender: custGender,
            nationality: custNationality,
            occupation: custOccupation,
            guest_category: custCategory,
          }
        )
          .then((res) => console.log())
          .catch((err) => console.log(err));
      }
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/editCustomerDetailsInBooking/",
        {
          booking_id: bookingId,
          custName: custName,
          custContact: custContact,
          room_count: roomCount,
          // res_id_list: resIdList,
          custEmail: custEmail,
          custAddressLine1: custAddressLine1,
          custAddressLine2: custAddressLine2,
          custAddressCity: custAddressCity,
          state: custState,
          country: custCountry,
          custAddressZip: custAddressZip,
          govt_id_type: govIdType,
          govt_id_number: govIdNum,
        }
      )
        .then((response) =>
          toast.success("Customer details updated successfully!")
        )
        .then((response) => setEditCustLoader(false))
        .then((response) => updateData());
      // .catch((err) => toast.error("Sorry! Failed to update the details."))
    }
  };

  //Scan User id
  const [ScannerData, setScannerData] = useState();
  const [loading, setloading] = useState(false);
  const getScannerUrl = () => {
    if (`${process.env.REACT_APP_ENV}` === "BETA") {
      return "https://text.motelpro.com/ScanDocDemo?images=front+back";
    } else if (`${process.env.REACT_APP_ENV}` === "PROD") {
      return "http://127.0.0.1:55080/scandoc?images=front+back";
    } else {
      return "http://127.0.0.1:55080/scandoc?images=front+back";
    }
  };

  const [showUserIdImages, setshowUserIdImages] = useState("0");
  const [Scandata, setScandata] = useState();
  const ScannedData = async () => {
    const URL = getScannerUrl();
    setloading(true);
    const ApiData = await fetch(URL)
      .then((data) => {
        return data.json();
      })
      .catch((err) => {
        throw new Error(err);
      });
    setloading(false);
    setScannerData(ApiData?.apiresponse);
    setImageFront(ApiData?.apiresponse?.imgfront);
    setImageBack(ApiData?.apiresponse?.imgback);
    setshowUserIdImages("1");

    if (ApiData?.apiresponse?.errormsg?.cd === 0) {
      setCustName(ApiData?.apiresponse?.docinfo?.fullname);
      setCustState(ApiData?.apiresponse?.docinfo?.state);
      setCustAddressZip(ApiData?.apiresponse?.docinfo?.zipcode);
      setCustAddressCity(ApiData?.apiresponse?.docinfo?.city);
      setCustAddressLine1(ApiData?.apiresponse?.docinfo?.addr1);

      let customer_name = "";
      if (ApiData?.apiresponse?.docinfo?.fullname) {
        customer_name = ApiData?.apiresponse?.docinfo?.fullname;
      } else {
        customer_name =
          ApiData?.apiresponse?.docinfo?.fname +
          ApiData?.apiresponse?.docinfo?.lname;
      }
      setCustName(customer_name);
      let dummy_email =
        customer_name.replace(/\s+/g, ".").toLowerCase() +
        hotelId +
        Math.round(Date.now() / 1000) +
        "@example.com";
      setCustEmail(dummy_email);
      const userIdPayload = {
        emailId: dummy_email,
        frontData: ApiData?.apiresponse?.imgfront,
        frontType: "png",
        backData: ApiData?.apiresponse?.imgback,
        backType: "png",
        hotelId: hotelId,
      };
      await setBookingUserIdImages(userIdPayload);
      await getUserId(custEmail);
    } else {
      toast.error(ApiData?.apiresponse?.errormsg?.txt);
    }
  };
  const [ImagesData, setImagesData] = useState();
  const [imageFront, setImageFront] = useState();
  const [imageBack, setImageBack] = useState();

  // useEffect(async () => {
  //   await getAllData();
  // }, []);

  // const getAllData = async () => {
  //   try {
  //     console.log(data?.customer_email);
  //     const data = await getUserId(data?.customer_email);
  //     setImagesData(data);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const [deleteId, setdeleteId] = useState("");

  //Customer Notes
  const [customerNotes, setcustomerNotes] = useState([]);
  const [loader, setloader] = useState(false);
  const [customerNoteData, setcustomerNoteData] = useState("");

  useEffect(async () => {
    await getAllCustomerData();
  }, []);

  const getAllCustomerData = async () => {
    try {
      const data2 = await getAllCustomerNotes(data?.customer_email);
      setcustomerNotes(data2);
    } catch (e) {
      console.error(e);
    }
  };

  const createCustomerNote = async () => {
    // setloading(true);
    const payload = {
      hotelId: hotel.hotelId,
      cust_email: custEmail,
      note: customerNoteData,
      do_not_rent: checked,
      bookingId: bookingId,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewCustomerNote(payload);

    // setloading(false);
    // if (data.message === "Customer note recorded") {
    //   toast.success("Successfully created the customer note");
    // } else {
    //   toast.error("Something went wrong !");
    // }
    setChecked(false);
    await getAllCustomerData();
    handleClose();
  };

  const deleteCustomerNote = async (id, notes) => {
    const data = await deleteTheCustomerNotes(
      custEmail,
      id,
      bookingId,
      localStorage.getItem("email"),
      notes
    );
    if (data.message === "Customer note deleted") {
      toast.success("Successfully deleted the customer note");
    } else {
      toast.error("Something went wrong !");
    }
    getAllCustomerData();
  };

  //Add delete Note Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClose = () => setShowDeleteModal(false);
  const handleDeleteShow = (id) => {
    setdeleteId(id);
    setShowDeleteModal(true);
  };

  const deleteNoteInModal = async (id) => {
    handleDeleteShow(id);
  };

  const deletCustomerNotes = async () => {
    const data = await deleteTheCustomerNotes(
      custEmail,
      deleteId,
      localStorage.getItem("email")
    );
    if (data.message === "Customer note deleted") {
      toast.success("Successfully deleted the customer note");
    } else {
      toast.error("Something went wrong !");
    }
    getAllCustomerData();
    handleDeleteClose();
  };

  //update Customer Note Modal
  const [updateNotesModal, setupdateNotesShow] = useState(false);

  const handleNoteClose = () => setupdateNotesShow(false);
  const handleNoteShow = () => setupdateNotesShow(true);
  const UpdateDonotRent = async () => {
    const data = await upadateNotes(custEmail);
    if (data === "Updated do not rent") {
      toast.success(data);
    } else {
      toast.error("Something went wrong");
    }
    getAllCustomerData();
    handleNoteClose();
  };

  //Add Booking Note Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [checked, setChecked] = React.useState(false);

  const handleChanges = () => {
    setChecked(!checked);
  };

  const webcamRef = React.useRef(null);

  const [image, setImage] = useState(undefined);
  const [backImage, setBackImage] = useState(undefined);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  const backcapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setBackImage(imageSrc);
  }, [webcamRef]);

  const videoConstraints = {
    width: 400,
    height: 300,
    facingMode: "user",
  };

  //Add Travel Agent Modal
  const [modalShow, setModalShow] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const [title, setTitle] = useState("");

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = (tit, url) => {
    setTitle(tit);
    setImgURL(url);
    setModalShow(true);
  };

  const [imageUpload, setimageUpload] = useState("Device");

  const [showFrontIdModal, setshowFrontIdModal] = useState(false);

  const handleCloseFrontIdModal = () => setshowFrontIdModal(false);
  const handleOpenFrontIdModal = () => {
    setshowFrontIdModal(true);
  };

  const [showBackIdModal, setshowBackIdModal] = useState(false);

  const handleCloseBackIdModal = () => setshowBackIdModal(false);
  const handleOpenBackIdModal = () => {
    setshowBackIdModal(true);
  };

  const deleteHotelLogo = (id) => {
    let data;
    if (id === "front") {
      data = images && images[0]?.data_url?.split("stayflexi.com/")[1];
    } else if (id === "back") {
      data =
        faviconImages && faviconImages[0]?.data_url?.split("stayflexi.com/")[1];
    } else {
      data =
        passportImages &&
        passportImages[0]?.data_url?.split("stayflexi.com/")[1];
    }

    if (data?.includes("defaultLogo")) {
    } else {
      handleSpringDataRequest(
        `core/api/v1/image/delete-image?imagePath=${data}`
      )
        .then((res) => {
          toast.success("Successfully deleted image");
          getUserId(custEmail);
        })
        .catch((err) => toast.error("Error in deleting image"));
    }
  };
  const [Severity, setSeverity] = useState("HIGH");

  //Set date of birth selection
  const setDateOfBirthSelection = (dob, isTime) => {
    try {
      setCustDOB(dob);
    } catch (e) {
      console.log(e);
    }
  };

  //Disable save button
  const [disableSave, setDisableSave] = useState(false);
  useEffect(() => {
    if (
      JSON.parse(hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails)
        .read === "YES" &&
      (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
    ) {
      if (custContact != "" && custContact == custEmergency) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    }
  }, [custContact, custEmergency]);

  const handleETAChange = (e) => {
    setCustETA(e);
    //formik.setFieldValue("customer_eta", e);
  };

  const handleETDChange = (e) => {
    setCustETD(e);
    //formik.setFieldValue("customer_etd", e);
  };

  const [clearedFields, setClearedFields] = useState(false);
  const [customerNameData, setcustomerNameData] = useState([]);
  const [customerPhoneData, setcustomerPhoneData] = useState([]);
  const [customerEmailData, setcustomerEmailData] = useState([]);
  const [showData, setshowData] = useState(false);

  const emptyValues = () => {
    setcustomerNameData([]);
    setcustomerEmailData([]);
    setcustomerPhoneData([]);
  };

  useEffect(async () => {
    if (custName !== "" && showData === true) {
      const data = await getAllCustomers(custName, "name");
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerNameData(sortedArr);
    }
    if (custName?.length === 1) {
      setcustomerNameData([]);
    }
  }, [custName]);

  useEffect(async () => {
    if (custEmail !== "" && showData === true) {
      const data = await getAllCustomers(custEmail, "email");
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerEmailData(sortedArr);
    }
    if (custEmail?.length === 1) {
      setcustomerEmailData([]);
    }
  }, [custEmail]);

  useEffect(async () => {
    if (custContact !== "" && showData === true) {
      const data = await getAllCustomers(custContact, "contact");

      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerPhoneData(sortedArr);
    }
    if (custContact?.length === 1) {
      setcustomerPhoneData([]);
    }
  }, [custContact]);

  //Populate customer fields
  const populateCustomerFields = (item) => {
    setCustName(item.name);
    setCustContact(item.contact);
    setCustEmail(item.email);
    //Gender
    if (item?.gender != null && item?.gender != "NA") {
      setCustGender(item?.gender);
    }
    //DOB
    if (item?.dateOfBirth && item?.dateOfBirth != "NA") {
      setCustDOB(new Date(item?.dateOfBirth));
    }
    //govtIdType
    if (
      item?.govtIdType &&
      item?.govtIdType != "" &&
      item?.govtIdType != "NA"
    ) {
      setGovIdType(item?.govtIdType);
    }
    //govtIdValue
    if (item?.govtIdValue) {
      setGovIdNum(item?.govtIdValue);
    }
    //addressLine1
    if (item?.addressLine1) {
      setCustAddressLine1(item?.addressLine1);
    }
    //addressLine2
    if (item?.addressLine2) {
      setCustAddressLine2(item?.addressLine2);
    }
    //city
    if (item?.city && item?.city != "NA") {
      setCustAddressCity(item?.city);
    }
    //state
    if (item?.state && item?.state != "NA") {
      setCustState(item?.state);
    }
    //Country
    if (item?.country && item?.country != "NA") {
      setCustCountry(item?.country);
    }
    //Zipcode
    if (item?.zipcode && item?.zipcode != "NA") {
      setCustAddressZip(item?.zipcode);
    }
    //nationality
    if (item?.nationality) {
      setCustNationality(item?.nationality);
    }
    //occupation
    if (item?.occupation) {
      setCustOccupation(item?.occupation);
    }
    //company name
    if (item?.companyName) {
      setCustCompany(item?.companyName);
    }
    if (item?.emergencyContact) {
      setCustEmergency(item?.emergencyContact);
    }
    if (item?.emergencyContactName) {
      setCustEmergencyName(item?.emergencyContactName);
    }
    setcustomerEmailData([]);
    setcustomerNameData([]);
    setcustomerPhoneData([]);
    setshowData(false);
  };

  //Clear all customer information fields
  const clearAllData = () => {
    //allow for customer data to be shown
    setshowData(true);
    setClearedFields(true);
    setCustName("");
    setCustEmail("");
    setCustContact("");
    setCustGender("Male");
    setCustDOB("");
    setCustAddressLine1("");
    setCustAddressLine2("");
    setCustAddressCity("");
    setCustState("");
    setCustCountry("");
    setCustAddressZip("");
    setCustNationality("");
    setCustOccupation("");
    setCustCompany("");
    setCustEmergency("");
    setCustEmergencyName("");
    setCustPurpose("");
    setSelectedPrograms(["No"]);
    setCustETA(new Date());
    setCustETD(new Date());
    setCustOCO("No");
    setCustApprovedOverseas("New");
    setPassportImageUpload("Camera");
    setPassportImage(undefined);
    setPassportImages([]);
    setimageUpload("Camera");
    setGovIdType("");
    setGovIdNum("");
    setImage(undefined);
    setBackImage(undefined);
    setImages([]);
    setFaviconImages([]);
  };

  const [enabledEmail, setenabledEmail] = useState(
    JSON.parse(
      hotel?.accessControl?.user_feature_map
        ?.CustomerInformationCaptureAndDisplay
    ).read === "YES" &&
      !data.customer_email?.includes("example") &&
      custEmail !== ""
  );
  const [enabledPhone, setenabledPhone] = useState(
    JSON.parse(
      hotel?.accessControl?.user_feature_map
        ?.CustomerInformationCaptureAndDisplay
    ).read === "YES" &&
      data?.customer_phone !== "0000000000" &&
      data?.customer_phone !== ""
  );

  return (
    <div>
      <div>
        <Grid container paddingTop={2}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={2}
            style={{ paddingTop: "0px" }}
          >
            {hotelType === "ISHA" ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <TextField
                    name="custName"
                    id="outlined-name"
                    label="Customer name"
                    onChange={(e) => setCustName(e.target.value)}
                    value={custName}
                    multiline
                    disabled={
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map
                          ?.CustomerInformationCaptureAndDisplay
                      ).read === "YES"
                    }
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <div
                    className="position-absolute"
                    style={{ top: "7rem", zIndex: "10000" }}
                  >
                    {customerNameData?.length > 0 && showData && (
                      <Card
                        style={{
                          width: "18.5rem",
                          height: "60%",
                          overflowY: "yes",
                          zIndex: "1000",
                        }}
                      >
                        <IconButton
                          // style={{
                          //   display: "block",
                          //   marginLeft: "auto",
                          //   marginRight: "1rem",
                          // }}
                          color="primary"
                        >
                          <CancelIcon onClick={() => emptyValues()} />
                        </IconButton>
                      </Card>
                    )}
                    {customerNameData?.length > 0 &&
                      showData &&
                      customerNameData?.map((item, index) => (
                        <Card
                          style={{
                            width: "18.5rem",
                            height: "60%",
                            overflowY: "yes",
                            zIndex: "1000",
                          }}
                          onClick={() => populateCustomerFields(item)}
                        >
                          <CardContent
                            style={{ padding: "3%", overflowWrap: "anywhere" }}
                          >
                            <div className="d-flex flex-column">
                              {/* <BsSearch className="mb-0 mr-2" /> */}
                              <div className="d-flex ">
                                <h4 className="mb-0 mr-4">{item.name}</h4>
                                {item.donotRent === "YES" && (
                                  <>
                                    <p className="badge badge-danger">
                                      Do not rent
                                    </p>
                                    {item?.severity === "LOW" && (
                                      <p className="badge badge-info ml-2">
                                        LOW
                                      </p>
                                    )}
                                    {item?.severity === "MEDIUM" && (
                                      <p className="badge badge-warning ml-2">
                                        MEDIUM
                                      </p>
                                    )}
                                    {item?.severity === "HIGH" && (
                                      <p className="badge badge-dark ml-2">
                                        HIGH
                                      </p>
                                    )}
                                    {item?.severity === "BLACKLIST" && (
                                      <p className="badge badge-danger ml-2">
                                        BLACKLIST
                                      </p>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="d-flex mb-0">
                                <p
                                  className="mr-3"
                                  style={{ fontSize: "13px" }}
                                >
                                  {item.email}
                                </p>
                                <p style={{ fontSize: "13px" }}>
                                  {item.contact}
                                </p>
                              </div>
                              <p className="mb-0">{item.notes}</p>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {((subUser?.toLowerCase() === "subuser" &&
                    JSON.parse(
                      hotel?.accessControl?.user_feature_map
                        ?.ResGridCustomerEmail
                    ).read !== "YES") ||
                    subUser?.toLowerCase() === "admin") && (
                    <TextField
                      name="custEmail"
                      id="standard-multiline-flexible"
                      label="Customer email"
                      onChange={(e) => setCustEmail(e.target.value)}
                      value={custEmail}
                      multiline
                      type="email"
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={custEmail?.length > 0 && !validateEmail(custEmail)}
                      helperText={
                        custEmail?.length > 0 && !validateEmail(custEmail)
                          ? "Please enter valid email"
                          : ""
                      }
                    />
                  )}
                  <div
                    className="position-absolute"
                    style={{ top: "7rem", zIndex: "10000" }}
                  >
                    {customerEmailData?.length > 0 && showData && (
                      <Card
                        style={{
                          width: "27.5rem",
                          height: "60%",
                          overflowY: "yes",
                          zIndex: "1000",
                        }}
                      >
                        <IconButton
                          // style={{
                          //   display: "block",
                          //   marginLeft: "auto",
                          //   marginRight: "1rem",
                          // }}
                          color="primary"
                        >
                          <CancelIcon onClick={() => emptyValues()} />
                        </IconButton>
                      </Card>
                    )}
                    {customerEmailData?.length > 0 &&
                      showData &&
                      customerEmailData?.map((item, index) => (
                        <Card
                          style={{
                            width: "27.5rem",
                            height: "60%",
                            overflowY: "yes",
                            zIndex: "1000",
                          }}
                          onClick={() => populateCustomerFields(item)}
                        >
                          <CardContent
                            style={{ padding: "3%", overflowWrap: "anywhere" }}
                          >
                            <div className="d-flex flex-column">
                              {/* <BsSearch className="mb-0 mr-2" /> */}
                              <div className="d-flex ">
                                <h4 className="mb-0 mr-4">{item.name}</h4>
                                {item.donotRent === "YES" && (
                                  <>
                                    <p className="badge badge-danger">
                                      Do not rent
                                    </p>
                                    {item?.severity === "LOW" && (
                                      <p className="badge badge-info ml-2">
                                        LOW
                                      </p>
                                    )}
                                    {item?.severity === "MEDIUM" && (
                                      <p className="badge badge-warning ml-2">
                                        MEDIUM
                                      </p>
                                    )}
                                    {item?.severity === "HIGH" && (
                                      <p className="badge badge-dark ml-2">
                                        HIGH
                                      </p>
                                    )}
                                    {item?.severity === "BLACKLIST" && (
                                      <p className="badge badge-danger ml-2">
                                        BLACKLIST
                                      </p>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="d-flex mb-0">
                                <p
                                  className="mr-3"
                                  style={{ fontSize: "13px" }}
                                >
                                  {item.email}
                                </p>
                                <p style={{ fontSize: "13px" }}>
                                  {item.contact}
                                </p>
                              </div>
                              <p className="mb-0">{item.notes}</p>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                  <Button
                    variant="custom-button"
                    type="submit"
                    style={{ marginTop: "10px" }}
                    onClick={() => clearAllData()}
                    disabled={disableSave}
                  >
                    Clear Details
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    name="custName"
                    id="outlined-name"
                    label="Customer name"
                    onChange={(e) => setCustName(e.target.value)}
                    value={custName}
                    disabled={
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map
                          ?.CustomerInformationCaptureAndDisplay
                      ).read === "YES"
                    }
                    multiline
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {((subUser?.toLowerCase() === "subuser" &&
                  JSON.parse(
                    hotel?.accessControl?.user_feature_map?.ResGridCustomerEmail
                  ).read !== "YES") ||
                  subUser?.toLowerCase() === "admin") && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      name="custEmail"
                      id="standard-multiline-flexible"
                      label="Customer email"
                      onChange={(e) => setCustEmail(e.target.value)}
                      value={custEmail}
                      disabled={enabledEmail}
                      multiline
                      type="email"
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={custEmail?.length > 0 && !validateEmail(custEmail)}
                      helperText={
                        custEmail?.length > 0 && !validateEmail(custEmail)
                          ? "Please enter valid email"
                          : ""
                      }
                    />
                  </Grid>
                )}
              </>
            )}
            {((subUser?.toLowerCase() === "subuser" &&
              JSON.parse(
                hotel?.accessControl?.user_feature_map?.ResGridCustomerPhone
              ).read !== "YES") ||
              subUser?.toLowerCase() === "admin") && (
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <TextField
                  name="custContact"
                  id="outlined-name"
                  label="Customer contact"
                  onChange={(e) =>
                    /^[+0-9]*$/.test(e.target.value) &&
                    setCustContact(e.target.value)
                  }
                  disabled={enabledPhone}
                  value={custContact}
                  multiline
                  type="text"
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    custContact?.length > 0 && !validatePhoneNumber(custContact)
                  }
                  helperText={
                    custContact?.length > 0 && !validatePhoneNumber(custContact)
                      ? "Please enter valid contact"
                      : ""
                  }
                />
                {hotelType === "ISHA" && (
                  <div
                    className="position-absolute"
                    style={{ top: "12rem", zIndex: "10000" }}
                  >
                    {customerPhoneData?.length > 0 && showData && (
                      <Card
                        style={{
                          width: "18.5rem",
                          height: "60%",
                          overflowY: "yes",
                          zIndex: "1000",
                        }}
                      >
                        <IconButton
                          // style={{
                          //   display: "block",
                          //   marginLeft: "auto",
                          //   marginRight: "1rem",
                          // }}
                          color="primary"
                        >
                          <CancelIcon onClick={() => emptyValues()} />
                        </IconButton>
                      </Card>
                    )}
                    {customerPhoneData?.length > 0 &&
                      showData &&
                      customerPhoneData?.map((item, index) => (
                        <Card
                          style={{
                            width: "18.5rem",
                            height: "60%",
                            overflowY: "yes",
                            zIndex: "1000",
                          }}
                          onClick={() => populateCustomerFields(item)}
                        >
                          <CardContent
                            style={{ padding: "3%", overflowWrap: "anywhere" }}
                          >
                            <div className="d-flex flex-column">
                              {/* <BsSearch className="mb-0 mr-2" /> */}
                              <div className="d-flex ">
                                <h4 className="mb-0 mr-4">{item.name}</h4>
                                {item.donotRent === "YES" && (
                                  <>
                                    <p className="badge badge-danger">
                                      Do not rent
                                    </p>
                                    {item?.severity === "LOW" && (
                                      <p className="badge badge-info ml-2">
                                        LOW
                                      </p>
                                    )}
                                    {item?.severity === "MEDIUM" && (
                                      <p className="badge badge-warning ml-2">
                                        MEDIUM
                                      </p>
                                    )}
                                    {item?.severity === "HIGH" && (
                                      <p className="badge badge-dark ml-2">
                                        HIGH
                                      </p>
                                    )}
                                    {item?.severity === "BLACKLIST" && (
                                      <p className="badge badge-danger ml-2">
                                        BLACKLIST
                                      </p>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="d-flex mb-0">
                                <p
                                  className="mr-3"
                                  style={{ fontSize: "13px" }}
                                >
                                  {item.email}
                                </p>
                                <p style={{ fontSize: "13px" }}>
                                  {item.contact}
                                </p>
                              </div>
                              <p className="mb-0">{item.notes}</p>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                  </div>
                )}
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <TextField
                select
                className="position-relative"
                name="customer_gender"
                id="customer_gender"
                fullWidth
                placeholder="Gender"
                value={custGender}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  setCustGender(e.target.value);
                }}
                defaultValue={"Male"}
                label="Gender"
                variant="outlined"
              >
                {hotelType === "ISHA"
                  ? ishaGenderList.map((genVal) => {
                      return (
                        <MenuItem key={genVal} value={genVal}>
                          {genVal}
                        </MenuItem>
                      );
                    })
                  : genderList.map((genVal) => {
                      return (
                        <MenuItem key={genVal} value={genVal}>
                          {genVal}
                        </MenuItem>
                      );
                    })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <TextField
                select
                className="position-relative"
                name="customer_category"
                id="customer_category"
                fullWidth
                placeholder="Guest category"
                value={custCategory}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  setCustCategory(e.target.value);
                }}
                label="Guest category"
                variant="outlined"
              >
                <MenuItem key={"ADULT"} value={"ADULT"}>
                  {"Adult"}
                </MenuItem>
                <MenuItem key={"CHILD"} value={"CHILD"}>
                  {"Child"}
                </MenuItem>
                <MenuItem key={"INFANT"} value={"INFANT"}>
                  {"Infant"}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat={dateFnsFormat}
                  fullWidth
                  disableMaskedInput
                  mask="___ ___, ____"
                  id="customer_dob"
                  name="customer_dob"
                  value={custDOB}
                  label="Date of Birth"
                  InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                  onChange={setDateOfBirthSelection}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  renderInput={(params) => {
                    params.error = false;
                    return <TextField fullWidth {...params} />;
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              {hotelType === "ISHA" && custAddressLine1?.trim().length < 12 ? (
                <>
                  <TextField
                    error
                    name="address1"
                    id="address1"
                    fullWidth
                    type="text"
                    placeholder="Address Line 1"
                    value={custAddressLine1}
                    sx={{ width: "100%" }}
                    onChange={(e) => setCustAddressLine1(e.target.value)}
                    label="Address Line 1"
                    variant="outlined"
                    helperText={"Address length is minimum 12 characters"}
                  />
                </>
              ) : (
                <>
                  <TextField
                    name="address1"
                    id="address1"
                    fullWidth
                    type="text"
                    placeholder="Address Line 1"
                    value={custAddressLine1}
                    sx={{ width: "100%" }}
                    onChange={(e) => setCustAddressLine1(e.target.value)}
                    label="Address Line 1"
                    variant="outlined"
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TextField
                name="address2"
                id="address2"
                fullWidth
                type="text"
                placeholder="Address Line 2"
                value={custAddressLine2}
                sx={{ width: "100%" }}
                onChange={(e) => setCustAddressLine2(e.target.value)}
                label="Address Line 2"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <TextField
                name="city"
                id="city"
                fullWidth
                type="text"
                placeholder="City"
                value={custAddressCity}
                sx={{ width: "100%" }}
                onChange={(e) => setCustAddressCity(e.target.value)}
                label="City"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <TextField
                name="custAddressState"
                id="outlined-name"
                label="State"
                placeholder="State"
                onChange={(e) => setCustState(e.target.value)}
                value={custState}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              {custCountry !== undefined && (
                <Autocomplete
                  fullWidth
                  autoComplete={false}
                  onChange={(eve, val) => {
                    // if (val !== custCountry) formik.setFieldValue("state", null);
                    setCustCountry(val);
                  }}
                  options={Object.keys(countries)}
                  value={custCountry}
                  renderInput={(params) => {
                    return <TextField {...params} label="Country" />;
                  }}
                />
              )}
              {/* <TextField
                name="custCountry"
                id="outlined-name"
                label="Country"
                placeholder="Country"
                onChange={(e) => setCustCountry(e.target.value)}
                value={custCountry}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="custAddressZip"
                id="outlined-name"
                label="Zip"
                placeholder="Zip"
                onChange={(e) => {
                  const re = /^[0-9A-Z\b]+$/;
                  if (e.target.value == "" || re.test(e.target.value)) {
                    setCustAddressZip(e.target.value);
                    if (
                      e.target.value.length >= 5 &&
                      e.target.value.length <= 10
                    ) {
                      //call the api function and get the city state and country vals
                      getGeoCodeValues(e.target.value);
                    }
                  }
                }}
                value={custAddressZip}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                renderInput={(params) => {
                  params.inputProps.autoComplete = "new-password";
                  return <TextField {...params} label="Nationality" />;
                }}
                onChange={(eve, val) => {
                  setCustNationality(val);
                }}
                options={nationalities}
                // options={
                //   isNull(formik.values.nationality) ||
                //   isUndefined(countries[formik.values.country])
                //     ? []
                //     : countries[formik.values.country]
                // }
                value={custNationality}
              ></Autocomplete>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="occupation"
                id="occupation"
                fullWidth
                type="text"
                placeholder="Occupation"
                value={custOccupation}
                sx={{ width: "100%" }}
                onChange={(e) => setCustOccupation(e.target.value)}
                label="Occupation"
                variant="outlined"
              />
            </Grid>
            {JSON.parse(
              hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
            ).read === "YES" &&
              (checkIshaAccess(hotel?.emailId) ||
                checkIshaUSAccess(hotel?.emailId)) && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      name="company_name"
                      id="company_name"
                      fullWidth
                      type="text"
                      placeholder="Company Name"
                      value={custCompany}
                      onChange={(e) => setCustCompany(e.target.value)}
                      label="Company Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      name="emergency_contact_name"
                      id="emergency_contact_name"
                      fullWidth
                      type="text"
                      required
                      placeholder="Emergency Contact Name"
                      value={custEmergencyName}
                      onChange={(e) => setCustEmergencyName(e.target.value)}
                      label="Emergency Contact Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    {custContact != "" && custEmergency == custContact ? (
                      <TextField
                        name="emergency_contact"
                        id="emergency_contact"
                        fullWidth
                        type="text"
                        placeholder="Emergency Contact"
                        value={custEmergency}
                        onChange={(e) => setCustEmergency(e.target.value)}
                        error
                        required
                        helperText={"Enter different number"}
                        label="Emergency Contact"
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        name="emergency_contact"
                        id="emergency_contact"
                        fullWidth
                        type="text"
                        required
                        placeholder="Emergency Contact"
                        value={custEmergency}
                        onChange={(e) => setCustEmergency(e.target.value)}
                        label="Emergency Contact"
                        variant="outlined"
                        error={
                          custEmergency?.length > 0 &&
                          !validatePhoneNumber(custEmergency)
                        }
                        helperText={
                          custEmergency?.length > 0 &&
                          !validatePhoneNumber(custEmergency)
                            ? "Please enter valid contact"
                            : ""
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      select
                      name="purposeOfVisit"
                      id="purposeOfVisit"
                      fullWidth
                      placeholder="Purpose of visit"
                      value={custPurpose}
                      onChange={(e) => setCustPurpose(e.target.value)}
                      label="Purpose of visit"
                      variant="outlined"
                    >
                      {ishaPurposeOfVisit?.length > 0 &&
                        ishaPurposeOfVisit.map((purpose, index) => {
                          return (
                            <MenuItem key={purpose} value={purpose}>
                              {purpose}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Programs Attended
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="programsAttended"
                        id="programsAttended"
                        label="Programs attended"
                        value={selectedPrograms}
                        onChange={handleProgramChange}
                        required="true"
                        multiple
                        style={{ width: "18.5rem" }}
                        // MenuProps={MenuProps}
                        placeholder="Choose Programs Attended"
                      >
                        {ishaProgramsAttended?.length > 0 &&
                          ishaProgramsAttended.map((program, index) => {
                            return (
                              <MenuItem key={program} value={program}>
                                {program}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    {checkIshaAccess(hotel?.emailId) && (
                      <TextField
                        select
                        className="position-relative"
                        name="ocoCheckinComplete"
                        id="ocoCheckinComplete"
                        fullWidth
                        placeholder="OCO checkin complete"
                        value={custOCO}
                        onChange={(e) => {
                          setCustOCO(e.target.value);
                        }}
                        label="OCO checkin complete"
                        variant="outlined"
                      >
                        <MenuItem key={"Yes"} value={"Yes"}>
                          {"Yes"}
                        </MenuItem>
                        <MenuItem key={"No"} value={"No"}>
                          {"No"}
                        </MenuItem>
                      </TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        style={{ width: "18.5rem" }}
                        label="Estimated time of arrival"
                        name="customer_eta"
                        id="customer_eta"
                        value={custETA}
                        onChange={handleETAChange}
                        InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                        renderInput={(params) => (
                          <TextField {...params} style={{ width: "18.5rem" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        style={{ width: "18.5rem" }}
                        label="Estimated time of departure"
                        name="customer_etd"
                        id="customer_etd"
                        value={custETD}
                        onChange={handleETDChange}
                        InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                        renderInput={(params) => (
                          <TextField {...params} style={{ width: "18.5rem" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    {checkIshaAccess(hotel?.emailId) && (
                      <TextField
                        name="approvedByOverseas"
                        id="approvedByOverseas"
                        select
                        className="position-relative"
                        fullWidth
                        type="text"
                        placeholder="Approved by overseas"
                        value={custApprovedOverseas}
                        onChange={(e) => {
                          setCustApprovedOverseas(e.target.value);
                        }}
                        label="Approved by overseas"
                        variant="outlined"
                      >
                        {ishaApprovedByOverseas?.length > 0 &&
                          ishaApprovedByOverseas.map((purpose, index) => {
                            return (
                              <MenuItem key={purpose} value={purpose}>
                                {purpose}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <FormControl
                      fullWidth
                      // sx={{ width: "12rem", marginLeft: "2rem" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Upload Passport Size Photo
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={passportImageUpload}
                        onChange={(e) => setPassportImageUpload(e.target.value)}
                        label="Upload Passport Size Photo"
                      >
                        <MenuItem value={"Device"}>Device upload</MenuItem>
                        <MenuItem value={"Camera"}>Camera upload</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {passportImageUpload === "Camera" && (
                      <div className="mt-2 d-flex">
                        <div className="d-flex flex-column mr-3">
                          {passportImage !== undefined && (
                            <img
                              src={`${passportImage}`}
                              style={{
                                width: "24rem",
                                heigth: "20rem",
                                borderRadius: "1rem",
                              }}
                            />
                          )}
                          <Button
                            variant="custom-button"
                            className="mr-2 mt-3"
                            onClick={() => handleOpenPassportModal()}
                          >
                            Upload Passport Size Photo
                          </Button>
                        </div>
                      </div>
                    )}
                  </Grid>
                  {passportImageUpload === "Device" && (
                    <Grid item xs={12}>
                      <div className="d-flex">
                        <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                          <h5 className="mb-3" style={{ marginRight: "13px" }}>
                            Passport Size Photo
                          </h5>
                          <ReactImageUploading
                            value={passportImages}
                            onChange={(image) => {
                              setPassportImages(image);
                            }}
                            dataURLKey="data_url"
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                            }) => (
                              // write your building UI
                              <Stack
                                // p="2"
                                // bgColor="gray.50"
                                // borderRadius="lg"
                                className="upload__image-wrapper"
                                sx={{
                                  borderRadius: "10px",
                                  height: "150px",
                                  backgroundColor: "#d3d3d336",
                                  padding: "1rem",
                                }}
                              >
                                {imageList.length === 0 && (
                                  <IconButton
                                    children={
                                      <UploadIcon
                                        icon="bi:upload"
                                        htmlColor="black"
                                      />
                                    }
                                    variant="outline"
                                    // height="100%"
                                    sx={{
                                      borderRadius: "20px",
                                      backgroundColor: "#d3d3d3",
                                      height: "100%",
                                    }}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                  ></IconButton>
                                )}
                                <Stack spacing="2">
                                  {imageList.map((image, index) => (
                                    <Stack
                                      sx={{
                                        position: "relative",
                                        padding: "0.5rem",
                                      }}
                                      key={index}
                                    >
                                      <img
                                        src={
                                          image["data_url"].includes("base64")
                                            ? image["data_url"]
                                            : image["data_url"] +
                                              "?lastmod=" +
                                              format(
                                                new Date(),
                                                "ddMMyyyyhhmmsstt"
                                              )
                                        }
                                        style={{
                                          height: "125px",
                                          width: "100%",
                                          borderRadius: "1rem",
                                          boxShadow: "md",
                                          border: "1px solid",
                                          borderColor: "gray.200",
                                        }}
                                        onClick={() =>
                                          handleFrontImagePopupShow("front")
                                        }
                                        alt=""
                                      />

                                      <IconButton
                                        children={
                                          <CloseIcon
                                            fontSize="xs"
                                            htmlColor="black"
                                          />
                                        }
                                        size="xs"
                                        sx={{
                                          position: "absolute",
                                          borderRadius: "100%",
                                          backgroundColor: "white",
                                          top: "0",
                                          // colorScheme="teal"
                                          marginTop: "0px !important",
                                          left: "0",
                                          "&:hover": {
                                            color: "black",
                                            backgroundColor: "#ffffffcc",
                                          },
                                        }}
                                        onClick={() => {
                                          onImageRemove(index);
                                          deleteHotelLogo("passport");
                                        }}
                                      />
                                      <IconButton
                                        children={
                                          <ChangeCircleIcon
                                            fontSize="xs"
                                            htmlColor="black"
                                          />
                                        }
                                        size="xs"
                                        sx={{
                                          position: "absolute",
                                          borderRadius: "100%",
                                          top: "0",
                                          backgroundColor: "white",
                                          // colorScheme="teal"
                                          marginTop: "0px !important",
                                          right: "0",
                                          "&:hover": {
                                            color: "black",
                                            backgroundColor: "#ffffffcc",
                                          },
                                        }}
                                        onClick={() => onImageUpdate(index)}
                                      />
                                    </Stack>
                                  ))}
                                </Stack>
                              </Stack>
                            )}
                          </ReactImageUploading>
                        </div>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Govt ID</InputLabel>
                <Select
                  name="govt_id_type"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={govIdType}
                  defaultValue={govIdType}
                  label="Govt ID"
                  onChange={(e) => setGovIdType(e.target.value)}
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {hotel.hotelCurrency === "INR"
                    ? govIdIndia?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))
                    : govIdUS?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="govtIdValue"
                style={{ width: "100%" }}
                label="GOVT ID Number"
                name="govtIdValue"
                variant="outlined"
                placeholder="Govt ID Number"
                value={govIdNum}
                onChange={(e) => setGovIdNum(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Upload Image
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={imageUpload}
                  style={{ width: "100%" }}
                  onChange={(e) => setimageUpload(e.target.value)}
                  label="Upload Image"
                >
                  <MenuItem value={"Device"}>Device upload</MenuItem>
                  <MenuItem value={"Camera"}>Camera upload</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center ">
            {/* {ScannerData?.docinfo?.fullname !== undefined && (
                  <div>
                    <img
                      className="mr-3"
                      src={`data:image/png;base64,${imageFront}`}
                      style={{
                        borderRadius: "1rem",
                        width: "120px",
                        height: "70px",
                      }}
                    />
                    <img
                      className="mr-3"
                      src={`data:image/png;base64,${imageBack}`}
                      style={{
                        borderRadius: "1rem",
                        width: "120px",
                        height: "70px",
                      }}
                    />
                  </div>
                )} */}

            <div className="w-100">
              {imageUpload === "Camera" && (
                <div className="mt-4 d-flex">
                  <div className="d-flex flex-column mr-3">
                    {image !== undefined && (
                      <img
                        src={`${image}`}
                        style={{
                          width: "24rem",
                          heigth: "20rem",
                          borderRadius: "1rem",
                        }}
                      />
                    )}
                    <Button
                      variant="custom-button"
                      className="mr-4 mt-3"
                      onClick={() => handleOpenFrontIdModal()}
                    >
                      Upload front id
                    </Button>
                  </div>
                  <div className="d-flex flex-column">
                    {backImage !== undefined && (
                      <img
                        src={`${backImage}`}
                        style={{
                          width: "24rem",
                          heigth: "20rem",
                          borderRadius: "1rem",
                        }}
                      />
                    )}
                    <Button
                      variant="custom-button"
                      className="mt-3"
                      onClick={() => handleOpenBackIdModal()}
                    >
                      Upload Back id
                    </Button>
                  </div>
                </div>
              )}
              {imageUpload === "Device" && (
                <div className="d-flex p-3 w-100">
                  <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                    <h5 className="mb-3" style={{ marginRight: "13px" }}>
                      ID front
                    </h5>
                    <ReactImageUploading
                      value={images}
                      onChange={(image) => {
                        setImages(image);
                      }}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <Stack
                          // p="2"
                          // bgColor="gray.50"
                          // borderRadius="lg"
                          className="upload__image-wrapper"
                          sx={{
                            borderRadius: "10px",
                            height: "150px",
                            backgroundColor: "#d3d3d336",
                            padding: "1rem",
                            width: "23rem",
                          }}
                        >
                          {imageList.length === 0 && (
                            <IconButton
                              children={
                                <UploadIcon
                                  icon="bi:upload"
                                  htmlColor="black"
                                />
                              }
                              variant="outline"
                              // height="100%"
                              sx={{
                                borderRadius: "20px",
                                backgroundColor: "#d3d3d3",
                                height: "100%",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            ></IconButton>
                          )}
                          <Stack spacing="2">
                            {imageList.map((image, index) => (
                              <Stack
                                sx={{
                                  position: "relative",
                                  padding: "0.5rem",
                                }}
                                key={index}
                              >
                                <img
                                  src={
                                    image["data_url"]?.includes("base64")
                                      ? image["data_url"]
                                      : image["data_url"] +
                                        "?lastmod=" +
                                        format(new Date(), "ddMMyyyyhhmmsstt")
                                  }
                                  style={{
                                    height: "125px",
                                    width: "100%",
                                    borderRadius: "1rem",
                                    boxShadow: "md",
                                    border: "1px solid",
                                    borderColor: "gray.200",
                                  }}
                                  alt=""
                                  onClick={() =>
                                    handleModalShow(
                                      "Front ID",
                                      image["data_url"]
                                    )
                                  }
                                />

                                <IconButton
                                  children={
                                    <CloseIcon
                                      fontSize="xs"
                                      htmlColor="black"
                                    />
                                  }
                                  size="xs"
                                  sx={{
                                    position: "absolute",
                                    borderRadius: "100%",
                                    backgroundColor: "white",
                                    top: "0",
                                    // colorScheme="teal"
                                    marginTop: "0px !important",
                                    left: "0",
                                    "&:hover": {
                                      color: "black",
                                      backgroundColor: "#ffffffcc",
                                    },
                                  }}
                                  onClick={() => {
                                    onImageRemove(index);
                                    deleteHotelLogo("front");
                                  }}
                                />
                                <IconButton
                                  children={
                                    <ChangeCircleIcon
                                      fontSize="xs"
                                      htmlColor="black"
                                    />
                                  }
                                  size="xs"
                                  sx={{
                                    position: "absolute",
                                    borderRadius: "100%",
                                    top: "0",
                                    backgroundColor: "white",
                                    // colorScheme="teal"
                                    marginTop: "0px !important",
                                    right: "0",
                                    "&:hover": {
                                      color: "black",
                                      backgroundColor: "#ffffffcc",
                                    },
                                  }}
                                  onClick={() => onImageUpdate(index)}
                                />
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      )}
                    </ReactImageUploading>
                  </div>
                  <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                    <h5 className="mb-3">ID back</h5>
                    <ReactImageUploading
                      value={faviconImages}
                      onChange={(image) => {
                        setFaviconImages(image);
                      }}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <Stack
                          // p="2"
                          // bgColor="gray.50"
                          // borderRadius="lg"
                          className="upload__image-wrapper"
                          sx={{
                            borderRadius: "10px",
                            height: "150px",
                            backgroundColor: "#d3d3d336",
                            padding: "1rem",
                            width: "23rem",
                          }}
                        >
                          {imageList.length === 0 && (
                            <IconButton
                              children={
                                <UploadIcon
                                  icon="bi:upload"
                                  htmlColor="black"
                                />
                              }
                              variant="outline"
                              sx={{
                                borderRadius: "20px",
                                backgroundColor: "#d3d3d3",
                                height: "100%",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            ></IconButton>
                          )}
                          <Stack spacing="2">
                            {imageList.map((image, index) => (
                              <Stack
                                sx={{
                                  position: "relative",
                                  padding: "0.5rem",
                                }}
                                key={index}
                              >
                                <img
                                  src={
                                    image["data_url"]?.includes("base64")
                                      ? image["data_url"]
                                      : image["data_url"] +
                                        "?lastmod=" +
                                        format(new Date(), "ddMMyyyyhhmmsstt")
                                  }
                                  style={{
                                    height: "125px",
                                    width: "100%",
                                    borderRadius: "1rem",
                                    boxShadow: "md",
                                    border: "1px solid",
                                    borderColor: "gray.200",
                                  }}
                                  alt=""
                                  onClick={() =>
                                    handleModalShow(
                                      "Back ID",
                                      image["data_url"]
                                    )
                                  }
                                />

                                <IconButton
                                  children={
                                    <CloseIcon
                                      fontSize="xs"
                                      htmlColor="black"
                                    />
                                  }
                                  size="xs"
                                  sx={{
                                    position: "absolute",
                                    borderRadius: "100%",
                                    backgroundColor: "white",
                                    top: "0",
                                    // colorScheme="teal"
                                    marginTop: "0px !important",
                                    left: "0",
                                    "&:hover": {
                                      color: "black",
                                      backgroundColor: "#ffffffcc",
                                    },
                                  }}
                                  onClick={() => {
                                    onImageRemove(index);
                                    deleteHotelLogo("back");
                                  }}
                                />
                                <IconButton
                                  children={
                                    <ChangeCircleIcon
                                      fontSize="xs"
                                      htmlColor="black"
                                    />
                                  }
                                  size="xs"
                                  sx={{
                                    position: "absolute",
                                    borderRadius: "100%",
                                    top: "0",
                                    backgroundColor: "white",
                                    // colorScheme="teal"
                                    marginTop: "0px !important",
                                    right: "0",
                                    "&:hover": {
                                      color: "black",
                                      backgroundColor: "#ffffffcc",
                                    },
                                  }}
                                  onClick={() => onImageUpdate(index)}
                                />
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      )}
                    </ReactImageUploading>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="horizontalSep" /> */}
        <div style={{ float: "right", marginBottom: "2rem" }}>
          {/* <Button
            variant="custom-button"
            onClick={onCancelClick}
            style={{ marginRight: "2rem" }}
          >
            Cancel
          </Button> */}
          {editCustLoader === false ? (
            <Button
              variant="custom-button"
              type="submit"
              onClick={() => updateCustomerDetails()}
              disabled={
                disableSave ||
                (hotelType === "ISHA" && custAddressLine1?.trim().length < 12)
              }
            >
              Save customer
            </Button>
          ) : (
            <Button variant="custom-button">
              <CircularProgress size="15px" />
            </Button>
          )}
        </div>

        <div className="d-flex align-items-center mb-3 mt-3">
          <h5
            style={{
              marginLeft: "16px",
              marginRight: "16px",
              marginBottom: "0px",
              marginTop: "5rem",
            }}
          >
            Customer Notes
          </h5>
          <AddIcon
            onClick={handleShow}
            disabled={loading}
            sx={{
              marginTop: "5rem",
              "&:hover": {
                backgroundColor: "#B5B5C3",
                borderRadius: "50px",
                marginTop: "5rem",
              },
            }}
          >
            Add customer note
            {loading && (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            )}
          </AddIcon>
          {(doNotRent === undefined || doNotRent?.do_not_rent === "NO") && (
            <Button
              style={{
                height: "2.5rem",
                display: "block",
                marginLeft: "0.5rem",
                marginRight: "1rem",
                background: "#F64E60",
                color: "#fff",
                marginTop: "5rem",
              }}
              onClick={handleDShow}
            >
              Do-Not Rent
              {/* {load && (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            )} */}
            </Button>
          )}

          {doNotRent && doNotRent?.do_not_rent === "YES" && (
            <Button
              variant="custom-button"
              style={{
                height: "2.5rem",
                display: "block",
                marginLeft: "0.5rem",
                marginRight: "1rem",
                marginTop: "5rem",
                color: "#fff",
              }}
              onClick={handleRShow}
            >
              Remove Do-Not Rent
              {/* {load && (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            )} */}
            </Button>
          )}
        </div>
        {customerNotes.length <= 0 ? (
          <h6 style={{ marginLeft: "16px" }} className="text-muted">
            No customer notes{" "}
          </h6>
        ) : (
          customerNotes?.map((e) => (
            <div className="d-flex align-items-center">
              <Card className="mb-2" style={{ marginLeft: "16px" }}>
                <Card.Body style={{ width: "40rem", padding: "1rem" }}>
                  {e.notes}
                </Card.Body>
              </Card>
              {e.do_not_rent === "YES" && (
                <p
                  className="mb-0 badge badge-danger ml-2"
                  style={{ fontSize: "13px" }}
                >
                  <DoNotDisturbIcon
                    className="mb-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleNoteShow()}
                  />
                </p>
              )}

              <DeleteIcon
                onClick={() =>
                  e.do_not_rent === "YES"
                    ? deleteNoteInModal(e.id)
                    : deleteCustomerNote(e.id, e?.notes)
                }
                className="ml-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          ))
        )}

        {/* delete Customer Note */}

        <Modal
          show={showFrontIdModal}
          onHide={handleCloseFrontIdModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton> Front id</Modal.Header>
          <Modal.Body>
            {!image ? (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </div>
            ) : (
              <img src={image} alt="test-ilustartion" />
            )}
            <Button
              variant="custom-button"
              onClick={() => (!image ? capture() : setImage(undefined))}
            >
              {!image ? "Capture photo" : "take photo"}
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          show={showBackIdModal}
          onHide={handleCloseBackIdModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>Back id</Modal.Header>
          <Modal.Body>
            {!backImage ? (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </div>
            ) : (
              <img src={backImage} alt="test-ilustartion" />
            )}
            <Button
              variant="custom-button"
              onClick={() =>
                !backImage ? backcapture() : setBackImage(undefined)
              }
            >
              {!backImage ? "Capture photo" : "take photo"}
            </Button>
          </Modal.Body>
        </Modal>

        <Modal
          show={showDeleteModal}
          onHide={handleDeleteClose}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete customer note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Deleting these will not remove donot rent status of customer
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="custom-button"
              onClick={() => deletCustomerNotes()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={updateNotesModal}
          onHide={handleNoteClose}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              Are you sure you want to remove donot rent?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleNoteClose}>
              Close
            </Button>
            <Button variant="custom-button" onClick={() => UpdateDonotRent()}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show} onHide={handleClose} style={{ zIndex: "3000" }}>
          <Modal.Header closeButton>
            <Modal.Title>Enter note for this customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="d-flex align-items-center ml-3 mb-3">
              <input
                type="checkbox"
                checked={checked}
                onChange={handleChanges}
                style={{ marginBottom: "0px" }}
              />
              <p
                className="mb-0 ml-2 badge badge-danger"
                style={{ fontSize: "17px" }}
              >
                Do not rent
              </p>
            </div> */}
            {/* <OutlinedInput
              label=""
              notched={false}
              className="input-lg w-100"
              placeholder="Enter a note"
              onChange={(e) => setcustomerNoteData(e.target.value)}
            /> */}

            <TextField
              required
              id="outlined-required"
              label="Customer notes"
              placeholder="Enter a note"
              className="w-100"
              onChange={(e) => setcustomerNoteData(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="custom-button" onClick={createCustomerNote}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={dshow} onHide={handleDClose} style={{ zIndex: "3000" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              Do you want to add Donot rent tag to this user
            </Modal.Title>
          </Modal.Header>
          <div
            style={{
              marginLeft: "3rem !important",
              marginRight: "3rem !important",
            }}
          >
            <select
              className="form-select form-select-lg ml-4 mr-4"
              size="lg"
              value={Severity}
              onChange={(e) => setSeverity(e.target.value)}
            >
              <option selected value="LOW">
                LOW
              </option>
              <option value="MEDIUM">MEDIUM</option>

              <option value="HIGH">HIGH</option>
              <option value="BLACKLIST">BLACKLIST</option>
            </select>
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDClose}>
              Close
            </Button>
            <Button variant="custom-button" onClick={createDoNotRentStatus}>
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={rshow} onHide={handleRClose} style={{ zIndex: "3000" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              Do you want to remove Donot rent tag to this user
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleRClose}>
              Close
            </Button>
            <Button variant="custom-button" onClick={removeDoNotRentStatus}>
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default CustomerInformation;
