// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Fab,
  Fade,
  FormHelperText,
  Grid,
  Grow,
  InputLabel,
  Paper,
  Popper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Card, Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  blockMultipleRooms,
  deleteMultipleRooms,
  getAvailableRooms,
  getPhysicalRoomIds,
  getRemovedRooms,
  makeRoomsAvailable,
  makeRoomsUnavailable,
  reAssignRoom,
  restoreDeleted,
  unBlockMultipleRooms,
} from "../InventoryCalendar/api/InventoryApi";
import {
  DropdownOptions,
  weekdays,
} from "../InventoryCalendar/constants/InventoryConstants";
import {
  customErrorMessage,
  dateFormat,
} from "../../../../src/app/pages/CalendarPage/constants/Calendar-constants";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useFormik } from "formik";
import * as yup from "yup";
import ConfirmationModal from "../InventoryCalendar/Views/ConfirmationModal";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { ENV } from "../../../../src/app/pages/CalendarPage/constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
} from "../../api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  textDisplay: {
    fontSize: "12px",
    marginRight: 6,
  },
  subText: {
    fontSize: "14px",
  },
}));

const BulkSetOnlineView = (props) => {
  const { componentType, refresh } = props;
  const {
    data,
    selectedRoom,
    rooms,
    setLineInvisible,
    setLineVisible,
    closeFunc,
    hotelId,
  } = props;
  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(
        new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
    },
  ]);

  //Amplitude check on Bulk Operations page
  const amplitudeEventProperties = {
    hotel_id: hotelId,
    environment: ENV,
    email: localStorage.getItem("email"),
    role: data?.accessControl?.ADMINRIGHTS,
  };

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectDays = weekdays;
  const [loading, setLoading] = useState(true);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectRoom, setSelectRoom] = useState({});
  const [roomSelected, setRoomsSelected] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [reason, setReason] = useState("");
  const [otherRoomTypes, setOtherRoomTypes] = useState({});
  const [otherRooms, setOtherRooms] = useState([]);

  const validationSchema =
    componentType === "delete-room" ||
    componentType === "restore" ||
    componentType === "reassign"
      ? yup.object({
          roomSelected: yup.array().required("Select atleast one room"),
        })
      : yup.object({
          currentDateSelection: yup
            .array()
            .required(
              "Select a range and click on apply for atleast one range"
            ),
          roomSelected:
            componentType !== "block-room"
              ? yup.array().required("Select atleast one room")
              : componentType === "block-room"
              ? selectedRoom !== "all-rooms"
                ? yup.array().required("Select atleast one room")
                : null
              : yup.array().required("Select atleast one room"),
        });

  const formik = useFormik({
    initialValues:
      componentType === "delete-room" ||
      componentType === "restore" ||
      componentType === "reassign"
        ? { roomSelected: [] }
        : { currentDateSelection: [], roomSelected: [] },
    validationSchema: validationSchema,
    onSubmit: () => {
      setShowConfirm(true);
    },
  });

  const getOtherRoomTypes = () => {
    let arr = [];
    let temporary = Object.assign(rooms);
    Object.keys(temporary).forEach((val) => {
      if (val !== selectedRoom) {
        arr.push({ value: val, label: temporary[val] });
      }
    });
    setOtherRooms(arr);
    if (arr.length !== 0) {
      setOtherRoomTypes(arr[0]);
    }
  };

  const reset = () => {
    setCurrentDateSelection([
      {
        startDate: new Date(new Date().setHours(0, 0, 0, 0)),
        endDate: new Date(
          new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
            0,
            0,
            0,
            0
          )
        ),
      },
    ]);
    setCurrentSelection([]);
    Object.keys(selectRoom).forEach((val, index) => {
      selectRoom[index] = { value: val.value, isSelected: false };
    });
    setSelectRoom({ ...selectRoom });
    setReason("");
  };

  useEffect(() => {
    getApiData();
  }, [selectedRoom]);

  const setOnlineFunc = () => {
    debugger;
    let temp = [...currentSelection];
    temp.forEach((val) => {
      val.physicalRoomIds = roomSelected;
    });

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/room/make-multiple-rooms-available?hotel_id=${hotelId}&hotelId=${hotelId}`,
      temp
    )
      .then((res) => {
        toast.success("Successfull");
        closeFunc();
        /* amplitude
          .getInstance()
          .logEvent(
            "USER PERFORMED SET ONLINE OPERATION IN BULK OPERATIONS",
            amplitudeEventProperties
          ); */
      })
      .catch((err) => console.error(err));
    // .then((resp) => {
    // await makeRoomsAvailable(temp);
    // getAllDatas();
  };

  const setUnavailableFunc = () => {
    let temp = [...currentSelection];
    temp.forEach((val) => {
      val.physicalRoomIds = roomSelected;
    });

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/room/make-multiple-rooms-unavailable?hotel_id=${hotelId}&hotelId=${hotelId}`,
      temp
    )
      .then((res) => {
        toast.success("Successfull");
        closeFunc();
        /* amplitude
          .getInstance()
          .logEvent(
            "USER PERFORMED SET OFFLINE OPERATION IN BULK OPERATIONS",
            amplitudeEventProperties
          ); */
      })
      .catch((err) => console.error(err));
    // await makeRoomsUnavailable(temp);
    // getAllDatas();
  };

  const setBlockFunc = () => {
    let temp = [...currentSelection];
    temp.forEach((val) => {
      val.physicalRoomIds = roomSelected;
      val.reason = reason;
    });

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/room/block-multiple-rooms?hotel_id=${hotelId}&hotelId=${hotelId}`,
      temp
    )
      .then((res) => {
        toast.success("Successfull");
        closeFunc();
        /* amplitude
          .getInstance()
          .logEvent(
            "USER PERFORMED BLOCK ROOM OPERATION IN BULK OPERATIONS",
            amplitudeEventProperties
          ); */
      })
      .catch((err) =>
        toast.error(
          "Selected room could not be blocked since it has future reservations."
        )
      );
    // try {
    //   await blockMultipleRooms(temp);
    //   // getAllDatas();
    // } catch (e) {

    //   console.log(e);
    // }
  };

  const setUnblockFunc = () => {
    let temp = [...currentSelection];
    temp.forEach((val) => {
      val.physicalRoomIds = roomSelected;
      val.reason = reason;
    });

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/room/unblock-multiple-rooms?hotel_id=${hotelId}&hotelId=${hotelId}`,
      temp
    )
      .then((res) => {
        toast.success("Successfull");
        closeFunc();
        /* amplitude
          .getInstance()
          .logEvent(
            "USER PERFORMED UNBLOCK ROOM OPERATION IN BULK OPERATIONS",
            amplitudeEventProperties
          ); */
      })
      .catch((err) => console.error(err));
    // await unBlockMultipleRooms(temp);
    // getAllDatas();
  };

  const deleteFunc = () => {
    let temp = {
      physicalRoomIds: roomSelected,
      roomType: selectedRoom === "all-rooms" ? "all" : selectedRoom,
    };

    handleSpringDataMutationRequest(
      "DELETE",
      `core/api/v1/room/delete-rooms?hotel_id=${hotelId}&hotelId=${hotelId}`,
      temp
    )
      .then((res) => {
        setAvailableRooms(
          availableRooms.filter((val) => !roomSelected.includes(val))
        );
        closeFunc();
        /* amplitude
          .getInstance()
          .logEvent(
            "USER PERFORMED DELETE ROOM OPERATION IN BULK OPERATIONS",
            amplitudeEventProperties
          ); */
      })
      .catch((err) =>
        setAvailableRooms(
          availableRooms.filter((val) => !roomSelected.includes(val))
        )
      );
    // await deleteMultipleRooms(temp);

    // getAllDatas();
  };

  const restoreFunc = () => {
    let temp = {
      physicalRoomIds: roomSelected,
      roomTypeName: selectedRoom === "all-rooms" ? "all" : rooms[selectedRoom],
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/room/make-deleted-room-available?hotel_id=${hotelId}&hotelId=${hotelId}`,
      temp
    )
      .then((res) => {
        setAvailableRooms(
          availableRooms.filter((val) => !roomSelected.includes(val))
        );
        closeFunc();
        /* amplitude
          .getInstance()
          .logEvent(
            "USER PERFORMED RESTORE DELETED ROOM OPERATION IN BULK OPERATIONS",
            amplitudeEventProperties
          ); */
      })
      .catch((err) =>
        setAvailableRooms(
          availableRooms.filter((val) => !roomSelected.includes(val))
        )
      );
    // await restoreDeleted(temp);
    // setAvailableRooms(
    //   availableRooms.filter((val) => !roomSelected.includes(val))
    // );
    // getAllDatas();
  };

  const reassignFunc = () => {
    let obj = {
      physicalRoomId: roomSelected[0],
      roomType: selectedRoom,
      newRoomType: otherRoomTypes.value,
      isId: true,
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/room/move-room?hotel_id=${hotelId}&hotelId=${hotelId}`,
      obj
    ).then((res) => {
      setAvailableRooms(
        availableRooms.filter((val) => !roomSelected.includes(val))
      );
      closeFunc();
      /* amplitude
        .getInstance()
        .logEvent(
          "USER PERFORMED REASSIGN ROOM TYPE OPERATION IN BULK OPERATIONS",
          amplitudeEventProperties
        ); */
    }).catch = (err) =>
      setAvailableRooms(
        availableRooms.filter((val) => !roomSelected.includes(val))
      );
    // await reAssignRoom(obj);

    // getAllDatas();
  };

  const getDisplayData = () => {
    switch (componentType) {
      case "set-online":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                Room Ids :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                Date Ranges :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "set-offline":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                Room Ids :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                Date Ranges :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "block-room":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                Room Ids :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>Reason :</Typography>
              <Typography className={classes.subText}>{reason}</Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                Date Ranges :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "unblock-room":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                Room Ids :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>Reason :</Typography>
              <Typography className={classes.subText}>{reason}</Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                Date Ranges :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "delete-room":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                Room Ids :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
          </Grid>
        );
      case "restore":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                Room Ids :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
          </Grid>
        );
      case "reassign":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                Room Ids :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                New room type :
              </Typography>
              <Typography className={classes.subText}>
                {otherRoomTypes.label}
              </Typography>
            </Grid>
          </Grid>
        );
    }
  };

  const setActionButton = async () => {
    setApiCall(true);
    try {
      switch (componentType) {
        case "set-online":
          setOnlineFunc();
          break;
        case "set-offline":
          setUnavailableFunc();
          break;
        case "block-room":
          setBlockFunc();
          break;
        case "unblock-room":
          setUnblockFunc();
          break;
        case "delete-room":
          deleteFunc();
          break;
        case "restore":
          restoreFunc();
          break;
        case "reassign":
          reassignFunc();
          break;
      }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
    } finally {
      reset();
      setApiCall(false);
      formik.resetForm();
      setShowConfirm(false);
      refresh();
    }
  };
  const getApiData = async () => {
    setLineVisible();
    try {
      switch (componentType) {
        case "set-online":
          await getRoomsForOnline();
          break;
        case "set-offline":
          await getRoomsForOnline();
          break;
        case "block-room":
          await getRoomsForOnline();
          break;
        case "unblock-room":
          await getRoomsForOnline();
          break;
        case "delete-room":
          await getRoomsForOnline();
          break;
        case "restore":
          await getRestoreRooms();
          break;
        case "reassign":
          getOtherRoomTypes();
          await getRoomsForOnline();
          break;
      }
      setLineInvisible();
    } catch (e) {
      console.log(e);
      setLineInvisible();
    }
  };

  const getRestoreRooms = async () => {
    setLoading(true);

    handleSpringDataRequest(
      `core/api/v1/room/get-deleted-rooms?hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((resp) => {
        if (resp === "" || resp.length === 0) {
          setAvailableRooms([]);
        }
        if (typeof resp === "number") {
          setAvailableRooms([resp]);
          setSelectRoom({ 0: { value: resp, isSelected: false } });
        }
        if (resp.length > 1) {
          setAvailableRooms(resp.split(","));
          let val = resp.split(",");
          val.forEach((value, index) => {
            val[index] = { value: value, isSelected: false };
          });
          setSelectRoom(val);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // const resp = await getRemovedRooms();
  };

  const getRoomsForOnline = async () => {
    setLoading(true);
    let resp = {};
    if (selectedRoom === "all-rooms") {
      handleSpringDataRequest(
        `core/api/v1/room/getPhysicalRoomIds?hotel_id=${hotelId}&hotelId=${hotelId}`
      )
        .then((resp) => {
          setAvailableRooms(resp);
          let val = [...resp];
          val.forEach((value, index) => {
            val[index] = { value: value, isSelected: false };
          });
          setSelectRoom(val);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    } else {
      handleSpringDataRequest(
        `core/api/v1/room/get-available-rooms?&roomTypeId=${selectedRoom}&hotel_id=${hotelId}&hotelId=${hotelId}`
      )
        .then((resp) => {
          setAvailableRooms(resp.toString().split(","));
          let val = resp.toString().split(",");
          val.forEach((value, index) => {
            val[index] = { value: value, isSelected: false };
          });
          setSelectRoom(val);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
      // resp = await getAvailableRooms({ roomTypeId: selectedRoom });
    }
  };

  const classes = useStyles();
  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const handleChipDelete = (val) => {
    const arr = currentSelection.filter((chip) => chip.id !== val.id);
    formik.setFieldValue("currentDateSelection", arr);
    setCurrentSelection(arr);
  };

  useEffect(() => {
    if (componentType === "block-room" && selectedRoom === "all-rooms") {
      setRoomsSelected(availableRooms);
      let arr = [];
      const data = availableRooms?.map((item) => {
        let obj = {};
        obj["value"] = item;
        obj["isSelected"] = true;
        arr.push(obj);
      });
      setSelectRoom(arr);
    } else {
      setRoomsSelected([]);
    }
  }, [availableRooms, selectedRoom]);

  return (
    <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
      <form
        style={{ width: "inherit" }}
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Grid container>
          {!loading && (
            <Grid container className={classes.textField} spacing={2}>
              <Grid item xs={12}>
                {componentType === "block-room" &&
                  selectedRoom === "all-rooms" && (
                    <p className="mb-2" style={{ fontWeight: "bold" }}>
                      By Default all rooms are selected
                    </p>
                  )}
                <InputLabel>Select RoomID(s)</InputLabel>
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                  id="roomSelected"
                  name="roomSelected"
                >
                  {availableRooms?.length <= 0 && (
                    <Typography> There are no rooms for selection</Typography>
                  )}
                  {availableRooms?.map((val, index) => {
                    return (
                      <Button
                        key={val}
                        variant={
                          selectRoom[index].isSelected
                            ? "contained"
                            : "outlined"
                        }
                        style={{ fontWeight: "bold" }}
                        onClick={() => {
                          selectRoom[index].isSelected =
                            !selectRoom[index].isSelected;
                          if (componentType === "reassign") {
                            Object.keys(selectRoom).forEach((key) => {
                              if (parseInt(key) !== index) {
                                selectRoom[key].isSelected = false;
                              }
                            });
                          }
                          setSelectRoom({ ...selectRoom });
                          if (selectRoom[index].isSelected) {
                            let tempArray = [...roomSelected];
                            if (componentType === "reassign") {
                              tempArray = [];
                            }
                            tempArray.push(selectRoom[index].value);
                            setRoomsSelected(tempArray);
                            formik.setFieldValue("roomSelected", tempArray);
                          } else {
                            let arr = roomSelected.filter(
                              (item) => item != selectRoom[index].value
                            );
                            setRoomsSelected(arr);
                            formik.setFieldValue("roomSelected", arr);
                          }
                        }}
                        color={
                          Boolean(formik.errors.roomSelected)
                            ? "error"
                            : "primary"
                        }
                      >
                        {" "}
                        {val}
                      </Button>
                    );
                  })}
                </Box>
                <FormHelperText
                  error={
                    formik.touched.roomSelected &&
                    Boolean(formik.errors.roomSelected)
                  }
                >
                  {formik.touched.roomSelected && formik.errors.roomSelected}
                </FormHelperText>
              </Grid>

              {componentType !== "delete-room" &&
                componentType !== "restore" &&
                componentType !== "reassign" &&
                componentType !== "edit-room" && (
                  <Grid item xs={12} sx={{ paddingTop: 2 }}>
                    <div>
                      <ClickAwayListener
                        onClickAway={() => {
                          setShowDatePicker(false);
                          setAnchorEl(null);
                        }}
                      >
                        <div>
                          <TextField
                            fullWidth
                            focused={showDatePicker}
                            required
                            error={
                              formik.touched.currentDateSelection &&
                              Boolean(formik.errors.currentDateSelection)
                            }
                            helperText={
                              formik.touched.currentDateSelection &&
                              formik.errors.currentDateSelection
                            }
                            InputProps={{ readOnly: true }}
                            value={getDateText()}
                            label="Date range"
                            onClick={(eve) => {
                              setAnchorEl(eve.currentTarget);
                              setShowDatePicker(true);
                            }}
                          ></TextField>
                          {showDatePicker && (
                            <Popper
                              style={{ zIndex: 2 }}
                              open={showDatePicker}
                              anchorEl={anchorEl}
                              placement="bottom"
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  style={{
                                    transformOrigin: (placement = "bottom-end"),
                                  }}
                                  {...TransitionProps}
                                >
                                  <Paper
                                    elevation={5}
                                    className="inventory-daterange-custom"
                                  >
                                    <DateRange
                                      color="#1BC5BD"
                                      rangeColors={["#1BC5BD"]}
                                      editableDateInputs={false}
                                      minDate={new Date()}
                                      moveRangeOnFirstSelection={false}
                                      onChange={(data) => {
                                        setCurrentDateSelection([
                                          { ...data.range1 },
                                        ]);
                                      }}
                                      ranges={currentDateSelection}
                                    ></DateRange>
                                    <Grid
                                      container
                                      item
                                      sx={{
                                        padding: 1,
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        sx={{ marginRight: 1 }}
                                        onClick={() => {
                                          setShowDatePicker(false);
                                        }}
                                        color="error"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        onClick={() => {
                                          const text = getDateText();
                                          const check =
                                            text + selectDays.join(",");
                                          if (
                                            currentSelection.findIndex(
                                              (val) => val.id === check
                                            ) === -1
                                          ) {
                                            currentSelection.push({
                                              text: getDateText(),
                                              id: check,
                                              datesText: selectDays.join(","),
                                              startDate: moment(
                                                currentDateSelection[0]
                                                  .startDate
                                              ).format(dateFormat),
                                              endDate: moment(
                                                currentDateSelection[0].endDate
                                              ).format(dateFormat),
                                            });
                                            formik.setFieldValue(
                                              "currentDateSelection",
                                              currentSelection
                                            );
                                            setCurrentSelection(
                                              currentSelection
                                            );
                                          }
                                          setShowDatePicker(false);
                                        }}
                                        color="primary"
                                      >
                                        Apply
                                      </Button>
                                    </Grid>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          )}
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Grid>
                )}
              {currentSelection.length > 0 && (
                <Fade in={currentSelection.length > 0}>
                  <Grid item xs={12}>
                    <InputLabel>Current Selections</InputLabel>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {currentSelection.map((data) => {
                        return (
                          <Chip
                            sx={{
                              width: "auto",
                              height: "auto",
                              fontWeight: "bold",
                            }}
                            variant="outlined"
                            color="primary"
                            onDelete={(eve) => {
                              handleChipDelete(data);
                            }}
                            label={
                              <div>
                                <Typography
                                  sx={{ padding: 0.5, fontWeight: "bold" }}
                                >
                                  {" "}
                                  {data.text}
                                </Typography>
                                <Divider />
                                <Typography
                                  sx={{
                                    padding: 0.5,
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                    fontWeight: "bold",
                                  }}
                                  display="inline-block"
                                >
                                  {" "}
                                  {data.datesText}
                                </Typography>
                              </div>
                            }
                            key={data.id}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                </Fade>
              )}
              {(componentType === "block-room" ||
                componentType === "unblock-room") && (
                <Grid item xs={12}>
                  <TextField
                    value={reason}
                    label="Reason"
                    fullWidth
                    multiline
                    rows={3}
                    onChange={(eve) => {
                      setReason(eve.target.value);
                    }}
                  ></TextField>
                </Grid>
              )}

              {(componentType === "reassign" ||
                componentType === "edit-room") && (
                <Grid item justifyContent="flex-start" container xs={12}>
                  <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Autocomplete
                      fullWidth
                      id="combo-box-demo"
                      value={otherRoomTypes}
                      onChange={(eve, value) => {
                        setOtherRoomTypes(value);
                      }}
                      disableClearable
                      getOptionLabel={(option) => option.label || ""}
                      isOptionEqualToValue={(opt, val) =>
                        opt.value === val.value
                      }
                      options={otherRooms}
                      renderInput={(params) => (
                        <TextField {...params} label="New Room Types" />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {!loading && (
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                className={classes.textField}
                justifyContent="flex-end"
                container
              >
                <Fab
                  type="submit"
                  disabled={apiCall}
                  variant="extended"
                  color="primary"
                >
                  {componentType === "set-online" && "Set Online"}
                  {componentType === "set-offline" && "Set Offline"}
                  {componentType === "block-room" && "Block Rooms"}
                  {componentType === "unblock-room" && "Unblock Rooms"}
                  {componentType === "delete-room" && "Delete Rooms"}
                  {componentType === "restore" && "Restore Rooms"}
                  {componentType === "reassign" && "Reassign Rooms"}
                </Fab>
              </Grid>
            </Grid>
          )}

          <ConfirmationModal
            open={showConfirm}
            onClose={() => {
              setShowConfirm(false);
            }}
            onBackdropClick={closeFunc}
            title={
              DropdownOptions.find((item) => item.val === componentType).text
            }
            loading={apiCall}
            displayData={showConfirm ? getDisplayData() : null}
            confirmAction={setActionButton}
          ></ConfirmationModal>
        </Grid>
      </form>
    </Grow>
  );
};

export default BulkSetOnlineView;
