import React, { Fragment } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#E5e5e5";

const InvoiceDualText = ({ boldText, normalText }) => {
  return (
    <View style={styles.dualText}>
      <Text style={styles.boldText}>{boldText}:</Text>
      <Text style={styles.normalText}>{normalText}</Text>
    </View>
  );
};

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={[styles.description, { fontSize: "10px" }]}>
      Date {"&"} Description
    </Text>
    <Text style={[styles.qty, { fontSize: "10px" }]}>Type</Text>
    <Text style={[styles.rate, { fontSize: "10px" }]}>Sub-total</Text>
    <Text style={[styles.rate, { fontSize: "10px" }]}>Tax</Text>
    <Text style={[styles.amount, { fontSize: "10px" }]}>Total</Text>
  </View>
);

// Handling customer address
const handleCustomerAddress = (address) => {
  if (!address.length > 0) {
    return null;
  } else if (address.startsWith("null")) {
    return null;
  } else if (address.startsWith("NA")) {
    return null;
  } else {
    return [`${address}, `];
  }
};

// Handling the date format
const handleDateFormat = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

// Handling direct bill folio amount into the report
// 1. Displaying the amount value and not the symbol (Coming negative since it is the "CREDIT")
// 2. Rounding of the digits to second point decimal

const handleDigits = (num) => {
  let absoluteNum = Math.abs(num);
  let formattedNum = parseFloat(absoluteNum);
  return parseInt(formattedNum).toFixed(2);
};

const CtaBillHeader = () => (
  <View style={styles.container}>
    <Text style={{ width: "25%", fontSize: "10px", marginTop: "5px" }}>
      Date
    </Text>
    <Text style={{ width: "50%", fontSize: "10px", marginTop: "5px" }}>
      Description
    </Text>
    <Text style={{ width: "25%", fontSize: "10px", marginTop: "5px" }}>
      Amount
    </Text>
  </View>
);

const InvoiceRoomRow = ({ items, symbol }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={index}>
      {/* <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flexStart",
          flexWrap: "wrap",
          marginLeft: "15px",
        }}
      > */}
      <Text
        style={{
          // marginBottom: 3,
          fontSize: "10px",
          width: "25%",
          // marginTop: "10px",
        }}
      >
        {handleDateFormat(item?.service_date)}
      </Text>
      <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "25%",
          // marginTop: "10px",
        }}
      >
        Direct Billing Receivable
        {/*item?.service_amount_desc*/}
      </Text>
      <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "25%",
          // marginTop: "10px",
        }}
      >
        {symbol} {handleDigits(item.service_total)}
      </Text>
      {/* </View> */}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

function DirectBillFolioDoc({
  invoice,
  invoiceSummary,
  hotelData,
  symbol,
  customDetails,
  hotelLogo,
}) {
  // get the image extension
  const getExtension = (img) => {
    if (img?.charAt(0) === "/") {
      return `data:image/jpeg;base64, ${img}`;
    } else if (img?.charAt(0) === "i") {
      return `data:image/png;base64, ${img}`;
    } else if (img?.charAt(0) === "U") {
      return `data:image/webp;base64, ${img}`;
    } else if (img?.charAt(0) === "R") {
      return `data:image/gif;base64, ${img}`;
    }
  };
  return (
    <View style={[styles.section, { marginTop: 15 }]}>
      <View
        style={[
          styles.dualText,
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
          },
        ]}
      >
        {hotelLogo && (
          <View>
            <Image
              style={{ width: "150px", height: "100px" }}
              src={getExtension(hotelLogo)}
            />
          </View>
        )}
        <View>
          <View style={{ marginBottom: 5 }}>
            <Text>Invoice</Text>
          </View>
          <Text
            style={{ fontSize: "10px", fontWeight: "bold", marginBottom: 3 }}
          >{`Folio#${invoice?.bookingid}`}</Text>
          <InvoiceDualText
            boldText={"Check-in"}
            normalText={handleDateFormat(invoice?.checkin)}
          />
          <InvoiceDualText
            boldText={"Check-out"}
            normalText={handleDateFormat(invoice?.checkout)}
          />

          <View style={styles.dualText}>
            <InvoiceDualText
              boldText={"Nights"}
              normalText={invoice?.num_nights}
            />
            <InvoiceDualText boldText={"Adults"} normalText={invoice?.adults} />
            <InvoiceDualText
              boldText={"Children"}
              normalText={invoice?.children}
            />
          </View>
          <InvoiceDualText
            boldText={"Room No.(s)"}
            normalText={invoice?.roomids}
          />
          <InvoiceDualText
            boldText={"Source"}
            normalText={invoice?.booking_source_displayname}
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ width: "50%" }}>
          <Text style={[styles.boldText, { marginBottom: 3 }]}>
            {hotelData?.hotel_name}
          </Text>
          <Text
            style={[styles.boldText, { marginBottom: 3, flexWrap: "wrap" }]}
          >
            {hotelData?.hotel_address}
          </Text>
          <View style={styles.dualText}>
            <Text style={styles.boldText}>Email : </Text>
            <Text style={styles.boldText}>{hotelData?.hotel_email}</Text>
          </View>
          <View style={styles.dualText}>
            <Text style={styles.boldText}>Phone:</Text>
            <Text style={styles.boldText}>{hotelData?.hotel_contact}</Text>
          </View>
        </View>
        <View style={{ width: "50%", textAlign: "right" }}>
          <Text
            style={{
              fontSize: "12px",
              paddingRight: "5px",
              marginBottom: 3,
            }}
          >
            Bill to
          </Text>
          <Text style={[styles.boldText, { marginBottom: 3.5 }]}>
            {invoice?.customer_name}
          </Text>
          {!invoice?.customer_address_full_str.startsWith("null") && (
            <Text
              style={[
                styles.boldText,
                {
                  marginBottom: 3.5,
                  marginLeft: 10,
                  flexWrap: "wrap",
                },
              ]}
            >
              {invoice?.customer_address_full_str}
            </Text>
          )}
          <View
            style={[
              {
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: 3.5,
              },
              { textAlign: "right" },
            ]}
          >
            <Text style={styles.boldText}>Email : </Text>
            <Text style={styles.boldText}>{invoice?.customer_email}</Text>
          </View>
          <View
            style={[
              {
                flexDirection: "row",
                marginBottom: 3.5,
                justifyContent: "flex-end",
              },
              { textAlign: "right" },
            ]}
          >
            <Text style={styles.boldText}>Phone:</Text>
            <Text style={styles.boldText}>{invoice?.customer_phone}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          marginTop: 30,
          flexDirection: "row",
          //   justifyContent: "space-between",
        }}
      >
        {invoice?.reservation_room_info_list && (
          <View>
            <View style={{ width: "100%" }}>
              <CtaBillHeader />
              <InvoiceRoomRow
                items={invoice?.cta_billed_items}
                symbol={symbol}
              />
            </View>
          </View>
        )}
      </View>
      <View
        style={{
          marginTop: 30,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View></View>
        <View>
          <InvoiceDualText
            boldText={"Total billed"}
            normalText={
              symbol + " " + handleDigits(invoiceSummary?.total_cta_billed)
            }
          />
          <InvoiceDualText
            boldText={"Total settled"}
            normalText={
              symbol + " " + handleDigits(invoiceSummary?.total_cta_settled)
            }
          />
          <InvoiceDualText
            boldText={"Balance due"}
            normalText={
              symbol + " " + handleDigits(invoiceSummary?.total_cta_balance)
            }
          />
        </View>
      </View>
    </View>
  );
}

export default DirectBillFolioDoc;

// Create styles
const styles = StyleSheet.create({
  image: {
    height: "125px",
    width: "auto",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "10px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "10px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "left",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    textAlign: "center",
    borderRightWidth: 1,
  },
  amount: {
    textAlign: "center",
    width: "15%",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    padding: "10px",
    inlineSize: "auto",
    fontStyle: "bold",
    justifyContent: "space-between",
  },
  resCardWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: "10px",
  },
  resCardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flexStart",
    fontSize: "10px",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: "4.5px",
    // height: 60,
    inlineSize: "auto",
  },
  resCardLeft: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginTop: "auto",
    marginBottom: "auto",
  },
  resCardHeader: {
    fontSize: "10px",
    fontWeight: "500",
    width: "40%",
    flexWrap: "wrap",
    display: "flex",
  },
  resCardVal: {
    marginLeft: "7.5px",
    fontSize: "10px",
    flexWrap: "wrap",
    width: "55%",
    display: "flex",
  },
  resCardRight: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginLeft: "10px",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingLeft: "10px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  signature: {
    marginTop: 50,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
});
