import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import "../groupDashborad.css";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import GroupAreaChart from "./GroupAreaChart";
import GroupLineChart from "./GroupLineChart";
import GroupBiaxialBarChart from "./GroupBiaxialBarChart";
import { RectSkeleton } from "./StatsSkeleton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "material-react-toastify";
import GroupBarChart from "./GroupBarChart";
import "./stats.css";
import { convertDateFormat, toSentenceCase } from "./util";

const PropertyStats = ({ date_Range, statsRefresh, currency }) => {
  const fullScreen = true;
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const groupEmail = localStorage.getItem("groupEmail");

  const selectedHotels = [
    new URLSearchParams(window.location.search)?.get("hotelId"),
  ];
  const hotelId = new URLSearchParams(window.location.search)?.get("hotelId");

  //------------------------Date Range Picker ----------------

  const lastSeven = new Date();
  lastSeven.setDate(lastSeven.getDate() - 7);
  const fastSeven = new Date();
  fastSeven.setDate(fastSeven.getDate() + 7);

  const [dateRange, setDateRange] = useState([
    {
      startDate: lastSeven,
      endDate: fastSeven,
      key: "selection",
    },
  ]);

  useEffect(() => {
    setDateRange(date_Range);
  }, [date_Range]);
  //----------------Rechart--------------------
  const colors = [
    "#96C7C1",
    "#CDF2CA",
    "#F7D59C",
    "#6B7AA1",
    "#F29191",
    "#6E7C7C",
    "#D3E0DC",
  ];

  //----------------------------Occupency Report Line, Revenue Report AreaGraph--------------------
  const [lineData, setLineData] = useState([]);
  const [occupancyLoader, setOccupencyLoader] = useState(false);

  const handleSalesReportData = (data) => {
    const dataArr = [];

    data.map((item) => {
      const myData = {};
      myData.date = item?.metric;
      myData.Occupancy = item?.occupancy?.toFixed(2);
      myData["Room sold"] = item?.nc_res_count;
      myData.Revenue = item?.nc_res_amount?.toFixed(2);
      myData.Adr = item?.adr?.toFixed(2);
      myData.Revpar = item?.revpar?.toFixed(2);
      dataArr.push(myData);
    });
    dataArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setLineData(dataArr);
  };

  const fetchingSalesData = () => {
    setOccupencyLoader(true);
    handleSpringDataRequest(
      `api/v2/reports/getReportData/?report_type=salesReport&start_date=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&end_date=${handelingDateForApi(dateRange[0]?.endDate)}`
    )
      .then((data) => {
        setOccupencyLoader(false);
        handleSalesReportData(data?.report_data);
      })
      .catch((err) => {
        console.log(err);
        setOccupencyLoader(false);
      });
  };

  // const handleData = (data) => {
  //   const dataArr = [];
  //   for (const key in data) {
  //     const myData = { ...data[key] };
  //     myData.netOccupancy = data[key]?.netOccupancy?.toFixed(2);
  //     myData.Occupancy = data[key]?.occupancy?.toFixed(2);
  //     myData.Revenue = data[key]?.revenue?.toFixed(2);
  //     myData.Revpar = data[key]?.revpar?.toFixed(2);
  //     myData.Adr = data[key]?.adr?.toFixed(2);
  //     myData["Room sold"] = data[key]?.roomSold;
  //     dataArr.push(myData);
  //   }
  //   dataArr.sort((a, b) => {
  //     const dateA = new Date(a.date);
  //     const dateB = new Date(b.date);
  //     return dateA - dateB;
  //   });
  //   setLineData(dataArr);
  // };
  // const fetchingSalesData = () => {
  //   setOccupencyLoader(true);
  //   handleSpringDataMutationRequest(
  //     "POST",
  //     `core/api/v1/reports/get-group-sales-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
  //       dateRange[0]?.startDate
  //     )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
  //     selectedHotels
  //   )
  //     .then((data) => {
  //       setOccupencyLoader(false);
  //       handleData(data);
  //     })
  //     .catch((err) => setOccupencyLoader(false));
  // };

  //------------Revenue by source , Stayflexi Booking--------------
  const [revenueBySource, setRevenueBySource] = useState([]);
  const [stayFlexiBooking, setStayFlexiBooking] = useState([]);
  const [stayflexiLoader, setStayflexiLoader] = useState(false);
  const handleRevenueBySourceData = (data) => {
    const arr = [];
    for (let key in data) {
      const tempObj = {};
      tempObj.name = toSentenceCase(key);
      tempObj.Revenue = Number(data[key].toFixed(2));
      arr.push(tempObj);
    }
    arr.sort((a, b) => b.Revenue - a.Revenue);
    setRevenueBySource(arr);
  };

  const handleStayFlexiBookingdata = (data) => {
    const dataArr = [];
    for (const key in data) {
      const myData = { ...data[key] };
      myData.date = convertDateFormat(data[key]?.date);
      myData.Revenue = data[key]?.revenue?.toFixed(2);
      myData["Room sold"] = data[key]?.roomSold;
      dataArr.push(myData);
    }
    dataArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setStayFlexiBooking(dataArr);
  };

  const fetchingRoomrevData = () => {
    setStayflexiLoader(true);
    // handleSpringDataMutationRequest(
    //   "POST",
    //   `core/api/v1/reports/get-group-roomrev-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
    //     dateRange[0]?.startDate
    //   )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
    //   selectedHotels
    // )
    //   .then((data) => {
    //     setStayflexiLoader(false);
    //     handleRevenueBySourceData(data?.sourceMap);
    //     handleStayFlexiBookingdata(data?.sfMap);
    //   })
    //   .catch((err) => setStayflexiLoader(false));

    handleSpringDataRequest(
      `core/api/v1/reports/get-hotel-room-revenue/?fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )} 00:00:00&toDate=${handelingDateForApi(dateRange[0]?.endDate)} 00:00:00`
    )
      .then((data) => {
        // console.log(data);
        setStayflexiLoader(false);
        handleRevenueBySourceData(data?.sourceMap);
        handleStayFlexiBookingdata(data?.sfMap);
      })
      .catch((err) => setStayflexiLoader(false));
  };

  //----------------------------Pick Up Trends-------------------
  const [pickUpTrends, setPickUpTrends] = useState([]);
  const [pickuptrendLoader, setPickuptrendingLoader] = useState(false);
  const handlePickUpTrendData = (data) => {
    const dataArr = [];
    for (const key in data) {
      const myData = { ...data[key] };
      myData.date = convertDateFormat(data[key]?.date);
      myData.Revenue = data[key]?.revenue?.toFixed(2);
      myData["Room sold"] = data[key]?.roomSold;
      dataArr.push(myData);
    }
    dataArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setPickUpTrends(dataArr);
  };
  const fetchingPickupData = () => {
    setPickuptrendingLoader(true);
    // handleSpringDataMutationRequest(
    //   "POST",
    //   `core/api/v1/reports/get-group-pickup-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
    //     dateRange[0]?.startDate
    //   )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
    //   selectedHotels
    // )
    //   .then((data) => {
    //     console.log(data);
    //     // setPickuptrendingLoader(false);
    //     // handlePickUpTrendData(data);
    //   })
    //   .catch((err) => setPickuptrendingLoader(false));

    handleSpringDataRequest(
      `core/api/v1/reports/get-hotel-pickup-trend/?fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )} 00:00:00&toDate=${handelingDateForApi(dateRange[0]?.endDate)} 00:00:00`
    )
      .then((data) => {
        setPickuptrendingLoader(false);
        handlePickUpTrendData(data);
      })
      .catch((err) => setPickuptrendingLoader(false));
  };

  //--------------------Payment Split By Type-----------------------
  const [paymentType, setPaymentType] = useState([]);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const handlePaymentData = (data) => {
    // console.log("handlePaymentData", data);

    const arr = [];
    for (let key in data) {
      const tempObj = {};
      tempObj.name = toSentenceCase(key);
      tempObj.Revenue = Number(data[key].toFixed(2));
      arr.push(tempObj);
    }
    // console.log("arr", arr);
    setPaymentType(arr);
  };
  const fetchingPaymentData = () => {
    setPaymentLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/get-group-payment-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
      selectedHotels
    )
      .then((data) => {
        setPaymentLoader(false);
        handlePaymentData(data);
      })
      .catch((err) => setPaymentLoader(false));
  };
  //----------------------------Tax Report and Booking revenue rate plan-------------------
  const [taxLoader, setTaxLoader] = useState(false);
  const [taxData, setTaxData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const handleTaxData = (data) => {
    const arr = [];
    for (let key in data) {
      const tempObj = {};
      tempObj.name = toSentenceCase(key);
      tempObj.Revenue = Number(data[key].toFixed(2));
      arr.push(tempObj);
    }

    setTaxData(arr);
  };

  const hadleBookingRevData = (data) => {
    const arr = [];
    for (let key in data) {
      const tempObj = {};
      tempObj.name = toSentenceCase(key);
      tempObj.Revenue = Number(data[key].toFixed(2));
      arr.push(tempObj);
    }

    setRevenueData(arr);
  };

  const fetchingTaxData = () => {
    setTaxLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/get-group-tax-rp-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
      selectedHotels
    )
      .then((data) => {
        setTaxLoader(false);
        console.log("taxdata", data);
        handleTaxData(data?.taxMap);
        hadleBookingRevData(data?.rpMap);
      })
      .catch((err) => setTaxLoader(false));
  };

  //-------------------------------------------------------------------------------------------------------
  useEffect(() => {
    getAllData();
  }, [statsRefresh]);

  const dayscalculation = () => {
    const timeDiff = Math.abs(
      dateRange[0]?.endDate?.getTime() - dateRange[0]?.startDate?.getTime()
    );
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  };

  const getAllData = () => {
    if (
      Math.abs((dateRange[0]?.endDate - new Date()) / (1000 * 60 * 60 * 24)) >
        180 ||
      Math.abs((dateRange[0]?.startDate - new Date()) / (1000 * 60 * 60 * 24)) >
        180
    ) {
      toast.error(
        "The date range should be within the past and future 6 months."
      );
      return;
    }
    if (dayscalculation() > 120) {
      toast.error("Selected date range can not more than 4 months.");
      return;
    }
    fetchingSalesData();
    fetchingRoomrevData();
    fetchingPickupData();
    fetchingPaymentData();
    fetchingTaxData();
  };

  const handelingDateForApi = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  return (
    <Box>
      <Box>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack spacing={4} className="position-relative" sx={{ mt: 1 }}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box sx={{ position: "relative" }}>
                <Button variant="outlined" onClick={handleClick} fullWidth>
                  {moment(dateRange[0].startDate).format("DD-MM-YY")} {" to "}
                  {moment(dateRange[0].endDate).format("DD-MM-YY")}
                </Button>
                {open ? (
                  <Box sx={styles}>
                    <Stack spacing={3}>
                      <Stack
                        className="position-relative w-100 d-flex justify-content-between"
                        direction="row"
                        sx={{ justifyContent: "space-between" }}
                      >
                        <DefinedRange
                          onChange={(item) => setDateRange([item.selection])}
                          ranges={dateRange}
                        />
                        <DateRange
                          editableDateInputs={true}
                          onChange={(item) => setDateRange([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={dateRange}
                        />
                      </Stack>

                      <Stack
                        className="position-relative w-100 d-flex justify-content-between"
                        direction="row"
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => setOpen(false)}
                          color="error"
                        >
                          Cencel
                        </Button>
                        <Button
                          variant="custom-button"
                          onClick={(item) => {
                            getAllData();
                            handleClickAway();
                          }}
                        >
                          Apply
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener>
          </Stack>
          --------------------------------------------------------------
          <FormControl fullWidth style={{ width: "40rem", mt: 1 }}>
              <InputLabel
                style={{ width: "40rem" }}
                id="demo-simple-select-label"
              >
                Hotel name
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Hotel name"
                value={selectedHotels}
                style={{ width: "40rem" }}
                onChange={handleChange}
                multiple
                required="true"
                placeholder="Select hotel names"
              >
                {bookingData?.map((item) => (
                  <MenuItem key={item?.hotelId} value={item?.hotelId}>
                    {item?.hotelName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </Box> */}

        {/* ------------------Recharts------------------------------------------------- */}
        <Box sx={{ backgroundColor: "#F5F5F5", p: 1, mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {" "}
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Occupancy
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Number of rooms sold out of total available rooms (blocked
                  rooms are excluded)
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupLineChart
                    lineData={lineData}
                    colors={colors}
                    fullScreen={fullScreen}
                    occLine={"Occupancy"}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Room sold
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Number of rooms sold by day for the given date range
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupLineChart
                    lineData={lineData}
                    colors={colors}
                    fullScreen={fullScreen}
                    occLine={"Room sold"}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            {/* //---------------pick up--------- */}
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Pick up revenue
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Rooms sold in advance for the future dates. This is important
                  metric to assess the property performance.
                </Typography>
                {pickuptrendLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBiaxialBarChart
                    colors={colors}
                    stayData={pickUpTrends}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Pick up room sold
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Rooms sold in advance for the future dates. This is important
                  metric to assess the property performance.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupLineChart
                    lineData={pickUpTrends}
                    colors={colors}
                    fullScreen={fullScreen}
                    occLine={"Room sold"}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Stayflexi room sold revenue
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  This graph visually represents the revenue generated directly
                  through the hotel’s website, facilitated by the Stayflexi
                  booking engine and the GHA partnership.
                </Typography>
                {stayflexiLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBiaxialBarChart
                    colors={colors}
                    stayData={stayFlexiBooking}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Stayflexi room sold
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  This graph illustrates bookings originating directly from the
                  hotel website, facilitated by the Stayflexi booking engine and
                  GHA.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupLineChart
                    lineData={stayFlexiBooking}
                    colors={colors}
                    fullScreen={fullScreen}
                    occLine={"Room sold"}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            {/* --------Pickup trend and inteligence report---- */}
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Revenue by day
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Room revenue generated by day for the given date range.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupAreaChart
                    lineData={lineData}
                    colors={colors}
                    revenueLine={["Revenue"]}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Revenue by source
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Room revenue generated by booking source for the given date
                  range.
                </Typography>
                {stayflexiLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBarChart
                    sourceData={revenueBySource}
                    colors={colors}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            {/* ----Revenue by hotels--- */}
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Revenue by rate plan
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Room revenue generated by rate plan for the given date range.
                </Typography>
                {taxLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBarChart
                    sourceData={revenueData}
                    colors={colors}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Revenue per available room
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  RevPAR (Revenue Per Available Room) statistics indicate the
                  average revenue generated per room in a property over a
                  specific timeframe.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupAreaChart
                    lineData={lineData}
                    colors={colors}
                    revenueLine={["Revpar"]}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Average daily rate
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  ADR (Average Daily Rate) statistics graph illustrates the
                  average room price charged per day within a designated
                  timeframe for a property. ADR is pivotal in assessing pricing
                  strategies and revenue performance.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupAreaChart
                    lineData={lineData}
                    colors={colors}
                    revenueLine={["Adr"]}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Payment method distribution
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  This graph visually presents the distribution of payment
                  methods used by both guests and the hotel to settle bills. It
                  depicts the relative proportions of payments made through
                  various methods, including cash, credit/debit cards, UPI
                  payments, and bank transfers. This analysis assists the
                  property in understanding preferred payment modes and
                  optimizing its payment processing systems accordingly.
                </Typography>
                {paymentLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBarChart
                    sourceData={paymentType}
                    colors={colors}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Taxes by type
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Taxes collected by the taxes type, for given date range.
                </Typography>
                {taxLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBarChart
                    sourceData={taxData}
                    colors={colors}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(PropertyStats);
