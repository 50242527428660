import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import "../FlexibleSlot/views/FlexibleSlotModal.css";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import PDFExportPanel from "../Reports/pdfExport/PDFExportPanel";
import "ag-grid-enterprise";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-responsive-modal/styles.css";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import moment from "moment";
import { toast } from "material-react-toastify";
import ClickAwayListenerHelper from "../../Utils/ClickAwayListenerHelper";
import * as BsIcons from "react-icons/bs";
import { Link } from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import ProgressBarWithCheckpoints from "./ProgressBarWithCheckpoints";

// function LinearProgressWithLabel(props) {
//   return (
//     <Box sx={{ display: "flex", alignItems: "center" }}>
//       <Box sx={{ width: "100%", mr: 1 }}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box sx={{ minWidth: 35 }}>
//         <Typography variant="body2" color="text.secondary">{`${Math.round(
//           props.value
//         )}%`}</Typography>
//       </Box>
//     </Box>
//   );
// }

function RunNightAuditModal({
  show,
  hide,
  dates,
  nightAduitData,
  progress,
  setProgress,
  mode,
  selectedState,
  setselectedState,
  selectedStatus,
  setselectedStatus,
  isNightAuditRunned,
  setisNightAuditRunned,
  getAllNightAuditData,
  setdates,
  createTracker,
  currentStep,
  setCurrentStep,
  includeAM,
}) {
  // defining variables and setting the state
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);
  const [selectedRows, setSelectedRows] = useState(null);
  const [nightAuditData, setnightAuditData] = useState([]);
  const [openModal, setopenModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [voidType, setvoidType] = useState("VOID");

  const [mockLoaders, setmockLoaders] = useState(false);

  const totalSteps = 3;

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const hotelId = hotel.hotelId;
  const hotelCurrency = hotel.accessControl.hotel.hotel_currency;
  const handleOpenModal = () => {
    if (
      selectedRows === null ||
      selectedRows === undefined ||
      selectedRows?.length <= 0
    ) {
      toast.error("Please select booking id");
    } else {
      setopenModal(true);
    }
  };
  const handleCloseModal = () => setopenModal(false);

  useEffect(() => {
    if (nightAduitData === undefined || nightAduitData === null) {
      createTracker();
    }
  }, [dates]);

  console.log("selectedStatus", selectedStatus);

  const getAllData = (str) => {
    setmockLoaders(true);
    handleSpringDataRequest(
      `core/api/v1/nightAudit/get-audit-date-bookings?auditDate=${moment(
        dates
      ).format("DD-MM-YYYY")}&opType=${selectedStatus}`
    )
      .then((res) => {
        setSelectedRows(null);
        setnightAuditData(res);
        setmockLoaders(false);
        if (str === "run") {
          if (selectedStatus === "CHECK_OUT") {
            setselectedState("CHECK_IN");
            // setProgress(50);
          } else if (selectedStatus === "CHECK_IN") {
            // setProgress(100);
            // updateTrackerStatus();
            setselectedState("ALL");
          } else if (selectedStatus === "NO_SHOW") {
            setProgress(100);
            setCurrentStep(4);
            updateTrackerStatus();
            setselectedState("ALL");
          }
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setmockLoaders(false);
      });
  };
  useEffect(() => {
    isNightAuditRun();
  }, [dates]);

  useEffect(() => {
    if (selectedStatus !== "ALL" && isNightAuditRunned === false) {
      getAllData();
    }
    //  else {
    //   setProgress(100);
    // }
  }, [selectedStatus, isNightAuditRunned, dates]);

  useEffect(() => {
    if (isNightAuditRunned) {
      setProgress(100);
      setCurrentStep(5);
      setselectedStatus("ALL");
    } else {
      setselectedStatus("CHECK_IN");
      setProgress(0);
      setCurrentStep(1);
    }
  }, [isNightAuditRunned]);

  const cellRenderer = (param, hotelId) => {
    let updatedBookingId = "";
    if (param?.value?.includes("SFBOOKING")) {
      updatedBookingId = param.value;
    } else {
      updatedBookingId =
        "SFBOOKING_" + String(hotelId) + "_" + String(param.value);
    }

    return (
      <Link
        to={{
          pathname: `/folio/${updatedBookingId}`,
          search: `?hotelId=${hotelId}`,
        }}
        target="_blank"
      >
        {param.value.split("_")[2]}
      </Link>
    );
  };

  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "Booking ID",
      cellRendererFramework: (params) => cellRenderer(params, hotelId),
      hide: false,
      dataType: "varchar",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "username",
      headerName: "Customer Name",
      hide: false,
      dataType: "varchar",
    },
    {
      field: "userEmail",
      headerName: "Email",
      hide: false,
      dataType: "varchar",
    },

    {
      field: "displayCheckin",
      headerName: "Check-in",
      hide: false,
      dataType: "date",
      dateFormat: "DD-MMM-YYYY hh A",
    },
    {
      field: "displayCheckout",
      headerName: "Check-out",
      // hide: props?.hotelType?.toLowerCase() === "isha" ? false : true,
      hide: false,
      dataType: "date",
      dateFormat: "DD-MMM-YYYY hh A",
    },

    {
      field: "bookingAmount",
      headerName: `Booking Amount (${hotelCurrency})`,
      valueFormatter: (params) => parseFloat(params.value).toFixed(2),
      dataType: "varchar",
    },
    {
      field: "balanceDue",
      headerName: `Balance due (${hotelCurrency})`,
      valueFormatter: (params) => parseFloat(params.value).toFixed(2),
      dataType: "varchar",
    },
    {
      field: "bookingStatus",
      headerName: "Booking Status",
      valueFormatter: (params) =>
        params.value === "ADMIN_CONFIRMED" ? "CHECKED IN" : params.value,
      dataType: "varchar",
      hide: false,
    },
  ]);

  const gridOptions = {
    readOnlyEdit: true,
    getRowId: (params) => params.data.id,
    pagination: true,
    rowSelection: "multiple",
    singleClickEdit: true,
  };

  const rowSelectionType = "multiple";

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // default column properties
  const defaultColDefs = {
    sortable: true, //sort the table
    floatingFilter: true, // getting the filter below the columns
    tooltipField: "name",
    filter: "agMultiColumnFilter", // setting the multi column filter for the table
    display: "subMenu", // how the text filter and the set filter will look like
    resizable: true,
  };

  // On selection of a row get its data
  const onSelectionChanged = (event) => {
    setSelectedRowsLength(event.api.getSelectedRows().length);
    setSelectedRows(event.api.getSelectedRows());
  };

  const isNightAuditRun = () => {
    const newDates = moment(dates).format("DD-MM-YYYY");
    handleSpringDataRequest(
      `core/api/v1/nightAudit/get-night-audit-tracker?currentDate=${
        nightAduitData
          ? moment(newDates, "DD-MM-YYYY").format("DD-MM-YYYY")
          : moment(dates).format("DD-MM-YYYY")
      }`
    )
      .then((res) => setisNightAuditRunned(res))
      .catch((err) => console.error(err));
  };

  const handleBulkCheckoutVoid = () => {
    let payload = {
      bookingsIds: selectedRows
        .filter((item) => item.balanceDue > 0)
        .map((e) => e.id),
      opType: "CHECK_OUT_VOID",
      voidType: voidType,
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/nightAudit/bulk-night-audit-operations`,
      payload
    )
      .then((res) => {
        toast.success("Operation Successfull");
        getAllData();
        handleCloseModal();
        setloader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setloader(false);
      });
  };

  const handleBulkOperations = () => {
    setloader(true);
    let ids;
    let payload;
    if (selectedState === "CASH_PAYMENT") {
      const bookins = selectedRows
        .filter((item) => item.balanceDue > 0)
        .map((e) => e.id);
      payload = {
        bookingsIds: bookins,
        opType: selectedState,
        voidType: null,
      };
    } else if (selectedState === "CHECK_OUT") {
      const bookins = selectedRows
        .filter((item) => item.balanceDue <= 0)
        .map((e) => e.id);
      payload = {
        bookingsIds: bookins,
        opType: selectedState,
        voidType: null,
      };
    } else {
      payload = {
        bookingsIds: selectedRows.map((e) => e.id),
        opType: selectedState,
        voidType:
          selectedState === "CANCEL_VOID" || selectedState === "NO_SHOW_VOID"
            ? voidType
            : null,
      };
    }
    const bids = selectedRows.filter((item) => item.balanceDue > 0);
    if (selectedState === "CHECK_OUT" && bids.length > 0) {
      handleBulkCheckoutVoid();
    }

    if (payload.bookingsIds.length > 0) {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/nightAudit/bulk-night-audit-operations`,
        payload
      )
        .then((res) => {
          getAllData("run");
          handleCloseModal();
          setloader(false);
        })
        .catch((err) => {
          toast.error("Something went wrong");
          setloader(false);
        });
    }
  };

  const [showLine, setshowLine] = useState(false);

  useEffect(() => {
    if (selectedState === "CHECK_OUT" && selectedRows?.length > 0) {
      const data = selectedRows?.filter((item) => item.balanceDue <= 0);
      setshowLine(data.length > 0 ? true : false);
    } else {
      setshowLine(false);
    }
  }, [selectedState, selectedRows]);

  const updateTrackerStatus = () => {
    const payload = {
      givenDate: nightAduitData
        ? moment(nightAduitData.audit_date, "DD MMM YYYY hh:mm A").format(
            "DD-MM-YYYY"
          )
        : moment(dates).format("DD-MM-YYYY"),
      hotelId: hotel.hotelId,
      status: "SUCCESS",
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/nightAudit/update-night-audit-status`,
      payload
    )
      .then((res) => {
        getAllNightAuditData();
        hide();
      })
      .catch((err) => {
        console.error(err);
        getAllNightAuditData();
        hide();
      });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={hide}
        dialogClassName={"myModalBody678"}
        // animation={false}
        contentClassName={"myModalContent678"}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <div
              className="d-flex align-items-center"
              style={{ height: "5rem" }}
            >
              <h2
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  marginRight: "2rem",
                }}
              >
                {`Run Night Audit  ${
                  nightAduitData !== undefined
                    ? dates.includes("AM")
                      ? ": " + moment(dates).format("DD-MM-YYYY")
                      : ": " +
                        moment(dates).subtract(1, "days").format("DD-MM-YYYY")
                    : ""
                } `}
              </h2>
              {(nightAduitData === undefined || nightAduitData === null) && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Night audit date"
                    inputFormat="MMM dd, yyyy"
                    value={dates}
                    onChange={(val) => setdates(val)}
                    renderInput={(params) => (
                      <TextField
                        disabled
                        size="small"
                        InputProps={{ readOnly: true }}
                        fullWidth
                        {...params}
                        sx={{ width: "35%" }}
                      />
                    )}
                    maxDate={
                      includeAM
                        ? new Date()
                        : new Date().setDate(new Date().getDate() - 1)
                    }
                  />
                </LocalizationProvider>
              )}
            </div>
            <Button variant="white" onClick={hide}>
              {" "}
              X{" "}
            </Button>
          </div>
          <p className="mb-3" style={{ fontSize: "15px", marginLeft: "2rem" }}>
            {" "}
            To run the night audit, follow the steps outlined below. Note that
            once an action is taken for a specific booking, it cannot be
            modified here. For settling a booking using the preferred payment
            mode, access the "Settle Due" option from the room folio and
            complete the payment process accordingly. After a successful night
            audit, it will display a "Success" status; otherwise, it will remain
            "Pending".f the night audit is scheduled to run in the AM, then
            during its execution for the present date, it will specifically
            process the audit for the preceding day.
          </p>
        </ModalTitle>
        <Divider />
        <ModalBody>
          {mockLoaders === false ? (
            <div className="d-flex flex-column w-100">
              {/* <LinearProgressWithLabel value={progress} /> */}
              <p className="text-muted mb-3" style={{ fontSize: "16px" }}>
                {selectedStatus === "CHECK_OUT"
                  ? "Pending departures indicates bookings that have been checked-in but do not have a corresponding checked-out marked. Please address these bookings to move forward to the next step."
                  : selectedStatus === "CHECK_IN"
                  ? "Pending arrivals refers to bookings where checked-in has not been marked even though the checked-out date has arrived.  Please address these bookings to move forward to the next step."
                  : "Pending checkins category includes bookings where check-ins have not been recorded and the checked-out date has not yet passed. Please take the necessary action to address these bookings and proceed accordingly."}
              </p>
              <ProgressBarWithCheckpoints
                steps={totalSteps}
                activeStep={currentStep}
              />
              <div
                className="d-flex justify-content-between w-100"
                style={{ marginBottom: "2rem" }}
              >
                {" "}
                {/* <FormControl fullWidth style={{ width: "15rem" }}>
                <InputLabel
                  style={{ width: "15rem" }}
                  id="demo-simple-select-label"
                >
                  Operation type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => setselectedStatus(e.target.value)}
                  style={{ marginLeft: "0rem", width: "15rem" }}
                  className="mr-4"
                  label="Operation type"
                  size="small"
                  value={selectedStatus}
                >
                  <MenuItem value={"CHECK_OUT"}>{"Pending Departure"}</MenuItem>
                  <MenuItem value={"CHECK_IN"}>{"Pending Arrival"}</MenuItem>
                  <MenuItem value={"NO_SHOW"}>{"No Show"}</MenuItem>
                </Select>
              </FormControl> */}
                <div className="d-flex">
                  {" "}
                  {selectedStatus === "CHECK_OUT" &&
                    nightAuditData?.length > 0 && (
                      <Button
                        variant="custom-button"
                        className="mr-3"
                        onClick={() => {
                          setselectedState("CHECK_OUT");
                          handleOpenModal();
                        }}
                      >
                        {" "}
                        Bulk checkout
                      </Button>
                    )}
                  {(selectedStatus === "CHECK_OUT" ||
                    selectedStatus === "BALANCE_DUES") &&
                    nightAuditData?.length > 0 &&
                    selectedRows !== null &&
                    selectedRows !== undefined &&
                    selectedRows.length > 0 &&
                    selectedRows?.filter((item) => item.balanceDue > 0).length >
                      0 && (
                      <Button
                        variant="custom-button"
                        className="mr-3"
                        onClick={() => {
                          setselectedState("CASH_PAYMENT");
                          handleOpenModal();
                        }}
                      >
                        {" "}
                        Bulk cash payment
                      </Button>
                    )}
                  {(selectedStatus === "CHECK_IN" ||
                    selectedStatus === "PENDING_CHECKINS") &&
                    nightAuditData?.length > 0 && (
                      <Button
                        variant="custom-button"
                        className="mr-3"
                        onClick={() => {
                          setselectedState("CANCEL_VOID");
                          handleOpenModal();
                        }}
                      >
                        {" "}
                        Bulk cancel
                      </Button>
                    )}
                  {selectedStatus === "PENDING_CHECKINS" &&
                    nightAuditData?.length > 0 && (
                      <Button
                        variant="custom-button"
                        className="mr-3"
                        onClick={() => {
                          setselectedState("CHECK_IN");
                          handleOpenModal();
                        }}
                      >
                        {" "}
                        Bulk Check-in
                      </Button>
                    )}
                  {selectedStatus === "PENDING_CHECKINS" &&
                    nightAuditData?.length > 0 && (
                      <Button
                        variant="custom-button"
                        className="mr-3"
                        onClick={() => {
                          setselectedState("NO_SHOW");
                          handleOpenModal();
                        }}
                      >
                        {" "}
                        Bulk no-show
                      </Button>
                    )}
                  {selectedStatus === "PENDING_CHECKINS" &&
                    nightAuditData?.length > 0 && (
                      <Button
                        variant="custom-button"
                        className="mr-3"
                        onClick={() => {
                          setProgress(100);
                          setCurrentStep(4);
                          setselectedStatus("ALL");
                          updateTrackerStatus();
                        }}
                      >
                        {" "}
                        Complete
                      </Button>
                    )}
                  {selectedStatus === "CHECK_IN" &&
                    nightAuditData?.length > 0 && (
                      <Button
                        variant="custom-button"
                        className="mr-3"
                        onClick={() => {
                          setselectedState("NO_SHOW_VOID");
                          handleOpenModal();
                        }}
                      >
                        {" "}
                        Bulk no-show void
                      </Button>
                    )}
                </div>
              </div>

              <div className="agTable">
                <div className="ag-theme-alpine" style={{ height: "100vh" }}>
                  {nightAuditData?.length > 0 ? (
                    <AgGridReact
                      className="agGridTable"
                      rowData={nightAuditData}
                      onGridReady={onGridReady}
                      columnDefs={columns}
                      defaultColDef={defaultColDefs}
                      enableBrowserTooltips={true}
                      onSelectionChanged={onSelectionChanged}
                      rowMultiSelectWithClick={true}
                      paginationPageSize={50}
                      alwaysShowBothConditions={true}
                      rowSelection={rowSelectionType}
                      // onCellEditingStopped={onCellEditRequest}
                      rowHeight={30.5}
                      // groupIncludeTotalFooter = {true}
                      sideBar={{
                        toolPanels: [
                          {
                            id: "columns",
                            labelDefault: "Columns",
                            labelKey: "columns",
                            iconKey: "columns",
                            toolPanel: "agColumnsToolPanel",
                          },
                        ],
                        defaultToolPanel: "none",
                      }}
                      groupMultiAutoColumn={true}
                      suppressAggFuncInHeader={true}
                      // groupRowAggNodes = {getTotalValue}
                      // suppressAggFilteredOnly = {true}
                      // getRowNodeId= {getRowNodeId}
                      statusBar={{
                        statusPanels: [
                          {
                            statusPanel: "agTotalAndFilteredRowCountComponent",
                            align: "left",
                          },
                          {
                            statusPanel: "agTotalRowCountComponent",
                            align: "center",
                          },
                          { statusPanel: "agFilteredRowCountComponent" },
                          { statusPanel: "agSelectedRowCountComponent" },
                          { statusPanel: "agAggregationComponent" },
                        ],
                      }}
                      gridOptions={gridOptions}
                    />
                  ) : (
                    <>
                      {selectedStatus === "CHECK_OUT" &&
                        selectedStatus !== "ALL" && (
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <h2 className="d-flex mr-3 mb-0">
                              There are no pending departure proceed for pending
                              balance dues.
                            </h2>
                            <Button
                              variant="custom-button"
                              style={{ marginTop: "2rem" }}
                              onClick={() => {
                                setProgress(66);
                                setCurrentStep(3);
                                setselectedStatus("BALANCE_DUES");
                              }}
                            >
                              {" "}
                              Proceed{" "}
                            </Button>
                          </div>
                        )}
                      {selectedStatus === "CHECK_IN" &&
                        selectedStatus !== "ALL" && (
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <h2 className="d-flex mr-3 mb-0">
                              There are no pending arrivals proceed for pending
                              departure
                            </h2>
                            <Button
                              variant="custom-button"
                              style={{ marginTop: "2rem" }}
                              onClick={() => {
                                setProgress(33);
                                setCurrentStep(2);
                                setselectedStatus("CHECK_OUT");
                              }}
                            >
                              {" "}
                              Proceed{" "}
                            </Button>
                          </div>
                        )}

                      {selectedStatus === "BALANCE_DUES" &&
                        selectedStatus !== "ALL" && (
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <h2 className="d-flex mr-3 mb-0">
                              There are no pending balance dues proceed for
                              completion of night audit.
                            </h2>
                            <Button
                              variant="custom-button"
                              style={{ marginTop: "2rem" }}
                              onClick={() => {
                                setProgress(100);
                                setCurrentStep(4);
                                setselectedStatus("ALL");
                                updateTrackerStatus();
                              }}
                            >
                              {" "}
                              Proceed{" "}
                            </Button>
                          </div>
                        )}
                      {selectedStatus === "NO_SHOW" &&
                        selectedStatus !== "ALL" && (
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <h2 className="d-flex mr-3 mb-0">
                              There are no pending no show proceed for
                              completion of night audit.
                            </h2>
                            <Button
                              variant="custom-button"
                              style={{ marginTop: "2rem" }}
                              onClick={() => {
                                setProgress(100);
                                setCurrentStep(4);
                                setselectedStatus("ALL");
                                updateTrackerStatus();
                              }}
                            >
                              {" "}
                              Proceed{" "}
                            </Button>
                          </div>
                        )}
                      {selectedStatus === "ALL" && (
                        <h2 className="d-flex align-items-center justify-content-center">
                          Congrats you have successfully run night audit.
                        </h2>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <MockLoaderTable />
          )}
        </ModalBody>
      </Modal>

      {/* Opening modal while marking the rooms bulk dirty and assigning staff */}
      <Modal show={openModal} onHide={handleCloseModal}>
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <h2 style={{ marginLeft: "2rem", marginTop: "1rem" }}>
              {selectedState === "CHECK_OUT" &&
                "Do you want to bulk checkout bookings"}
              {selectedState === "CHECK_IN" &&
                "Do you want to bulk check-in bookings"}
              {selectedState === "NO_SHOW" &&
                "Do you want to bulk mark no-show bookings"}
              {selectedState === "CANCEL_VOID" &&
                "Do you want to bulk cancel bookings"}
              {selectedState === "NO_SHOW_VOID" &&
                "Do you want to mark bulk no-show void bookings"}
              {selectedState === "CHECK_OUT_VOID" &&
                "Do you want to bulk checkout void bookings"}
              {selectedState === "CASH_PAYMENT" &&
                "Do you want to bulk take cash payment"}
            </h2>
            <Button variant="white" onClick={handleCloseModal}>
              {" "}
              X{" "}
            </Button>
          </div>
        </ModalTitle>
        <Divider />
        <ModalBody>
          <div>
            {selectedRows && (
              <>
                <p>
                  {selectedState === "CHECK_OUT"
                    ? "Bulk Cash Payment Settled against the below bookings."
                    : "Selected Booking Ids:"}
                </p>

                <div
                  className="chipsContainer"
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {selectedState === "CHECK_OUT" &&
                    selectedRows.map(
                      (item) =>
                        item.balanceDue <= 0 && (
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-evenly",
                              // marginRight: "5px",
                              marginTop: "5px",
                            }}
                          >
                            <Chip label={item.id} />
                          </div>
                        )
                    )}
                </div>
                <div>
                  {showLine &&
                    selectedRows?.filter((item) => item.balanceDue > 0).length >
                      0 && (
                      <div style={{ marginTop: "1rem" }}>
                        <Divider />
                        <p className="mt-3 mb-3">
                          To bulk check out against the below bookings take
                          appropriate steps from the void type box.
                        </p>
                      </div>
                    )}
                  {selectedState === "CHECK_OUT" &&
                    selectedRows?.filter((item) => item.balanceDue > 0).length >
                      0 && (
                      <div>
                        <div
                          className="chipsContainer"
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                            maxWidth: "100%",
                          }}
                        >
                          {selectedRows.map(
                            (item) =>
                              item.balanceDue > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-evenly",
                                    // marginRight: "5px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <Chip label={item.id} />
                                </div>
                              )
                          )}
                        </div>
                        <FormControl fullWidth style={{ marginTop: "1rem" }}>
                          <InputLabel id="demo-simple-select-label">
                            Void type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(e) => setvoidType(e.target.value)}
                            className="mr-4"
                            label="Void type"
                            size="small"
                            value={voidType}
                          >
                            <MenuItem value={"APPLY_REFUND"}>
                              {"Apply Cancellation Policy"}
                            </MenuItem>
                            <MenuItem value={"NO_VOID"}>
                              {"Don't void"}
                            </MenuItem>

                            <MenuItem value={"CHARGE_ONE_NIGHT"}>
                              {"Charge 1 night and void remaining"}
                            </MenuItem>
                            <MenuItem value={"VOID"}>
                              {"Void all remaining charges"}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    )}
                </div>
                <div>
                  <div
                    className="chipsContainer"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      maxWidth: "100%",
                    }}
                  >
                    {selectedState !== "CHECK_OUT" &&
                      selectedState !== "CASH_PAYMENT" &&
                      selectedRows.map((item) => (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                            // marginRight: "5px",
                            marginTop: "5px",
                          }}
                        >
                          <Chip label={item.id} />
                        </div>
                      ))}
                    {selectedState === "CASH_PAYMENT" &&
                      selectedRows.map(
                        (item) =>
                          item.balanceDue > 0 && (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-evenly",
                                // marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              <Chip label={item.id} />
                            </div>
                          )
                      )}
                  </div>
                </div>
                {(selectedState === "CANCEL_VOID" ||
                  selectedState === "NO_SHOW_VOID") && (
                  <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      Void type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setvoidType(e.target.value)}
                      className="mr-4"
                      label="Void type"
                      size="small"
                      value={voidType}
                    >
                      <MenuItem value={"APPLY_REFUND"}>
                        {"Apply Cancellation Policy"}
                      </MenuItem>
                      <MenuItem value={"NO_VOID"}>{"Don't void"}</MenuItem>

                      <MenuItem value={"CHARGE_ONE_NIGHT"}>
                        {"Charge 1 night and void remaining"}
                      </MenuItem>
                      <MenuItem value={"VOID"}>
                        {"Void all remaining charges"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </>
            )}

            <div
              className="buttons"
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "10px",
                borderTop: "1px solid #DDDDDD",
                paddingTop: "10px",
              }}
            >
              <Button variant="custom-button" onClick={handleCloseModal}>
                Close
              </Button>
              <Button
                variant="custom-button"
                disabled={
                  selectedState === "CASH_PAYMENT" &&
                  selectedRows !== null &&
                  selectedRows !== undefined &&
                  selectedRows.length > 0 &&
                  selectedRows?.filter((item) => item.balanceDue <= 0)
                    .length === selectedRows.length
                }
                onClick={handleBulkOperations}
              >
                {!loader ? "Confirm" : <CircularProgress size="15px" />}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default RunNightAuditModal;

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
