import React from "react";
import successAnimationData from "./celebration.json";
import Lottie from "react-lottie";
import { Box, Button, Typography } from "@mui/material";

export default function Success() {
  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleClick = () => {
    const redirectLink =
      "https://join.stayflexi.com/signup/?utm_source=custompricing";
    window.open(redirectLink, "_blank");

    // window.location.href = redirectLink;
  };
  return (
    <Box>
      <Lottie options={successAnimation} height={220} width={220} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" align="center" sx={{ color: "white" }}>
          Thank you! Your discounted quote is on its way, and our representative
          will contact you soon.
        </Typography>
        <Button sx={{ mt: 4 }} variant="custom-button" onClick={handleClick}>
          Start a free 14-day trial
        </Button>
      </Box>
    </Box>
  );
}
