/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import Routes from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import ErrorPage1 from "./modules/ErrorsExamples/ErrorPage1";
import { ErrorBoundary } from "react-error-boundary";
import { getAllImagesAndUrl } from "./pages/BookingDashboard/apis/BrandingModalApi";
import { QueryClient, QueryClientProvider } from "react-query";
import DashboardPage from "./pages/DashboardPage";

export default function App({ store, persistor, basename }) {
  //to register the firebase background notification service if the user enables notification
  const registerWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
  };
  const [imageData, setImageData] = useState();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    },
  });
  useEffect(async () => {
    registerWorker();
    await getAllData();
    document.title = window.location.hostname;
  }, []);

  const getAllData = async () => {
    try {
      const data = await getAllImagesAndUrl();
      setImageData(data);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary
            // FallbackComponent={ErrorPage1}
            FallbackComponent={DashboardPage}
            onError={() => {
              const urlParams = new URLSearchParams(window.location.search);
              const hotelId = urlParams.get("hotelId");
              window.location.href = `/dashboard?hotelId=${hotelId}`;
              // window.location.reload();
            }}
            onReset={() => {
              window.location.reload();
            }}
          >
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                <MaterialThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <AuthInit>
                      <Routes />
                    </AuthInit>
                  </I18nProvider>
                </MaterialThemeProvider>
              </BrowserRouter>
            </React.Suspense>
          </ErrorBoundary>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}
