import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PaymentBarChart from "./PaymentBarChart";
import PaymentAreaChart from "./PaymentAreaChart";
import { DateRange, DefinedRange } from "react-date-range";
import { red } from "@mui/material/colors";
import moment from "moment";
import { handleSpringDataRequest } from "../../../api";
import PaymentTab from "./PaymentTab";
import PaymentBar from "./PaymentBar";
import Currencies from "../../../Utils/Currencies.json";
import PaymentDashboardLoader from "./PaymentDashboardLoader";
import { connect } from "react-redux";
import "./PaymentGraphs.css";
import EventIcon from "@mui/icons-material/Event";

import { formatToday } from "../../GroupDashboard/Stats/util";
import { toast } from "material-react-toastify";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Dashboard = ({ fullScreen, data }) => {
  //---------------handelingDateForApi-------------------
  const handelingDateForApi = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  //-----------Access Control--------------
  const [accessPayment, setAccessPayment] = useState();
  useEffect(() => {
    setAccessPayment(JSON.parse(data?.PaymentAutomation));
    // console.log("access control hs", JSON.parse(data?.PaymentAutomation));
  }, []);

  //------------------Currency Symbol--------------------
  const [currency, setCurrency] = useState("");
  useEffect(() => {
    let currencySymbol = "";
    const res = Currencies?.map((item) => {
      if (item.code === localStorage.getItem("hotelCurrency")) {
        currencySymbol = item.symbol;
      }
    });
    setCurrency(currencySymbol);
  }, []);
  //------------------------Date Range Picker ----------------
  const lastFifteen = new Date();
  lastFifteen.setDate(lastFifteen.getDate() - 15);
  const today = new Date();
  today.setDate(today.getDate() + 0);

  const [dateRange, setDateRange] = useState([
    {
      startDate: lastFifteen,
      endDate: today,
      key: "selection",
    },
  ]);

  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };
  //----------------------------
  const toSentenceCase = (sentence) => {
    const word =
      sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
    return word.replace("_", " ");
  };
  // const hotelId = new URL(window.location.href)?.searchParams?.get("hotelId");
  // const [offlinePayment, setOfflinePayment] = useState([]);
  // const [onlinePayment, setOnlinePayment] = useState([]);
  const [payments, setPayments] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [reversals, setReversals] = useState([]);

  const [paymentDay, setPaymentDay] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [manualAuto, setManualAuto] = useState([]);
  const [gatewayDay, setGatewayDay] = useState([]);

  const [todayPayment, setTodayPayment] = useState([]);
  const [todayTotal, setTodayTotal] = useState();
  const [loader, setLoader] = useState(false);
  //--------------------------------------------------------
  function compareDates(a, b) {
    const dateA = new Date(
      a.name.split("-").reverse().join("-") // Convert dd-mm-yyyy to yyyy-mm-dd
    );
    const dateB = new Date(
      b.name.split("-").reverse().join("-") // Convert dd-mm-yyyy to yyyy-mm-dd
    );

    return dateA - dateB;
  }

  const handlePaymentDay = (data) => {
    const arr = [];
    Object.keys(data).map((elm) => {
      const myObj = { ...data[elm] };
      myObj.name = toSentenceCase(elm);
      myObj.Total = Number(myObj?.total?.toFixed(2));
      myObj.Amount = Number(myObj?.online?.toFixed(2));
      arr.push(myObj);
    });
    arr.sort(compareDates);
    setPaymentDay(arr);
    console.log("arr", arr);
  };

  const handlePaymentMethod = (data) => {
    const arr = [];
    Object.keys(data).map((elm) => {
      const myObj = {};
      myObj.name = toSentenceCase(elm);
      myObj.Payment = Number(data[elm].toFixed(2));
      arr.push(myObj);
    });
    arr.sort((a, b) => a?.Payment - b?.Payment);
    setPaymentType(arr);
  };

  const handleManualAuto = (data) => {
    const arr = [];
    Object.keys(data).map((elm) => {
      if (elm !== "GATEWAY") {
        const myObj = {};
        myObj.name = toSentenceCase(elm);
        myObj.Payment = Number(data[elm].toFixed(2));
        arr.push(myObj);
      }
    });
    setManualAuto(arr);
  };

  const handleTodayData = (data) => {
    const arr = [];
    Object.keys(data).map((elm) => {
      const myObj = {};
      myObj.name = toSentenceCase(elm);
      myObj.Payment = Number(data[elm].toFixed(2));
      arr.push(myObj);
    });
    setTodayPayment(arr);
  };
  //--------------------------------------------------------
  const PaymentDataAssignment = (data) => {
    console.log(data);
    // setOfflinePayment(data?.offlinePayList);
    // setOnlinePayment(data?.onlinePayList);
    setPayments(data?.payList);
    setRefunds(data?.refundList);
    setReversals(data?.reversalList);
    setTransfers(data?.transferList);

    // setPaymentType(data?.paymentMethodMap);
    handlePaymentMethod(data?.paymentMethodMap);
    // setManualAuto(data?.paymentTypeMap);
    handleManualAuto(data?.paymentTypeMap);

    handlePaymentDay(data?.totalPayDateMap);
    // setPaymentDay(data?.totalPayDateMap);
    // setGatewayDay(data?.totalPayDateMap);
  };

  const gettingPaymentData = () => {
    setLoader(true);
    handleSpringDataRequest(
      `core/api/v1/reports/get-range-payment-data?fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`
    )
      .then((data) => {
        setLoader(false);
        PaymentDataAssignment(data);
      })
      .catch((err) => setLoader(false));
  };

  const gettingTodayPaymentData = () => {
    handleSpringDataRequest(
      `core/api/v1/reports/get-today-payment-data?fromDate=${handelingDateForApi(
        new Date()
      )}&toDate=${handelingDateForApi(new Date())}`
    )
      .then((data) => {
        handleTodayData(data?.paymentMethodMap);
        setTodayTotal(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    gettingTodayPaymentData();
    gettingPaymentData();
  }, []);

  const dayscalculation = () => {
    const timeDiff = Math.abs(
      dateRange[0]?.endDate?.getTime() - dateRange[0]?.startDate?.getTime()
    );
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  };

  const getAllData = () => {
    if (dayscalculation() > 31) {
      toast.error("Selected date range can not more than one month");
      return;
    }
    gettingPaymentData();
  };
  return (
    <>
      {loader ? (
        <PaymentDashboardLoader />
      ) : (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h5">Today {formatToday()}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Card sx={{ height: 220 }}>
                <CardContent>
                  <PaymentAreaChart
                    sourceData={todayPayment}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card sx={{ height: 220 }}>
                <CardContent>
                  <Typography variant="h7" color="text.secondary">
                    Payments collected today
                  </Typography>
                  <Typography sx={{ fontSize: 18 }} gutterBottom>
                    {currency} {todayTotal?.totalPayments?.toFixed(2)}
                  </Typography>
                  <Typography variant="h7" color="text.secondary">
                    Payouts mode today
                  </Typography>
                  <Typography sx={{ fontSize: 18 }} gutterBottom>
                    {currency} {todayTotal?.totalTransfer?.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={5}>
              <Card sx={{ height: 220 }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#FAFAFA",
                    }}
                  >
                    <Tooltip title="Notifications" placement="right">
                      <NotificationsIcon
                        sx={{ color: "gray" }}
                        fontSize="large"
                      />
                    </Tooltip>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 145,
                    }}
                  >
                    {accessPayment?.read === "YES" ? (
                      <Typography align="center" fontSize={20}>
                        No notifications
                      </Typography>
                    ) : (
                      <Alert severity="warning">
                        {"Please enable the payments automation."}
                      </Alert>
                    )}
                  </Box>
                </CardContent>
              </Card>
              {/* <Card sx={{ mt: 1 }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "#6B7AA1" }} aria-label="recipe">
                      P
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title="Please enable the payments automation."
                  subheader="September 18, 2023"
                />
              </Card> */}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={4} className="position-relative" sx={{ mt: 1 }}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box sx={{ position: "relative" }}>
                    <Button
                      variant="outlined"
                      onClick={handleClick}
                      // fullWidth
                      sx={{ height: 36, color: "black", borderColor: "black" }}
                    >
                      {moment(dateRange[0].startDate).format("MMM DD, YYYY")}{" "}
                      {"   to   "}
                      {moment(dateRange[0].endDate).format("MMM DD, YYYY")}
                      <EventIcon sx={{ marginLeft: 1 }} />
                    </Button>
                    {open ? (
                      <Box sx={styles}>
                        <Stack spacing={3}>
                          <Stack
                            className="position-relative w-100 d-flex justify-content-between"
                            direction="row"
                            sx={{ justifyContent: "space-between" }}
                          >
                            <DefinedRange
                              onChange={(item) =>
                                setDateRange([item.selection])
                              }
                              ranges={dateRange}
                            />
                            <DateRange
                              editableDateInputs={true}
                              onChange={(item) =>
                                setDateRange([item.selection])
                              }
                              moveRangeOnFirstSelection={false}
                              ranges={dateRange}
                            />
                          </Stack>

                          <Stack
                            className="position-relative w-100 d-flex justify-content-between"
                            direction="row"
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => setOpen(false)}
                              color="error"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="custom-button"
                              onClick={(item) => {
                                getAllData();
                                handleClickAway();
                              }}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </Stack>
                      </Box>
                    ) : null}
                  </Box>
                </ClickAwayListener>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ background: "#FAFAFA", mt: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box className="stats">
                  <Typography align="center" variant="h6">
                    Payment by day
                  </Typography>
                  <Typography sx={{ color: "gray" }} align="center">
                    Volume of payments transacted by day for given date range,
                    this includes cash, card and various modes.
                  </Typography>
                  <PaymentBarChart
                    sourceData={paymentDay}
                    fullScreen={fullScreen}
                    currency={currency}
                    barXaxis={"Total"}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="stats">
                  <Typography align="center" variant="h6">
                    Payment by type
                  </Typography>
                  <Typography sx={{ color: "gray" }} align="center">
                    Volume of payments transacted by payment type for given date
                    range.
                  </Typography>
                  <PaymentBar
                    sourceData={paymentType}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="stats">
                  <Typography align="center" variant="h6">
                    Manual vs automated
                  </Typography>
                  <Typography sx={{ color: "gray" }} align="center">
                    Automated payments are all the transactions automatically
                    collected by system. This includes booking engine, flex
                    collect, payment link payments.
                  </Typography>
                  <PaymentBar
                    sourceData={manualAuto}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="stats">
                  <Typography align="center" variant="h6">
                    Gateway payments by day
                  </Typography>
                  <Typography sx={{ color: "gray" }} align="center">
                    All the payments happened over payment gateways like stripe,
                    razorpay etc.
                  </Typography>
                  <PaymentBarChart
                    sourceData={paymentDay}
                    fullScreen={fullScreen}
                    currency={currency}
                    barXaxis={"Amount"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <PaymentTab
              payments={payments}
              // offlinePayment={offlinePayment}
              // onlinePayment={onlinePayment}
              refunds={refunds}
              transfers={transfers}
              reversals={reversals}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel.accessControl.user_feature_map };
}
export default connect(mapStateToProps)(Dashboard);
