import React, { useEffect, useState } from "react";
import {
  djangoHandleDataRequests,
  handleSpringDataRequest,
} from "../../../../api/index";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Paper,
  Stack,
  styled,
  Tooltip,
  Typography,
  Switch,
  Button,
  FormControlLabel,
  Box,
  Chip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as AiIcons from "react-icons/ai";
import Currencies from "../../../../Utils/Currencies";
import { getBookingId } from "../../../../Utils/Utils";
import { connect } from "react-redux";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import FlashManagerReportLoader from "../FlashManagerReport/FlashManagerReportLoader";
import AuditReportDoc from "./AuditReportDoc";
import BootstrapTable from "react-bootstrap-table-next";
import ReportWrapper from "../../ReportWrapper";

import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import FlashManagerReportDoc from "../FlashManagerReport/FlashManagerReportDoc";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        // backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        backgroundImg: "linear-gradient(-150deg, #1ab394, #1a91ae)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function AuditReport({ data, hideIcon, configDate }) {
  // Getting the hotel data
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  //getting the hotel name from redux
  const hotelName = data?.hotelName;

  // Setting up the hotel currency
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;
  const [currency, setCurrency] = useState("");
  // Get the currency symbol
  const handleGetCurrency = () =>
    Currencies?.filter((item) => {
      if (item.code === hotelCurrency) {
        return setCurrency(item.symbol);
      }
    });

  useEffect(() => {
    handleGetCurrency();
  }, []);

  const [date, setDate] = useState(!hideIcon ? new Date() : configDate);

  const currentDate = !hideIcon
    ? moment().format("yyyy-MM-DD")
    : moment(configDate).format("yyyy-MM-DD");
  const [reportData, setReportData] = useState();
  const [reportDate, setReportDate] = useState(
    !hideIcon ? new Date() : configDate
  );
  const [formattedDate, setFormattedDate] = useState(currentDate);
  const [visibilityStatus, setVisibilityStatus] = useState(false);
  const [display, setDisplay] = useState("none");
  const [displayPrintReport, setDisplayPrintReport] = useState("none");
  const [address, setAddress] = useState({
    address1: data?.accessControl?.hotel?.hotel_address,
    address2: data?.accessControl?.hotel?.hotel_address_line2,
    city: data?.accessControl?.hotel?.hotel_city,
    state: data?.accessControl?.hotel?.hotel_state,
    country: data?.accessControl?.hotel?.hotel_country,
    pincode: data?.accessControl?.hotel?.hotel_zipcode,
  });

  useEffect(() => {
    const d = moment(reportDate).format("yyyy-MM-DD");
    setFormattedDate(d);
  }, [reportDate]);

  // setting the date
  const [selectionRange, setSelectionRange] = useState([
    {
      // startDate: moment().subtract(3, 'months'),
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Formatting the date while sending the data
  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const [flashManagerLoading, setFlashManagerLoading] = useState(false);

  // Find if we are getting the flash manager report for today or the past days
  const [isToday, setIsToday] = useState("true");
  useEffect(() => {
    if (moment().isSame(date)) {
      setIsToday("true");
    } else {
      setIsToday("false");
    }
  }, [date]);

  function getData() {
    setFlashManagerLoading(true);
    djangoHandleDataRequests(
      `reports/getReportData/?report_type=flashManagerReport&start_date=${formatDate(
        date
      )}&end_date=${formatDate(date)}&is_today=${isToday}`
    )
      .then((res) => {
        console.log("counter_flash_report", res?.report_data);
        setReportData(res.report_data);
      })
      .then((res) => setFlashManagerLoading(false));
    setVisibilityStatus(false);
  }

  function displayCalendar() {
    setVisibilityStatus(!visibilityStatus);
  }

  useEffect(() => {
    visibilityStatus ? setDisplay("block") : setDisplay("none");
  }, [visibilityStatus]);

  useEffect(() => {
    getData();
  }, [date]);

  // Defining the breadcrumbs
  const handleBreadcrumbClick = (event) => {
    event.preventDefault();
  };

  // Handling the report data
  const handleReportData = (val) => {
    if (val === null) {
      return 0;
    } else {
      if (currency.startsWith("Rs")) {
        return parseFloat(val).toFixed(2);
      } else {
        return parseFloat(val).toFixed(2);
      }
    }
  };

  // Handle room sold, etc.
  const handleReportInt = (val) => {
    if (val == null) {
      return 0;
    } else {
      return parseInt(val);
    }
  };

  const [base64Img, setBase64Img] = useState(null);
  const handleBase64Img = () => {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => setBase64Img(res))
      .catch((err) => toast.error("Error in fethcing the hotel logo!"));
  };

  useEffect(() => {
    handleBase64Img();
  }, []);

  // Summary view
  const [summaryView, setSummaryView] = useState(true);

  // Preparing data for
  // 1. property summary report
  const propSummData = [
    {
      metric: <b>Metric</b>,
      data: <b>{moment(date).format("YYYY-MM-DD")}</b>,
    },
    {
      metric: "Rooms sold",
      data: handleReportInt(reportData?.prop_flash_report[0]?.td),
    },
    {
      metric: "Occupancy (%)",
      data: handleReportData(reportData?.prop_flash_report[0]?.td_occupancy),
    },
    {
      metric: "ADR - Average daily rate",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.prop_flash_report[0]?.td_ADR)}
        </div>
      ),
    },
    {
      metric: "RevPAR - Revenue per available room",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.prop_flash_report[0]?.td_REVPAR)}
        </div>
      ),
    },
  ];
  // 2. Inventory Report
  const invSummData = [
    {
      metric: <b>Metric</b>,
      data: <b>Data</b>,
    },
    {
      metric: "Total rooms",
      data: parseInt(reportData?.inventory_flash_report?.total_rooms),
    },
    {
      metric: "Out of order rooms",
      data: parseInt(reportData?.inventory_flash_report?.disabled_rooms),
    },
    {
      metric: "Sold rooms",
      data: parseInt(reportData?.inventory_flash_report.booked_rooms),
    },
    {
      metric: "Available rooms",
      data: parseInt(reportData?.inventory_flash_report.available_rooms),
    },
  ];
  // 3. Room revenue report
  const roomRevSummReport = [
    {
      metric: <b>Metric</b>,
      data: <b>{moment(date).format("YYYY-MM-DD")}</b>,
    },
    {
      metric: "Room revenue",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.prop_flash_report[0]?.td_rev)}
        </div>
      ),
    },
    {
      metric: "POS revenue",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.pos_flash_report[0]?.td_amount)}
        </div>
      ),
    },
    {
      metric: "Services revenue",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.services_flash_report[0]?.td_amount)}
        </div>
      ),
    },
    {
      metric: "Booking fee ",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.prop_flash_report[0]?.td_bfee)}
        </div>
      ),
    },
    {
      metric: <b>Total</b>,
      data: (
        <b>
          {currency} {handleReportData(reportData?.total_rev.td_rev_total)}
        </b>
      ),
    },
  ];
  // 4. Tax revenue summary report
  const taxRevSumReport = [
    {
      metric: <b>Metric</b>,
      data: <b>{moment(date).format("YYYY-MM-DD")}</b>,
    },
    {
      metric: "Partial tax exempt revenue",
      data: (
        <div>
          {currency}{" "}
          {/* {handleReportData(
            reportData?.prop_flash_report[0]?.td_partial_texempt_rev
          )} */}
          {handleReportData(
            reportData?.prop_flash_report[0]?.td_partial_texmpt_rev
          )}
        </div>
      ),
    },
    {
      metric: "Total tax exempt revenue",
      data: (
        <div>
          {currency}{" "}
          {/* {handleReportData(
            reportData?.prop_flash_report[0]?.td_partial_texempt_rev
          )} */}
          {handleReportData(
            reportData?.prop_flash_report[0]?.td_total_texmpt_rev
          )}
        </div>
      ),
    },
    {
      metric: "Taxable revenue",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.prop_flash_report[0]?.td_txble_rev)}
        </div>
      ),
    },
    {
      metric: <b>Total</b>,
      data: (
        <b>
          {currency}{" "}
          {handleReportData(
            Number(reportData?.prop_flash_report[0]?.td_total_texmpt_rev) +
              Number(
                reportData?.prop_flash_report[0]?.td_partial_texmpt_rev +
                  Number(reportData?.prop_flash_report[0].td_txble_rev)
              )
          )}
        </b>
      ),
    },
  ];
  // 5. Tax summary report
  const taxSumReport = [
    {
      metric: <b>Metric</b>,
      data: <b>{moment(date).format("YYYY-MM-DD")}</b>,
    },
    {
      metric: "Room taxes",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.prop_flash_report[0]?.td_rev_tax)}
        </div>
      ),
    },
    {
      metric: "POS taxes",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(reportData?.pos_flash_report[0]?.td_amount_tax)}
        </div>
      ),
    },
    {
      metric: "Services taxes",
      data: (
        <div>
          {currency}{" "}
          {handleReportData(
            reportData?.services_flash_report[0]?.td_amount_tax
          )}
        </div>
      ),
    },
    {
      metric: <b>Total</b>,
      data: (
        <b>
          {currency} {handleReportData(reportData?.total_tax.td_tax_total)}
        </b>
      ),
    },
  ];

  // 6. Payment report summary - Implemented in the return function
  // 7. House report summary
  const houseSumReport = [
    {
      metric: <b>Metric</b>,
      data: <b>{moment(date).format("YYYY-MM-DD")}</b>,
    },
    {
      metric: "Today bookings",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.newbookings
      ),
    },
    {
      metric: "In house",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.inhouse
      ),
    },
    {
      metric: "Today arrivals",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.checkins
      ),
    },
    {
      metric: "Expected arrivals",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.confirmed
      ),
    },
    {
      metric: "Today departures",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.checkouts
      ),
    },
    {
      metric: "Pending departures",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.pendingcheckouts
      ),
    },
    {
      metric: "Cancellations",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.cancellations
      ),
    },
    {
      metric: "On hold",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.holds
      ),
    },
    {
      metric: "No shows",
      data: handleReportInt(
        reportData?.house_flash_report?.house_summary?.no_shows
      ),
    },
  ];

  // Getting all the detailed reports
  // 1. Room revene report detailed
  const [roomRevDetailed, setRoomRevDetailed] = useState(null);
  const handleRoomRevDetailedData = () =>
    djangoHandleDataRequests(
      `reports/getReportData/?report_type=roomRevenueReport&start_date=${formatDate(
        date
      )}&end_date=${formatDate(date)}&date_filter_mode=staythrough`
    ).then((res) => setRoomRevDetailed(res.report_data));

  useEffect(() => {
    handleRoomRevDetailedData();
  }, [date]);

  // Get total room revenue sum
  const getTotalRoomRevenueSum = () => {
    let sum = 0;
    const data = roomRevDetailed?.map((item) => (sum += item?.room_revenue));
    return Number(sum).toFixed(2);
  };

  // Get total room taxes sum
  const getTotalRoomTaxesSum = () => {
    let sum = 0;
    const data = roomRevDetailed?.map((item) => (sum += item?.room_taxes));
    return Number(sum).toFixed(2);
  };

  // Get total taxable revenue sum
  const getTotalTaxRevSum = () => {
    let sum = 0;
    const data = roomRevDetailed?.map((item) => (sum += item?.taxable_revenue));
    return Number(sum).toFixed(2);
  };

  // Get total tax exempt rev sum
  const getTotalTaxExeRevSum = () => {
    let sum = 0;
    const data = roomRevDetailed?.map(
      (item) => (sum += item?.tax_exempt_revenue)
    );
    return Number(sum).toFixed(2);
  };

  const roomRevCols = [
    {
      dataField: "bookingid_display",
      text: "Booking ID",
      footer: "Total",
      dataType: "varchar",
      printWidth: "8%",
    },
    {
      dataField: "user_name",
      text: "Customer name",
      footer: "-",
      dataType: "varchar",
      printWidth: "16%",
    },
    {
      dataField: "status",
      text: "Status",
      footer: "-",
      dataType: "varchar",
      printWidth: "16%",
    },
    {
      dataField: "room_revenue",
      text: `Room revenue (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.room_revenue).toFixed(2);
      },
      footer: getTotalRoomRevenueSum(),
      dataType: "float",
      printWidth: "13%",
    },
    {
      dataField: "room_taxes",
      text: `Room taxes (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.room_taxes).toFixed(2);
      },
      footer: getTotalRoomTaxesSum(),
      dataType: "float",
      printWidth: "13%",
    },
    {
      dataField: "taxable_revenue",
      text: `Taxable revenue (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.taxable_revenue).toFixed(2);
      },
      footer: getTotalTaxRevSum(),
      dataType: "float",
      printWidth: "13%",
    },
    {
      dataField: "tax_exempt_revenue",
      text: `Taxable exempt revenue (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.tax_exempt_revenue).toFixed(2);
      },
      footer: getTotalTaxExeRevSum(),
      dataType: "float",
      printWidth: "12%",
    },
    {
      dataField: "excess_exempt",
      text: `Excess exempt (${currency})`,
      footer: "-",
      dataType: "varchar",
      printWidth: "8%",
    },
  ];

  //2. Payment gateway report detailed
  const [pgDetailed, setPgDetailed] = useState(null);
  const handlePgReportData = () =>
    djangoHandleDataRequests(
      `reports/getReportData/?report_type=paymentsReport&start_date=${formatDate(
        date
      )}&end_date=${formatDate(date)}&showAll=${false}`
    ).then((res) => setPgDetailed(res.report_data));
  useEffect(() => {
    handlePgReportData();
  }, [date]);

  // Get total pg amount
  const getTotalPgAmt = () => {
    let sum = 0;
    const data = pgDetailed?.map((item) => (sum += item?.amount));
    return Number(sum).toFixed(2);
  };

  // Get total refund amount
  const getTotalRefAmt = () => {
    let sum = 0;
    const data = pgDetailed?.map((item) => (sum += item?.refund_amount));
    return Number(sum).toFixed(2);
  };

  // Get total net amount
  const getTotalNetAmt = () => {
    let sum = 0;
    const data = pgDetailed?.map((item) => (sum += item?.nett));
    return Number(sum).toFixed(2);
  };

  const pgCols = [
    {
      dataField: "captured_time",
      text: "Date",
      footer: "Total",
      dataType: "date",
      printWidth: "10%",
      // dateFormat:""
    },
    {
      dataField: "bookingid_display",
      text: "Booking ID",
      footer: "-",
      dataType: "varchar",
      printWidth: "8%",
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      footer: "-",
      dataType: "varchar",
      printWidth: "15%",
    },
    {
      dataField: "amount",
      text: `Amount (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.amount).toFixed(2);
      },
      footer: getTotalPgAmt(),
      dataType: "float",
      printWidth: "12%",
    },
    {
      dataField: "refund_amount",
      text: `Refund Amount (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.refund_amount).toFixed(2);
      },
      footer: getTotalRefAmt(),
      dataType: "float",
      printWidth: "12%",
    },
    {
      dataField: "nett",
      text: `Net Amount (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.nett).toFixed(2);
      },
      footer: getTotalNetAmt(),
      dataType: "float",
      printWidth: "12%",
    },
    {
      dataField: "gateway",
      text: "Gateway",
      footer: "-",
      dataType: "varchar",
      printWidth: "12%",
    },
    {
      dataField: "payment_status",
      text: "Payment Status",
      footer: "-",
      dataType: "varchar",
      printWidth: "15%",
    },
    {
      dataField: "payment_type",
      text: "Payment Type",
      footer: "-",
      dataType: "varchar",
      printWidth: "12%",
    },
    {
      dataField: "payment_issuer",
      text: "Payment Issuer",
      footer: "-",
      dataType: "varchar",
      printWidth: "12%",
    },
    {
      dataField: "card_last4",
      text: "Payment Card last 4",
      footer: "-",
      dataType: "varchar",
      printWidth: "10%",
    },
  ];

  // 3. Counter payment report
  const [detailedCounterPay, setDetailedCounterPay] = useState(null);
  const handleCounterPayData = () =>
    djangoHandleDataRequests(
      `reports/getReportData/?report_type=counterReport&start_date=${formatDate(
        date
      )}&end_date=${formatDate(date)}`
    ).then((res) => setDetailedCounterPay(res.report_data));
  useEffect(() => {
    handleCounterPayData();
  }, [date]);

  // Get total counter pay amount
  const getTotalCounterAmt = () => {
    let sum = 0;
    const data = detailedCounterPay?.map((item) => (sum += item.amount ?? 0.0));
    return Number(sum).toFixed(2);
  };

  // Get total counter cash refund
  const getTotalCounterCashRef = () => {
    let sum = 0;
    const data = detailedCounterPay?.map(
      (item) => (sum += item.cash_refund ?? 0.0)
    );
    return Number(sum).toFixed(2);
  };

  // Get total counter net amount
  const getTotalCounterNetAmt = () => {
    let sum = 0;
    const data = detailedCounterPay?.map(
      (item) => (sum += item.net_amount ?? 0.0)
    );
    return Number(sum).toFixed(2);
  };

  const counterPayCols = [
    {
      dataField: "creation_date",
      text: "Service Date",
      footer: "Total",
      dataType: "date",
      printWidth: "5%",
    },
    {
      dataField: "bookingid_display",
      text: "Booking ID",
      footer: "-",
      dataType: "varchar",
      printWidth: "8%",
    },
    {
      dataField: "service_description",
      text: "Payment Type",
      footer: "-",
      dataType: "varchar",
      printWidth: "15%",
    },
    {
      dataField: "amount",
      text: `Amount (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.amount).toFixed(2);
      },
      footer: getTotalCounterAmt(),
      dataType: "float",
      printWidth: "12%",
    },
    {
      dataField: "cash_refund",
      text: `Refund (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.cash_refund).toFixed(2);
      },
      footer: getTotalCounterCashRef(),
      dataType: "float",
      printWidth: "12%",
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      footer: "-",
      dataType: "varchar",
      printWidth: "15%",
    },
    {
      dataField: "service_amount_desc",
      text: "Service Amount Description",
      footer: "-",
      dataType: "varchar",
      printWidth: "15%",
    },
    {
      dataField: "net_amount",
      text: `Net Amount (${currency})`,
      formatter: (cell, row) => {
        return Number(row?.net_amount).toFixed(2);
      },
      footer: getTotalCounterNetAmt(),
      dataType: "float",
      printWidth: "15%",
    },
    {
      dataField: "user_email",
      text: "Action By",
      footer: "-",
      formatter: (cell, row) => {
        return (
          <Typography style={{ position: "relative", wordBreak: "break-word" }}>
            {cell}
          </Typography>
        );
      },
      dataType: "varchar",
      printWidth: "18%",
    },
  ];

  // 4. House detailed report
  // Data prep for house reports
  const houseReportTypes = [
    {
      name: "ADMIN_CONFIRMED",
      display_name: "In-house",
    },
    {
      name: "CANCELLED",
      display_name: "Cancellations",
    },
    {
      name: "CHECKINS",
      display_name: "Arrivals",
    },
    {
      name: "CHECKOUTS",
      display_name: "Departures",
    },
    {
      name: "NEW_BOOKINGS",
      display_name: "New bookings",
    },
    {
      name: "NO_SHOW",
      display_name: "No shows",
    },
    {
      name: "ON_HOLD",
      display_name: "On hold",
    },
  ];
  const [houseReport, setHouseReport] = useState(null);
  const [initialHouseReport, setInitialHouseReport] = useState(null);
  const handleHouseReportData = () =>
    djangoHandleDataRequests(
      `reports/generateDashDataLite/?date=${moment(date).format(
        "ddd MMM DD YYYY"
      )}&is_today=${moment(formattedDate)
        .isSame(moment(moment().toDate()).format("ddd MMM DD YYYY"))
        .toString()}`
    )
      // .then((res) => {
      //   setInitialHouseReport(res);
      // })
      .then((res) => setHouseReport(res))
      // .then((data) => formatHouseReport(data))
      // .then((data) => console.log("house report : ", data))
      .catch((err) => toast.error("Sorry cannot fetch the data!"));

  useEffect(() => {
    handleHouseReportData();
  }, [date]);

  console.log("house report : ", houseReport);

  // Format the data
  // function formatHouseReport(initialHouseReport) {
  //   return Object.values(initialHouseReport).forEach((type) => {
  //     console.log("type : ", type);
  //     type.forEach((booking) => {
  //       if (booking.hasOwnProperty("booking_id")) {
  //         let tempId = booking.booking_id.split("_");
  //         console.log("temp id : ", tempId);
  //         tempId = tempId[2];
  //         booking.booking_id = tempId;
  //       }
  //     });
  //   });
  // }

  const houseReportCols = [
    {
      dataField: "booking_id",
      text: "Booking Id",
      formatter: (cell) => (
        <Typography style={{ wordWrap: "break-word" }}> {cell} </Typography>
      ),
      dataType: "varchar",
      printWidth: "8%",
    },
    {
      dataField: "room_ids",
      text: "Room No.(s)",
      dataType: "vachar",
      printWidth: "10%",
    },
    {
      dataField: "booking_amount",
      text: "Booking amt.",
      dataType: "varchar",
      printWidth: "10%",
    },
    {
      dataField: "booking_made_on",
      text: "Booking made on",
      dataType: "date",
      dateFormat: "MMM DD, YYYY HH:mm A",
      printWidth: "8%",
    },
    {
      dataField: "booking_user_name",
      text: "Customer name",
      dataType: "varchar",
      printWidth: "15%",
    },
    {
      dataField: "booking_user_phone",
      text: "Customer phone",
      dataType: "vachar",
      printWidth: "15%",
    },
    {
      dataField: "reservation_status",
      text: "Status",
      dataType: "vachar",
      printWidth: "12%",
    },
    {
      dataField: "booking_source",
      text: "Booking source",
      dataType: "vachar",
      printWidth: "15%",
    },
    {
      dataField: "cin",
      text: "Check-In",
      dataType: "date",
      dateFormat: "MMM DD, YYYY HH:mm A",
      printWidth: "8%",
    },
    {
      dataField: "cout",
      text: "Check-out",
      dataType: "date",
      dateFormat: "MMM DD, YYYY HH:mm A",
      printWidth: "8%",
    },
  ];

  // Meal plan report
  const [mealPlanReport, setMealPlanReport] = useState(null);
  const handleGetMealPlanReport = async () => {
    try {
      const res = await handleSpringDataRequest(
        `core/api/v1/reports/get-pax-and-meal-data/?date=${formatDate(date)}`
      );
      setMealPlanReport(res);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    handleGetMealPlanReport();
  }, [date]);

  // Define the cell style
  const cellStyle = (cell, row, rowIndex, colIndex) => {
    return {
      fontSize: "10px",
      wordBreak: "break-word",
    };
  };

  return (
    <ReportWrapper
      showReportTypes={false}
      hideDrawer={true}
      backBtn={true}
      reportName={"Night audit report"}
      hideIcon={hideIcon}
      filters={
        <Stack direction={"row"} spacing={2}>
          {!hideIcon && (
            <Stack sx={{ marginTop: "auto", marginBottom: "auto" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Select date"
                  // inputFormat="yyyy-MM-dd"
                  inputFormat="dd MMM yyyy"
                  value={date}
                  onChange={(item) => setDate(item)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </Stack>
          )}
          <Stack>
            <Tooltip title="Click on the button to toggle between summary view and the detailed view">
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={summaryView}
                    onChange={(e) => setSummaryView(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Summary view"
              />
            </Tooltip>
          </Stack>
          {reportData && (
            <PDFDownloadLink
              document={
                <Document
                  title={`${`auditReport_${hotelId}_${moment().format(
                    "DD-MM-YY"
                  )}`}.pdf`}
                >
                  <Page size="A4" style={styles.page}>
                    {summaryView ? (
                      <AuditReportDoc
                        symbol={currency}
                        reportData={reportData}
                        hotelName={hotelName}
                        reportDate={moment(date).format("MMM DD YYYY")}
                        hotelLogo={base64Img ? base64Img : null}
                        address={address}
                        mealPlanReport={mealPlanReport}
                      />
                    ) : (
                      <AuditReportDoc
                        symbol={currency}
                        reportData={reportData}
                        hotelName={hotelName}
                        reportDate={moment(date).format("MMM DD YYYY")}
                        hotelLogo={base64Img ? base64Img : null}
                        address={address}
                        houseReportTypes={houseReportTypes}
                        houseReport={houseReport}
                        roomRevReport={roomRevDetailed}
                        roomRevCols={roomRevCols}
                        pgReport={pgDetailed}
                        pgCols={pgCols}
                        counterPayReport={detailedCounterPay}
                        counterPayCols={counterPayCols}
                        summaryView={summaryView}
                        houseReportCols={houseReportCols}
                        mealPlanReport={mealPlanReport}
                      />
                    )}
                  </Page>
                </Document>
              }
              fileName={`${`auditReport_${hotelId}_${moment().format(
                "DD-MM-YY"
              )}`}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <p style={{ fontSize: "0.8rem" }}>Loading document...</p>
                ) : (
                  <a
                    href={url}
                    style={{ fontSize: "0.8rem" }}
                    rel={"noreferrer"}
                    target="_blank"
                    download={"Audit Report"}
                  >
                    {window.screen.width > 416 && (
                      <div
                        style={{
                          color: "black",
                          backgroundColor: "transparent",
                          border: "none",
                          fontSize: "15px",
                        }}
                      >
                        <Tooltip title="Print audit report">
                          <IconButton>
                            <AiIcons.AiFillPrinter
                              size="27.5px"
                              color="black"
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </a>
                )
              }
            </PDFDownloadLink>
          )}
        </Stack>
      }
      contentArea={
        <div
          className="flashManagerReportWrapper"
          style={{ marginTop: "15px" }}
        >
          {!flashManagerLoading ? (
            <div className="flashManagerReportData" id="auditReport">
              {/* Property Report and Inventory Report */}
              <Stack>
                <Card style={{ backgroundColor: "transparent" }}>
                  <CardContent style={{ backgroundColor: "transparent" }}>
                    <Stack
                      direction="row"
                      className="position-relative w-100 justify-content-between"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "49.5% 49.5%",
                      }}
                    >
                      <Card sx={{ position: "relative", width: "100%" }}>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Property Report
                          </Typography>
                          <Divider />
                          <Stack spacing={2} className="position-relative mt-4">
                            {propSummData &&
                              propSummData.map((item) => (
                                <Stack
                                  direction="row"
                                  className="position-relative w-100 justify-content-between"
                                >
                                  <Typography>{item.metric}</Typography>
                                  <Typography>{item.data}</Typography>
                                </Stack>
                              ))}
                          </Stack>
                        </CardContent>
                      </Card>
                      <Card sx={{ position: "relative", width: "100%" }}>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Inventory Report
                          </Typography>
                          <Divider />
                          <Stack spacing={2} className="position-relative mt-4">
                            {invSummData &&
                              invSummData.map((item) => (
                                <Stack
                                  direction="row"
                                  className="position-relative w-100 justify-content-between"
                                >
                                  <Typography>{item.metric}</Typography>
                                  <Typography>{item.data}</Typography>
                                </Stack>
                              ))}
                          </Stack>
                        </CardContent>
                      </Card>
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>

              {/* Room revenue report / Tax revenue report / Tax report */}
              <Stack className="position-relative w-100 mt-10">
                <Card sx={{ padding: "5px" }}>
                  <CardContent>
                    <Stack
                      direction="row"
                      // spacing={1}
                      className="position-relative w-100 justify-content-between"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "33% 33% 33%",
                      }}
                    >
                      <Card sx={{ position: "relative", width: "100%" }}>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Room revenue report
                          </Typography>
                          <Divider />
                          <Stack spacing={2} className="position-relative mt-4">
                            {roomRevSummReport &&
                              roomRevSummReport.map((item) => (
                                <Stack
                                  direction="row"
                                  className="position-relative w-100 justify-content-between"
                                >
                                  <Typography>{item.metric}</Typography>
                                  <Typography>{item.data}</Typography>
                                </Stack>
                              ))}
                          </Stack>
                        </CardContent>
                      </Card>
                      <Card sx={{ position: "relative", width: "100%" }}>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Tax revenue report
                          </Typography>
                          <Divider />
                          <Stack spacing={2} className="position-relative mt-4">
                            {taxRevSumReport &&
                              taxRevSumReport.map((item) => (
                                <Stack
                                  direction="row"
                                  className="position-relative w-100 justify-content-between"
                                >
                                  <Typography>{item.metric}</Typography>
                                  <Typography>{item.data}</Typography>
                                </Stack>
                              ))}
                          </Stack>
                        </CardContent>
                      </Card>
                      <Card sx={{ position: "relative", width: "100%" }}>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Tax report
                          </Typography>
                          <Divider />
                          <Stack spacing={2} className="position-relative mt-4">
                            {taxSumReport &&
                              taxSumReport.map((item) => (
                                <Stack
                                  direction="row"
                                  className="position-relative w-100 justify-content-between"
                                >
                                  <Typography>{item.metric}</Typography>
                                  <Typography>{item.data}</Typography>
                                </Stack>
                              ))}
                          </Stack>
                        </CardContent>
                      </Card>
                    </Stack>
                    {!summaryView && roomRevDetailed && (
                      <Card sx={{ position: "relative", marginTop: "10px" }}>
                        <CardContent>
                          <Typography style={{ color: "#B5B5C3" }}>
                            Detailed room revenue report
                          </Typography>
                          <BootstrapTable
                            keyField="bookingid_display"
                            data={roomRevDetailed}
                            columns={roomRevCols}
                            noDataIndication="No data found"
                          />
                        </CardContent>
                      </Card>
                    )}
                  </CardContent>
                </Card>
              </Stack>

              {/* Payments Report */}
              <Stack className="position-relative mt-10">
                <Card>
                  <CardContent>
                    <Stack>
                      <Card>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Payments Report
                          </Typography>
                          <Divider />
                          <Stack spacing={2}>
                            <Stack
                              direction="row"
                              className="position-relative w-100 justify-content-between mt-5"
                            >
                              <Typography>
                                <b>Metric</b>
                              </Typography>
                              <Typography>
                                <b>{moment(date).format("YYYY-MM-DD")}</b>
                              </Typography>
                            </Stack>
                            {reportData?.counter_flash_report.length > 0 &&
                              reportData?.counter_flash_report
                                .filter(
                                  (elm) =>
                                    elm?.service_description !==
                                    "Direct Billing"
                                )
                                ?.map((item) => (
                                  <Stack
                                    direction="row"
                                    className="position-relative w-100 justify-content-between"
                                  >
                                    <Typography>
                                      {item.service_description ===
                                      "Offline Check Payment"
                                        ? "Offline Cheque Payment"
                                        : item.service_description}
                                    </Typography>
                                    <Typography className="alignCols">
                                      {currency}{" "}
                                      {handleReportData(item.td_amount)}
                                    </Typography>
                                  </Stack>
                                ))}
                            {reportData?.pg_payments_flash_report.length > 0 &&
                              reportData?.pg_payments_flash_report.map(
                                (item, idx) => (
                                  <Stack
                                    direction="row"
                                    className="position-relative w-100 justify-content-between"
                                  >
                                    <Typography>{item.payment_type}</Typography>
                                    <Typography>
                                      {currency}{" "}
                                      {handleReportData(item.td_amount)}
                                    </Typography>
                                  </Stack>
                                )
                              )}
                            <Stack
                              direction="row"
                              className="position-relative w-100 justify-content-between"
                            >
                              <Typography style={{ fontWeight: 500 }}>
                                Total
                              </Typography>
                              <Typography
                                className="alignCols"
                                style={{ fontWeight: 500 }}
                              >
                                {currency}{" "}
                                {handleReportData(
                                  reportData?.total_payments.td_payments_total -
                                    (reportData?.counter_flash_report?.filter(
                                      (elm) =>
                                        elm?.service_description ===
                                        "Direct Billing"
                                    )[0]?.td_amount || 0)
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Stack>
                    {!summaryView && pgDetailed && (
                      <Card sx={{ position: "relative", marginTop: "10px" }}>
                        <CardContent>
                          <Typography style={{ color: "#B5B5C3" }}>
                            Detailed payment gateway report
                          </Typography>
                          <BootstrapTable
                            keyField="bookingid_display"
                            data={pgDetailed}
                            columns={pgCols}
                            noDataIndication="No data found"
                          />
                        </CardContent>
                      </Card>
                    )}
                    {!summaryView && detailedCounterPay && (
                      <Card sx={{ position: "relative", marginTop: "10px" }}>
                        <CardContent>
                          <Typography style={{ color: "#B5B5C3" }}>
                            Detailed counter payment report
                          </Typography>
                          <BootstrapTable
                            keyField="bookingid_display"
                            data={detailedCounterPay}
                            columns={counterPayCols}
                            noDataIndication="No data found"
                          />
                        </CardContent>
                      </Card>
                    )}
                  </CardContent>
                </Card>
              </Stack>

              {/* Direct Billing Report */}
              <Stack className="position-relative mt-10">
                <Card>
                  <CardContent>
                    <Stack>
                      <Card>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Direct Billing Report
                          </Typography>
                          <Divider />
                          <Stack spacing={2}>
                            <Stack
                              direction="row"
                              className="position-relative w-100 justify-content-between mt-5"
                            >
                              <Typography>
                                <b>Metric</b>
                              </Typography>
                              <Typography>
                                <b>{moment(date).format("YYYY-MM-DD")}</b>
                              </Typography>
                            </Stack>
                            {reportData?.counter_flash_report
                              .filter(
                                (elm) =>
                                  elm?.service_description === "Direct Billing"
                              )
                              ?.map((item) => (
                                <Stack
                                  direction="row"
                                  className="position-relative w-100 justify-content-between"
                                >
                                  <Typography>
                                    {item.service_description}
                                  </Typography>
                                  <Typography className="alignCols">
                                    {currency}{" "}
                                    {handleReportData(item.td_amount)}
                                  </Typography>
                                </Stack>
                              ))}

                            <Stack
                              direction="row"
                              className="position-relative w-100 justify-content-between"
                            >
                              <Typography style={{ fontWeight: 500 }}>
                                Total
                              </Typography>
                              <Typography
                                className="alignCols"
                                style={{ fontWeight: 500 }}
                              >
                                {currency}{" "}
                                {handleReportData(
                                  reportData?.counter_flash_report.filter(
                                    (elm) =>
                                      elm?.service_description ===
                                      "Direct Billing"
                                  )[0]?.td_amount || 0
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Stack>
                    {/* {!summaryView && pgDetailed && (
                      <Card sx={{ position: "relative", marginTop: "10px" }}>
                        <CardContent>
                          <Typography style={{ color: "#B5B5C3" }}>
                            Detailed payment gateway report
                          </Typography>
                          <BootstrapTable
                            keyField="bookingid_display"
                            data={pgDetailed}
                            columns={pgCols}
                            noDataIndication="No data found"
                          />
                        </CardContent>
                      </Card>
                    )}
                    {!summaryView && detailedCounterPay && (
                      <Card sx={{ position: "relative", marginTop: "10px" }}>
                        <CardContent>
                          <Typography style={{ color: "#B5B5C3" }}>
                            Detailed counter payment report
                          </Typography>
                          <BootstrapTable
                            keyField="bookingid_display"
                            data={detailedCounterPay}
                            columns={counterPayCols}
                            noDataIndication="No data found"
                          />
                        </CardContent>
                      </Card>
                    )} */}
                  </CardContent>
                </Card>
              </Stack>

              {/* House Report */}
              <Stack className="position-relative mt-10">
                <Card>
                  <CardContent>
                    <Card>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          House report
                        </Typography>
                        <Divider />
                        <Stack spacing={2} className="position-relative mt-4">
                          {houseSumReport &&
                            houseSumReport.map((item) => (
                              <Stack
                                direction="row"
                                className="position-relative w-100 justify-content-between"
                              >
                                <Typography>{item.metric}</Typography>
                                <Typography>{item.data}</Typography>
                              </Stack>
                            ))}
                        </Stack>
                      </CardContent>
                    </Card>
                    <Stack>
                      {!summaryView &&
                        houseReport &&
                        houseReportTypes.map((item, index) => (
                          <Card
                            sx={{ position: "relative", marginTop: "10px" }}
                            key={index}
                          >
                            <CardContent>
                              <Typography style={{ color: "#B5B5C3" }}>
                                Detailed {item?.display_name} report
                              </Typography>
                              <BootstrapTable
                                keyField="bookingid_display"
                                data={houseReport[item?.name]}
                                columns={houseReportCols}
                                noDataIndication="No data found"
                                cellStyle={cellStyle}
                              />
                            </CardContent>
                          </Card>
                        ))}
                    </Stack>
                  </CardContent>
                </Card>

                {/* Meal plan report */}
                {mealPlanReport && (
                  <div className="fmSubReport">
                    {/* <div className="multipleReports"> */}
                    <Card>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Rate plan report
                        </Typography>
                        <Divider />
                        <div className="propData">
                          <Stack spacing={2}>
                            <Typography>
                              Total guest count :{" "}
                              {mealPlanReport?.adults +
                                mealPlanReport?.children}
                            </Typography>
                            <Stack direction="row" spacing={2}>
                              <Typography>
                                Total adults : {mealPlanReport?.adults}
                              </Typography>
                              <Typography>
                                Total children : {mealPlanReport?.children}
                              </Typography>
                            </Stack>
                            <Stack
                              style={{
                                position: "relative",
                                width: "100%",
                                marginTop: "10px",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {mealPlanReport?.mealPlanList
                                ?.map(
                                  (meals) => `${meals?.name} (${meals?.count})`
                                )
                                .join(", ")}
                            </Stack>
                          </Stack>
                        </div>{" "}
                      </CardContent>
                    </Card>
                    {/* </div> */}
                  </div>
                )}
              </Stack>
            </div>
          ) : (
            <FlashManagerReportLoader />
          )}
        </div>
      }
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}

export default connect(mapStateToProps)(AuditReport);
