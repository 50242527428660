import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { HorizontalRule } from "@mui/icons-material";
import moment from "moment";
import {
  dateFormat,
  channelLogoMap,
} from "../../CalendarPage/constants/Calendar-constants";

function UnAssignRoom(props) {
  const [unassigned, setunAssigned] = useState([]);

  const [loader, setloader] = useState(false);

  const save = (id) => {
    setloader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/unassignReservation?reservationId=${id}`
    )
      .then((res) => (props.isGrid ? props.reloadGrid() : ""))
      .then((res) => props.updateInvoice())
      .then((res) => props.updateSummaryData())

      .then((res) =>
        toast.success(`Successfully unassigned room  for ${props.bookingId}`)
      )
      .then((res) => invalidateNightAuditReservation(id))
      .then((res) => setloader(false))
      .catch((err) => toast.error("Something went wrong"))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getUnassignedData();
  }, []);

  const getUnassignedData = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/assigned-res-list?bookingId=${props.bookingId}`
    )
      .then((res) => {
        console.log(res);
        setunAssigned(res);
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  const invalidateNightAuditReservation = (id) => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/invalidate-night-audit-tracker-reservation?resId=${id}`
    )
      .then((res) => console.log())
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <div id="external-events">
        {unassigned.length === 0 ? (
          <h2 style={{ marginLeft: "14rem", marginTop: "2rem" }}>
            All rooms are already unassigned
          </h2>
        ) : (
          unassigned.map((resrv) => (
            <Card
              key={resrv.reservationId}
              variant="outlined"
              sx={{ mb: 1 }}
              style={{
                marginTop: "1rem",
                marginLeft: "2rem",
                marginRight: "2rem",
              }}
              className="fc-event"
            >
              <CardHeader
                sx={{ pb: 1 }}
                title={resrv?.name}
                action={
                  <Grid className="d-flex align-items-center">
                    <Button
                      variant="custom-button"
                      onClick={() => {
                        save(resrv.reservationId);
                      }}
                    >
                      Unassign
                    </Button>
                  </Grid>
                }
              ></CardHeader>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item container>
                    <div className="d-flex flex-column">
                      <Typography>{`${resrv.bookingId}`}</Typography>
                      <Typography>{`Room Id - ${resrv.roomId}`}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))
        )}
      </div>
    </div>
  );
}

export default UnAssignRoom;
