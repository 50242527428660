import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { toast } from "material-react-toastify";
import MockLoaderTable from "./MockLoaderTable";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import cellEditFactory from "react-bootstrap-table2-editor";

function IshaGpms({
  data,
  bookingId,
  hotelId,
  updateInvoice,
  updateSummaryData,
}) {
  const [gpmsData, setgpmsData] = useState([]);
  const [successRefund, setsuccessRefund] = useState([]);
  const [loader, setloader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addManualEntry, setAddManualEntry] = useState(false);
  const [row, setRow] = useState({});
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const columns = [
    {
      dataField: "pg_charge_id",
      text: "Payment Charge ID",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      style: {
        wordBreak: "break-all",
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "payment_amount",
      text: "Payment Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },

    {
      dataField: "refund_amount",
      text: "Refund Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },

      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: "500",
          textDecoration: "underline",
        };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        return true;
      },
    },

    {
      dataField: "cancel_charge",
      text: "Cancel Charge",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },

      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: "500",
          textDecoration: "underline",
        };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        return true;
      },
    },

    {
      dataField: "refund_date",
      text: "Refund Date",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "refund_status",
      text: "Refund Status",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },

    {
      dataField: "id",
      text: " ",
      sort: false,
      headerStyle: {
        fontSize: "15px",
      },
      formatter: (cell, row) => {
        return (
          row.refund_status === "PENDING" && (
            <Button
              variant="custom-button"
              onClick={() => {
                setRow(row);
                setOpenModal(true);
              }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Refund"
              )}
            </Button>
          )
        );
      },
      editable: false,
    },
  ];

  const successcolumns = [
    {
      dataField: "pg_charge_id",
      text: "Payment Refund ID",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      style: {
        wordBreak: "break-all",
        fontSize: "15px",
      },
      editable: false,
    },

    {
      dataField: "refund_amount",
      text: "Refund Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },

      editable: false,
    },

    {
      dataField: "refund_date",
      text: "Refund Date",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "refund_status",
      text: "Refund Status",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
  ];

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    handleSpringDataRequest(
      `core/api/v1/payment-service/get-gpms-pending-refunds?bookingId=${bookingId}`
    )
      .then((res) => {
        setgpmsData(res.pendingRefunds);
        setsuccessRefund(res.otherRefunds);
      })
      .catch((err) => toast.error("Error in fetching the data"));
  };

  const procesRefund = () => {
    const payload = {
      pendingRefundId: row?.id,
      hotelId: hotelId,
      bookingId: bookingId,
      sfPaymentId: row?.sf_payment_id,
      pgChargeId: row?.pg_charge_id,
      refundAmt: row?.refund_amount,
      cancelFee: row?.cancel_charge,
      reservationId: row.reservation_id,
    };
    setloader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/payment-service/process-gpms-pending-refund`,
      payload
    )
      .then((res) => {
        toast.success("Operation successfull");
        getAllData();
        updateInvoice();
        updateSummaryData();
        setloader(false);
        setOpenModal(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        getAllData();
        setloader(false);
        setOpenModal(false);
      });
  };

  const handleCloseAddManualEntry = () => setAddManualEntry(false);
  const handleOpenAddManualEntry = () => setAddManualEntry(true);
  const [manualRefundAmt, setManualRefundAmt] = useState(0.0);
  const [manualRefundDesc, setManualRefundDesc] = useState("");
  const [loadingManualRefund, setLoadingManualRefund] = useState(false);
  const handleAddManualRefund = async () => {
    try {
      setLoadingManualRefund(true);
      const res = await handleSpringDataRequest(
        `core/api/v1/reservation/add-cash-refund-for-isha?bookingId=${bookingId}&refundAmount=${manualRefundAmt}`
      );
      toast.success(res);
      getAllData();
    } catch (err) {
      toast.error(err);
    } finally {
      setLoadingManualRefund(false);
      handleCloseAddManualEntry();
    }
  };

  return (
    <div>
      {" "}
      <div style={{ marginTop: "25px" }}>
        <Button variant="custom-button" onClick={handleOpenAddManualEntry}>
          Add manual refund entry
        </Button>
      </div>
      {gpmsData ? (
        <div className="invoiceSummTableWrapper">
          <h2 style={{ marginTop: "1rem" }}>Pending</h2>
          <ToolkitProvider
            keyField="service_id"
            data={gpmsData}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      afterSaveCell: (oldValue, newValue, row, column) => {
                        console.log("row", row, newValue, column);
                        if (column.dataField === "refund_amount") {
                          row.refund_amount = newValue;
                        } else if (column.dataField === "cancel_charge") {
                          row.cancel_charge = newValue;
                        }
                      },
                    })}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockLoaderTable />
      )}
      <Divider style={{ marginTop: "2rem", marginBottom: "2rem" }} />
      {successRefund ? (
        <div className="invoiceSummTableWrapper">
          <h2>Processed</h2>
          <ToolkitProvider
            keyField="service_id"
            data={successRefund}
            columns={successcolumns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockLoaderTable />
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography align="center">
            Are you sure you want to issue the refund?
          </Typography>

          <div
            className="buttons"
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "10px",
              borderTop: "1px solid #DDDDDD",
              paddingTop: "20px",
            }}
          >
            <Button variant="secondary" onClick={() => setOpenModal(false)}>
              No
            </Button>
            <Button
              variant="custom-button"
              onClick={procesRefund}
              disabled={loader}
            >
              {!loader ? "Yes" : <CircularProgress size="15px" />}
            </Button>
          </div>
        </Box>
      </Modal>
      {/* Adding the manual entry */}
      <Modal
        open={addManualEntry}
        onClose={() => handleOpenAddManualEntry()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={[
            style,
            { padding: 2, borderRadius: "15px", justifyContent: "flex-start" },
          ]}
        >
          <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
            Add manual entry below
          </Typography>
          <Stack className="mt-15" d-flex>
            <Stack spacing={2}>
              <TextField
                id="outlined-name"
                label="Refund amount"
                name="refund-amount"
                value={manualRefundAmt}
                onChange={(e) => setManualRefundAmt(e.target.value)}
                type={"number"}
              />
            </Stack>
          </Stack>
          <Stack
            className="mt-15 d-flex justify-content-between"
            direction={"row"}
          >
            <Button
              variant="secondary"
              onClick={() => handleCloseAddManualEntry()}
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="custom-button"
              onClick={handleAddManualRefund}
              disabled={loadingManualRefund}
              style={{ fontSize: "15px", fontWeight: "bold" }}
            >
              {!loadingManualRefund ? (
                "Add entry"
              ) : (
                <CircularProgress size="15px" />
              )}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(IshaGpms);
