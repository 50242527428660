// ** React Imports
import {
  Add,
  CheckCircle,
  Close,
  Error,
  ErrorOutline,
  ExpandMore,
  HorizontalRule,
  Remove,
} from "@mui/icons-material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import * as AiIcons from "react-icons/ai";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { cloneDeep } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Drawer,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  Paper,
  Popper,
  Grow,
  MenuItem,
  MenuList,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { useFormik } from "formik";
import { isArray, isNull, isUndefined } from "lodash";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import CardSwipe from "react-card-swipe";
import {
  default as ImageUploading,
  default as ReactImageUploading,
} from "react-images-uploading";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as yup from "yup";
import { cardNumberCVVFormat, getCardType } from "../../../Utils/cardType";
import {
  getAllImagesAndUrl,
  getUserId,
  setBookingUserIdImages,
  setIshaGuestDocument,
  setIshaOtherGuestDocuments,
  setOtherGuestImages,
} from "../../BookingDashboard/apis/BrandingModalApi";
import {
  getAllCustomers,
  getAllUnassignedReservations,
} from "../../FlexibleSlot/api/FlexibleSlotApi";
import {
  checkRoomAvailability,
  getCtaNamesNew,
} from "../../InventoryCalendar/api/InventoryApi";
import {
  getcustomPGinfo,
  saveCardInfo,
} from "../../RevenueManagement/Promotions/apis/PromotionApis";
import {
  getCheckinCheckoutSlots,
  getQuickBookPricePayload,
  getTaxNamesForRoom,
  perfomAdminBooking,
  getGeoCodeDetails,
  addSegmentAndSubSegment,
  handleGetDoorLockDetails,
  getReservationInfoDjango,
} from "../api/ReservationApi";
import { ENV } from "../constants/AmplitudeConstants";
import {
  customErrorMessage,
  dateFnsFormat,
  dateFormat,
  displaySlots,
  displayTimes,
  getApprovedByOverseas,
  getCountriesAndStates,
  getGenderList,
  getIshaGenderList,
  getIshaProgramsAttended,
  getIshaPurposeOfVisit,
  getIshaRelationWithPrimary,
  getNationalityList,
  segmentMap,
  subSegmentMap,
} from "../constants/Calendar-constants";
import { NewReservationModel } from "../models/NewReservationModel";
import { PriceModel } from "../models/PricesModel";
import BlockView from "./BlockView";
import CommonSlider from "./CommonSlider";
import DimmingDotsLoader from "./LoaderCustom";
import amplitude from "amplitude-js";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import ReactDOM from "react-dom";
import Webcam from "react-webcam";
import {
  getAllPromocodes,
  getAllPromocodesPriceList,
  checkPromoCode,
  checkPromoCodeQuickBook,
} from "../../Promocodes/apis/PromocodeApi";
import { getHotelAddons } from "../../HotelAddons/HotelAddonsAPI";
import NewGuestView from "./NewGuestView";
import NewChildView from "./NewChildView";
import imageCompression from "browser-image-compression";
import DayLevelPricing from "./DayLevelPricing";
import "./Searchable.css";
import SearchableDropdown from "./SearchableDropdown";
import SearchModal from "./SearchModal";
import ModalCommonSlider from "./ModalCommonSlider";
import { Link } from "react-router-dom";

import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";

import PrintEmptyResCard from "../../Folios/PrintEmptyResCard";
import PrintResCard from "../../Folios/PrintResCard";
import ClickAwayListenerHelper from "../../../Utils/ClickAwayListenerHelper";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import ArticleIcon from "@mui/icons-material/Article";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CardforButtons from "./CardforButtons";
import successAnimationData from "../Views/lotties/celebration.json";
import Lottie from "react-lottie";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  summaryInner: {
    margin: theme.spacing(1),
  },
  column: {
    flexBasis: "33.33%",
    whiteSpace: "nowrap",
  },
  footer: {
    margin: theme.spacing(3),
    float: "right",
  },
}));

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const BookingModal = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const handleCleanRoom = (rooms) => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/hk/bulk-mark-rooms-clean`,
      {
        roomIds: rooms,
        staffId: "",
      }
    )
      .then((res) => {})
      .catch((err) => {});
  };

  const handleCheckCleanStatus = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/get-room-dirty-status`,
      Object.values(props?.bookingData?.rtRoomIds)[0]
    ).then((data) => {
      const arr = [];
      Object.keys(data).map((elm) => {
        if (elm !== "UNASSIGNED" && data[elm] === "DIRTY") {
          arr.push(elm);
        }
      });
      arr.length > 0 && handleCleanRoom(arr);
    });
  };

  const classes = useStyles();
  const {
    close,
    open,
    bookingData,
    invoiceData,
    customFolioConfig,
    currency,
    allCompanyDetails,
    summaryData,
    hotelType,
    hotelLogo,
    defaultCtadropdown,
    reservationData,
    reload,
  } = props;
  const envType = process.env.REACT_APP_ENV;
  /* PRINT RESERVATION */
  // Modal for printing Registration card
  // Giving an option to print normal Registration card or empty res card for hotels to fill out the details
  const [openResCard, setOpenResCard] = useState(false);
  const handleOpenResCard = () => setOpenResCard(true);
  const handleCloseResCard = () => setOpenResCard(false);

  // Checkbox to determine whether to print with details of fill up the details on own
  const [manualResCard, setManualResCard] = useState(false);
  const handleChangeManualResCard = (e) => {
    setManualResCard(e.target.checked);
  };

  // Magic link modal
  const [openMagicLinkModal, setOpenMagicLinkModal] = useState(false);
  const handleOpenMagicLinkModal = () => {
    setOpenMagicLinkModal(true);
  };

  const handleCloseMagicLinkModal = () => {
    setOpenMagicLinkModal(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));

  // handling booking confirmation email loading
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  // pre populating magic link modal fields
  const [magicLinkMail, setMagicLinkMail] = useState("");
  const [magicLinkPhone, setMagicLinkPhone] = useState("");
  // send magic link
  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  function handleSendMagicLink() {
    if (!validateEmail(magicLinkMail)) {
      toast.error("Please enter valid email");
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail.includes("example.com")) {
      toast.error("Please enter valid email");
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail == "" || magicLinkPhone == "") {
      toast.error("Empty email or phone, please enter valid email and phone.");
      setMagicLinkLoading(false);
      return;
    }
    setMagicLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendSelfServiceLinkCust/",
      {
        bookingId: bookingData.bookingId,
        email: magicLinkMail,
        phoneNumber: magicLinkPhone,
      }
    )
      .then((res) => toast.success("Magic link sent successfully."))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseMagicLinkModal())
      .then((res) => setMagicLinkMail(reservationData.user_email))
      .then((res) => setMagicLinkPhone(reservationData.user_contact))
      .catch((err) => toast.error(err.response.data))
      .catch((err) => setMagicLinkLoading(false));
  }

  // Payment Flows
  const loggedUserEmail = localStorage.getItem("email");
  const [offlineCardPayLoading, setOfflineCardPayLoading] = useState(false);

  // accepting cash payment modal
  const [openCashPayment, setOpenCashPayment] = useState(false);
  const handleOpenCashPayment = () => {
    if (
      hotelType === "ISHA" &&
      Number(reservationData.total_amount_with_services).toFixed(2) > 100000
    ) {
      toast.error("Cannot take cash transaction of more than 100000 in cash");
    } else {
      if (hotelType === "ISHA") {
        toast.error(
          "Please ensure to preview the E-Receipt before accepting the payment"
        );
      }
      setOpenCashPayment(true);
    }
  };

  // handling the format in which data is being sent
  const handleSentDateFormat = (recordDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(recordDate).format("YYYY-MM-DD")
    ) {
      return moment(recordDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(recordDate).format("YYYY-MM-DD") + " 00:00:00";
  };

  // cash payment loading
  const [cashPayLoading, setCashPayLoading] = useState(false);
  const [amount, setAmount] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  // cheque pay loading
  const [offlineCheckPayLoading, setOfflineCheckPayLoading] = useState(false);
  const [offlineCardAmt, setOfflineCardAmt] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  const [offlineCheckDate, setOfflineCheckDate] = useState(new Date());
  const [offlineCashDate, setOfflineCashDate] = useState(new Date());
  const [chequeDesc, setChequeDesc] = useState();
  const [serviceAmountDesc, setServiceAmountDesc] = useState();
  const [offlineCardDesc, setOfflineCardDesc] = useState();
  const handleCloseCashPayment = () => setOpenCashPayment(false);
  const handleCloseOfflineCardPay = () => {
    setOpenOfflineCardPay(false);
  };

  const handleCashPayment = () => {
    setCashPayLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/recordCashPayment/", {
      hotelId: hotel.hotelId,
      bookingID: reservationData.booking_id,
      reservation_id: "",
      amount: amount,
      offline_card_date: handleSentDateFormat(offlineCashDate),
      service_amount_desc: serviceAmountDesc,
      logged_user_email: loggedUserEmail,
    })
      .then((res) => toast.success(`Cash payment of ${amount} recorded`))
      .then((res) => setCashPayLoading(false))
      .then((res) => setAmount(""))
      .then((res) => setServiceAmountDesc())
      .then((res) => handleCloseCashPayment())
      .catch((error) => setCashPayLoading(false))
      .catch((error) => handleCloseCashPayment())
      .catch((error) => toast.error(error));
  };

  // UPI payment modal loading
  const [openUpi, setOpenUpi] = useState(false);
  const handleOpenUpiPay = () => setOpenUpi(true);
  const handleCloseUpiPay = () => setOpenUpi(false);
  const [upiPayLoading, setUpiPayLoading] = useState(false);

  const [upiDate, setUpiDate] = useState(new Date());

  const [upiAmt, setUpiAmt] = useState(reservationData?.balance_due);
  const [upiDesc, setUpiDesc] = useState("");

  const handleUpiPayment = () => {
    setUpiPayLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/recordUPIPayment/", {
      hotelId: hotel?.hotelId,
      bookingID: reservationData?.booking_id,
      reservation_id: "",
      amount: Number(upiAmt),
      offline_upi_date: handleSentDateFormat(upiDate),
      service_amount_desc: upiDesc,
      logged_user_email: loggedUserEmail,
    })
      // .then((res) => updateSummaryDataFromCache())
      .then((res) =>
        toast.success(
          `UPI payment of ${upiAmt} recorded for ${reservationData?.booking_id}!`
        )
      )

      .then((res) => setUpiPayLoading(false))
      .then((res) => setUpiAmt(""))
      .then((res) => setUpiDate(new Date()))
      .then((res) => setUpiDesc(""))
      .then((res) => handleCloseUpiPay())
      .catch((err) => toast.error(err))
      .then((err) => setUpiPayLoading(false))
      .then((err) => handleCloseUpiPay());
  };

  // Other payment mode modal loading
  const [openOtherModePay, setOpenOtherModePay] = useState(false);
  const handleOpenOtherModePay = () => setOpenOtherModePay(true);
  const handleCloseOtherModePay = () => setOpenOtherModePay(false);
  const [otherModePayLoading, setOtherModePayLoading] = useState(false);

  // Bank transfer payment mode modal
  const [openBankTransferModePay, setOpenBankTransferModePay] = useState(false);
  const handleOpenBankTransferModePay = () => setOpenBankTransferModePay(true);
  const handleCloseBankTransferModePay = () =>
    setOpenBankTransferModePay(false);

  const [bankTransferModePayLoading, setBankTransferModePayLoading] =
    useState(false);

  const [bankTransferModeDate, setBankTransferModeDate] = useState(new Date());

  const [bankTransferModeAmt, setBankTransferModeAmt] = useState(
    reservationData?.balance_due
  );
  const [bankTransferModeDesc, setBankTransferModeDesc] = useState("");

  const [otherModeDate, setOtherModeDate] = useState(new Date());

  const [otherModeAmt, setOtherModeAmt] = useState(
    reservationData?.balance_due
  );
  const [otherModeDesc, setOtherModeDesc] = useState("");

  const handleBankTransferPayment = () => {
    setBankTransferModePayLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/recordBankTransferPayment/",
      {
        hotelId: hotel?.hotelId,
        bookingID: reservationData?.booking_id,
        reservation_id: "",
        amount: bankTransferModeAmt,
        bank_transfer_date: handleSentDateFormat(bankTransferModeDate),
        service_amount_desc: bankTransferModeDesc,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) =>
        toast.success(
          `Bank transfer payment of ${bankTransferModeAmt} recorded for ${reservationData?.booking_id}!`
        )
      )
      .catch((err) => toast.error(err))
      .finally(() => setBankTransferModeAmt(""))
      .finally(() => setBankTransferModeDate(new Date()))
      .finally(() => setBankTransferModeDesc(""))
      .finally(() => handleCloseBankTransferModePay())
      .finally(() => setBankTransferModePayLoading(false));
  };

  const handleOtherModePayment = () => {
    setOtherModePayLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/recordOtherPayment/", {
      hotelId: hotel?.hotelId,
      bookingID: reservationData?.booking_id,
      reservation_id: "",
      amount: otherModeAmt,
      other_payment_date: handleSentDateFormat(otherModeDate),
      service_amount_desc: otherModeDesc,
      logged_user_email: loggedUserEmail,
    })
      .then((res) =>
        toast.success(
          `Other payment of ${otherModeAmt} recorded for ${reservationData?.booking_id}!`
        )
      )

      .then((res) => setOtherModePayLoading(false))
      .then((res) => setOtherModeAmt(""))
      .then((res) => setOtherModeDate(new Date()))
      .then((res) => setOtherModeDesc(""))
      .then((res) => handleCloseOtherModePay())
      .catch((err) => setOtherModePayLoading(false))
      .catch((err) => handleCloseOtherModePay())
      .catch((err) => toast.error(err));
  };

  // paymentLink modal

  const [openSendPaymentLink, setOpenSendPaymentLink] = useState(false);
  const handleOpenSendPaymentLink = () => setOpenSendPaymentLink(true);
  const handleCloseSendPaymentLink = () => setOpenSendPaymentLink(false);

  // cash deposit modal

  const [openCashDeposit, setOpenCashDeposit] = useState(false);
  const handleOpenCashDeposit = () => setOpenCashDeposit(true);
  const handleCloseCashDeposit = () => setOpenCashDeposit(false);
  const [offlineCheckAmt, setOfflineCheckAmt] = useState(
    reservationData?.balance_due
  );

  // handling cash deposit date format
  const handleOpenCashDepositDate = (cashDepositDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(cashDepositDate).format("YYYY-MM-DD")
    ) {
      return moment(cashDepositDate).format("MMM DD YYYY HH:mm:ss");
    }
    return moment(cashDepositDate).format("MMM DD YYYY") + " 00:00:00";
  };
  // deposit loading
  const [depositLoading, setDepositLoading] = useState(false);
  // handling the cash deposit

  const handleDeposit = () => {
    setDepositLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/addServiceToInvoice/", {
      hotelId: hotel?.hotelId,
      booking_id: reservationData?.booking_id,
      reservation_id: "",
      service_item: {
        service_date: handleOpenCashDepositDate(depositDate),
        service_amount: depositAmt,
        service_desc: depositName,
        service_amount_type: "DEPOSIT",
      },
      logged_user_email: loggedUserEmail,
    })
      .then((res) => toast.success("Deposit recorded successfully."))

      .then((res) => setDepositLoading(false))
      .then((res) => setDepositAmt(""))
      .then((res) => setDepositDate(new Date()))
      .then((res) => setDepositName())
      .then((res) => handleCloseCashDeposit())
      .catch((error) => setDepositLoading(false))
      .catch((error) => handleCloseCashDeposit())
      .catch((error) => toast.error(error));
  };

  // fields for for cash deposit

  const [depositAmt, setDepositAmt] = useState(0);
  const [depositDate, setDepositDate] = useState(new Date());
  const [depositName, setDepositName] = useState();
  const [offlineCardDate, setOfflineCardDate] = useState(new Date());
  const handleCloseOfflineCheckPay = () => setOpenOfflineCheckPay(false);

  const [openOfflineCardPay, setOpenOfflineCardPay] = useState(false);
  const handleOpenOfflineCardPay = () => {
    if (hotelType === "ISHA") {
      toast.error(
        "Please ensure to preview the E-Receipt before accepting the payment"
      );
    }
    setOpenOfflineCardPay(true);
  };

  const handleOfflineCardPayment = () => {
    const payload = {
      hotelId: hotel?.hotelId,
      bookingID: reservationData.booking_id,
      reservation_id: "",
      amount: offlineCardAmt,
      offline_card_date: handleSentDateFormat(offlineCardDate),
      service_amount_desc: offlineCardDesc,
      logged_user_email: loggedUserEmail,
    };

    setOfflineCardPayLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/recordOfflineCardPayment/",
      payload
    )
      .then((res) =>
        toast.success(
          `Card payment of ${offlineCardAmt} recorded for ${reservationData.booking_id}!`
        )
      )

      .then((res) => setOfflineCardPayLoading(false))
      .then((res) => setOfflineCardAmt(""))
      .then((res) => setOfflineCardDate(new Date()))
      .then((res) => setOfflineCardDesc())
      .then((res) => handleCloseOfflineCardPay())
      .catch((err) => setOfflineCardPayLoading(false))
      .catch((err) => handleCloseOfflineCardPay())
      .catch((err) => toast.error(err));
  };

  // handling the offline cheque payment flows

  const handleOfflineCheckPayment = () => {
    setOfflineCheckPayLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/recordOfflineCheckPayment/",
      {
        hotelId: hotel?.hotelId,
        booking_id: reservationData?.booking_id,
        reservation_id: "",
        amount: offlineCheckAmt,
        offline_card_date: handleSentDateFormat(offlineCardDate),
        logged_user_email: loggedUserEmail,
        service_amount_desc: chequeDesc,
      }
    )
      .then((res) =>
        toast.success(`Offline cheque payment of ${offlineCheckAmt} recorded`)
      )

      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc())
      .then((res) => handleCloseOfflineCheckPay())
      .catch((error) => toast.error(error))
      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => handleCloseOfflineCheckPay())
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc());
  };

  // paymentLink loading
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
  const [paymentLinkEmail, setPaymentLinkEmail] = useState(
    reservationData?.user_email
  );
  const [paymentLinkMobile, setPaymentLinkMobile] = useState(
    reservationData?.user_contact
  );
  const [paymentLinkAmt, setPaymentLinkAmt] = useState(
    reservationData?.balance_due
  );
  const [openOfflineCheckPay, setOpenOfflineCheckPay] = useState(false);
  const handleOpenOfflineCheckPay = () => setOpenOfflineCheckPay(true);
  const [hideCheckin, sethideCheckin] = useState(false);

  // sending customer payment link
  function handlePaymentLink() {
    setPaymentLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendInvoicePaymentCustEmail/",
      {
        hotel_id: hotel?.hotelId,
        booking_id: reservationData?.booking_id,
        reservation_id: null,
        email: paymentLinkEmail,
        phone: paymentLinkMobile,
        amount: paymentLinkAmt,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) =>
        toast.success("Payment link sent to the customer successfully.")
      )

      .then((res) => setPaymentLinkLoading(false))
      .then((res) => handleCloseSendPaymentLink())
      .catch((err) => setPaymentLinkLoading(false))
      .catch((err) => toast.error(err));
  }

  const [opens, setOpens] = React.useState(false);

  const handleClick = () => {
    setOpens((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpens(false);
  };

  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "1px solid",
    p: 1,
    bgcolor: "background.paper",
  };

  const settleDuesComp = () => {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ position: "relative" }}>
          <Button
            variant="custom-button"
            style={{
              height: "4rem",
              width: "100%",
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
            startIcon={<AttachMoneyIcon />}
            onClick={handleClick}
          >
            Settle dues
          </Button>
          {opens ? (
            <Box sx={styles}>
              <div className="settleDuesActions">
                {JSON.parse(hotel?.accessControl?.user_feature_map?.CashPayment)
                  .read === "YES" && (
                  <div className="item" onClick={() => handleOpenCashPayment()}>
                    Cash payment
                  </div>
                )}
                {JSON.parse(
                  hotel?.accessControl?.user_feature_map?.OfflineCardPayment
                ).read === "YES" && (
                  <div
                    className="item"
                    onClick={() => handleOpenOfflineCardPay()}
                  >
                    Offline card payment
                  </div>
                )}
                {JSON.parse(
                  hotel?.accessControl?.user_feature_map?.OfflineChequePayment
                ).read === "YES" && (
                  <div
                    className="item"
                    onClick={() => handleOpenOfflineCheckPay()}
                  >
                    Offline cheque payment
                  </div>
                )}
                {hotelType !== "ISHA" && currency === "INR" && (
                  <div className="item" onClick={() => handleOpenUpiPay()}>
                    UPI Payment
                  </div>
                )}

                {hotelType !== "ISHA" &&
                  JSON.parse(
                    hotel?.accessControl?.user_feature_map?.OtherPaymentModes
                  ).read === "YES" && (
                    <div
                      className="item"
                      onClick={() => handleOpenOtherModePay()}
                    >
                      Other payment modes
                    </div>
                  )}

                {hotelType !== "ISHA" &&
                  JSON.parse(
                    hotel?.accessControl?.user_feature_map?.OtherPaymentModes
                  ).read === "YES" && (
                    <div
                      className="item"
                      onClick={() => handleOpenBankTransferModePay()}
                    >
                      Bank transfer
                    </div>
                  )}
                {hotelType !== "ISHA" &&
                  JSON.parse(
                    hotel?.accessControl?.user_feature_map?.CashDeposit
                  ).read === "YES" && (
                    <div
                      className="item"
                      onClick={() => handleOpenCashDeposit()}
                    >
                      Cash deposit
                    </div>
                  )}
                {hotelType !== "ISHA" && (
                  <div
                    className="item"
                    onClick={() => handleOpenSendPaymentLink()}
                  >
                    Send payment link
                  </div>
                )}
              </div>
            </Box>
          ) : null}
        </Box>
      </ClickAwayListener>
    );
  };

  useEffect(async () => {
    if (Object.entries(bookingData).length > 0) {
      const data = await getReservationInfoDjango(bookingData.reservationId);
      setMagicLinkMail(data.user_email);
      setMagicLinkPhone(data.user_contact);
      setUpiAmt(data.balance_due);
      setAmount(data.balance_due);
      setOfflineCardAmt(data.balance_due);
      setBankTransferModeAmt(data.balance_due);
      setOtherModeAmt(data.balance_due);
      setOfflineCheckAmt(data.balance_due);
      setPaymentLinkAmt(data.balance_due);
    }
  }, [bookingData]);

  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();

      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const checkin = async () => {
    // handleCleanRoom();
    handleCheckCleanStatus();
    try {
      const res = await handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/reservation/admin-confirmed-resids` +
          "?hotelId=" +
          hotel?.hotelId +
          "&hotel_id=" +
          hotel?.hotelId,
        {
          resIds: [bookingData?.reservationId],
          bookingId: bookingData?.bookingId,
        }
      );
      if (res.code === "200") {
        toast.success("Successfully checked in");
        reload();
        sethideCheckin(true);
      }
      await handleGetDoorLockDetails({
        bookingId: bookingData?.bookingId,
        reservationId: bookingData?.reservationId,
        actionRequired: "make",
      });
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      <CommonSlider open={open} onClose={close}>
        <div className="d-flex flex-column w-100">
          <div>
            {" "}
            <IconButton sx={{ float: "right" }} onClick={close}>
              <Close />
            </IconButton>
          </div>
          <Divider />
          <div
            className="d-flex flex-column w-100"
            style={{ marginTop: "3rem" }}
          >
            <Lottie options={successAnimation} height={150} width={150} />

            <p
              style={{
                marginTop: "2rem",
                marginLeft: "10px",
                marginRight: "10px",
                fontSize: "1.6rem",
                fontWeight: "200",
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
                fontWeight: "bold",
              }}
            >
              {`Booking is successfully created for ${bookingData?.guestName}.`}
            </p>
            <p
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                fontSize: "1.4rem",
                fontWeight: "200",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3rem",
                fontWeight: "bold",
              }}
            >{`Booking id - ${bookingData.bookingId}`}</p>

            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                {!hideCheckin &&
                  invoiceData?.invoice?.is_enquiry !== "true" &&
                  JSON.parse(hotel?.accessControl?.user_feature_map?.CheckIn)
                    .read === "YES" && (
                    <div onClick={() => checkin()}>
                      <CardforButtons
                        heading={"Check-in"}
                        variant="custom-button"
                      ></CardforButtons>
                    </div>
                  )}
                <div onClick={() => handleOpenResCard()}>
                  <CardforButtons
                    heading={"Print Reg. Card"}
                    variant="custom-button"
                  ></CardforButtons>
                </div>

                {String(whiteLabelled) === "DISABLED" && (
                  <div onClick={() => handleOpenMagicLinkModal()}>
                    <CardforButtons
                      heading={"Send Magic Link"}
                      variant="custom-button"
                    ></CardforButtons>
                  </div>
                )}

                {hotelType !== "ISHA" && reservationData?.balance_due > 0 && (
                  <div>
                    <div
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      <CardforButtons
                        heading={"Settle dues"}
                        variant="custom-button"
                      ></CardforButtons>
                    </div>

                    <Popper
                      style={{ zIndex: 3 }}
                      open={menuOpen}
                      anchorEl={anchorEl}
                      placement="bottom-end"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: (placement = "bottom-end"),
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={() => {
                                setMenuOpen(false);
                              }}
                            >
                              <MenuList
                                id="composition-menu"
                                aria-labelledby="composition-button"
                              >
                                <div
                                  className="settleDuesActions"
                                  style={{ padding: "1rem" }}
                                >
                                  {JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.CashPayment
                                  ).read === "YES" && (
                                    <div
                                      className="item"
                                      onClick={() => handleOpenCashPayment()}
                                    >
                                      Cash payment
                                    </div>
                                  )}
                                  {JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.OfflineCardPayment
                                  ).read === "YES" && (
                                    <div
                                      className="item"
                                      onClick={() => handleOpenOfflineCardPay()}
                                    >
                                      Offline card payment
                                    </div>
                                  )}
                                  {JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.OfflineChequePayment
                                  ).read === "YES" && (
                                    <div
                                      className="item"
                                      onClick={() =>
                                        handleOpenOfflineCheckPay()
                                      }
                                    >
                                      Offline cheque payment
                                    </div>
                                  )}
                                  {hotelType !== "ISHA" &&
                                    currency === "INR" && (
                                      <div
                                        className="item"
                                        onClick={() => handleOpenUpiPay()}
                                      >
                                        UPI Payment
                                      </div>
                                    )}

                                  {hotelType !== "ISHA" &&
                                    JSON.parse(
                                      hotel?.accessControl?.user_feature_map
                                        ?.OtherPaymentModes
                                    ).read === "YES" && (
                                      <div
                                        className="item"
                                        onClick={() => handleOpenOtherModePay()}
                                      >
                                        Other payment modes
                                      </div>
                                    )}

                                  {hotelType !== "ISHA" &&
                                    JSON.parse(
                                      hotel?.accessControl?.user_feature_map
                                        ?.OtherPaymentModes
                                    ).read === "YES" && (
                                      <div
                                        className="item"
                                        onClick={() =>
                                          handleOpenBankTransferModePay()
                                        }
                                      >
                                        Bank transfer
                                      </div>
                                    )}
                                  {hotelType !== "ISHA" &&
                                    JSON.parse(
                                      hotel?.accessControl?.user_feature_map
                                        ?.CashDeposit
                                    ).read === "YES" && (
                                      <div
                                        className="item"
                                        onClick={() => handleOpenCashDeposit()}
                                      >
                                        Cash deposit
                                      </div>
                                    )}
                                  {hotelType !== "ISHA" && (
                                    <div
                                      className="item"
                                      onClick={() =>
                                        handleOpenSendPaymentLink()
                                      }
                                    >
                                      Send payment link
                                    </div>
                                  )}
                                </div>
                                {/* <MenuItem
                            onClick={() => {
                              setMenuOpen(!menuOpen);
                              setMenuValue("upgrade");
                            }}
                            key="upgrade"
                          >
                            Move and upgrade resrvation
                          </MenuItem> */}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                )}
                <Link
                  to={{
                    pathname: `/folio/${bookingData.bookingId}`,
                    search: `&hotelId=${hotel.hotelId}`,
                  }}
                  target="_blank"
                >
                  <CardforButtons
                    heading={"View folio"}
                    variant="custom-button"
                  ></CardforButtons>
                  {/* <Button
                  style={{
                    width: "100%",
                    marginRight: "1rem",
                    height: "4rem",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                  startIcon={<ArticleIcon />}
                  variant="custom-button"
                >
                  View folio
                </Button> */}
                </Link>

                {/* <Button
                  onClick={() => handleOpenMagicLinkModal()}
                  variant="custom-button"
                  style={{
                    marginRight: "1rem",
                    height: "4rem",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                  startIcon={<AutoFixNormalIcon />}
                >
                  Send magic link
                </Button> */}
              </div>
            </div>
            <div
              className="d-flex w-100"
              style={{
                marginTop: "2rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            ></div>
          </div>
        </div>

        <Modal
          show={openMagicLinkModal}
          onHide={handleCloseMagicLinkModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <div className="d-flex flex-column">
              <Modal.Title>Send magic link</Modal.Title>
              <p className="text-muted mb-0">
                Magic Link connects your guests to your property in real time.
                They can view the itinerary, perform self check-ins, room
                upgrades, stay extensions, and add-on purchases.
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="fieldWrapper"
              style={{
                position: "relative",
                width: "100%",
                fontSize: "12px",
                fontWeight: 500,
                backgroundColor: "#C8E5F3",
                padding: "5px",
                borderRadius: "5px",
                color: "black",
              }}
            >
              {envType.toLowerCase() === "prod"
                ? `https://selfservice.stayflexi.com/booking/${bookingData.bookingId}`
                : `https://beta.selfservice.stayflexi.com/booking/${bookingData.bookingId}`}
            </div>
            <br></br>
            <div className="fieldWrapper">
              <TextField
                id="outlined-name"
                label="Email"
                name="email"
                helperText={
                  "By default customer's email has been populated. Override if necessary."
                }
                value={magicLinkMail}
                onChange={(e) => setMagicLinkMail(e.target.value)}
                sx={{ width: "100%" }}
              />
            </div>
            <br></br>
            <div className="fieldWrapper">
              <TextField
                id="outlined-name"
                label="Phone number"
                name="phone"
                helperText={
                  "By default customer's contact number has been populated. Override if necessary."
                }
                value={magicLinkPhone}
                onChange={(e) => {
                  /^[\+0-9]*$/.test(e.target.value) &&
                    setMagicLinkPhone(e.target.value);
                }}
                sx={{ width: "100%" }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="submit w-auto"
              variant="custom-button"
              onClick={() => handleSendMagicLink()}
              disabled={magicLinkLoading}
            >
              {magicLinkLoading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Send Magic Link"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal to provide the type of res card that needs to be printed */}
        <Modal
          show={openResCard}
          onHide={handleCloseResCard}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Print Registration card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="folioConfirmationWrapper">
              <div
                className="folioContent"
                style={{
                  borderBottom: "1px solid #DDDDDD",
                  paddingBottom: "15px",
                  marginBottom: "15px",
                }}
              >
                <Stack direction="row" spacing={3}>
                  <Checkbox
                    checked={manualResCard}
                    onChange={handleChangeManualResCard}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <div>
                    I'll fill up the details manually into the Registration
                    card!
                  </div>
                </Stack>
              </div>
              <Stack direction="row">
                <div className="updateBtn">
                  {manualResCard === true ? (
                    <div className="item" style={{ paddingTop: "10px" }}>
                      <PDFDownloadLink
                        document={
                          <Document title={bookingData.bookingId}>
                            <Page size="A4" style={styles.page} wrap>
                              <PrintEmptyResCard
                                invoice={invoiceData?.invoice}
                                symbol={currency}
                                hotelData={invoiceData?.hotel}
                                customDetails={customFolioConfig}
                                hotelLogo={hotelLogo}
                                summaryData={summaryData}
                                hotelType={hotelType}
                              />
                            </Page>
                          </Document>
                        }
                        fileName={`${bookingData.bookingId}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <p style={{ fontSize: "0.8rem" }}>
                              Loading document...
                            </p>
                          ) : (
                            <a
                              href={url}
                              style={{ fontSize: "0.8rem" }}
                              rel={"noreferrer"}
                              target="_blank"
                            >
                              {window.screen.width > 416 && (
                                <Button
                                  variant="custom-button"
                                  onClick={() =>
                                    setTimeout(() => {
                                      handleCloseResCard();
                                    }, 1500)
                                  }
                                >
                                  Print Registration card
                                </Button>
                              )}
                            </a>
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                  ) : (
                    <div className="item" style={{ paddingTop: "10px" }}>
                      <PDFDownloadLink
                        document={
                          <Document title={bookingData.bookingId}>
                            <Page size="A4" style={styles.page} wrap>
                              <PrintResCard
                                allCompanyDetails={allCompanyDetails}
                                invoice={invoiceData?.invoice}
                                symbol={currency}
                                hotelData={invoiceData?.hotel}
                                customDetails={customFolioConfig}
                                hotelLogo={hotelLogo}
                                summaryData={summaryData}
                                hotelType={hotelType}
                                ctaDetails={
                                  defaultCtadropdown?.filter(
                                    (cta) =>
                                      cta?.cta_name ===
                                      invoiceData?.invoice?.booking_source
                                  )[0]
                                }
                              />
                            </Page>
                          </Document>
                        }
                        fileName={`${bookingData.bookingId}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <p style={{ fontSize: "0.8rem" }}>
                              Loading document...
                            </p>
                          ) : (
                            <a
                              href={url}
                              style={{ fontSize: "0.8rem" }}
                              rel={"noreferrer"}
                              target="_blank"
                            >
                              {window.screen.width > 416 && (
                                <Button
                                  variant="custom-button"
                                  onClick={() =>
                                    setTimeout(() => {
                                      handleCloseResCard();
                                    }, 1500)
                                  }
                                >
                                  Print Registration card
                                </Button>
                              )}
                            </a>
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                  )}
                </div>
              </Stack>
            </div>
          </Modal.Body>
        </Modal>

        {/* settle dues --- offline card payment modal */}
        <Modal
          show={openOfflineCardPay}
          onHide={handleCloseOfflineCardPay}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Offline card payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settleDuesWrapper">
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Card amount"
                    name="amount"
                    className="mb-3"
                    value={offlineCardAmt}
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setOfflineCardAmt(e.target.value)
                        : setOfflineCardAmt(offlineCardAmt)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    disabled={
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.EditBookingPrice
                      ).read !== "YES"
                    }
                  />
                  {/* <TextField
                      error
                      id="outlined-name"
                      label="Card amount"
                      name="amount"
                      value={offlineCardAmt}
                      onChange={(e) => (/^[0-9.]*$/.test(e.target.value) ? setOfflineCardAmt(e.target.value) : setOfflineCardAmt(offlineCardAmt))}
                      sx={{ width: "100%" }}
                      type={"text"}
                      helperText={"Please enter the positive value"}
                    /> */}
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date"
                      value={offlineCardDate}
                      onChange={(newValue) => {
                        setOfflineCardDate(newValue);
                      }}
                      name="offline_card_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Amount description"
                    className="mb-3 mt-3"
                    name="service_amount_desc"
                    value={serviceAmountDesc}
                    onChange={(e) => setOfflineCardDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>

              <div
                className="settleDuesSubmitBtns"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseOfflineCardPay()}
                >
                  Cancel
                </button>
                {offlineCardPayLoading === false ? (
                  <>
                    {JSON.parse(
                      hotel?.accessControl?.user_feature_map?.EnquiryDashboard
                    ).read === "YES" ? (
                      <Button
                        className="submit"
                        variant="custom-button"
                        onClick={() => handleOfflineCardPayment()}
                        disabled={offlineCardAmt > 0 ? false : true}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        className="submit"
                        onClick={() => handleOfflineCardPayment()}
                        variant="custom-button"
                        disabled={offlineCardAmt > 0 ? false : true}
                      >
                        Submit
                      </Button>
                    )}
                  </>
                ) : (
                  <button className="submit">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          style={{ zIndex: "3000" }}
          show={openCashPayment}
          onHide={handleCloseCashPayment}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter cash amount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                {/* <div className="settleDuesHeading">Enter cash amount</div>
                <div className="close" onClick={() => handleCloseCashPayment()}>
                  X
                </div> */}
              </div>
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Cash amount"
                    name="amount"
                    value={amount}
                    className="mb-4"
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setAmount(e.target.value)
                        : setAmount(amount)
                    }
                    disabled={
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.EditBookingPrice
                      ).read !== "YES"
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Return to customer calculator"
                    name="amount"
                    className="mb-4"
                    value={
                      Number(amount) >=
                      Number(reservationData.balance_due).toFixed(2)
                        ? (
                            Number(amount) -
                            Number(reservationData.balance_due).toFixed(2)
                          ).toFixed(2)
                        : "Less amount"
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    InputProps={{ inputProps: { min: 0 } }}
                    disabled
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date"
                      value={offlineCashDate}
                      onChange={(newValue) => {
                        setOfflineCashDate(newValue);
                      }}
                      name="offline_card_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Amount description"
                    className="mb-4 mt-3"
                    name="service_amount_desc"
                    value={serviceAmountDesc}
                    onChange={(e) => setServiceAmountDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>
              <div
                className="settleDuesSubmitBtns"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseCashPayment()}
                >
                  Cancel
                </button>
                {cashPayLoading === false ? (
                  <Button
                    className="submit"
                    onClick={() => handleCashPayment()}
                    variant="custom-button"
                    disabled={
                      serviceAmountDesc !== ""
                        ? amount > 0
                          ? false
                          : true
                        : true
                    }
                  >
                    Submit
                  </Button>
                ) : (
                  <Button className="submit">
                    <CircularProgress size="15px" />
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* settle dues --- record payment from other sources */}
        <Modal
          show={openOtherModePay}
          onHide={handleCloseOtherModePay}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Other Payment modes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settleDuesWrapper">
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    required
                    id="outlined-name"
                    label="Deposit amount"
                    name="deposit_amount"
                    className="mb-3"
                    value={otherModeAmt}
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setOtherModeAmt(e.target.value)
                        : setOtherModeAmt(otherModeAmt)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Service date"
                      value={otherModeDate}
                      onChange={(newValue) => {
                        setOtherModeDate(newValue);
                      }}
                      name="deposit_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Description"
                    name="other_mode_desc"
                    className="mt-3 mb-3"
                    value={otherModeDesc}
                    onChange={(e) => setOtherModeDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="cancelBtn"
                    onClick={() => handleCloseOtherModePay()}
                  >
                    Cancel
                  </Button>
                  {otherModePayLoading === false ? (
                    <Button
                      className="submit"
                      variant="custom-button"
                      onClick={() => handleOtherModePayment()}
                      disabled={otherModeAmt > 0 ? false : true}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* settle dues --- Bank Transfer */}
        <Modal
          show={openBankTransferModePay}
          onHide={handleCloseBankTransferModePay}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title> Enter bank transfer amount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settleDuesWrapper">
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Amount"
                    name="amount"
                    value={bankTransferModeAmt}
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setBankTransferModeAmt(e.target.value)
                        : setBankTransferModeAmt(bankTransferModeAmt)
                    }
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    type={"text"}
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date"
                      value={bankTransferModeDate}
                      onChange={(newValue) => {
                        setBankTransferModeDate(newValue);
                      }}
                      name="upi_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Remarks"
                    name="description"
                    value={bankTransferModeDesc}
                    onChange={(e) => setBankTransferModeDesc(e.target.value)}
                    sx={{
                      width: "100%",
                      marginBottom: "1rem",
                      marginTop: "1rem",
                    }}
                  />
                </div>
              </div>
              <div className="settleDuesSubmitBtns">
                {bankTransferModePayLoading === false ? (
                  <Button
                    className="submit"
                    variant="custom-button"
                    onClick={() => handleBankTransferPayment()}
                    disabled={bankTransferModeAmt > 0 ? false : true}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button className="submit">
                    <CircularProgress size="15px" />
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* settle dues --- cash deposit modal */}
        <Modal
          show={openCashDeposit}
          onHide={handleCloseCashDeposit}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cash Deposit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settleDuesWrapper">
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    required
                    id="outlined-name"
                    label="Cash deposit amount*"
                    name="deposit_amount"
                    className="mb-3"
                    value={depositAmt}
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setDepositAmt(e.target.value)
                        : setDepositAmt(depositAmt)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Service date"
                      value={depositDate}
                      onChange={(newValue) => {
                        setDepositDate(newValue);
                      }}
                      name="deposit_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Description*"
                    className="mb-3 mt-3"
                    name="folio_item_name"
                    value={depositName}
                    onChange={(e) => setDepositName(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="cancelBtn"
                    onClick={() => handleCloseCashDeposit()}
                  >
                    Cancel
                  </Button>
                  {depositLoading === false ? (
                    <Button
                      className="submit"
                      variant="custom-button"
                      onClick={() => handleDeposit()}
                      disabled={depositAmt > 0 ? false : true}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* settle dues --- record UPI payment modal */}
        <Modal
          show={openUpi}
          onHide={handleCloseUpiPay}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>UPI Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settleDuesWrapper">
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="UPI amount"
                    name="amount"
                    className="mb-3"
                    value={upiAmt}
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setUpiAmt(e.target.value)
                        : setUpiAmt(upiAmt)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date"
                      value={upiDate}
                      onChange={(newValue) => {
                        setUpiDate(newValue);
                      }}
                      name="upi_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Description"
                    name="description"
                    className="mb-3 mt-3"
                    value={upiDesc}
                    onChange={(e) => setUpiDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>
              <div
                className="settleDuesSubmitBtns"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  className="cancelBtn"
                  onClick={() => handleCloseOfflineCheckPay()}
                >
                  Cancel
                </Button>
                {upiPayLoading === false ? (
                  <Button
                    className="submit"
                    variant="custom-button"
                    onClick={() => handleUpiPayment()}
                    disabled={upiAmt > 0 ? false : true}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button className="submit">
                    <CircularProgress size="15px" />
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* settle dues --- paymentLink */}
        <Modal
          show={openSendPaymentLink}
          onHide={handleCloseSendPaymentLink}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Send Payment Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settleDuesWrapper">
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Email"
                    name="email"
                    className="mb-3"
                    helperText={
                      "By default customer's email has been populated. Override if necessary."
                    }
                    value={paymentLinkEmail}
                    onChange={(e) => setPaymentLinkEmail(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Mobile number"
                    name="mobile"
                    className="mb-3"
                    helperText={
                      "By default customer's mobile has been populated. Override if necessary."
                    }
                    value={paymentLinkMobile}
                    onChange={(e) => setPaymentLinkMobile(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Amount"
                    className="mb-3"
                    helperText={
                      "By default total due amount has been populated. Override if necessary."
                    }
                    name="service_amount_desc"
                    value={paymentLinkAmt}
                    onChange={(e) => setPaymentLinkAmt(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>
              <div
                className="settleDuesSubmitBtns"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button className="cancelBtn">Cancel</Button>
                {paymentLinkLoading === false && hotelType !== "ISHA" ? (
                  <Button
                    variant="custom-button"
                    className="submit w-auto"
                    onClick={() => handlePaymentLink()}
                  >
                    Send Payment Link
                  </Button>
                ) : (
                  <Button className="submit">
                    <CircularProgress size="15px" />
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* settle dues --- offline cheque payment modal */}
        <Modal
          show={openOfflineCheckPay}
          onHide={handleCloseOfflineCheckPay}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Offline Cheque Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settleDuesWrapper">
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Cheque amount"
                    name="amount"
                    className="mb-3"
                    value={offlineCheckAmt}
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setOfflineCheckAmt(e.target.value)
                        : setOfflineCheckAmt(offlineCheckAmt)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date"
                      value={offlineCheckDate}
                      onChange={(newValue) => {
                        setOfflineCheckDate(newValue);
                      }}
                      name="offline_check_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Description"
                    className="mb-3 mt-3"
                    name="description"
                    value={chequeDesc}
                    onChange={(e) => setChequeDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>
              <div
                className="settleDuesSubmitBtns"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  className="cancelBtn"
                  onClick={() => handleCloseOfflineCheckPay()}
                >
                  Cancel
                </Button>
                {offlineCheckPayLoading === false ? (
                  <Button
                    className="submit"
                    variant="custom-button"
                    onClick={() => handleOfflineCheckPayment()}
                    disabled={offlineCheckAmt > 0 ? false : true}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button className="submit">
                    <CircularProgress size="15px" />
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </CommonSlider>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    hotelType: state.auth.hotelType,
  };
}
export default connect(mapStateToProps)(BookingModal);
