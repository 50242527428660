import React, { useState, useEffect } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import "material-react-toastify/dist/ReactToastify.css";
import { getBookingId } from "../../../../Utils/Utils";

const borderColor = "#E5e5e5";

// formatting the date
const formatDate = () => {
  return moment().format("MMM DD YYYY hh:mm A");
};

// rounding of the digits
const handleDigits = (val) => {
  if (val == null) {
    return 0;
  } else {
    return parseFloat(val).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  }
};

// changing digits into Integer
const handleDigitsInt = (val) => {
  if (val == null) {
    return 0;
  } else {
    return parseInt(val);
  }
};

// Getting the flash manager report header
const FlashManagerReportHeader = ({ reportDate }) => (
  <View style={[styles.scContainer, { fontSize: "7px" }]}>
    <Text style={[styles.metric, { fontSize: "7px", width: "70%" }]}>
      Metric
    </Text>
    <Text style={[styles.newmatric, { fontSize: "7px", width: "30%" }]}>
      {reportDate}
    </Text>
  </View>
);

// Single column flash manager report
const FlashManagerReportHeaderSC = () => (
  <View style={[styles.scContainer, { fontSize: "7px" }]}>
    <Text style={[styles.metric, { fontSize: "7px", width: "70%" }]}>
      Metric
    </Text>
    <Text style={[styles.newmatric, { fontSize: "7px", width: "30%" }]}>
      Data
    </Text>
  </View>
);

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

// Calculating the width %
function returnWidthOnDataType(col) {
  const { printWidth, dataType } = col;
  if (printWidth) {
    return String(printWidth);
  } else if (dataType === "varchar") {
    return "10%";
  } else if (dataType === "float") {
    return "5%";
  } else if (dataType === "integer") {
    /*less width as no decimals are there*/
    return "4%";
  } else if (dataType === "date") {
    return "5%";
  } else {
    return "10%";
  }
}

// Format values
function formatValue(col, val) {
  const { dataField, dataType, dateFormat } = col;
  if (dataType) {
    if (dataType === "date" && val !== "Total") {
      if (dateFormat && val) {
        return moment(val, dateFormat).format("MMM DD 'YY");
      } else if (moment(val).format("MMM DD 'YY") !== "Invalid date") {
        return moment(val).format("MMM DD 'YY");
      }
      return val;
    } else if (dataType === "float") {
      if (val) {
        return Number(val)?.toFixed(2);
      }
      return val;
    } else if (dataType === "integer") {
      if (val) {
        return parseInt(val);
      }
      return val;
    } else if (dataType === "varchar") {
      if (dataField?.includes("booking_id")) {
        return getBookingId(val);
      }
      return val;
    }
    return val;
  }
  return val;
}

/* Generate expanded table view */
function GenerateExtendedData({
  reportName,
  columns,
  rows,
  displayAggAmt = true,
}) {
  return (
    <View style={{ marginTop: "10px" }}>
      <Text style={{ marginBottom: "5px", fontSize: "7px" }}>{reportName}</Text>
      <View style={styles.tableHeader}>
        {columns?.map((col, index) => (
          <Text
            style={[
              styles.headerInfo,
              { fontSize: "7px", width: returnWidthOnDataType(col) },
            ]}
            key={index}
          >
            {col.text}
          </Text>
        ))}
      </View>
      {rows?.map((row, index) => (
        <View style={styles.infoWrapper} key={index}>
          {columns?.map((col) => (
            <Text style={[styles.info, { width: returnWidthOnDataType(col) }]}>
              {formatValue(col, row[col.dataField])}
            </Text>
          ))}
        </View>
      ))}
      {displayAggAmt && (
        <View style={styles.tableHeader}>
          {columns.map((col, index) => (
            <Text
              style={[
                styles.headerInfo,
                { fontSize: "7px", width: returnWidthOnDataType(col) },
              ]}
              key={index}
            >
              {col.footer}
            </Text>
          ))}
        </View>
      )}
    </View>
  );
}

function AuditReportDoc({
  reportData,
  symbol,
  hotelName,
  reportDate,
  hotelLogo,
  address,
  houseReportTypes = null,
  houseReport = null,
  houseReportCols = null,
  roomRevReport = null,
  roomRevCols = null,
  pgReport = null,
  pgCols = null,
  counterPayReport = null,
  counterPayCols = null,
  summaryView,
  mealPlanReport,
}) {
  const [finalAddress, setfinalAddress] = useState("");
  const concatAddress = () => {
    const addr1 =
      address &&
      address.address1 !== null &&
      address.address1 !== "NA" &&
      address.address1;
    const addr2 =
      address &&
      address.address2 !== null &&
      address.address2 !== "NA" &&
      address.address2;

    let finaladd = "";
    if (addr1 && addr1 !== undefined && (addr2 === null || addr2 === undefined))
      finaladd = addr1;
    else if (
      addr2 &&
      addr2 !== undefined &&
      (addr1 === null || addr1 === undefined)
    )
      finaladd = addr2;
    else finaladd = addr1 + ", " + addr2;
    setfinalAddress(finaladd);
  };

  // Handling customer address
  const handleCustomerAddress = (address) => {
    if (!address.length > 0) {
      return "";
    } else if (address.includes("null")) {
      return "";
    } else if (address.includes("NA")) {
      return "";
    } else {
      let parsed = parseInt(address);
      return String(parsed) === String(NaN) ? [`${address}, `] : parsed + "";
    }
  };

  useEffect(() => {
    concatAddress();
  }, [address]);

  return (
    <View style={[styles.section, { marginTop: 2 }]}>
      <View
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <View style={{ position: "relative", width: "33%" }}>
          <Text style={{ fontSize: "8px" }}>
            <b>Audit Report - {reportDate}</b>
          </Text>
          <Text style={{ fontSize: "7px", color: "grey" }}>
            Report printed on : {moment().format("MMM DD YYYY hh:mm A")}
          </Text>
        </View>
        {hotelLogo && (
          <View
            style={{ positon: "relative", width: "33%", alignItems: "center" }}
          >
            <Image
              style={{ width: "35px", height: "auto" }}
              src={getExtension(hotelLogo)}
            />
          </View>
        )}
        <View
          style={{ position: "relative", width: "33%", textAlign: "right" }}
        >
          <Text style={{ fontSize: "7px", color: "grey" }}>{hotelName}</Text>

          <Text style={{ fontSize: "7px", color: "grey" }}>{finalAddress}</Text>
          <Text style={{ fontSize: "7px", color: "grey" }}>
            {handleCustomerAddress(address.city) +
              handleCustomerAddress(address.state) +
              handleCustomerAddress(address.country) +
              handleCustomerAddress(address.pincode)}
          </Text>
        </View>
      </View>

      {/* Property report */}
      <View style={{ marginTop: "2.5px" }}>
        <Text style={{ fontSize: "7px" }}>Property report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Rooms sold
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {parseInt(reportData?.prop_flash_report[0].td)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Occupancy
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {reportData?.prop_flash_report[0].td_occupancy}%
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                ADR - Average Daily Rate
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_ADR)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                RevPAR - Revenue Per Available Room
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_REVPAR)}
            </Text>
          </View>
        </View>
      </View>

      {/* Revenue report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "7px" }}>Revenue report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Room Revenue
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_rev)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                POS Revenue
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.pos_flash_report[0].td_amount)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Services Revenue
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.services_flash_report[0].td_amount)}
            </Text>
          </View>
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Booking fee
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_bfee)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol + " " + handleDigits(reportData?.total_rev.td_rev_total)}
            </Text>
          </View>
        </View>
      </View>
      {/* Tax Revenue Report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "7px" }}>Tax Revenue Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Partial tax Exempt Revenue
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.prop_flash_report[0].td_partial_texmpt_rev
                )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Total tax Exempt Revenue
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.prop_flash_report[0].td_total_texmpt_rev
                )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Taxable Revenue
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_txble_rev)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(
                  Number(reportData?.prop_flash_report[0].td_txble_rev) +
                    Number(
                      reportData?.prop_flash_report[0].td_total_texmpt_rev
                    ) +
                    Number(
                      reportData?.prop_flash_report[0].td_partial_texmpt_rev
                    )
                )}
            </Text>
          </View>
        </View>
      </View>

      {/* Taxes Report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "7px" }}>Taxes Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Room Taxes
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_rev_tax)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                POS Taxes
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.pos_flash_report[0].td_amount_tax)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Services Tax
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.services_flash_report[0].td_amount_tax
                )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol + " " + handleDigits(reportData?.total_tax.td_tax_total)}
            </Text>
          </View>
        </View>
      </View>

      {/* Extended room revenue report */}
      {!summaryView && roomRevReport && roomRevCols
        ? roomRevReport?.length > 0 && (
            <GenerateExtendedData
              columns={roomRevCols}
              rows={roomRevReport}
              reportName={"Detailed room rev. report"}
            />
          )
        : null}

      {/* Payments Report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "7px" }}>Payments Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          {reportData?.counter_flash_report?.length > 0 &&
            reportData?.counter_flash_report
              .filter((elm) => elm?.service_description !== "Direct Billing")
              ?.map((item, idx) => (
                <View style={styles.row}>
                  <View
                    style={[
                      styles.metric,
                      {
                        display: "flex",
                        width: "70%",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        { marginBottom: 3, fontSize: "7px", marginRight: 5 },
                      ]}
                    >
                      {item.service_description === "Offline Check Payment"
                        ? "Offline Cheque Payment"
                        : item.service_description}
                    </Text>
                  </View>
                  <Text style={[styles.newmatric, { fontSize: "7px" }]}>
                    {symbol + " " + handleDigits(item.td_amount)}
                  </Text>
                </View>
              ))}

          {reportData?.pg_payments_flash_report?.length > 0 &&
            reportData?.pg_payments_flash_report.map((item, idx) => (
              <View style={styles.row}>
                <View
                  style={[
                    styles.metric,
                    {
                      display: "flex",
                      width: "70%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      { marginBottom: 3, fontSize: "7px", marginRight: 5 },
                    ]}
                  >
                    {item.payment_type}
                  </Text>
                </View>
                <Text style={[styles.newmatric, { fontSize: "7px" }]}>
                  {symbol + " " + handleDigits(item.td_amount)}
                </Text>
              </View>
            ))}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.total_payments.td_payments_total -
                    (reportData?.counter_flash_report?.filter(
                      (elm) => elm?.service_description === "Direct Billing"
                    )[0]?.td_amount || 0)
                )}
            </Text>
          </View>
          {/* Dont touch this */}
          <View style={styles.newrow}></View>
        </View>
      </View>
      {/* Direct Billing Report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "7px" }}>Direct Billing Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          {reportData?.counter_flash_report?.length > 0 &&
            reportData?.counter_flash_report
              .filter((elm) => elm?.service_description === "Direct Billing")
              ?.map((item, idx) => (
                <View style={styles.row}>
                  <View
                    style={[
                      styles.metric,
                      {
                        display: "flex",
                        width: "70%",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        { marginBottom: 3, fontSize: "7px", marginRight: 5 },
                      ]}
                    >
                      {item.service_description}
                    </Text>
                  </View>
                  <Text style={[styles.newmatric, { fontSize: "7px" }]}>
                    {symbol + " " + handleDigits(item.td_amount)}
                  </Text>
                </View>
              ))}

          {/* {reportData?.pg_payments_flash_report?.length > 0 &&
            reportData?.pg_payments_flash_report.map((item, idx) => (
              <View style={styles.row}>
                <View
                  style={[
                    styles.metric,
                    {
                      display: "flex",
                      width: "70%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      { marginBottom: 3, fontSize: "7px", marginRight: 5 },
                    ]}
                  >
                    {item.payment_type}
                  </Text>
                </View>
                <Text style={[styles.newmatric, { fontSize: "7px" }]}>
                  {symbol + " " + handleDigits(item.td_amount)}
                </Text>
              </View>
            ))} */}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.counter_flash_report?.filter(
                    (elm) => elm?.service_description === "Direct Billing"
                  )[0]?.td_amount || 0
                )}
            </Text>
          </View>
          {/* Dont touch this */}
          <View style={styles.newrow}></View>
        </View>
      </View>

      {/* Extended payment gateway report */}
      {!summaryView && pgReport && pgCols
        ? pgReport?.length > 0 && (
            <GenerateExtendedData
              columns={pgCols}
              rows={pgReport}
              reportName={"Payment gateway report"}
            />
          )
        : null}

      {/* Extended counter payment report */}
      {!summaryView && counterPayReport && counterPayCols
        ? counterPayReport?.length > 0 && (
            <GenerateExtendedData
              columns={counterPayCols}
              rows={counterPayReport}
              reportName={"Counter payment report"}
            />
          )
        : null}

      {/* Inventory Report */}
      <View
        style={{ marginTop: "8px" }}
        // break={summaryView ? false : true}
      >
        <Text style={{ fontSize: "7px" }}>Inventory Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeaderSC />

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Total Rooms
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(reportData?.inventory_flash_report.total_rooms)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Available Rooms
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.inventory_flash_report.available_rooms
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Booked Rooms
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(reportData?.inventory_flash_report.booked_rooms)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Disabled Rooms
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.inventory_flash_report.disabled_rooms
              )}
            </Text>
          </View>
        </View>
      </View>
      {/* House Report */}
      <View style={{ marginTop: "10px" }}>
        <Text style={{ fontSize: "7px" }}>House Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeaderSC />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Today Bookings
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.newbookings
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                In House
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.inhouse
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Today Arrivals
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.checkins
              )}
            </Text>
          </View>

          {reportData?.house_flash_report.house_summary.confirmed > 0 && (
            <View style={styles.row}>
              <View
                style={[
                  styles.metric,
                  {
                    display: "flex",
                    width: "70%",
                  },
                ]}
              >
                <Text
                  style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
                >
                  Expected Arrivals
                </Text>
              </View>
              <Text style={[styles.newmatric, { fontSize: "7px" }]}>
                {handleDigitsInt(
                  reportData?.house_flash_report.house_summary.confirmed
                )}
              </Text>
            </View>
          )}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Today Departures
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.checkouts
              )}
            </Text>
          </View>

          {reportData?.house_flash_report.house_summary.pendingcheckouts >
            0 && (
            <View style={styles.row}>
              <View
                style={[
                  styles.metric,
                  {
                    display: "flex",
                    width: "70%",
                  },
                ]}
              >
                <Text
                  style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
                >
                  Pending Departures
                </Text>
              </View>
              <Text style={[styles.newmatric, { fontSize: "7px" }]}>
                {handleDigitsInt(
                  reportData?.house_flash_report.house_summary.pendingcheckouts
                )}
              </Text>
            </View>
          )}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                Cancellations{" "}
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.cancellations
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                On Hold{" "}
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.holds
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "7px", marginRight: 5 }]}
              >
                No Shows{" "}
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "7px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.no_shows
              )}
            </Text>
          </View>
        </View>
      </View>
      {/* House reports extended */}
      {!summaryView && houseReportTypes && houseReport && houseReportCols
        ? houseReportTypes.map(
            (item) =>
              houseReport[item?.name].length > 0 && (
                <View style={{ marginTop: "15px" }}>
                  <GenerateExtendedData
                    rows={houseReport[item?.name]}
                    columns={houseReportCols}
                    reportName={`${item?.display_name} report`}
                    displayAggAmt={false}
                  />
                  {/* <Text style={{ fontSize: "7px", marginBottom: "5px" }}>
                {item.display_name}
              </Text>
              <HouseReportHeader />
              <HouseReport row={houseReport[item?.name]} /> */}
                </View>
              )
          )
        : null}

      {/* Meal plan report */}
      <View style={{ marginTop: "8px" }} break={!summaryView}>
        <Text style={{ fontSize: "7px" }}>Rate plan report</Text>
        <View style={[styles.tableContainer, { padding: "5px" }]}>
          <Text style={{ fontSize: "7px" }}>
            Total guest count :{" "}
            {mealPlanReport?.adults + mealPlanReport?.children}
          </Text>
          <View
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              marginTop: "8px",
            }}
          >
            <Text style={{ marginRight: "10px", fontSize: "7px" }}>
              Total adults : {mealPlanReport?.adults}
            </Text>
            <Text style={{ fontSize: "7px" }}>
              Total children : {mealPlanReport?.children}
            </Text>
          </View>
          <View
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
              flexWrap: "wrap",
            }}
          >
            <Text style={{ marginRight: "5px", fontSize: "8px" }}>
              {mealPlanReport?.mealPlanList
                ?.map((mealPlan) => `${mealPlan?.name} (${mealPlan?.count})`)
                .join(", ")}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default AuditReportDoc;

// Create styles
const styles = StyleSheet.create({
  image: {
    height: "125px",
    width: "auto",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    margin: 1,
    padding: 5,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "10px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "10px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    // textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  scContainer: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 20,
    // textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  metric: {
    width: "50%",
    borderLeftColor: borderColor,
    borderLefttWidth: 1,
    // textAlign: "center",
    paddingLeft: 3.5,
    paddingTop: 2,
    textAlign: "left",
  },
  newmatric: {
    width: "30%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    // textAlign: "center",
    paddingLeft: 3.5,
    paddingTop: 2,
    textAlign: "left",
  },
  data: {
    width: "16.66%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingLeft: 3.5,
    textAlign: "right",
  },
  headIng: {
    width: "16.66%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingLeft: 3.5,
    textAlign: "center",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    // flexWrap: "wrap",
    marginTop: 2,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    inlineSize: "auto",
    fontStyle: "bold",
    paddingLeft: 5,
  },
  newrow: {
    flexDirection: "row",
    border: "0px",
    // borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    inlineSize: "auto",
    fontStyle: "bold",
    paddingLeft: 5,
  },
  taxRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 32,
    fontStyle: "bold",
  },
  signature: {
    marginTop: 50,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
  tableHeader: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderLeft: "none",
  },
  headerInfo: {
    position: "relative",
    width: "12%",
    marginRight: "2px",
    wordBreak: "break-all",
    borderLeft: "1px solid #E5e5e5",
    fontSize: "8px",
    padding: "2.5px",
  },
  infoWrapper: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    borderTop: "none",
    borderLeft: "none",
  },
  info: {
    position: "relative",
    width: "12%",
    marginRight: "2px",
    borderLeft: "1px solid #E5e5e5",
    fontSize: "7px",
    wordBreak: "break-word",
    padding: "2px",
  },
});
