import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Alert,
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import "../groupDashborad.css";
import { handleSpringDataMutationRequest } from "../../../api";
import { DateRange, DefinedRange } from "react-date-range";
import GroupAreaChart from "./GroupAreaChart";
import GroupLineChart from "./GroupLineChart";
import GroupBiaxialBarChart from "./GroupBiaxialBarChart";
import { useTheme } from "@mui/material/styles";
import {
  CirculerSkeleton,
  NoDataAvailable,
  RectSkeleton,
} from "./StatsSkeleton";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "material-react-toastify";
import "./stats.css";
import GroupBarChart from "./GroupBarChart";
import { toSentenceCase } from "./util";
import Currencies from "../../../Utils/Currencies.json";
import EventIcon from "@mui/icons-material/Event";

const GroupStats = ({ bookingData, groupEmail, hotelIds, fullScreen }) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  useEffect(() => {
    console.log("fullScreen", fullScreen);
    var screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    console.log("Screen width: " + screenWidth + " pixels");
  }, [fullScreen]);

  const [selectedHotels, setSelectedHotels] = useState([
    bookingData[0]?.hotelId,
  ]);
  //------------------Currency Symbol--------------------
  const [currency, setCurrency] = useState("");
  useEffect(() => {
    let currencySymbol = "";
    const res = Currencies?.map((item) => {
      if (item.code === localStorage.getItem("hotelCurrency")) {
        currencySymbol = item.symbol;
      }
    });
    setCurrency(currencySymbol);
  }, []);

  //------------------------Date Range Picker ----------------
  const lastSeven = new Date();
  lastSeven.setDate(lastSeven.getDate() - 7);
  const fastSeven = new Date();
  fastSeven.setDate(fastSeven.getDate() + 7);

  const [dateRange, setDateRange] = useState([
    {
      startDate: lastSeven,
      endDate: fastSeven,
      key: "selection",
    },
  ]);

  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };
  //----------------Rechart--------------------
  const colors = [
    "#96C7C1",
    "#CDF2CA",
    "#F7D59C",
    "#6B7AA1",
    "#F29191",
    "#6E7C7C",
    "#D3E0DC",
  ];

  //----------------------------Occupency Report Line, Revenue Report AreaGraph--------------------
  const [lineData, setLineData] = useState([]);
  const [occupancyLoader, setOccupencyLoader] = useState(false);
  const handleData = (data) => {
    const dataArr = [];
    for (const key in data) {
      const myData = { ...data[key] };
      myData.netOccupancy = data[key]?.netOccupancy?.toFixed(2);
      myData.Occupancy = data[key]?.occupancy?.toFixed(2);
      myData.Revenue = data[key]?.revenue?.toFixed(2);
      myData.Revpar = data[key]?.revpar?.toFixed(2);
      myData.Adr = data[key]?.adr?.toFixed(2);
      myData["Room sold"] = data[key]?.roomSold;
      dataArr.push(myData);
    }
    dataArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setLineData(dataArr);
  };
  const fetchingSalesData = () => {
    setOccupencyLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/get-group-sales-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
      selectedHotels
    )
      .then((data) => {
        setOccupencyLoader(false);
        handleData(data);
      })
      .catch((err) => setOccupencyLoader(false));
  };

  //------------Revenue by source , Stayflexi Booking--------------
  const [revenueBySource, setRevenueBySource] = useState([]);
  const [stayFlexiBooking, setStayFlexiBooking] = useState([]);
  const [stayflexiLoader, setStayflexiLoader] = useState(false);
  const handleRevenueBySourceData = (data) => {
    const arr = [];
    for (let key in data) {
      const tempObj = {};
      tempObj.name = toSentenceCase(key);
      tempObj.Revenue = Number(data[key].toFixed(2));
      arr.push(tempObj);
    }

    arr.sort((a, b) => b.Revenue - a.Revenue);
    setRevenueBySource(arr);
  };

  const handleStayFlexiBookingdata = (data) => {
    const dataArr = [];
    for (const key in data) {
      const myData = { ...data[key] };
      myData.Revenue = data[key]?.revenue?.toFixed(2);
      myData["Room sold"] = data[key]?.roomSold;
      dataArr.push(myData);
    }
    dataArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setStayFlexiBooking(dataArr);
  };

  const fetchingRoomrevData = () => {
    setStayflexiLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/get-group-roomrev-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
      selectedHotels
    )
      .then((data) => {
        setStayflexiLoader(false);
        handleRevenueBySourceData(data?.sourceMap);
        handleStayFlexiBookingdata(data?.sfMap);
      })
      .catch((err) => setStayflexiLoader(false));
  };

  //----------------------------Pick Up Trends-------------------
  const [pickUpTrends, setPickUpTrends] = useState([]);
  const [pickuptrendLoader, setPickuptrendingLoader] = useState(false);
  const handlePickUpTrendData = (data) => {
    // console.log("handlePickUpTrendData", data);
    const dataArr = [];
    for (const key in data) {
      const myData = { ...data[key] };
      myData.Revenue = data[key]?.revenue?.toFixed(2);
      myData["Room sold"] = data[key]?.roomSold;
      dataArr.push(myData);
    }
    dataArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setPickUpTrends(dataArr);
  };
  const fetchingPickupData = () => {
    setPickuptrendingLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/get-group-pickup-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
      selectedHotels
    )
      .then((data) => {
        setPickuptrendingLoader(false);
        handlePickUpTrendData(data);
      })
      .catch((err) => setPickuptrendingLoader(false));
  };

  //--------------------Payment Split By Type-----------------------
  const [paymentType, setPaymentType] = useState([]);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const handlePaymentData = (data) => {
    // console.log("handlePaymentData", data);

    const arr = [];
    for (let key in data) {
      const tempObj = {};
      tempObj.name = toSentenceCase(key);
      tempObj.Revenue = Number(data[key].toFixed(2));
      arr.push(tempObj);
    }
    console.log("paymentType", arr);
    setPaymentType(arr);
  };
  const fetchingPaymentData = () => {
    setPaymentLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/get-group-payment-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
      selectedHotels
    )
      .then((data) => {
        setPaymentLoader(false);
        handlePaymentData(data);
      })
      .catch((err) => setPaymentLoader(false));
  };
  //----------------------------Tax Report and Booking revenue rate plan-------------------
  const [taxLoader, setTaxLoader] = useState(false);
  const [taxData, setTaxData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const handleTaxData = (data) => {
    const arr = [];
    for (let key in data) {
      const tempObj = {};
      tempObj.name = toSentenceCase(key);
      tempObj.Revenue = Number(data[key].toFixed(2));
      arr.push(tempObj);
    }
    setTaxData(arr);
  };

  const hadleBookingRevData = (data) => {
    const arr = [];
    for (let key in data) {
      const tempObj = {};
      tempObj.name = toSentenceCase(key);
      tempObj.Revenue = Number(data[key].toFixed(2));
      arr.push(tempObj);
    }

    setRevenueData(arr);
  };

  const fetchingTaxData = () => {
    setTaxLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/get-group-tax-rp-statistics/?emailId=${groupEmail}&fromDate=${handelingDateForApi(
        dateRange[0]?.startDate
      )}&toDate=${handelingDateForApi(dateRange[0]?.endDate)}`,
      selectedHotels
    )
      .then((data) => {
        setTaxLoader(false);
        console.log("taxdata", data);
        handleTaxData(data?.taxMap);
        hadleBookingRevData(data?.rpMap);
      })
      .catch((err) => setTaxLoader(false));
  };

  //-------------------------------------------------------------------------------------------------------
  useEffect(() => {
    getAllData();
  }, []);

  const dayscalculation = () => {
    const timeDiff = Math.abs(
      dateRange[0]?.endDate?.getTime() - dateRange[0]?.startDate?.getTime()
    );
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  };

  const getAllData = () => {
    if (
      Math.abs((dateRange[0]?.endDate - new Date()) / (1000 * 60 * 60 * 24)) >
        180 ||
      Math.abs((dateRange[0]?.startDate - new Date()) / (1000 * 60 * 60 * 24)) >
        180
    ) {
      toast.error(
        "The date range should be within the past and future 6 months."
      );
      return;
    }
    if (dayscalculation() > 120) {
      toast.error("Selected date range can not more than 4 months.");
      return;
    }
    fetchingSalesData();
    fetchingRoomrevData();
    fetchingPickupData();
    fetchingPaymentData();
    fetchingTaxData();
  };

  const handelingDateForApi = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);
    return formattedDate;
  };
  //-------------Dropdown management -----------------
  useEffect(() => {
    console.log(bookingData);
    const arr = [];
    bookingData.map((elm) => {
      arr.push(elm?.hotelId);
    });
  }, [bookingData]);

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log("value", value);
    // if (value[1] === "all") {
    //   console.log("all");
    //   return;
    // }
    if (value.length > 7) {
      toast.error("you can not select more than seven hotel once");
      return;
    }
    if (value?.filter((elm) => elm === "all").length > 0) {
      const arr = [];
      bookingData.map((item) => arr.push(item?.hotelId));
      setSelectedHotels(arr);
      return;
    }
    setSelectedHotels(typeof value === "string" ? value.split(",") : value);
  };
  //------------Dropdown Apply button-------------
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDrpdownApply = () => {
    getAllData();
    setDropdownOpen(false);
  };

  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            p: "1rem",
          }}
        >
          <Stack spacing={4} className="position-relative" sx={{ mt: 1 }}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box sx={{ position: "relative" }}>
                <Button
                  variant="outlined"
                  onClick={handleClick}
                  fullWidth
                  sx={{ height: 36, color: "black", borderColor: "black" }}
                >
                  {moment(dateRange[0].startDate).format("DD MMM YYYY")}{" "}
                  {" to "}
                  {moment(dateRange[0].endDate).format("DD MMM YYYY")}
                </Button>
                {open ? (
                  <Box sx={styles}>
                    <Stack spacing={3}>
                      <Stack
                        className="position-relative w-100 d-flex justify-content-between"
                        direction="row"
                        sx={{ justifyContent: "space-between" }}
                      >
                        <DefinedRange
                          onChange={(item) => setDateRange([item.selection])}
                          ranges={dateRange}
                        />
                        <DateRange
                          editableDateInputs={true}
                          onChange={(item) => setDateRange([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={dateRange}
                        />
                      </Stack>

                      <Stack
                        className="position-relative w-100 d-flex justify-content-between"
                        direction="row"
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => setOpen(false)}
                          color="error"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="custom-button"
                          onClick={(item) => {
                            getAllData();
                            handleClickAway();
                          }}
                        >
                          Apply
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener>
          </Stack>
          <FormControl fullWidth style={{ width: "40rem", mt: 1 }}>
            <InputLabel
              style={{ width: "40rem" }}
              id="demo-simple-select-label"
            >
              Hotel name
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Hotel name"
              value={selectedHotels}
              style={{ width: "40rem" }}
              onChange={handleChange}
              multiple
              required="true"
              placeholder="Select hotel names"
              open={dropdownOpen}
              onOpen={() => setDropdownOpen(true)}
              onClose={() => setDropdownOpen(false)}
            >
              {bookingData?.length <= 7 && (
                <MenuItem value={"all"}>All</MenuItem>
              )}
              {bookingData?.map((item) => (
                <MenuItem key={item?.hotelId} value={item?.hotelId}>
                  {item?.hotelName}
                </MenuItem>
              ))}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setSelectedHotels([])}
                >
                  Clear
                </Button>
                <Button variant="custom-button" onClick={handleDrpdownApply}>
                  Apply
                </Button>
              </Box>
            </Select>
          </FormControl>
          {/* <Stack>
            <Button
              variant="custom-button"
              sx={{ color: "white!important", height: 36 }}
              className="mr-auto "
              onClick={getAllData}
            >
              Apply
            </Button>
          </Stack> */}
        </Box>

        <Alert severity="warning" sx={{ fontSize: "1rem" }}>
          The data in the graph is an approximation, it is updated every 24
          hours, any changes in the last 24 hours may or may not reflect in this
          statistics. For more precise information, please see the report
          section for each property.
        </Alert>

        {/* ------------------Recharts------------------------------------------------- */}

        <Box sx={{ backgroundColor: "#F5F5F5", p: 1, mt: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {" "}
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Occupancy
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Number of rooms sold out of total available rooms (blocked
                  rooms are excluded)
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupLineChart
                    lineData={lineData}
                    colors={colors}
                    fullScreen={fullScreen}
                    occLine={"Occupancy"}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Room sold
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Number of rooms sold by day for the given date range
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupLineChart
                    lineData={lineData}
                    colors={colors}
                    fullScreen={fullScreen}
                    occLine={"Room sold"}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Revenue by day
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Room revenue generated by day for the given date range.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupAreaChart
                    lineData={lineData}
                    colors={colors}
                    revenueLine={["Revenue"]}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Revenue by source
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Room revenue generated by booking source for the given date
                  range.
                </Typography>
                {stayflexiLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBarChart
                    sourceData={revenueBySource}
                    colors={colors}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            {/* ----Revenue by hotels--- */}
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Revenue by rate plan
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  Room revenue generated by rate plan for the given date range.
                </Typography>
                {taxLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBarChart
                    sourceData={revenueData}
                    colors={colors}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>

            {/* <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Revenue per available room
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  RevPAR (Revenue Per Available Room) statistics indicate the
                  average revenue generated per room in a property over a
                  specific timeframe.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupAreaChart
                    lineData={lineData}
                    colors={colors}
                    revenueLine={["Revpar"]}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Average daily rate
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  ADR (Average Daily Rate) statistics graph illustrates the
                  average room price charged per day within a designated
                  timeframe for a property. ADR is pivotal in assessing pricing
                  strategies and revenue performance.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupAreaChart
                    lineData={lineData}
                    colors={colors}
                    revenueLine={["Adr"]}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Payment method distribution
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  This graph visually presents the distribution of payment
                  methods used by both guests and the hotel to settle bills. It
                  depicts the relative proportions of payments made through
                  various methods, including cash, credit/debit cards, UPI
                  payments, and bank transfers. This analysis assists the
                  property in understanding preferred payment modes and
                  optimizing its payment processing systems accordingly.
                </Typography>
                {paymentLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBarChart
                    sourceData={paymentType}
                    colors={colors}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Pick up revenue
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  This data depicts the income generated from room sales on a
                  specific date, encompassing bookings for both current and
                  future dates.
                </Typography>
                {pickuptrendLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBiaxialBarChart
                    colors={colors}
                    stayData={pickUpTrends}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Pick up room sold
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  The “Pick Up” graph illustrates the total bookings made for a
                  specific date, whether it’s for the current day or a future
                  one. It displays the increase or decrease in bookings
                  (pick-ups or drops) for that particular date. This graph helps
                  property in comprehending fluctuations in demand, facilitating
                  informed decision-making and strategic planning based on
                  pick-up trends.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupLineChart
                    lineData={pickUpTrends}
                    colors={colors}
                    fullScreen={fullScreen}
                    occLine={"Room sold"}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Stayflexi room sold revenue
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  This graph visually represents the revenue generated directly
                  through the hotel’s website, facilitated by the Stayflexi
                  booking engine and the GHA partnership.
                </Typography>
                {stayflexiLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBiaxialBarChart
                    colors={colors}
                    stayData={stayFlexiBooking}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Stayflexi room sold
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  This graph illustrates bookings originating directly from the
                  hotel website, facilitated by the Stayflexi booking engine and
                  GHA.
                </Typography>

                {occupancyLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupLineChart
                    lineData={stayFlexiBooking}
                    colors={colors}
                    fullScreen={fullScreen}
                    occLine={"Room sold"}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="stats">
                <Typography variant="h6" align="center">
                  Taxation insights
                </Typography>
                <Typography sx={{ color: "gray" }} align="center">
                  The “Taxation Insights” graph depicts the itemized breakdown
                  of taxes accrued by the property. It showcases various tax
                  types (e.g., sales tax, occupancy tax, GST) collected from
                  guests. This visualization enhances comprehension of tax
                  implications on the hotel’s revenue.
                </Typography>
                {taxLoader ? (
                  <RectSkeleton />
                ) : (
                  <GroupBarChart
                    sourceData={taxData}
                    colors={colors}
                    fullScreen={fullScreen}
                    currency={currency}
                  />
                )}
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(GroupStats);
