// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import SetPricingComponent from "./SetPricingView";
import InventoryCapping from "./InventoryCappingView";
import SetOnlineView from "./SetOnlineView";
import UpdateRatesView from "./UpdateRatesView";
import { DropdownOptions } from "../constants/InventoryConstants";
import { getAllNonHiddenRoomTypes } from "../api/InventoryApi";
import CommonSlider from "../../CalendarPage/Views/CommonSlider";
import OpenCloseView from "./OpenCloseViews";
import { connect } from "react-redux";
import { handleSpringDataRequest } from "../../../api";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(2),
  },
  linerProgress: {
    marginBottom: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginop: theme.spacing(2),
    },
  },
  header: {
    padding: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
    },
  },
};

const EditEventSidebar = (props) => {
  const EditRateAccess =
    JSON.parse(props?.data?.accessControl?.user_feature_map?.EditRates).read ===
    "YES";

  const EditInevntoryAccess =
    JSON.parse(props?.data?.accessControl?.user_feature_map?.EditInventory)
      .read === "YES";

  const [rooms, setRooms] = React.useState();
  const [selectedRoom, setSelectedRoom] = useState("");
  const [actionType, setActionType] = useState(
    EditRateAccess
      ? "set-price"
      : EditInevntoryAccess
      ? "set-inventory"
      : "edit-room"
  );
  const [dataLoading, setDataLoading] = useState(true);
  const [showLine, setShowLine] = useState(false);
  const classes = useStyles();
  const { handleAddEventSidebar, open, refresh } = props;

  const setLineVisible = () => {
    setShowLine(true);
  };

  const setLineInvisible = () => {
    setShowLine(false);
  };

  const isOnlineComponent = (selectType) => {
    if (
      selectType === "set-online" ||
      selectType === "set-offline" ||
      selectType === "block-room" ||
      selectType === "unblock-room" ||
      selectType === "delete-room" ||
      selectType === "edit-room" ||
      "sold-out"
    ) {
      return true;
    }
    return false;
  };

  const getActionName = (name) => {
    const item = DropdownOptions.find((option) => option.val === name);
    return item.text;
  };

  const intialFunc = async () => {
    setLineVisible();
    setDataLoading(true);
    let resp = await getAllNonHiddenRoomTypes();
    setSelectedRoom(Object.keys(resp)[0]);
    setRooms(resp);
    setDataLoading(false);
  };

  useEffect(() => {
    intialFunc();
  }, []);

  const [roomAndPlans, setroomAndPlans] = useState([]);

  useEffect(() => {
    handleSpringDataRequest(`core/api/v1/room/get-all-roomtype-obj`)
      .then((res) => {
        setroomAndPlans(res);
      })
      .catch((err) => console.error(err));
  }, []);

  const [AccessControl, setAccessControl] = useState([
    {
      EditRateAccess: new Set([
        "Set Pricing",
        "Rate Multiplier",
        "Update Rates from OTA",
        "Min Advanced Booking Offset",
        "Max Advanced Booking Offset",
      ]),
    },
    {
      EditInevntoryAccess: new Set([
        "Set Inventory",
        "Inventory Capping",
        "Set Offline",
        "Set Online",
        "Block Rooms",
        "Unblock Rooms",
        "Set Sold Out",
        "Reassign Room Type",
        "Close/Open Room Type",
        "Close/Open Rate Plan",
        "Close/Open On Arrival",
        "Close/Open On Departure",
        "Min Length of Stay",
        "Max Length of Stay",
        "Delete Rooms",
        "Restore Deleted Rooms",
        "Edit Room Ids",
      ]),
    },
  ]);

  console.log(
    "sa",
    JSON.parse(props?.data?.accessControl?.user_feature_map?.HideBlockRooms)
      .read
  );

  return (
    <div className={classes.root}>
      <CommonSlider
        open={open}
        onClose={() => {
          handleAddEventSidebar();
        }}
      >
        <Grid container>
          <Grid container item xs={12} className={classes.header}>
            <Grid
              container
              xs={10}
              spacing={2}
              justifyContent="flex-start"
              item
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Action Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={actionType}
                    label="Action Type"
                    title={getActionName(actionType)}
                    onChange={(eve) => {
                      if (isOnlineComponent(eve.target.value)) {
                        setSelectedRoom("all-rooms");
                      }
                      if (
                        selectedRoom === "all-rooms" &&
                        !isOnlineComponent(eve.target.value)
                      ) {
                        setSelectedRoom(Object.keys(rooms)[0]);
                      }
                      setActionType(eve.target.value);
                    }}
                    MenuProps={MenuProps}
                  >
                    {DropdownOptions.map((name) => {
                      if (EditRateAccess) {
                        if (AccessControl[0]["EditRateAccess"].has(name.text)) {
                          return (
                            <MenuItem key={name.text} value={name.val}>
                              {name.text}
                            </MenuItem>
                          );
                        }
                      }
                      if (EditInevntoryAccess) {
                        if (
                          AccessControl[1]["EditInevntoryAccess"].has(
                            name.text
                          ) &&
                          name.text === "Unblock Rooms" &&
                          props?.data?.accessControl?.ADMINRIGHTS !== "ADMIN" &&
                          props?.data?.accessControl?.ADMINRIGHTS !==
                            "SUBADMIN" &&
                          JSON.parse(
                            props?.data?.accessControl?.user_feature_map
                              ?.HideUnBlockRooms
                          ).read !== "YES"
                        ) {
                          return (
                            <MenuItem key={name.text} value={name.val}>
                              {name.text}
                            </MenuItem>
                          );
                        }

                        if (
                          AccessControl[1]["EditInevntoryAccess"].has(
                            name.text
                          ) &&
                          name.text === "Block Rooms" &&
                          props?.data?.accessControl?.ADMINRIGHTS !== "ADMIN" &&
                          props?.data?.accessControl?.ADMINRIGHTS !==
                            "SUBADMIN" &&
                          JSON.parse(
                            props?.data?.accessControl?.user_feature_map
                              ?.HideBlockRooms
                          ).read !== "YES"
                        ) {
                          return (
                            <MenuItem key={name.text} value={name.val}>
                              {name.text}
                            </MenuItem>
                          );
                        }

                        if (
                          AccessControl[1]["EditInevntoryAccess"].has(
                            name.text
                          ) &&
                          name.text !== "Block Rooms" &&
                          name.text !== "Unblock Rooms" &&
                          name.text !== "Delete Rooms" &&
                          name.text !== "Restore Deleted Rooms" &&
                          props?.data?.accessControl?.ADMINRIGHTS !== "ADMIN" &&
                          props?.data?.accessControl?.ADMINRIGHTS !== "SUBADMIN"
                        ) {
                          return (
                            <MenuItem key={name.text} value={name.val}>
                              {name.text}
                            </MenuItem>
                          );
                        }

                        if (
                          AccessControl[1]["EditInevntoryAccess"].has(
                            name.text
                          ) &&
                          (props?.data?.accessControl?.ADMINRIGHTS ===
                            "ADMIN" ||
                            props?.data?.accessControl?.ADMINRIGHTS ===
                              "SUBADMIN")
                        ) {
                          return (
                            <MenuItem key={name.text} value={name.val}>
                              {name.text}
                            </MenuItem>
                          );
                        }
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {!dataLoading && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      hidden={
                        actionType === "rate-multiplier" ||
                        actionType === "inventory-cap" ||
                        actionType === "Set Max Adv Offset" ||
                        actionType === "Set Cutoff" ||
                        actionType === "Set Max Los" ||
                        actionType === "Set Min Los" ||
                        actionType === "Close On Departure" ||
                        actionType === "Close On Arrival" ||
                        actionType === "Close Room" ||
                        actionType === "Close Rate" ||
                        actionType === "set-inventory" ||
                        actionType === "set-price"
                      }
                      id="demo-simple-select-label"
                    >
                      Room Types
                    </InputLabel>
                    <Select
                      disabled={
                        actionType === "sold-out" ||
                        actionType === "update-ota" ||
                        actionType === "edit-room"
                      }
                      labelId="demo-simple-select-label"
                      onChange={(eve) => {
                        setSelectedRoom(eve.target.value);
                      }}
                      hidden={
                        actionType === "rate-multiplier" ||
                        actionType === "inventory-cap" ||
                        actionType === "Set Max Adv Offset" ||
                        actionType === "Set Cutoff" ||
                        actionType === "Set Max Los" ||
                        actionType === "Set Min Los" ||
                        actionType === "Close On Departure" ||
                        actionType === "Close On Arrival" ||
                        actionType === "Close Room" ||
                        actionType === "Close Rate" ||
                        actionType === "set-inventory" ||
                        actionType === "set-price"
                      }
                      id="demo-simple-select"
                      value={selectedRoom}
                      label="Room Types"
                      MenuProps={MenuProps}
                    >
                      {actionType === "sold-out" && (
                        <MenuItem key="all-rooms" value={"all-rooms"}>
                          {" "}
                          All Rooms
                        </MenuItem>
                      )}
                      {actionType !== "sold-out" &&
                        isOnlineComponent(actionType) && (
                          <MenuItem key="all-rooms" value={"all-rooms"}>
                            {" "}
                            All Rooms
                          </MenuItem>
                        )}
                      {Object.keys(rooms).map((name) => (
                        <MenuItem key={name} value={name}>
                          {rooms[name]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container xs={2} item justifyContent="flex-end">
              <IconButton title="close" onClick={handleAddEventSidebar}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          {showLine && <LinearProgress className={classes.linerProgress} />}
          {!showLine && <Divider sx={{ width: "100%", marginBottom: 2 }} />}

          {!dataLoading && (
            <Grid sx={{ width: "100%", height: "100%" }} container>
              {actionType === "set-price" && (
                <SetPricingComponent
                  data={props.data}
                  componentType="set-price"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                  roomAndPlanscopy={cloneDeep(roomAndPlans)}
                />
              )}
              {actionType === "set-inventory" && (
                <SetPricingComponent
                  data={props.data}
                  componentType="set-inventory"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}
              {actionType === "rate-multiplier" && (
                <InventoryCapping
                  componentType="rate-multiplier"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}
              {actionType === "inventory-cap" && (
                <InventoryCapping
                  componentType="inventory-cap"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}
              {actionType === "set-online" && (
                <SetOnlineView
                  data={props.data}
                  componentType="set-online"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "set-offline" && (
                <SetOnlineView
                  data={props.data}
                  componentType="set-offline"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "block-room" && (
                <SetOnlineView
                  data={props.data}
                  componentType="block-room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "unblock-room" && (
                <SetOnlineView
                  data={props.data}
                  componentType="unblock-room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "delete-room" && (
                <SetOnlineView
                  data={props.data}
                  componentType="delete-room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "restore" && (
                <SetOnlineView
                  data={props.data}
                  componentType="restore"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "sold-out" && (
                <UpdateRatesView
                  data={props.data}
                  componentType="sold-out"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "update-ota" && (
                <UpdateRatesView
                  data={props.data}
                  componentType="update-ota"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "reassign" && (
                <SetOnlineView
                  data={props.data}
                  componentType="reassign"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "edit-room" && (
                <UpdateRatesView
                  data={props.data}
                  componentType="edit-room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                />
              )}

              {actionType === "Close Rate" && (
                <OpenCloseView
                  componentType="Close Rate"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}

              {actionType === "Close Room" && (
                <OpenCloseView
                  componentType="Close Room"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}

              {actionType === "Close On Arrival" && (
                <OpenCloseView
                  componentType="Close On Arrival"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}

              {actionType === "Close On Departure" && (
                <OpenCloseView
                  componentType="Close On Departure"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}

              {actionType === "Set Min Los" && (
                <OpenCloseView
                  componentType="Set Min Los"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}

              {actionType === "Set Max Los" && (
                <OpenCloseView
                  componentType="Set Max Los"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}

              {actionType === "Set Cutoff" && (
                <OpenCloseView
                  componentType="Set Cutoff"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}

              {actionType === "Set Max Adv Offset" && (
                <OpenCloseView
                  componentType="Set Max Adv Offset"
                  selectedRoom={selectedRoom}
                  rooms={rooms}
                  setLineInvisible={setLineInvisible}
                  refresh={refresh}
                  setLineVisible={setLineVisible}
                  closeFunc={handleAddEventSidebar}
                  roomAndPlans={roomAndPlans}
                />
              )}
            </Grid>
          )}
        </Grid>
      </CommonSlider>
    </div>
  );
};
function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}

export default connect(mapStateToProps)(EditEventSidebar);
