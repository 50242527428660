import React, { useState, useEffect } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import "material-react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";

const borderColor = "#E5e5e5";

// formatting the date
const formatDate = () => {
  return moment().format("MMM DD YYYY hh:mm A");
};

// rounding of the digits
const handleDigits = (val) => {
  if (val == null) {
    return 0;
  } else {
    return parseFloat(val).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  }
};

// changing digits into Integer
const handleDigitsInt = (val) => {
  if (val == null) {
    return 0;
  } else {
    return parseInt(val);
  }
};

// Getting the flash manager report header
const FlashManagerReportHeader = ({ reportDate }) => (
  <View style={[styles.container, { fontSize: "8px" }]}>
    <Text style={[styles.metric, { fontSize: "8px" }]}>Metric</Text>
    <Text style={[styles.headIng, { fontSize: "8px" }]}>{reportDate}</Text>
    <Text style={[styles.headIng, { fontSize: "8px" }]}>
      Month to date (MTD)
    </Text>
    <Text style={[styles.headIng, { fontSize: "8px" }]}>
      Year to date (YTD)
    </Text>
  </View>
);

// Single column flash manager report
const FlashManagerReportHeaderSC = () => (
  <View style={[styles.scContainer, { fontSize: "8px" }]}>
    <Text style={[styles.metric, { fontSize: "8px", width: "70%" }]}>
      Metric
    </Text>
    <Text style={[styles.newmatric, { fontSize: "8px", width: "30%" }]}>
      Data
    </Text>
  </View>
);

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

function FlashManagerReportDoc({
  reportData,
  symbol,
  hotelName,
  reportDate,
  hotelLogo,
  address,
  isDbField,
  taxesList,
  detailedView,
  taxBreakdown,
  mealPlanReport,
}) {
  const [finalAddress, setfinalAddress] = useState("");
  const concatAddress = () => {
    const addr1 =
      address &&
      address.address1 !== null &&
      address.address1 !== "NA" &&
      address.address1;
    const addr2 =
      address &&
      address.address2 !== null &&
      address.address2 !== "NA" &&
      address.address2;

    let finaladd = "";
    if (addr1 && addr1 !== undefined && (addr2 === null || addr2 === undefined))
      finaladd = addr1;
    else if (
      addr2 &&
      addr2 !== undefined &&
      (addr1 === null || addr1 === undefined)
    )
      finaladd = addr2;
    else finaladd = addr1 + ", " + addr2;
    setfinalAddress(finaladd);
  };

  // Handling customer address
  const handleCustomerAddress = (address) => {
    if (!address.length > 0) {
      return "";
    } else if (address.includes("null")) {
      return "";
    } else if (address.includes("NA")) {
      return "";
    } else {
      let parsed = parseInt(address);
      return String(parsed) === String(NaN) ? [`${address}, `] : parsed + "";
    }
  };

  useEffect(() => {
    concatAddress();
  }, [address]);

  // Get the total of the payment gateway report
  function getTotalForPg(report = [], type) {
    console.log("report : ", report);
    const sum = report?.reduce((acc, curr) => {
      acc += parseFloat(curr[type]);
      return acc;
    }, 0);
    return Number(sum.toFixed(2));
  }

  return (
    <View
      style={{
        position: "relative",
        width: "100%",
        padding: 10,
      }}
    >
      <View
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            position: "relative",
            width: "33%",
          }}
        >
          <Text style={{ fontSize: "10px" }}>
            <b>Flash Manager Report - {reportDate}</b>
          </Text>
          <Text style={{ fontSize: "8px", color: "grey" }}>
            Report printed on : {moment().format("MMM DD YYYY hh:mm A")}
          </Text>
        </View>
        {hotelLogo && getExtension(hotelLogo) && (
          <View
            style={{ positon: "relative", alignItems: "center", width: "33%" }}
          >
            <Image style={{ width: "45px" }} src={getExtension(hotelLogo)} />
          </View>
        )}
        <View
          style={{
            position: "relative",
            width: "33%",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Text style={{ fontSize: "10px" }}>{hotelName}</Text>

          <Text style={{ fontSize: "8px", color: "grey" }}>{finalAddress}</Text>
          <Text style={{ fontSize: "8px", color: "grey" }}>
            {handleCustomerAddress(address.city) +
              handleCustomerAddress(address.state) +
              handleCustomerAddress(address.country) +
              handleCustomerAddress(address.pincode)}
          </Text>
        </View>
      </View>
      {/* Property report */}
      <View style={{ marginTop: "10px" }}>
        <Text style={{ fontSize: "8px" }}>Property report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Rooms sold
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {parseInt(reportData?.prop_flash_report[0].td)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {parseInt(reportData?.prop_flash_report[0].mtd)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {parseInt(reportData?.prop_flash_report[0].ytd)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Occupancy
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {reportData?.prop_flash_report[0].td_occupancy}%
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {reportData?.prop_flash_report[0].mtd_occupancy}%
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {reportData?.prop_flash_report[0].ytd_occupancy}%
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                ADR - Average Daily Rate
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_ADR)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].mtd_ADR)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].ytd_ADR)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                RevPAR - Revenue Per Available Room
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_REVPAR)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].mtd_REVPAR)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].ytd_REVPAR)}
            </Text>
          </View>
        </View>
      </View>
      {/* Revenue report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "8px" }}>Revenue report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Room Revenue
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_rev)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].mtd_rev)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].ytd_rev)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                POS Revenue
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.pos_flash_report[0]?.td_amount)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.pos_flash_report[0]?.mtd_amount)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.pos_flash_report[0]?.ytd_amount)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Services Revenue
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.services_flash_report[0].td_amount)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.services_flash_report[0].mtd_amount)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.services_flash_report[0].ytd_amount)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Booking fee
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_bfee)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].mtd_bfee)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].ytd_bfee)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol + " " + handleDigits(reportData?.total_rev.td_rev_total)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol + " " + handleDigits(reportData?.total_rev.mtd_rev_total)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol + " " + handleDigits(reportData?.total_rev.ytd_rev_total)}
            </Text>
          </View>
        </View>
      </View>
      {/* Tax Revenue Report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "8px" }}>Tax Revenue Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Partial tax Exempt Revenue
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.prop_flash_report[0].td_partial_texmpt_rev
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.prop_flash_report[0].mtd_partial_texmpt_rev
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.prop_flash_report[0].ytd_partial_texmpt_rev
                )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Total tax Exempt Revenue
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.prop_flash_report[0].td_total_texmpt_rev
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.prop_flash_report[0].mtd_total_texmpt_rev
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.prop_flash_report[0].ytd_total_texmpt_rev
                )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Taxable Revenue
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_txble_rev)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].mtd_txble_rev)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].ytd_txble_rev)}
            </Text>
          </View>
        </View>
      </View>
      {/* Taxes Report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "8px" }}>Taxes Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Room Taxes
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].td_rev_tax)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].mtd_rev_tax)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.prop_flash_report[0].ytd_rev_tax)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                POS Taxes
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.pos_flash_report[0].td_amount_tax)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.pos_flash_report[0].mtd_amount_tax)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.pos_flash_report[0].ytd_amount_tax)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Services Tax
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.services_flash_report[0].td_amount_tax
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.services_flash_report[0].mtd_amount_tax
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.services_flash_report[0].ytd_amount_tax
                )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol + " " + handleDigits(reportData?.total_tax.td_tax_total)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol + " " + handleDigits(reportData?.total_tax.mtd_tax_total)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol + " " + handleDigits(reportData?.total_tax.ytd_tax_total)}
            </Text>
          </View>
        </View>
      </View>

      {/* Tax breakdown report */}
      {detailedView && (
        <View style={{ marginTop: "8px" }} break>
          <Text style={{ fontSize: "8px" }}>Tax Breakdown Report</Text>
          <View style={styles.tableContainer}>
            <FlashManagerReportHeader reportDate={reportDate} />
            {taxesList?.map((tax) => (
              <View style={{ display: "flex", flexDirection: "column" }}>
                <View style={styles.row}>
                  <View
                    style={[
                      styles.metric,
                      {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        flexWrap: "flex-wrap",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        { marginBottom: 3, fontSize: "8px", marginRight: 5 },
                      ]}
                    >
                      {tax}
                    </Text>
                  </View>
                  <Text
                    style={[
                      styles.data,
                      { fontSize: "8px", borderLeft: "none" },
                    ]}
                  >
                    {" "}
                  </Text>
                  <Text
                    style={[
                      styles.data,
                      { fontSize: "8px", borderLeft: "none" },
                    ]}
                  >
                    {" "}
                  </Text>
                  <Text
                    style={[
                      styles.data,
                      { fontSize: "8px", borderLeft: "none" },
                    ]}
                  >
                    {" "}
                  </Text>
                </View>
                {taxBreakdown?.map((type) => (
                  <View style={styles.row}>
                    <View
                      style={[
                        styles.metric,
                        {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          flexWrap: "flex-wrap",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          { marginBottom: 3, fontSize: "8px", marginRight: 5 },
                        ]}
                      >
                        {type
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </Text>
                    </View>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {type?.toLowerCase() === "tax_percent"
                        ? reportData?.prop_flash_report[0]
                            ?.td_tax_breakdown_list[
                            reportData?.prop_flash_report[0]?.td_tax_breakdown_list?.findIndex(
                              (item) => item?.tax_name === tax
                            )
                          ][type] + "%"
                        : symbol +
                          " " +
                          handleDigits(
                            reportData?.prop_flash_report[0]
                              ?.td_tax_breakdown_list[
                              reportData?.prop_flash_report[0]?.td_tax_breakdown_list?.findIndex(
                                (item) => item?.tax_name === tax
                              )
                            ][type]
                          )}
                    </Text>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {type?.toLowerCase() === "tax_percent"
                        ? reportData?.prop_flash_report[0]
                            ?.mtd_tax_breakdown_list[
                            reportData?.prop_flash_report[0]?.mtd_tax_breakdown_list?.findIndex(
                              (item) => item?.tax_name === tax
                            )
                          ][type] + "%"
                        : symbol +
                          " " +
                          handleDigits(
                            reportData?.prop_flash_report[0]
                              ?.mtd_tax_breakdown_list[
                              reportData?.prop_flash_report[0]?.mtd_tax_breakdown_list?.findIndex(
                                (item) => item?.tax_name === tax
                              )
                            ][type]
                          )}
                    </Text>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {type?.toLowerCase() === "tax_percent"
                        ? reportData?.prop_flash_report[0]
                            ?.ytd_tax_breakdown_list[
                            reportData?.prop_flash_report[0]?.ytd_tax_breakdown_list?.findIndex(
                              (item) => item?.tax_name === tax
                            )
                          ][type] + "%"
                        : symbol +
                          " " +
                          handleDigits(
                            reportData?.prop_flash_report[0]
                              ?.ytd_tax_breakdown_list[
                              reportData?.prop_flash_report[0]?.ytd_tax_breakdown_list?.findIndex(
                                (item) => item?.tax_name === tax
                              )
                            ][type]
                          )}
                    </Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      )}

      {/* Payments Summary Report */}
      <View style={{ marginTop: "8px" }} break={!detailedView ? true : false}>
        <Text style={{ fontSize: "8px" }}>Payments Summary Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          {reportData?.counter_flash_report.length > 0 &&
            reportData?.counter_flash_report.map(
              (item, idx) =>
                item?.service_description.toLowerCase() !==
                  "direct billing" && (
                  <View style={styles.row}>
                    <View
                      style={[
                        styles.metric,
                        {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          flexWrap: "flex-wrap",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          { marginBottom: 3, fontSize: "8px", marginRight: 5 },
                        ]}
                      >
                        {item.service_description === "Offline Check Payment"
                          ? "Offline Cheque Payment"
                          : item.service_description}
                      </Text>
                    </View>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {symbol + " " + handleDigits(item.td_amount)}
                    </Text>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {symbol + " " + handleDigits(item.mtd_amount)}
                    </Text>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {symbol + " " + handleDigits(item.ytd_amount)}
                    </Text>
                  </View>
                )
            )}

          {reportData?.pg_payments_summary_flash_report.length > 0 &&
            reportData?.pg_payments_summary_flash_report.map((item, idx) => (
              <View style={styles.row}>
                <View
                  style={[
                    styles.metric,
                    {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      flexWrap: "flex-wrap",
                    },
                  ]}
                >
                  <Text
                    style={[
                      { marginBottom: 3, fontSize: "8px", marginRight: 5 },
                    ]}
                  >
                    {item.payment_type}
                  </Text>
                </View>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.td_amount)}
                </Text>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.mtd_amount)}
                </Text>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.ytd_amount)}
                </Text>
              </View>
            ))}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.total_payments.td_payments_total -
                    (isDbField
                      ? reportData?.counter_flash_report.filter(
                          (item) =>
                            item.service_description === "Direct Billing"
                        )[0].td_amount
                      : 0)
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.total_payments.mtd_payments_total -
                    (isDbField
                      ? reportData?.counter_flash_report.filter(
                          (item) =>
                            item.service_description === "Direct Billing"
                        )[0].mtd_amount
                      : 0)
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.total_payments.ytd_payments_total -
                    (isDbField
                      ? reportData?.counter_flash_report.filter(
                          (item) =>
                            item.service_description === "Direct Billing"
                        )[0].ytd_amount
                      : 0)
                )}
            </Text>
          </View>
          {/* Dont touch this */}
          {/* <View style={styles.newrow}></View> */}
        </View>
      </View>

      {/* Payments Detailed Report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "8px" }}>Payments Detailed Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          {reportData?.counter_flash_report.length > 0 &&
            reportData?.counter_flash_report.map(
              (item, idx) =>
                item?.service_description.toLowerCase() !==
                  "direct billing" && (
                  <View style={styles.row}>
                    <View
                      style={[
                        styles.metric,
                        {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          flexWrap: "flex-wrap",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          { marginBottom: 3, fontSize: "8px", marginRight: 5 },
                        ]}
                      >
                        {item.service_description === "Offline Check Payment"
                          ? "Offline Cheque Payment"
                          : item.service_description}
                      </Text>
                    </View>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {symbol + " " + handleDigits(item.td_amount)}
                    </Text>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {symbol + " " + handleDigits(item.mtd_amount)}
                    </Text>
                    <Text style={[styles.data, { fontSize: "8px" }]}>
                      {symbol + " " + handleDigits(item.ytd_amount)}
                    </Text>
                  </View>
                )
            )}

          {reportData?.pg_payments_flash_report.length > 0 &&
            reportData?.pg_payments_flash_report.map((item, idx) => (
              <View style={styles.row}>
                <View
                  style={[
                    styles.metric,
                    {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      flexWrap: "flex-wrap",
                    },
                  ]}
                >
                  <Text
                    style={[
                      { marginBottom: 3, fontSize: "8px", marginRight: 5 },
                    ]}
                  >
                    {item.payment_type}
                  </Text>
                </View>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.td_amount)}
                </Text>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.mtd_amount)}
                </Text>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.ytd_amount)}
                </Text>
              </View>
            ))}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.total_payments.td_payments_total -
                    (isDbField
                      ? reportData?.counter_flash_report.filter(
                          (item) =>
                            item.service_description === "Direct Billing"
                        )[0].td_amount
                      : 0)
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.total_payments.mtd_payments_total -
                    (isDbField
                      ? reportData?.counter_flash_report.filter(
                          (item) =>
                            item.service_description === "Direct Billing"
                        )[0].mtd_amount
                      : 0)
                )}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(
                  reportData?.total_payments.ytd_payments_total -
                    (isDbField
                      ? reportData?.counter_flash_report.filter(
                          (item) =>
                            item.service_description === "Direct Billing"
                        )[0].ytd_amount
                      : 0)
                )}
            </Text>
          </View>
          {/* Dont touch this */}
          <View style={styles.newrow}></View>
        </View>
      </View>

      {/* Payment gateway breakup */}
      {/* {reportData?.pg_payments_flash_report.length > 0 && (
        <View style={{ marginTop: "8px" }}>
          <Text style={{ fontSize: "8px" }}>Payment gateway breakup</Text>
          <View style={styles.tableContainer}>
            <FlashManagerReportHeader reportDate={reportDate} />
            {reportData?.pg_payments_flash_report.map((item, idx) => (
              <View style={styles.row}>
                <View
                  style={[
                    styles.metric,
                    {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      flexWrap: "flex-wrap",
                    },
                  ]}
                >
                  <Text
                    style={[
                      { marginBottom: 3, fontSize: "8px", marginRight: 5 },
                    ]}
                  >
                    {item.payment_type}
                  </Text>
                </View>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.td_amount)}
                </Text>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.mtd_amount)}
                </Text>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.ytd_amount)}
                </Text>
              </View>
            ))}
          </View>
        </View>
      )} */}

      {/* Direct billing report */}
      {isDbField && (
        <View style={{ marginTop: "8px" }}>
          <Text style={{ fontSize: "8px" }}>Direct Billing Report</Text>
          <View style={styles.tableContainer}>
            <FlashManagerReportHeader reportDate={reportDate} />
            {reportData?.counter_flash_report.length > 0 &&
              reportData?.counter_flash_report.map(
                (item, idx) =>
                  item?.service_description.toLowerCase() ===
                    "direct billing" && (
                    <View style={styles.row}>
                      <View
                        style={[
                          styles.metric,
                          {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "flex-wrap",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              marginBottom: 3,
                              fontSize: "8px",
                              marginRight: 5,
                            },
                          ]}
                        >
                          {item.service_description === "Offline Check Payment"
                            ? "Offline Cheque Payment"
                            : item.service_description}
                        </Text>
                      </View>
                      <Text style={[styles.data, { fontSize: "8px" }]}>
                        {symbol + " " + handleDigits(item.td_amount)}
                      </Text>
                      <Text style={[styles.data, { fontSize: "8px" }]}>
                        {symbol + " " + handleDigits(item.mtd_amount)}
                      </Text>
                      <Text style={[styles.data, { fontSize: "8px" }]}>
                        {symbol + " " + handleDigits(item.ytd_amount)}
                      </Text>
                    </View>
                  )
              )}

            {/* Dont touch this */}
            <View style={styles.newrow}></View>
          </View>
        </View>
      )}

      {/* Expense Report */}
      <View style={{ marginTop: "8px" }}>
        <Text style={{ fontSize: "8px" }}>Expense Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeader reportDate={reportDate} />
          {reportData?.expense_report.length > 0 &&
            reportData?.expense_report.map((item, idx) => (
              <View style={styles.row}>
                <View
                  style={[
                    styles.metric,
                    {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      flexWrap: "flex-wrap",
                    },
                  ]}
                >
                  <Text
                    style={[
                      { marginBottom: 3, fontSize: "8px", marginRight: 5 },
                    ]}
                  >
                    {item.type_of_expanse}
                  </Text>
                </View>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.td_amount)}
                </Text>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.mtd_amount)}
                </Text>
                <Text style={[styles.data, { fontSize: "8px" }]}>
                  {symbol + " " + handleDigits(item.ytd_amount)}
                </Text>
              </View>
            ))}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: "flex-wrap",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Total
              </Text>
            </View>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.total_expenses.td_expense_total)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.total_expenses.mtd_expense_total)}
            </Text>
            <Text style={[styles.data, { fontSize: "8px" }]}>
              {symbol +
                " " +
                handleDigits(reportData?.total_expenses.ytd_expense_total)}
            </Text>
          </View>
        </View>
      </View>

      {/* Inventory Report */}
      <View style={{ marginTop: "8px" }} break={true}>
        <Text style={{ fontSize: "8px" }}>Inventory Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeaderSC />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Total Rooms
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(reportData?.inventory_flash_report.total_rooms)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Available Rooms
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.inventory_flash_report.available_rooms
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Booked Rooms
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(reportData?.inventory_flash_report.booked_rooms)}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Out of order rooms
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.inventory_flash_report.disabled_rooms
              )}
            </Text>
          </View>
        </View>
      </View>
      {/* House Report */}
      <View style={{ marginTop: "25px" }} break>
        <Text style={{ fontSize: "8px" }}>House Report</Text>
        <View style={styles.tableContainer}>
          <FlashManagerReportHeaderSC />
          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Today Bookings
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.newbookings
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                In House
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.inhouse
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Today Arrivals
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.checkins
              )}
            </Text>
          </View>

          {reportData?.house_flash_report.house_summary.confirmed > 0 && (
            <View style={styles.row}>
              <View
                style={[
                  styles.metric,
                  {
                    display: "flex",
                    width: "70%",
                  },
                ]}
              >
                <Text
                  style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
                >
                  Expected Arrivals
                </Text>
              </View>
              <Text style={[styles.newmatric, { fontSize: "8px" }]}>
                {handleDigitsInt(
                  reportData?.house_flash_report.house_summary.confirmed
                )}
              </Text>
            </View>
          )}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Today Departures
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.checkouts
              )}
            </Text>
          </View>

          {reportData?.house_flash_report.house_summary.pendingcheckouts >
            0 && (
            <View style={styles.row}>
              <View
                style={[
                  styles.metric,
                  {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    flexWrap: "flex-wrap",
                  },
                ]}
              >
                <Text
                  style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
                >
                  Pending Departures
                </Text>
              </View>
              <Text style={[styles.newmatric, { fontSize: "8px" }]}>
                {handleDigitsInt(
                  reportData?.house_flash_report.house_summary.pendingcheckouts
                )}
              </Text>
            </View>
          )}

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                Cancellations{" "}
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.cancellations
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                On Hold{" "}
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.holds
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <View
              style={[
                styles.metric,
                {
                  display: "flex",
                  width: "70%",
                },
              ]}
            >
              <Text
                style={[{ marginBottom: 3, fontSize: "8px", marginRight: 5 }]}
              >
                No Shows{" "}
              </Text>
            </View>
            <Text style={[styles.newmatric, { fontSize: "8px" }]}>
              {handleDigitsInt(
                reportData?.house_flash_report.house_summary.no_shows
              )}
            </Text>
          </View>
        </View>
      </View>

      {detailedView && (
        <View style={{ marginTop: "8px" }}>
          <Text style={{ fontSize: "8px" }}>Rate plan Report</Text>
          <View style={[styles.tableContainer, { padding: "5px" }]}>
            <Text style={{ fontSize: "8px" }}>
              Total guest count :{" "}
              {mealPlanReport?.adults + mealPlanReport?.children}
            </Text>
            <View
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                marginTop: "8px",
              }}
            >
              <Text style={{ marginRight: "10px", fontSize: "8px" }}>
                Total adults : {mealPlanReport?.adults}
              </Text>
              <Text style={{ fontSize: "8px" }}>
                Total children : {mealPlanReport?.children}
              </Text>
            </View>
            <View
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                flexWrap: "wrap",
              }}
            >
              <Text style={{ marginRight: "5px", fontSize: "8px" }}>
                {mealPlanReport?.mealPlanList
                  ?.map((mealPlan) => `${mealPlan?.name} (${mealPlan?.count})`)
                  .join(", ")}
              </Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

export default FlashManagerReportDoc;

// Create styles
const styles = StyleSheet.create({
  image: {
    height: "125px",
    width: "auto",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    marginTop: 5,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "10px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "10px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    // textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  scContainer: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 20,
    // textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  metric: {
    width: "50%",
    borderLeftColor: borderColor,
    borderLefttWidth: 1,
    // textAlign: "center",
    paddingLeft: 3.5,
    paddingTop: 2,
    textAlign: "left",
  },
  newmatric: {
    width: "30%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    // textAlign: "center",
    paddingLeft: 3.5,
    paddingTop: 2,
    textAlign: "left",
  },
  data: {
    width: "16.66%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingLeft: 3.5,
    textAlign: "right",
  },
  headIng: {
    width: "16.66%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingLeft: 3.5,
    textAlign: "center",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginTop: 2,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    inlineSize: "auto",
    fontStyle: "bold",
    paddingLeft: 5,
  },
  newrow: {
    flexDirection: "row",
    border: "0px",
    // borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    inlineSize: "auto",
    fontStyle: "bold",
    paddingLeft: 5,
  },
  taxRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 32,
    fontStyle: "bold",
  },
  signature: {
    marginTop: 50,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
});
