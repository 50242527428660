import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Tooltip,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import {
  handleDataMutationRequest,
  handleSpringDataMutationRequest,
} from "../../../../api";
import Modal from "@mui/material/Modal";
import { toast } from "material-react-toastify";

function AllGroupUsers({ bookingData, env }) {
  const [raised, setRaised] = useState({});
  const [groupTaxCategorydata, setGroupTaxCategorydata] = useState();
  const [userRolesData, setuserRolesData] = useState([]);
  const [loader, setloader] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [selectedRole, setselectedRole] = useState("");
  const [selectedHotelIds, setselectedHotelIds] = useState([]);
  const [selectedEmailId, setselectedEmailId] = useState("");
  const [unAssignedHotels, setunAssignedHotels] = useState([]);
  const [userEmail, setuserEmail] = useState("");
  const [userPassword, setuserPassword] = useState("");
  const [allHotelRoles, setallHotelRoles] = useState([]);
  const [assignRoleId, setassignRoleId] = useState("");
  const [assignHotelHotelId, setassignHotelHotelId] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");
  const [selectedUnAssignedHotels, setselectedUnAssignedHotels] = useState([]);
  const handleOpenAssignModal = (num) => {
    setselectedEmailId(num[0]);
    setassignHotelHotelId(num[1]);
    getAllUserRoleData(num);
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => setOpenAssignModal(false);

  const [openUnAssignModal, setopenUnAssignModal] = useState(false);
  const handleOpenUnAssignModal = (num) => {
    setselectedEmailId(num[0]);
    setunAssignedHotels(num[1]);
    setselectedUnAssignedHotels(num[1]);
    setopenUnAssignModal(true);
  };

  const handleCloseUnAssignModal = () => setopenUnAssignModal(false);

  const [openUserModal, setopenUserModal] = useState(false);
  const handleOpenUserModal = (num) => {
    setopenUserModal(true);
  };
  const handleCloseUserModal = () => {
    setuserEmail("");
    setuserPassword("");
    setassignRoleId("");
    setselectedHotelIds([]);
    setopenUserModal(false);
  };

  const handleChangeForUnassign = (event) => {
    const {
      target: { value },
    } = event;

    setunAssignedHotels(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const toggleRaised = (num) => {
    raised[num] = !raised[num];
    setRaised({ ...raised });
  };

  useEffect(() => {
    getAllData();
    if (bookingData && bookingData?.length > 0) {
      getAllRoles();
    }
  }, [bookingData]);

  const getAllData = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/groupAccesscontrol/getAllGroupUsers`,
      bookingData?.map((item) => item.hotelId)
    )
      .then((res) => {
        setGroupTaxCategorydata(Object.entries(JSON.parse(res.message)));
      })
      .catch((err) => console.error(err));
  };

  const getAllUserRoleData = (num) => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/groupAccesscontrol/getGroupUserRoles?email=${num[0]}`,
      num[1]
    )
      .then((res) => {
        setuserRolesData(JSON.parse(res.message));
      })
      .catch((err) => console.error(err));
  };

  const AssignHotels = () => {
    if (selectedHotelIds.length === 0) {
      return toast.error("Please select hotels");
    }
    if (selectedRole === "") {
      return toast.error("Please select role");
    }
    setloader(true);
    const hotelId = Object.values(userRolesData[selectedRole])[0];
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/groupAccesscontrol/assignUserAndRoleToHotel?roleId=${selectedRole}&emailId=${selectedEmailId}&existingHotelId=${hotelId}`,
      selectedHotelIds
    )
      .then((res) => {
        toast.success("Successfull");
        setloader(false);
        getAllData();
        handleCloseAssignModal();
      })
      .catch((err) => {
        toast.error("Error in assigning hotels");
        setloader(false);
      });
  };
  const UnAssignHotels = () => {
    if (unAssignedHotels.length === 0) {
      return toast.error("Please select hotels");
    }
    setloader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/groupAccesscontrol/unAssignUsersFromHotel?emailId=${selectedEmailId}`,
      unAssignedHotels
    )
      .then((res) => {
        toast.success("Successfull");
        getAllData();
        setloader(false);
        handleCloseUnAssignModal();
      })
      .catch((err) => {
        toast.error("Error in unassigning hotels");
        setloader(false);
      });
  };
  const [hotels, sethotels] = useState(new Map());

  useEffect(() => {
    let map = new Map();
    if (bookingData && bookingData?.length > 0) {
      const data = bookingData?.map((item) => {
        map.set(item.hotelId, item.hotelName);
      });
    }
    sethotels(map);
  }, [bookingData]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedHotelIds(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  const getAllRoles = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/groupAccesscontrol/getAllRoleForHotels`,
      bookingData?.map((item) => item.hotelId)
    )
      .then((res) => {
        const data = JSON.parse(res.message);
        const activeRoles = [];
        data.forEach((hotelRoles) => {
          hotelRoles.forEach((role) => {
            if (role.status === "ACTIVE") {
              activeRoles.push({
                id: role.id,
                hotel_id: role.hotel_id,
                roleName: role.role_name,
              });
            }
          });
        });

        setallHotelRoles(activeRoles);
      })
      .catch((err) => console.error(err));
  };
  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const addUser = () => {
    if (!validateEmail(userEmail)) {
      return toast.error("Please enter correct email");
    }
    if (userPassword === "") {
      return toast.error("Please enter user password");
    }
    if (selectedHotelIds.length === 0) {
      return toast.error("Please select hotels");
    }
    if (assignRoleId === "") {
      return toast.error("Please select role");
    }
    setloader(true);
    let url;

    if (env === "DJANGO_LOCAL") {
      url = `http://127.0.0.1:6460/user/subUserAuth?hotel_id=${selectedHotelIds[0]}&hotelId=${selectedHotelIds[0]}`;
    } else if (env === "BETA") {
      url = `https://beta.api.stayflexi.com/user/subUserAuth?hotel_id=${selectedHotelIds[0]}&hotelId=${selectedHotelIds[0]}`;
    } else {
      url = `https://api.stayflexi.com/user/subUserAuth?hotel_id=${selectedHotelIds[0]}&hotelId=${selectedHotelIds[0]}`;
    }

    const payload = {
      username: userEmail,
      password: userPassword,
      token: "",
      isPresent: false,
      loggedInUser: "",
    };

    fetchPost(url, payload)
      .then((data) => {})
      .catch((error) => {
        console.error("Error during fetch:", error);
      });

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/groupAccesscontrol/copyUserFromOneHotelToAnother?roleId=${assignRoleId}&emailId=${userEmail}`,
      selectedHotelIds
    )
      .then((res) => {
        toast.success("Successfull");
        setloader(false);
        getAllData();
        handleCloseUserModal();
      })
      .catch((err) => {
        toast.error("Error in assigning hotels");
        setloader(false);
      });
  };

  async function fetchPost(url, data) {
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            localStorage.getItem("token").split("master")[0] + "master",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      return await response.json();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }

  const handleSelectAll = () => {
    const allHotelIds = bookingData.map((item) => item.hotelId);
    setselectedHotelIds(allHotelIds);
  };

  const handleDeselectAll = () => {
    setselectedHotelIds([]);
  };

  const allSelected = selectedHotelIds.length === bookingData.length;
  const noneSelected = selectedHotelIds.length === 0;

  return (
    <div>
      {" "}
      <Grid container>
        <div className="d-flex flex-column">
          <h2>All users</h2>
          <p>Use this page to create and manage hotel access controls</p>
        </div>
        <Grid item container sx={{ marginBottom: 3 }} xs={12}>
          <Grid
            item
            xs={6}
            style={{ alignItems: "center" }}
            container
            justifyContent="flex-start"
          >
            <Button
              onClick={() => handleOpenUserModal()}
              variant="custom-button"
            >
              {" "}
              Copy user
            </Button>
            <TextField
              onChange={(e) => setsearchTerm(e.target.value)}
              placeholder="Search by email"
              label="Search by email"
              // className="w-75"
              notched={false}
              type="search"
              size="small"
              style={{
                borderRadius: "5px",
                width: "55%",
                marginLeft: "2rem",
              }}
            />
          </Grid>
        </Grid>
        <Modal
          open={openUserModal}
          onClose={handleCloseUserModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="addServiceModal">
            <div className="addServiceWrapper" style={{ width: "90%" }}>
              <div className="serviceHeaderWrapper">
                <div className="serviceHeader">Copy user</div>
                <div className="cross" onClick={() => handleCloseUserModal()}>
                  X
                </div>
              </div>

              <div className="horizontal" />

              <div className="invoiceInfoWrapper">
                <div>
                  <div className="mb-2 ">
                    <TextField
                      label="User email"
                      className="input-lg w-100 mt-3"
                      placeholder="Enter user email"
                      onChange={(e) => setuserEmail(e.target.value)}
                      value={userEmail}
                      name="schedule_name"
                      id="schedule_name"
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-2 ">
                    <TextField
                      label="User password"
                      className="input-lg w-100 mt-3"
                      placeholder="Enter user password here"
                      onChange={(e) => setuserPassword(e.target.value)}
                      value={userPassword}
                      name="schedule_name"
                      id="schedule_name"
                      type="password"
                    />
                  </div>
                </div>
                <h4 className="mt-2 mb-4"> Assign role and hotel to user</h4>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setassignRoleId(e.target.value)}
                    label="Roles"
                    value={assignRoleId}
                  >
                    {allHotelRoles?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {`Hotel name - ${item.hotel_id}, Role name - ${item.roleName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                  <InputLabel id="demo-simple-select-label">Hotels</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Hotels"
                    value={selectedHotelIds}
                    multiple
                    onChange={handleChange}
                  >
                    {!allSelected && (
                      <MenuItem onClick={handleSelectAll}>Select All</MenuItem>
                    )}
                    {!noneSelected && (
                      <MenuItem onClick={handleDeselectAll}>
                        Deselect All
                      </MenuItem>
                    )}
                    {bookingData?.map((item) => (
                      <MenuItem key={item.hotelId} value={item.hotelId}>
                        {`${item.hotelName} - ${item.hotelId}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="addServiceBtns">
              <button className="cancel" onClick={() => handleCloseUserModal()}>
                Cancel
              </button>
              <Button
                variant="custom-button"
                onClick={addUser}
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Box>
        </Modal>

        {groupTaxCategorydata
          ?.filter((val) => {
            if (searchTerm === "") {
              return val;
            } else if (
              val[0].toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return val;
            }
          })
          ?.map((num, index) => {
            return (
              <Grid key={num.id} item xs={12}>
                <Card
                  onMouseOver={() => {
                    toggleRaised(num.id);
                  }}
                  onMouseOut={() => {
                    toggleRaised(num.id);
                  }}
                  raised={raised[num.id]}
                  sx={{ marginBottom: 2 }}
                >
                  <CardHeader
                    title={`${num[0]}`}
                    action={
                      <Grid item spacing={2} container>
                        <Grid item>
                          <Button
                            variant="custom-button"
                            onClick={() => handleOpenAssignModal(num)}
                          >
                            Assign hotels
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="custom-button"
                            onClick={() => handleOpenUnAssignModal(num)}
                          >
                            Unassign hotels
                          </Button>
                        </Grid>
                      </Grid>
                    }
                  >
                    <Divider />
                  </CardHeader>
                  <Divider />
                  <CardContent>
                    <Grid item container spacing={3}>
                      <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                        <ListItemText
                          primary="User email"
                          secondary={num[0]}
                        ></ListItemText>
                      </Grid>
                      <Grid item xs={4} sm={3} md={6} lg={6} xl={6} mr={6}>
                        <ListItemText
                          primary="Associated hotels"
                          secondary={num[1]
                            ?.map((e) => {
                              return `${hotels.get(e)} - ${e}`;
                            })
                            .join(", ")}
                        ></ListItemText>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}

        <Modal
          open={openAssignModal}
          onClose={handleCloseAssignModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="addServiceModal">
            <div className="addServiceWrapper" style={{ width: "90%" }}>
              <div className="serviceHeaderWrapper">
                <div className="serviceHeader">
                  Assign role and hotel to user
                </div>
                <div className="cross" onClick={() => handleCloseAssignModal()}>
                  X
                </div>
              </div>

              <div className="horizontal" />

              <div className="invoiceInfoWrapper">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setselectedRole(e.target.value)}
                    label="Roles"
                    value={selectedRole}
                  >
                    {Object.entries(userRolesData)?.map((entry) => (
                      <MenuItem key={entry[0]} value={entry[0]}>
                        Role name - {Object.keys(entry[1])[0]}, Hotel name -{" "}
                        {hotels.get(Object.values(entry[1])[0])} -{" "}
                        {Object.values(entry[1])[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                  <InputLabel id="demo-simple-select-label">Hotels</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Hotels"
                    value={selectedHotelIds}
                    multiple
                    onChange={handleChange}
                  >
                    {bookingData?.map(
                      (item) =>
                        !assignHotelHotelId.includes(item.hotelId) && (
                          <MenuItem key={item.hotelId} value={item.hotelId}>
                            {`${item.hotelName} - ${item.hotelId}`}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="addServiceBtns">
              <button
                className="cancel"
                onClick={() => handleCloseAssignModal()}
              >
                Cancel
              </button>
              <Button
                variant="custom-button"
                onClick={AssignHotels}
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={openUnAssignModal}
          onClose={handleCloseUnAssignModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="addServiceModal">
            <div className="addServiceWrapper" style={{ width: "90%" }}>
              <div className="serviceHeaderWrapper">
                <div className="serviceHeader">Unassign user from hotel</div>
                <div
                  className="cross"
                  onClick={() => handleCloseUnAssignModal()}
                >
                  X
                </div>
              </div>

              <div className="horizontal" />

              <div className="invoiceInfoWrapper">
                <h5 className="mt-2 mb-3">
                  Select the hotel for which access need to be removed
                </h5>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                  <InputLabel id="demo-simple-select-label">Hotels</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Hotels"
                    value={unAssignedHotels}
                    multiple
                    onChange={handleChangeForUnassign}
                  >
                    {selectedUnAssignedHotels?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {`${hotels.get(item)} - ${item}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="addServiceBtns">
              <button
                className="cancel"
                onClick={() => handleCloseUnAssignModal()}
              >
                Cancel
              </button>
              <Button
                variant="custom-button"
                onClick={UnAssignHotels}
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Box>
        </Modal>
      </Grid>
    </div>
  );
}

export default AllGroupUsers;
