import React, { useEffect, useState } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { Card, Modal, ModalTitle, ModalBody } from "react-bootstrap";
import {
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  IconButton,
  Collapse,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  MenuItem,
  Fab,
  InputLabel,
  FormControl,
  Select,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import UploadIcon from "@mui/icons-material/Upload";
import { isArray, isNull, isUndefined } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import Webcam from "react-webcam";
import { ExpandMore } from "@mui/icons-material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  checkIshaAccess,
  checkIshaUSAccess,
  dateFnsFormat,
  getApprovedByOverseas,
  getGenderList,
  getIshaGenderList,
  getIshaProgramsAttended,
  getIshaPurposeOfVisit,
  getIshaRelationWithPrimary,
  getNationalityList,
  maxCompressMBSize,
} from "../constants/Calendar-constants";
import { useFormik } from "formik";
import { format } from "date-fns";
import { getCountriesAndStates } from "../constants/Calendar-constants";
import { getGeoCodeDetails } from "../api/ReservationApi";
import {
  default as ImageUploading,
  default as ReactImageUploading,
} from "react-images-uploading";
import { getAllCustomers } from "../../FlexibleSlot/api/FlexibleSlotApi";
import moment from "moment";
import imageCompression from "browser-image-compression";

const NewGuestView = (props) => {
  const ishaProgramsAttended = getIshaProgramsAttended();
  const ishaRelationWithPrimary = getIshaRelationWithPrimary();
  const ishaApprovedByOverseas = getApprovedByOverseas();
  const genderList = getGenderList();
  const countries = getCountriesAndStates();
  const nationalities = getNationalityList();
  const checkIn = props?.checkin;
  const checkOut = props?.checkout;

  useEffect(() => {
    if (existingGuestDetails?.guestETA) {
      setETA(new Date(existingGuestDetails?.guestETA));
    } else {
      setETA(new Date(checkIn));
    }
    if (existingGuestDetails?.guestETD) {
      setETD(new Date(existingGuestDetails?.guestETD));
    } else {
      setETD(new Date(checkOut));
    }
  }, [checkIn, checkOut]);
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const hotelId = hotel?.hotelId;
  const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const validationSchema = yup.object({
    // guestFirstName: yup
    //   .string("Enter First Name")
    //   .required("First Name is required"),
    // guestLastName: yup
    //   .string("Enter Last Name")
    //   .required("Last Name is required"),
    // guestPhone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  });

  const defaultAddressDetails = props?.primaryAddress;

  const ishaPurposeOfVisit = getIshaPurposeOfVisit(hotelId);
  const ishaGenderList = getIshaGenderList(hotelId);
  const [imageUpload, setImageUpload] = useState("Camera");
  const [images, setImages] = useState([]);
  // Government IDs --- US properties
  const govIdUS = ["Passport", "Drivers License", "Others"];

  // Government IDs --- Indian properties
  const govIdIndia = ["Passport", "Drivers License", "Aadhar", "Others"];

  const webcamRef = React.useRef(null);

  // Camera Upload - Front ID
  const [showFrontIdModal, setshowFrontIdModal] = useState(false);
  const handleCloseFrontIdModal = () => setshowFrontIdModal(false);
  const handleOpenFrontIdModal = () => {
    setshowFrontIdModal(true);
  };
  const [frontImage, setFrontImage] = useState(undefined);
  const frontCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setFrontImage(imageSrc);
  }, [webcamRef]);

  // Camera Upload - Back ID
  const [showBackIdModal, setshowBackIdModal] = useState(false);
  const handleCloseBackIdModal = () => setshowBackIdModal(false);
  const handleOpenBackIdModal = () => {
    setshowBackIdModal(true);
  };
  const [backImage, setBackImage] = useState(undefined);
  const backCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setBackImage(imageSrc);
  }, [webcamRef]);

  //Passport image - isha requirement
  const [passportImageUpload, setPassportImageUpload] = useState("Camera");
  const [showPassportModal, setShowPassportModal] = useState(false);
  const handleClosePassportModal = () => setShowPassportModal(false);
  const handleOpenPassportModal = () => setShowPassportModal(true);
  const [passportImage, setPassportImage] = useState(undefined);
  const passportCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPassportImage(imageSrc);
  }, [webcamRef]);
  const [passportImages, setPassportImages] = useState([]);

  const videoConstraints = {
    width: 400,
    height: 300,
    facingMode: "user",
  };

  const [showFrontImagePopup, setshowFrontImagePopup] = useState(false);
  const [showPopUpImages, setshowPopUpImages] = useState("");

  const handleshowFrontImagePopupClose = () => setshowFrontImagePopup(false);
  const handleFrontImagePopupShow = (data) => {
    setshowPopUpImages(data);
    setshowFrontImagePopup(true);
  };

  const [faviconImages, setFaviconImages] = useState([]);

  const [addressCheck, setAddressCheck] = useState(false);

  const existingGuestDetails = props?.existingData[props?.dataIndex];
  const existingIdProof =
    props?.roomIndex !== undefined
      ? props?.email !== undefined
        ? props?.existingIdProofData.filter(
            (idProof, index) => idProof?.emailId == props?.email
          )[0]
        : undefined
      : props?.existingIdProofData[props?.dataIndex];

  const existingDocumentsData =
    props?.roomIndex !== undefined
      ? props?.email !== undefined
        ? props?.existingDocumentsData.filter(
            (docData, index) => docData?.emailId == props?.email
          )[0]
        : undefined
      : props?.existingDocumentsData[props?.dataIndex];

  const [selectedPrograms, setSelectedPrograms] = useState(["-"]);

  useEffect(() => {
    if (existingGuestDetails) {
      setshowData(false);
      setSelectedPrograms(existingGuestDetails?.guestPrograms?.split(","));
    }
    if (existingIdProof) {
      const devType = existingIdProof?.deviceType;
      const passDevType = existingIdProof?.passDeviceType;
      if (devType) {
        setImageUpload(devType);
      }
      if (devType == "Device") {
        if (existingIdProof?.deviceFrontData != null) {
          let tempImages = [...images];
          tempImages.push(existingIdProof?.deviceFrontData);
          setImages(tempImages);
        }
        if (existingIdProof?.deviceBackData != null) {
          let tempFaviconImages = [...faviconImages];
          tempFaviconImages.push(existingIdProof?.deviceBackData);
          setFaviconImages(tempFaviconImages);
        }
      }
      if (passDevType) {
        setPassportImageUpload(passDevType);
      }
      if (passDevType == "Device") {
        if (existingIdProof?.devicePassportData != null) {
          let tempImages = [...passportImages];
          tempImages.push(existingIdProof?.devicePassportData);
          setPassportImages(tempImages);
        }
      }
    }
    if (existingDocumentsData) {
      setDocument1(existingDocumentsData?.doc1);
      setDocument2(existingDocumentsData?.doc2);
      let image1Name = document.getElementById(
        "guest" + props?.dataIndex + "document1Name"
      );
      let image2Name = document.getElementById(
        "guest" + props?.dataIndex + "document2Name"
      );
      if (existingDocumentsData?.doc1) {
        image1Name.innerText = existingDocumentsData?.doc1Name;
      }
      if (existingDocumentsData?.doc2) {
        image2Name.innerText = existingDocumentsData?.doc2Name;
      }
    }
  }, []);

  useEffect(() => {
    if (addressCheck) {
      guestFormik.setFieldValue(
        "guestAddress1",
        defaultAddressDetails?.address1
      );
      guestFormik.setFieldValue(
        "guestAddress2",
        defaultAddressDetails?.address2
      );
      guestFormik.setFieldValue("guestZipcode", defaultAddressDetails?.zipcode);
      guestFormik.setFieldValue("guestCity", defaultAddressDetails?.city);
      guestFormik.setFieldValue("guestState", defaultAddressDetails?.state);
      guestFormik.setFieldValue("guestCountry", defaultAddressDetails?.country);
    }

    // } else {
    //   guestFormik.setFieldValue("guestAddress1", "");
    //   guestFormik.setFieldValue("guestAddress2", "");
    //   guestFormik.setFieldValue("guestZipcode", "");
    //   guestFormik.setFieldValue("guestCity", "");
    //   guestFormik.setFieldValue("guestState", "");
    //   guestFormik.setFieldValue("guestCountry", "");
    // }
  }, [addressCheck]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  const guestFormik = useFormik({
    initialValues: {
      guestFirstName: existingGuestDetails?.guestName
        ? existingGuestDetails?.guestName?.split(" ")[0]
        : "",
      guestLastName: existingGuestDetails?.guestName
        ? existingGuestDetails?.guestName?.split(" ")[1]
        : "",
      guestEmail: existingGuestDetails?.guestEmail
        ? existingGuestDetails?.guestEmail
        : "",
      guestPhone: existingGuestDetails?.guestPhone
        ? existingGuestDetails?.guestPhone
        : "",
      guestGender: existingGuestDetails?.guestGender
        ? existingGuestDetails?.guestGender
        : "Male",
      guestDOB:
        existingGuestDetails?.guestDOB && existingGuestDetails?.guestDOB != "NA"
          ? moment(existingGuestDetails?.guestDOB, "YYYY-MM-DD", false).toDate()
          : "",
      guestNationality: existingGuestDetails?.guestNationality
        ? existingGuestDetails?.guestNationality
        : "",
      guestPrograms: existingGuestDetails?.guestPrograms
        ? existingGuestDetails?.guestPrograms
        : "-",
      guestPrimaryRelation: existingGuestDetails?.guestPrimaryRelation
        ? existingGuestDetails?.guestPrimaryRelation
        : "Other",
      guestGovtIdValue: existingGuestDetails?.guestGovtIdValue
        ? existingGuestDetails?.guestGovtIdValue
        : "",
      guestGovtIdType: existingGuestDetails?.guestGovtIdType
        ? existingGuestDetails?.guestGovtIdType
        : "",
      guestAddress1: existingGuestDetails?.guestAddress1
        ? existingGuestDetails?.guestAddress1
        : "",
      guestAddress2: existingGuestDetails?.guestAddress2
        ? existingGuestDetails?.guestAddress2
        : "",
      guestZipcode: existingGuestDetails?.guestZipcode
        ? existingGuestDetails?.guestZipcode
        : "",
      guestCity: existingGuestDetails?.guestCity
        ? existingGuestDetails?.guestCity
        : "",
      guestState: existingGuestDetails?.guestState
        ? existingGuestDetails?.guestState
        : "",
      guestCountry: existingGuestDetails?.guestCountry
        ? existingGuestDetails?.guestCountry
        : null,
      guestOccupation: existingGuestDetails?.guestOccupation
        ? existingGuestDetails?.guestOccupation
        : "",
      guestApprovedOverseas: existingGuestDetails?.guestApprovedOverseas
        ? existingGuestDetails?.guestApprovedOverseas
        : "New",
      guestETA: existingGuestDetails?.guestETA
        ? existingGuestDetails?.guestETA
        : "",
      guestETD: existingGuestDetails?.guestETD
        ? existingGuestDetails?.guestETD
        : "",
      guestOCOCheckin: existingGuestDetails?.guestOCOCheckin
        ? existingGuestDetails?.guestOCOCheckin
        : "No",
    },
    onSubmit: async (values) => {
      let email =
        values.guestEmail === ""
          ? values.guestFirstName.replace(/\s+/g, ".").toLowerCase() +
            hotelId +
            Math.round(Date.now() / 1000) +
            "@example.com"
          : values.guestEmail;
      let tempGuestDOB = "";
      if (values.guestDOB != "" && values.guestDOB != "NA") {
        console.log("guest dob value: ", values.guestDOB);
        tempGuestDOB = moment(values.guestDOB, "YYYY-MM-DD", false).format(
          "YYYY-MM-DD"
        );
      }
      let temp = {
        guestName: values.guestFirstName + " " + values.guestLastName,
        guestEmail: email,
        guestPhone: values.guestPhone,
        guestGender: values.guestGender,
        guestDOB: tempGuestDOB,
        guestAddress1: values.guestAddress1,
        guestAddress2: values.guestAddress2,
        guestZipcode: values.guestZipcode,
        guestCity: values.guestCity,
        guestState: values.guestState,
        guestCountry: values.guestCountry,
        guestNationality: values.guestNationality,
        guestPrograms: values.guestPrograms,
        guestPrimaryRelation: values.guestPrimaryRelation,
        guestGovtIdType: values.guestGovtIdType,
        guestGovtIdValue: values.guestGovtIdValue,
        guestOccupation: values.guestOccupation,
        guestETA: moment(ETA).format("YYYY-MM-DD HH:mm:ss"),
        guestETD: moment(ETD).format("YYYY-MM-DD HH:mm:ss"),
        guestApprovedOverseas: values.guestApprovedOverseas,
        guestOCOCheckin: values.guestOCOCheckin,
        guestCategory: "ADULT",
      };
      let tempFrontDataImage =
        images[0] !== undefined ? images[0]?.file : undefined;
      let tempBackDataImage =
        faviconImages[0] !== undefined ? faviconImages[0]?.file : undefined;
      let tempPassportImage =
        passportImages[0] !== undefined ? passportImages[0]?.file : undefined;
      const options = {
        maxSizeMB: maxCompressMBSize,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      let compressedFrontData = null;
      let compressedBackData = null;
      let compressedPassportData = null;
      if (tempFrontDataImage !== undefined) {
        const compressedFile = await imageCompression(
          tempFrontDataImage,
          options
        );
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;
            compressedFrontData = result;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (tempBackDataImage !== undefined) {
        const compressedFile = await imageCompression(
          tempBackDataImage,
          options
        );
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;
            compressedBackData = result;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (tempPassportImage !== undefined) {
        const compressedFile = await imageCompression(
          tempPassportImage,
          options
        );
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;
            compressedPassportData = result;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      let tempIdProof = {
        emailId: email,
        deviceType: imageUpload,
        passDeviceType: passportImageUpload,
        frontData:
          frontImage !== undefined
            ? frontImage.split(",")[1]
            : compressedFrontData !== null
            ? compressedFrontData?.split(",")[1]
            : null,
        frontType:
          frontImage !== undefined
            ? "jpeg"
            : images[0]
            ? images[0].file?.type
            : null,
        backData:
          backImage !== undefined
            ? backImage.split(",")[1]
            : compressedBackData !== null
            ? compressedBackData?.split(",")[1]
            : null,
        backType:
          backImage !== undefined
            ? "jpeg"
            : faviconImages[0]
            ? faviconImages[0]?.file?.type
            : null,
        passportImageData:
          passportImage != undefined
            ? passportImage.split(",")[1]
            : compressedPassportData !== null
            ? compressedPassportData?.split(",")[1]
            : null,
        passportImageType:
          passportImage !== undefined
            ? "jpeg"
            : passportImages[0]
            ? passportImages[0].file.type
            : null,
        deviceFrontData: images[0] != undefined ? images[0] : null,
        deviceBackData: faviconImages[0] != undefined ? faviconImages[0] : null,
        devicePassportData:
          passportImages[0] != undefined ? passportImages[0] : null,
      };

      let image1Name = document.getElementById(
        "guest" + props?.dataIndex + "document1Name"
      );
      let image2Name = document.getElementById(
        "guest" + props?.dataIndex + "document2Name"
      );
      let tempDocuments = {
        emailId: email,
        doc1: document1,
        doc2: document2,
        doc1Name: image1Name?.innerText,
        doc2Name: image2Name?.innerText,
      };

      if (existingIdProof) {
        if (props?.roomIndex != undefined) {
          // for group booking need to update based on email ID
          props.updateIdProof(tempIdProof, email);
        } else {
          props.updateIdProof(tempIdProof, props?.dataIndex);
        }
      } else {
        props.addIdProof(tempIdProof);
      }

      if (existingGuestDetails) {
        if (props?.roomIndex != undefined) {
          props.updateGuestFunc(temp, props?.roomIndex, props?.dataIndex);
        } else {
          props.updateGuestFunc(temp, props?.dataIndex);
        }
      } else {
        if (props?.roomIndex != undefined) {
          props.addGuestFunc(temp, props?.roomIndex);
        } else {
          props.addGuestFunc(temp);
        }
      }

      if (existingDocumentsData) {
        if (props?.roomIndex != undefined) {
          // for group booking need to update based on email ID
          props.updateDocumentData(tempDocuments, email);
        } else {
          props.updateDocumentData(tempDocuments, props?.dataIndex);
        }
      } else {
        props.addDocumentData(tempDocuments);
      }

      //console.log(temp);
      props.onClose();
    },
  });

  //console.log(guestFormik.values);
  //Setting the date of birth selection in formik
  const setDateOfBirthSelection = (dob) => {
    try {
      guestFormik.setFieldValue("guestDOB", dob);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let attendedPrograms = typeof value === "string" ? value.split(",") : value;
    setSelectedPrograms(attendedPrograms);
    guestFormik.setFieldValue("guestPrograms", attendedPrograms.toString());
  };

  //ETA, ETD, OCO Checkin
  const [ETA, setETA] = useState(new Date());
  const [ETD, setETD] = useState(new Date());

  const handleETAChange = (e) => {
    setETA(e);
  };

  const handleETDChange = (e) => {
    setETD(e);
  };

  const getGeoCodeValues = (zipcode) => {
    try {
      getGeoCodeDetails(zipcode).then((resp) => {
        const re = /^[0-9\b]+$/;
        //Cases where the city is not mentioned in response
        if (!re.test(resp.city)) {
          guestFormik.setFieldValue("guestCity", resp.city);
        } else {
          guestFormik.setFieldValue("guestCity", "");
        }
        guestFormik.setFieldValue("guestState", resp.state);
        guestFormik.setFieldValue("guestCountry", resp.country);
      });
    } catch (e) {
      console.log(e);
    }
  };

  //Auto complete flow
  const [customerNameData, setcustomerNameData] = useState([]);
  const [customerPhoneData, setcustomerPhoneData] = useState([]);
  const [customerEmailData, setcustomerEmailData] = useState([]);
  const [showData, setshowData] = useState(true);

  //Auto fill based on first name
  useEffect(async () => {
    if (guestFormik.values.guestFirstName !== "") {
      const data = await getAllCustomers(
        guestFormik.values.guestFirstName,
        "name"
      );
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerNameData(sortedArr);
    }
    if (guestFormik.values.guestFirstName.length === 1) {
      setcustomerNameData([]);
    }
    if (guestFormik.values.guestFirstName === "") {
      setshowData(true);
    }
  }, [guestFormik.values.guestFirstName]);

  //Auto fill based on email
  useEffect(async () => {
    if (guestFormik.values.guestEmail !== "") {
      const data = await getAllCustomers(
        guestFormik.values.guestEmail,
        "email"
      );
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerEmailData(sortedArr);
    }
    if (guestFormik.values.guestEmail.length === 1) {
      setcustomerEmailData([]);
    }
    if (guestFormik.values.guestEmail === "") {
      setshowData(true);
    }
  }, [guestFormik.values.guestEmail]);

  //Auto fill on guest contact
  useEffect(async () => {
    if (guestFormik.values.guestPhone !== "") {
      const data = await getAllCustomers(
        guestFormik.values.guestPhone,
        "contact"
      );

      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerPhoneData(sortedArr);
    }
    if (guestFormik.values.guestPhone.length === 1) {
      setcustomerPhoneData([]);
    }
    if (guestFormik.values.guestPhone === "") {
      setshowData(true);
    }
  }, [guestFormik.values.guestPhone]);

  //Populating fields based on selection
  const populateCustomerFields = (item) => {
    console.log(item);
    //guest name
    if (item?.name) {
      guestFormik.setFieldValue("guestFirstName", item?.name.split(" ")[0]);
      if (item?.name.split(" ")?.length > 1) {
        guestFormik.setFieldValue("guestLastName", item?.name.split(" ")[1]);
      }
    }
    //guest email
    if (item?.email) {
      guestFormik.setFieldValue("guestEmail", item?.email);
    }
    //guest phone - contact
    if (item?.contact) {
      guestFormik.setFieldValue("guestPhone", item?.contact);
    }
    //guest gender - gender
    if (item?.gender && item?.gender != "NA") {
      guestFormik.setFieldValue("guestGender", item?.gender);
    }
    //guest dob - dateOfBirth
    if (
      item?.dateOfBirth &&
      item?.dateOfBirth != "NA" &&
      item?.dateOfBirth != ""
    ) {
      guestFormik.setFieldValue("guestDOB", item?.dateOfBirth);
    }
    //guest nationality - nationality
    if (item?.nationality) {
      guestFormik.setFieldValue("guestNationality", item?.nationality);
    }
    //guest govt id value - govtIdValue
    if (item?.govtIdValue) {
      guestFormik.setFieldValue("guestGovtIdValue", item?.govtIdValue);
    }
    //guest govt id type - govtIdType
    if (item?.govtIdType && item?.govtIdType != "NA") {
      guestFormik.setFieldValue("guestGovtIdType", item?.govtIdType);
    }
    //guest address1 - addressLine1
    if (item?.addressLine1) {
      guestFormik.setFieldValue("guestAddress1", item?.addressLine1);
    }
    //guest address2 - addressLine2
    if (item?.addressLine2) {
      guestFormik.setFieldValue("guestAddress2", item?.addressLine2);
    }
    //guest zipcode - zipcode
    if (item?.zipcode) {
      guestFormik.setFieldValue("guestZipcode", item?.zipcode);
    }
    //guest city - city
    if (item?.city) {
      guestFormik.setFieldValue("guestCity", item?.city);
    }
    //guest state - state
    if (item?.state && item?.state != "NA") {
      guestFormik.setFieldValue("guestState", item?.state);
    }
    //guest country - country
    if (item?.country && item?.country != "NA") {
      guestFormik.setFieldValue("guestCountry", item?.country);
    }
    //guest occupation - occupation
    if (item?.occupation) {
      guestFormik.setFieldValue("guestOccupation", item?.occupation);
    }
    setcustomerNameData([]);
    setcustomerEmailData([]);
    setcustomerPhoneData([]);
    setshowData(false);
  };

  //Clearing all values
  const emptyValues = () => {
    setcustomerNameData([]);
    setcustomerEmailData([]);
    setcustomerPhoneData([]);
  };

  //Add attachments

  // Create a reference to the hidden file input element
  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  const [document1, setDocument1] = useState("");
  const [document2, setDocument2] = useState("");
  const handleSetDocumentState = (name, docType, base64) => {
    switch (name) {
      case "guest" + props?.dataIndex + "document1":
        setDocument1({ data: base64, type: docType });
        break;
      case "guest" + props?.dataIndex + "document2":
        setDocument2({ data: base64, type: docType });
        break;
      default:
      // code block
    }
  };

  //Converting documents to base64
  const convertToBase64 = (eve) => {
    const file = eve.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
      .then((data) => {
        let type = data.split("/")[1].split(";")[0];
        if (type === "pdf") {
          type = "application/pdf";
        } else {
          type = "image/" + type;
        }
        const base64 = data.split(",")[1];
        handleSetDocumentState(eve.target.name, type, base64);
      })
      .catch((err) => console.log("error caught : ", err));
  };

  return (
    <form
      noValidate
      style={{ height: "auto" }}
      onSubmit={guestFormik.handleSubmit}
    >
      {/* Modal for Camera capture front ID */}
      <Modal
        show={showFrontIdModal}
        onHide={handleCloseFrontIdModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton> Front id</Modal.Header>
        <Modal.Body>
          {!frontImage ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={frontImage} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            onClick={() =>
              !frontImage ? frontCapture() : setFrontImage(undefined)
            }
          >
            {!frontImage ? "Capture photo" : "take photo"}
          </Button>
        </Modal.Body>
      </Modal>
      {/* Modal for Camera capture front ID */}
      <Modal
        show={showBackIdModal}
        onHide={handleCloseBackIdModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton>Back id</Modal.Header>
        <Modal.Body>
          {!backImage ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={backImage} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            onClick={() =>
              !backImage ? backCapture() : setBackImage(undefined)
            }
          >
            {!backImage ? "Capture photo" : "take photo"}
          </Button>
        </Modal.Body>
      </Modal>
      {/* Modal for Camera capture for Passport Image */}
      <Modal
        show={showPassportModal}
        onHide={handleClosePassportModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton> Passport size Photo</Modal.Header>
        <Modal.Body>
          {!passportImage ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={passportImage} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            onClick={() =>
              !passportImage ? passportCapture() : setPassportImage(undefined)
            }
          >
            {!passportImage ? "Capture photo" : "take photo"}
          </Button>
        </Modal.Body>
      </Modal>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1c-content1"
          id="panel1c-header1"
        >
          <div>
            <Typography>Additional Guest Details</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                required
                id="guestFirstName"
                type={"text"}
                label="First name"
                name="guestFirstName"
                value={guestFormik.values.guestFirstName}
                onChange={guestFormik.handleChange}
                // onBlur={guestFormik.handleBlur}
                fullWidth
              />
            </Grid>
            <div className="position-absolute" style={{ top: "7.5rem" }}>
              {customerNameData?.length > 0 && showData && (
                <Card
                  style={{
                    width: "18rem",
                    height: "40%",
                    overflowY: "yes",
                    zIndex: "1000",
                  }}
                >
                  <IconButton
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "1rem",
                    }}
                    color="primary"
                  >
                    <CancelIcon onClick={() => emptyValues()} />
                  </IconButton>
                </Card>
              )}
              {customerNameData?.length > 0 &&
                showData &&
                customerNameData?.map((item, index) => (
                  <Card
                    style={{
                      width: "18rem",
                      height: "40%",
                      overflowY: "yes",
                      zIndex: "1000",
                    }}
                    onClick={() => populateCustomerFields(item)}
                  >
                    <Card.Body style={{ padding: "3%" }}>
                      <div className="d-flex flex-column">
                        {/* <BsSearch className="mb-0 mr-2" /> */}
                        <div className="d-flex ">
                          <h4 className="mb-0 mr-4">{item.name}</h4>
                          {item.donotRent === "YES" && (
                            <>
                              <p className="badge badge-danger">Do not rent</p>
                              {item?.severity === "LOW" && (
                                <p className="badge badge-info ml-2">LOW</p>
                              )}
                              {item?.severity === "MEDIUM" && (
                                <p className="badge badge-warning ml-2">
                                  MEDIUM
                                </p>
                              )}
                              {item?.severity === "HIGH" && (
                                <p className="badge badge-dark ml-2">HIGH</p>
                              )}
                              {item?.severity === "BLACKLIST" && (
                                <p className="badge badge-danger ml-2">
                                  BLACKLIST
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="d-flex mb-0">
                          <p className="mr-3" style={{ fontSize: "13px" }}>
                            {item.email}
                          </p>
                          <p style={{ fontSize: "13px" }}>{item.contact}</p>
                        </div>
                        <p className="mb-0">{item.notes}</p>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
            </div>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                required
                id="guestLastName"
                label="Last name"
                name="guestLastName"
                value={guestFormik.values.guestLastName}
                onChange={guestFormik.handleChange}
                onBlur={guestFormik.handleBlur}
                type={"text"}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                required
                id="guestEmail"
                label="Email Address"
                name="guestEmail"
                type={"text"}
                value={guestFormik.values.guestEmail}
                onChange={guestFormik.handleChange}
                onBlur={guestFormik.handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                id="guestPhone"
                label="Contact Number"
                name="guestPhone"
                type={"number"}
                value={guestFormik.values.guestPhone}
                onChange={guestFormik.handleChange}
                onBlur={guestFormik.handleBlur}
                fullWidth
              />
            </Grid>
            {JSON.parse(
              props?.data?.accessControl?.user_feature_map
                ?.IshaExtraGuestDetails
            ).read === "YES" &&
            (checkIshaAccess(props?.data?.emailId) ||
              checkIshaUSAccess(props?.data?.emailId)) ? (
              <>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <TextField
                    select
                    id="guestGender"
                    label="Gender"
                    name="guestGender"
                    value={guestFormik.values.guestGender}
                    onChange={(e) => {
                      guestFormik.setFieldValue("guestGender", e.target.value);
                    }}
                    fullWidth
                  >
                    {ishaGenderList.map((genVal) => {
                      return (
                        <MenuItem key={genVal} value={genVal}>
                          {genVal}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat={dateFnsFormat}
                      fullWidth
                      disableMaskedInput
                      mask="___ ___, ____"
                      id="guestDOB"
                      name="guestDOB"
                      onChange={setDateOfBirthSelection}
                      // className={classes.textField}
                      value={guestFormik.values.guestDOB}
                      label="Date of Birth"
                      InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                      onBlur={guestFormik.handleBlur}
                      renderInput={(params) => {
                        params.error = Boolean(guestFormik.errors.guestDOB);
                        return (
                          <TextField
                            helperText={guestFormik.errors.guestDOB}
                            fullWidth
                            {...params}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Upload Passport Size Photo
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={passportImageUpload}
                      onChange={(e) => setPassportImageUpload(e.target.value)}
                      label="Passport Upload Image"
                    >
                      <MenuItem value={"Device"}>Device upload</MenuItem>
                      <MenuItem value={"Camera"}>Camera upload</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {passportImageUpload === "Camera" && (
                    <div className="mt-4 d-flex">
                      <div className="d-flex flex-column mr-3">
                        {passportImage !== undefined && (
                          <img
                            src={`${passportImage}`}
                            style={{
                              width: "21rem",
                              heigth: "20rem",
                              borderRadius: "1rem",
                            }}
                          />
                        )}
                        <Button
                          variant="custom-button"
                          className="mr-4 mt-3"
                          onClick={() => handleOpenPassportModal()}
                        >
                          Upload Passport Size Photo
                        </Button>
                      </div>
                    </div>
                  )}
                </Grid>
                {passportImageUpload === "Device" && (
                  <Grid item xs={12}>
                    <div className="d-flex">
                      <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                        <h5 className="mb-3" style={{ marginRight: "13px" }}>
                          Passport Size Photo
                        </h5>
                        <ReactImageUploading
                          value={passportImages}
                          onChange={(image) => {
                            setPassportImages(image);
                          }}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <Stack
                              // p="2"
                              // bgColor="gray.50"
                              // borderRadius="lg"
                              className="upload__image-wrapper"
                              sx={{
                                borderRadius: "10px",
                                height: "150px",
                                backgroundColor: "#d3d3d336",
                                padding: "1rem",
                              }}
                            >
                              {imageList.length === 0 && (
                                <IconButton
                                  children={
                                    <UploadIcon
                                      icon="bi:upload"
                                      htmlColor="black"
                                    />
                                  }
                                  variant="outline"
                                  // height="100%"
                                  sx={{
                                    borderRadius: "20px",
                                    backgroundColor: "#d3d3d3",
                                    height: "100%",
                                  }}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                ></IconButton>
                              )}
                              <Stack spacing="2">
                                {imageList.map((image, index) => (
                                  <Stack
                                    sx={{
                                      position: "relative",
                                      padding: "0.5rem",
                                    }}
                                    key={index}
                                  >
                                    <img
                                      src={
                                        image["data_url"].includes("base64")
                                          ? image["data_url"]
                                          : image["data_url"] +
                                            "?lastmod=" +
                                            format(
                                              new Date(),
                                              "ddMMyyyyhhmmsstt"
                                            )
                                      }
                                      style={{
                                        height: "125px",
                                        width: "100%",
                                        borderRadius: "1rem",
                                        boxShadow: "md",
                                        border: "1px solid",
                                        borderColor: "gray.200",
                                      }}
                                      onClick={() =>
                                        handleFrontImagePopupShow("front")
                                      }
                                      alt=""
                                    />

                                    <IconButton
                                      children={
                                        <CloseIcon
                                          fontSize="xs"
                                          htmlColor="black"
                                        />
                                      }
                                      size="xs"
                                      sx={{
                                        position: "absolute",
                                        borderRadius: "100%",
                                        backgroundColor: "white",
                                        top: "0",
                                        // colorScheme="teal"
                                        marginTop: "0px !important",
                                        left: "0",
                                        "&:hover": {
                                          color: "black",
                                          backgroundColor: "#ffffffcc",
                                        },
                                      }}
                                      onClick={() => onImageRemove(index)}
                                    />
                                    <IconButton
                                      children={
                                        <ChangeCircleIcon
                                          fontSize="xs"
                                          htmlColor="black"
                                        />
                                      }
                                      size="xs"
                                      sx={{
                                        position: "absolute",
                                        borderRadius: "100%",
                                        top: "0",
                                        backgroundColor: "white",
                                        // colorScheme="teal"
                                        marginTop: "0px !important",
                                        right: "0",
                                        "&:hover": {
                                          color: "black",
                                          backgroundColor: "#ffffffcc",
                                        },
                                      }}
                                      onClick={() => onImageUpdate(index)}
                                    />
                                  </Stack>
                                ))}
                              </Stack>
                            </Stack>
                          )}
                        </ReactImageUploading>
                      </div>
                    </div>
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    select
                    id="guestGender"
                    label="Gender"
                    name="guestGender"
                    value={guestFormik.values.guestGender}
                    onChange={(e) => {
                      guestFormik.setFieldValue("guestGender", e.target.value);
                    }}
                    fullWidth
                  >
                    {genderList.map((genVal) => {
                      return (
                        <MenuItem key={genVal} value={genVal}>
                          {genVal}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat={dateFnsFormat}
                      fullWidth
                      disableMaskedInput
                      mask="___ ___, ____"
                      id="guestDOB"
                      name="guestDOB"
                      onChange={setDateOfBirthSelection}
                      // className={classes.textField}
                      value={guestFormik.values.guestDOB}
                      label="Date of Birth"
                      onBlur={guestFormik.handleBlur}
                      renderInput={(params) => {
                        params.error = Boolean(guestFormik.errors.guestDOB);
                        return (
                          <TextField
                            helperText={guestFormik.errors.guestDOB}
                            fullWidth
                            {...params}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={addressCheck}
                    onChange={() => {
                      setAddressCheck(!addressCheck);
                    }}
                    defaultChecked={addressCheck}
                  />
                }
                label={"Address same as primary guest"}
              ></FormControlLabel>
            </Grid>
            {/* verification details grid items */}
            <Grid item xs={4}>
              <TextField
                select
                variant="outlined"
                label="Govt ID"
                value={guestFormik.values.guestGovtIdType}
                fullWidth
                placeholder="Select an ID type"
                id="guestGovtIdType"
                name="guestGovtIdType"
                onChange={(e) => {
                  guestFormik.setFieldValue("guestGovtIdType", e.target.value);
                }}
              >
                {hotel.hotelCurrency === "INR"
                  ? govIdIndia.map((item, index) => (
                      <MenuItem key={index + item} value={item}>
                        {item}
                      </MenuItem>
                    ))
                  : govIdUS.map((item, index) => (
                      <MenuItem key={index + item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="guestGovtIdValue"
                style={{ width: "100%" }}
                label="GOVT ID Number"
                name="guestGovtIdValue"
                variant="outlined"
                placeholder="Govt ID Number"
                value={guestFormik.values.guestGovtIdValue}
                onChange={guestFormik.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Upload Image
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={imageUpload}
                  onChange={(e) => setImageUpload(e.target.value)}
                  label="Upload Image"
                >
                  <MenuItem value={"Device"}>Device upload</MenuItem>
                  <MenuItem value={"Camera"}>Camera upload</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {imageUpload === "Camera" && (
                <div className="mt-4 d-flex">
                  <div className="d-flex flex-column mr-3">
                    {frontImage !== undefined && (
                      <img
                        src={`${frontImage}`}
                        style={{
                          width: "21rem",
                          heigth: "20rem",
                          borderRadius: "1rem",
                        }}
                      />
                    )}
                    <Button
                      variant="custom-button"
                      className="mr-4 mt-3"
                      onClick={() => handleOpenFrontIdModal()}
                    >
                      Upload front id
                    </Button>
                  </div>
                  <div className="d-flex flex-column">
                    {backImage !== undefined && (
                      <img
                        src={`${backImage}`}
                        style={{
                          width: "21rem",
                          heigth: "20rem",
                          borderRadius: "1rem",
                        }}
                      />
                    )}
                    <Button
                      variant="custom-button"
                      className="mt-3"
                      onClick={() => handleOpenBackIdModal()}
                    >
                      Upload Back id
                    </Button>
                  </div>
                </div>
              )}
            </Grid>
            {imageUpload === "Device" && (
              <Grid item xs={12}>
                <div className="d-flex">
                  <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                    <h5 className="mb-3" style={{ marginRight: "13px" }}>
                      ID front
                    </h5>
                    <ReactImageUploading
                      value={images}
                      onChange={(image) => {
                        setImages(image);
                      }}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <Stack
                          // p="2"
                          // bgColor="gray.50"
                          // borderRadius="lg"
                          className="upload__image-wrapper"
                          sx={{
                            borderRadius: "10px",
                            height: "150px",
                            backgroundColor: "#d3d3d336",
                            padding: "1rem",
                          }}
                        >
                          {imageList.length === 0 && (
                            <IconButton
                              children={
                                <UploadIcon
                                  icon="bi:upload"
                                  htmlColor="black"
                                />
                              }
                              variant="outline"
                              // height="100%"
                              sx={{
                                borderRadius: "20px",
                                backgroundColor: "#d3d3d3",
                                height: "100%",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            ></IconButton>
                          )}
                          <Stack spacing="2">
                            {imageList.map((image, index) => (
                              <Stack
                                sx={{
                                  position: "relative",
                                  padding: "0.5rem",
                                }}
                                key={index}
                              >
                                <img
                                  src={
                                    image["data_url"].includes("base64")
                                      ? image["data_url"]
                                      : image["data_url"] +
                                        "?lastmod=" +
                                        format(new Date(), "ddMMyyyyhhmmsstt")
                                  }
                                  style={{
                                    height: "125px",
                                    width: "100%",
                                    borderRadius: "1rem",
                                    boxShadow: "md",
                                    border: "1px solid",
                                    borderColor: "gray.200",
                                  }}
                                  onClick={() =>
                                    handleFrontImagePopupShow("front")
                                  }
                                  alt=""
                                />

                                <IconButton
                                  children={
                                    <CloseIcon
                                      fontSize="xs"
                                      htmlColor="black"
                                    />
                                  }
                                  size="xs"
                                  sx={{
                                    position: "absolute",
                                    borderRadius: "100%",
                                    backgroundColor: "white",
                                    top: "0",
                                    // colorScheme="teal"
                                    marginTop: "0px !important",
                                    left: "0",
                                    "&:hover": {
                                      color: "black",
                                      backgroundColor: "#ffffffcc",
                                    },
                                  }}
                                  onClick={() => onImageRemove(index)}
                                />
                                <IconButton
                                  children={
                                    <ChangeCircleIcon
                                      fontSize="xs"
                                      htmlColor="black"
                                    />
                                  }
                                  size="xs"
                                  sx={{
                                    position: "absolute",
                                    borderRadius: "100%",
                                    top: "0",
                                    backgroundColor: "white",
                                    // colorScheme="teal"
                                    marginTop: "0px !important",
                                    right: "0",
                                    "&:hover": {
                                      color: "black",
                                      backgroundColor: "#ffffffcc",
                                    },
                                  }}
                                  onClick={() => onImageUpdate(index)}
                                />
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      )}
                    </ReactImageUploading>
                  </div>
                  <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                    <h5 className="mb-3">ID back</h5>
                    <ReactImageUploading
                      value={faviconImages}
                      onChange={(image) => {
                        setFaviconImages(image);
                      }}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <Stack
                          // p="2"
                          // bgColor="gray.50"
                          // borderRadius="lg"
                          className="upload__image-wrapper"
                          sx={{
                            borderRadius: "10px",
                            height: "150px",
                            backgroundColor: "#d3d3d336",
                            padding: "1rem",
                          }}
                        >
                          {imageList.length === 0 && (
                            <IconButton
                              children={
                                <UploadIcon
                                  icon="bi:upload"
                                  htmlColor="black"
                                />
                              }
                              variant="outline"
                              sx={{
                                borderRadius: "20px",
                                backgroundColor: "#d3d3d3",
                                height: "100%",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            ></IconButton>
                          )}
                          <Stack spacing="2">
                            {imageList.map((image, index) => (
                              <Stack
                                sx={{
                                  position: "relative",
                                  padding: "0.5rem",
                                }}
                                key={index}
                              >
                                <img
                                  src={
                                    image["data_url"].includes("base64")
                                      ? image["data_url"]
                                      : image["data_url"] +
                                        "?lastmod=" +
                                        format(new Date(), "ddMMyyyyhhmmsstt")
                                  }
                                  style={{
                                    height: "125px",
                                    width: "100%",
                                    borderRadius: "1rem",
                                    boxShadow: "md",
                                    border: "1px solid",
                                    borderColor: "gray.200",
                                  }}
                                  onClick={() =>
                                    handleFrontImagePopupShow("back")
                                  }
                                  alt=""
                                />

                                <IconButton
                                  children={
                                    <CloseIcon
                                      fontSize="xs"
                                      htmlColor="black"
                                    />
                                  }
                                  size="xs"
                                  sx={{
                                    position: "absolute",
                                    borderRadius: "100%",
                                    backgroundColor: "white",
                                    top: "0",
                                    // colorScheme="teal"
                                    marginTop: "0px !important",
                                    left: "0",
                                    "&:hover": {
                                      color: "black",
                                      backgroundColor: "#ffffffcc",
                                    },
                                  }}
                                  onClick={() => onImageRemove(index)}
                                />
                                <IconButton
                                  children={
                                    <ChangeCircleIcon
                                      fontSize="xs"
                                      htmlColor="black"
                                    />
                                  }
                                  size="xs"
                                  sx={{
                                    position: "absolute",
                                    borderRadius: "100%",
                                    top: "0",
                                    backgroundColor: "white",
                                    // colorScheme="teal"
                                    marginTop: "0px !important",
                                    right: "0",
                                    "&:hover": {
                                      color: "black",
                                      backgroundColor: "#ffffffcc",
                                    },
                                  }}
                                  onClick={() => onImageUpdate(index)}
                                />
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      )}
                    </ReactImageUploading>
                  </div>
                </div>
              </Grid>
            )}
            {/* address details grid items */}
            <Grid item xs={12}>
              <TextField
                name="guestAddress1"
                id="guestAddress1"
                fullWidth
                type="text"
                placeholder="Address"
                disabled={addressCheck}
                value={guestFormik.values.guestAddress1}
                onChange={guestFormik.handleChange}
                onBlur={guestFormik.handleBlur}
                label="Address Line 1"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="guestAddress2"
                id="guestAddress2"
                fullWidth
                type="text"
                disabled={addressCheck}
                placeholder="Address Line 2"
                value={guestFormik.values.guestAddress2}
                onChange={guestFormik.handleChange}
                onBlur={guestFormik.handleBlur}
                label="Address Line 2"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                name="guestZipcode"
                id="guestZipcode"
                fullWidth
                disabled={addressCheck}
                type="text"
                placeholder="Zip Code"
                value={guestFormik.values.guestZipcode}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value == "" || re.test(e.target.value)) {
                    guestFormik.handleChange(e);
                    if (
                      e.target.value.length >= 5 &&
                      e.target.value.length <= 10
                    ) {
                      //call the api function and get the city state and country vals
                      getGeoCodeValues(e.target.value);
                    }
                  }
                }}
                onBlur={guestFormik.handleBlur}
                label="Zip"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                fullWidth
                disabled={addressCheck}
                onChange={(eve, val) => {
                  if (val !== guestFormik.values.guestCountry)
                    guestFormik.setFieldValue("guestState", null);
                  guestFormik.setFieldValue("guestCountry", val);
                }}
                options={Object.keys(countries)}
                value={guestFormik.values.guestCountry}
                renderInput={(params) => {
                  params.inputProps.autoComplete = "new-password";
                  return <TextField {...params} label="Country" />;
                }}
              ></Autocomplete>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                name="guestCity"
                id="guestCity"
                fullWidth
                type="text"
                disabled={addressCheck}
                placeholder="City"
                value={guestFormik.values.guestCity}
                onChange={guestFormik.handleChange}
                onBlur={guestFormik.handleBlur}
                label="City"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                fullWidth
                disabled={addressCheck}
                renderInput={(params) => {
                  params.inputProps.autoComplete = "new-password";
                  return <TextField {...params} label="State" />;
                }}
                onChange={(eve, val) => {
                  guestFormik.setFieldValue("guestState", val);
                }}
                options={
                  isNull(guestFormik.values.guestCountry) ||
                  isUndefined(countries[guestFormik.values.guestCountry])
                    ? []
                    : countries[guestFormik.values.guestCountry]
                }
                value={guestFormik.values.guestState}
              ></Autocomplete>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Autocomplete
                fullWidth
                renderInput={(params) => {
                  params.inputProps.autoComplete = "new-password";
                  return <TextField {...params} label="Nationality" />;
                }}
                onChange={(eve, val) => {
                  guestFormik.setFieldValue("guestNationality", val);
                }}
                options={nationalities}
                // options={
                //   isNull(formik.values.nationality) ||
                //   isUndefined(countries[formik.values.country])
                //     ? []
                //     : countries[formik.values.country]
                // }
                value={guestFormik.values.guestNationality}
              ></Autocomplete>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                id="guestOccupation"
                label="Occupation"
                name="guestOccupation"
                type={"text"}
                value={guestFormik.values.guestOccupation}
                onChange={guestFormik.handleChange}
                onBlur={guestFormik.handleBlur}
                fullWidth
              />
            </Grid>
            {JSON.parse(
              props?.data?.accessControl?.user_feature_map
                ?.IshaExtraGuestDetails
            ).read === "YES" &&
              (checkIshaAccess(props?.data?.emailId) ||
                checkIshaUSAccess(props?.data?.emailId)) && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Programs Attended
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="guestPrograms"
                        label="Programs attended"
                        value={selectedPrograms}
                        onChange={handleChange}
                        required="true"
                        multiple
                        style={{ width: "16.5rem" }}
                        // MenuProps={MenuProps}
                        placeholder="Choose Programs Attended"
                      >
                        {ishaProgramsAttended?.length > 0 &&
                          ishaProgramsAttended.map((program, index) => {
                            return (
                              <MenuItem key={program} value={program}>
                                {program}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      select
                      id="guestPrimaryRelation"
                      label="Relationship With Primary"
                      name="guestPrimaryRelation"
                      value={guestFormik.values.guestPrimaryRelation}
                      onChange={(e) => {
                        guestFormik.setFieldValue(
                          "guestPrimaryRelation",
                          e.target.value
                        );
                      }}
                      fullWidth
                    >
                      {ishaRelationWithPrimary?.length > 0 &&
                        ishaRelationWithPrimary.map((relation, index) => {
                          return (
                            <MenuItem key={relation} value={relation}>
                              {relation}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    {checkIshaAccess(props?.data?.emailId) && (
                      <TextField
                        name="guestApprovedOverseas"
                        id="guestApprovedOverseas"
                        select
                        className="position-relative"
                        fullWidth
                        type="text"
                        placeholder="Approved by overseas"
                        value={guestFormik.values.guestApprovedOverseas}
                        onChange={guestFormik.handleChange}
                        onBlur={guestFormik.handleBlur}
                        label="Approved by overseas"
                        variant="outlined"
                      >
                        {ishaApprovedByOverseas?.length > 0 &&
                          ishaApprovedByOverseas.map((purpose, index) => {
                            return (
                              <MenuItem key={purpose} value={purpose}>
                                {purpose}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Estimated time of arrival"
                        value={ETA}
                        onChange={handleETAChange}
                        InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Estimated time of departure"
                        value={ETD}
                        onChange={handleETDChange}
                        InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    {checkIshaAccess(props?.data?.emailId) && (
                      <TextField
                        select
                        className="position-relative"
                        name="ocoCheckinComplete"
                        id="ocoCheckinComplete"
                        fullWidth
                        placeholder="OCO checkin complete"
                        value={guestFormik.values.guestOCOCheckin}
                        onChange={(e) => {
                          guestFormik.setFieldValue(
                            "guestOCOCheckin",
                            e.target.value
                          );
                        }}
                        label="OCO checkin complete"
                        variant="outlined"
                      >
                        <MenuItem key={"Yes"} value={"Yes"}>
                          {"Yes"}
                        </MenuItem>
                        <MenuItem key={"No"} value={"No"}>
                          {"No"}
                        </MenuItem>
                      </TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      for={"guest" + props?.dataIndex + "document1"}
                      variant="custom-button"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        hiddenFileInput1.current.click();
                      }}
                    >
                      Upload Document 1
                      <input
                        style={{
                          color: document1 !== "" ? "#20c997" : "black",
                          display: "none",
                        }}
                        id={"guest" + props?.dataIndex + "document1"}
                        type="file"
                        className="form-control"
                        accept=".pdf, .jpg, .jpeg, .png"
                        name={"guest" + props?.dataIndex + "document1"}
                        ref={hiddenFileInput1}
                        onChange={(eve) => {
                          let imageName = document.getElementById(
                            "guest" + props?.dataIndex + "document1Name"
                          );
                          let inputImage = eve.target.files[0];
                          imageName.innerText = inputImage.name;
                          convertToBase64(eve);
                        }}
                      />
                      <br />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                    <Button
                      for={"guest" + props?.dataIndex + "document2"}
                      variant="custom-button"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        hiddenFileInput2.current.click();
                      }}
                    >
                      Upload Document 2
                      <input
                        style={{
                          color: document2 !== "" ? "#20c997" : "black",
                          display: "none",
                        }}
                        id={"guest" + props?.dataIndex + "document2"}
                        type="file"
                        className="form-control"
                        accept=".pdf, .jpg, .jpeg, .png"
                        name={"guest" + props?.dataIndex + "document2"}
                        ref={hiddenFileInput2}
                        onChange={(eve) => {
                          let imageName = document.getElementById(
                            "guest" + props?.dataIndex + "document2Name"
                          );
                          let inputImage = eve.target.files[0];
                          imageName.innerText = inputImage.name;
                          convertToBase64(eve);
                        }}
                      />
                      <br />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                    <span
                      id={"guest" + props?.dataIndex + "document1Name"}
                      style={{ color: "green", fontSize: "small" }}
                    ></span>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                    <span
                      id={"guest" + props?.dataIndex + "document2Name"}
                      style={{ color: "green", fontSize: "small" }}
                    ></span>
                  </Grid>
                </>
              )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Fab
        color="primary"
        variant="extended"
        type="submit"
        style={{ margin: "30px" }}
      >
        Save Guest Detail
      </Fab>
    </form>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(NewGuestView);

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
